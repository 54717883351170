import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBody from '../../ui/Modal/ModalBody/ModalBody';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions';
import IAppState from '../../../interfaces/store/IAppState';
import IInterpretationState from '../../../interfaces/store/IInterpretationState';
import Spinner from '../../ui/Spinner/Spinner';
import Alert from '../../ui/Alert/Alert';
import Table from '../../ui/Table/Table';
import Button, { EButtonSize } from '../../ui/Button/Button';
import BadgeList from '../../ui/BadgeList/BadgeList';
import { useAttributeOptions } from '../../../hooks/useAttributeOptions';
import { ECategoryType } from '../../../shared/category-data';
import { IOption } from '../../ui/Input/Input';
import { ETranslation } from '../../../translations/translation-keys';

interface IProps {
	onAdd: (id: string) => void;
	// onAdd: (attribute: IAttribute) => void;
}


const InterpretationUserAvailabilityModal: React.FC<IProps> = ({ onAdd }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { attributeOptions } = useAttributeOptions<IOption>([ECategoryType.INTERPRETATION_SUBTYPE, ECategoryType.INTERPRETATION_SEARCH ]);

	const { userAvailabilities, userAvailabilitiesError, userAvailabilitiesLoading, interpretation, updateLoading, loading } = useSelector<IAppState, IInterpretationState>(state => state.interpretation);

	const id = interpretation?.id;

	const existingIds = interpretation?.interpretationUserRows?.map(userRow => userRow.user.id) ?? [];

	useEffect(() => {
		if (id) {
			dispatch(actions.getAvailableInterpreters(id));
		}
	}, [id, dispatch]);

	return (
		<>
			<ModalBody>
				{userAvailabilitiesError && <Alert>{userAvailabilitiesError}</Alert>}
				{userAvailabilitiesLoading || updateLoading || loading  ? <Spinner /> : (userAvailabilities && userAvailabilities.length > 0) ? <Table condensed>
						<tbody>
							<tr>
								<th colSpan={2}>{t(ETranslation.COMMON_INTERPRETER)}</th>
							</tr>
						</tbody>
						<tbody>
							{userAvailabilities?.filter(availability => !existingIds.includes(availability.user.id)).map(availability => {
								const { isAvailable, user, reason } = availability;
								const { id, attributes, name } = user;
								return (
								<tr key={id}>
									<td style={{ textDecoration: isAvailable ? 'none' : 'line-through', padding: '.5rem 0' }}>
										<b>{name}</b><br/>
										{attributes && <BadgeList items={attributes.map(attr => ({...attr, name: attributeOptions.find(option => option.value === attr.id)?.label ?? ''})).filter(attr => attr.name.length > 0)} /> }
									</td>
									<td style={{ width: 1 }}>
										{isAvailable && <Button size={EButtonSize.X_SMALL} onClick={() => onAdd(id)}>{t(ETranslation.COMMON_ADD_NEW)}</Button>}
										{reason}
									</td>
								</tr>
							)})}
						</tbody>
					</Table> : <p>{t(ETranslation.INTREPRETATION_NO_WORKER_TO_SELECT)}</p>}
			</ModalBody>
		</>
	);
};

export default InterpretationUserAvailabilityModal;
