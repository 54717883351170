import React from "react";
import { ISummary } from "../../../store/actions/summariesActions";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import { EInterpreterFeeType } from "../../../shared/interpretation-data";
import { IOrganization } from "../../../interfaces/IOrganization";
import OrganizationInfoBox from "../../Organization/OrganizationInfoBox/OrganizationInfoBox";

interface IProps {
	summary: ISummary;
}

const SummaryHeader: React.FC<IProps> = ({ summary }) => {
	const { t } = useTranslation();
	return (
		<table>
			<tbody>
				<tr>
					<td>{summary.organizationYoupret.name}</td>
					<td>{summary.created}</td>
				</tr>
				<tr>
					<td>{summary.organizationYoupret.streetName}</td>
				</tr>
				<tr>
					<td>
						{summary.organizationYoupret.zip} {summary.organizationYoupret.city}
					</td>
				</tr>
				<tr>
					<td>
						{t(ETranslation.COMMON_COMPANY_BUSINESSID)} {summary.organizationYoupret.businessId}
					</td>
				</tr>
				<tr>
					<td>
						<b>
							{summary.user.name}
							{(summary.user.feeType === EInterpreterFeeType.FEE_TYPE_EEZY ||
								summary.user.feeType === EInterpreterFeeType.FEE_TYPE_TRUSTER) &&
							summary.user.socialSecurityNumber
								? ` (${summary.user.socialSecurityNumber})`
								: ""}
						</b>
						<br />
						{summary.user.organizations &&
							summary.user.organizations.map((organization: IOrganization, key) => {
								return <OrganizationInfoBox key={key} organization={organization} />;
							})}
					</td>
				</tr>
			</tbody>
		</table>
	);
};

export default SummaryHeader;
