import React from "react";
import { ILead } from "../../../interfaces/ILead";
import { useMailTemplateSelectionModal } from "../../Sendgrid/MailTemplateSelection/useMailTemplateSelectionModal";
import Button from "../../ui/Button/Button";
import { useDispatch } from "react-redux";
import { updateSentMails } from "../../../store/actions/leadsActions";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IProps {
	leads: ILead[];
}

const LeadsSendMailButton: React.FC<IProps> = ({ leads }) => {
	const { t } = useTranslation()
	const openModal = useMailTemplateSelectionModal(leads);
	const dispatch = useDispatch();
	const sendMails = () => {
		openModal(leads).then((result) => {
			dispatch(updateSentMails(result.items, result.template))
		})
	}
	return <Button onClick={sendMails}>{t(ETranslation.COMMON_SEND_MAIL)}</Button>
}

export default LeadsSendMailButton;