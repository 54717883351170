import React, { useContext, useEffect, useState } from "react";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import TasksList from "../../components/Tasks/TasksList";
import { useDispatch } from "react-redux";
import { ITask, getTasks, saveTask } from "../../store/actions/tasksActions";
import Button from "../../components/ui/Button/Button";
import ModalContext, { EModalSize } from "../../components/ui/Modal/ModalContext";
import Task from "../../components/Tasks/Task";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import SearchTasks from "../../components/Tasks/SearchTasks";
import { RouteComponentProps } from "react-router-dom";
import { ERoute } from "../../classes/Routes";
import { TasksPagination } from "../../hooks/useTasks";
import Spinner from "../../components/ui/Spinner/Spinner";

interface IProps extends RouteComponentProps {
	task: ITask | null;
}

const TasksPage: React.FC<IProps> = ({ task, history }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const {
		tasks,
		error,
		taskItems,
		loading,
		searchHandler,
		clearTasks,
		loaderRef,
		hasNext
	} = TasksPagination();

	useEffect(() => {
		searchHandler({page: 1});
		return(()=>{
			clearTasks();
		})

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	const loaderEl = <p ref={loaderRef} style={{ color: hasNext ? "#ccc" : "#fff" }}>{hasNext ? t(ETranslation.COMMON_SHOW_MORE_ROWS) : ""}</p>;

	const { setModal, closeModal } = useContext(ModalContext);

	const [selectedTask, setSelectedTask] = useState<ITask | null>(null);


	const openTaskModal = () => {
		setModal({
			title: "",
			content: <Task
				saveTask={saveTaskCb}
				removeTaskItem={(id) => {
					const taskToAlter = selectedTask;
					taskToAlter?.taskItems.splice(id, 1);
					setSelectedTask(taskToAlter);
				}}
			/>,
			isOpen: true,
			size: EModalSize.MEDIUM,
			onModalClose: () => {
				closeModal();
				setSelectedTask(null);
			}
		})
	}

	const newTask = () => {
		setSelectedTask(null);
		openTaskModal();
	}

	const saveTaskCb = (task: ITask) => {
		dispatch(saveTask(task))
		closeModal()
		history.push(ERoute.TASKS_LIST);
		dispatch(getTasks());
	}

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_TASKS)}</Heading><Button onClick={newTask}>{t(ETranslation.TASKS_ORDER_TASK)}</Button>
			<hr />
			<SearchTasks />
			<TasksList tasks={tasks} taskItems={taskItems} error={error} loading={loading} />
			{loading ? <Spinner /> : loaderEl}
		</Container>
	)
}

export default TasksPage;