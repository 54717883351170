import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import { ETranslation } from "../../translations/translation-keys";
import { RouteComponentProps } from "react-router-dom";
import CommentsSearch from "./CommentsSearch";
import CommentsList from "./CommentsList";
import { getCommentsClear } from "../../store/actions/commentActions";
import { useDispatch } from "react-redux";


interface IProps extends RouteComponentProps { }

const CommentsPage: React.FC<IProps> = ({ history }) => {

	const { t } = useTranslation();

	// Cleanup
	const dispatch = useDispatch();
	useEffect(() => {
		return () => { dispatch(getCommentsClear()) }
	}, [dispatch])

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_COMMENTS)}</Heading>
			<CommentsSearch />
			<CommentsList />
		</Container>
	)
}

export default CommentsPage;