import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "../../components/ui/Container/Container";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { EezyTrusterPagination, PAGINATION_FETCH_SIZE } from "../../hooks/useEezyTrusterEvents";
import Spinner from "../../components/ui/Spinner/Spinner";
import EezyTrusterEventsList from "../../components/Eezy/EezyTrusterEvents/EezyTrusterEventsList";
import { ETranslation } from "../../translations/translation-keys";
import Heading from "../../components/Heading/Heading";
import { getEventsClear } from "../../store/actions";
import Alert, { EAlertColor } from "../../components/ui/Alert/Alert";
import { ERoute, Routes } from "../../classes/Routes";

interface IMatch { }

interface IProps extends RouteComponentProps<IMatch> { }

const EezyTrusterEventsPage: React.FC<IProps> = ({ history, match }) => {

	const { events, eventsLoading, error, searchHandler, loaderRef, hasMoreEvents } = EezyTrusterPagination()
	const dispatch = useDispatch();
	useEffect(() => {
		searchHandler({ start: 0, fetchSize: PAGINATION_FETCH_SIZE })
		return () => {
			dispatch(getEventsClear())
		}
	}, [dispatch, searchHandler])

	const { t } = useTranslation();

	const onRowClick = (id: string) =>{
		history.push(Routes.withParams(ERoute.EEZY_TRUSTER_EVENT, { id }));
	}

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_EEZY_TRUSTER_EVENTS)}</Heading>
			{error && <Alert color={EAlertColor.WARNING}>{error}</Alert>}
			{events && <EezyTrusterEventsList events={events} onClick={onRowClick} />}
			{eventsLoading && <Spinner />}
			<p
				ref={loaderRef}
				style={{ color: hasMoreEvents ? "#ccc" : "#fff" }}
			>
				{hasMoreEvents ? t(ETranslation.COMMON_SHOW_MORE_ROWS) : ""}
			</p>
		</Container>
	);
};

export default EezyTrusterEventsPage;
