import React, { useEffect, useState } from "react";
import { useCreateInput } from "../../hooks/useCreateInput";
import { ICostCenter, IOrganization, IOrganizationInputData } from "../../interfaces/IOrganization";
import { ETranslation } from "../../translations/translation-keys";
import { getInputData, initForm, validateInputs } from "../ui/Input/input-utils";
import { useTranslation } from "react-i18next";
import Button from "../ui/Button/Button";
import OrganizationCostCenters from "./OrganizationCostCenter";
import { getUserOptions, searchInterpreters } from "../../services/userServices";
import ITagElement from "./ITagElement";
import Accordion from "../ui/Accordion/Accordion";
import { useInterpretation } from "../../hooks/useInterpretation";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import { useOrganizationEditInputs } from "./OrganizationEdit/OrganizationEditInputs/OrganizationEditInputs";
import { useInitSearchValues } from "../../hooks/useInitSearchValues";
import { getOrganizationGroupOptions, searchOrganizationGroups } from "../../services/organizationGroupService";
import * as actions from '../../store/actions';
import { ITag } from "../../store/actions/tagsActions";
import EditButtons from "../ui/EditButtons/EditButtons";
import InputGroup from "../ui/InputGroup/InputGroup";
import { durationsToOptions } from "./durations-data";
import SiteOnlyContent from "../ui/SiteOnlyContent/SiteOnlyContent";
import YoupretOnlyContent from "../ui/SiteOnlyContent/YoupretOnlyContent";
import { ESitePrefix } from "../../interfaces/ISite";
import SalaryInfoAttach from "../SalaryInfo/SalaryInfoAttach";
import { useSalaryInfoAttach } from "../SalaryInfo/useSalaryInfoAttach";

interface IProps {
	saveOrganization: (organization: IOrganization) => void;
	organization: IOrganization;
	navigateToList: () => void;
}

const OrganizationEdit: React.FC<IProps> = ({ saveOrganization, organization, navigateToList }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { salaryInfos, setSalaryInfos, salaryInfosAddHandler, salaryInfosDeleteHandler, salaryInfosEditHandler } = useSalaryInfoAttach();

	const { deleteError, deleteLoading, deleteOk, } = useSelector((state: IAppState) => ({
		currentUser: state.auth.user,
		deleteLoading: state.organization.organizationDeleteLoading,
		deleteError: state.organization.organizationDeleteError,
		deleteOk: state.organization.organizationDeleteOk,
	}));

	const [organizationRole, setOrganizationRole] = useState(organization.type);
	const [editableCostCenter, setEditableCostCenter] = useState<ICostCenter[]>(organization.costCenters ?? []);
	const [organizationTags, setOrganizationTags] = useState<ITag[]>(organization.tags ?? []); // organization.tags
	const { getSiteInterpretationTypes } = useInterpretation();

	const { inputs, setInputs, EInputs } = useOrganizationEditInputs(organization);
	const createInput = useCreateInput(inputs, setInputs, { showValidation: true, labelStyles: { fontWeight: 'bold' } });

	const { setId: setOrganizationGroupId, isLoading: isLoadingOrganizationGroups } = useInitSearchValues(setInputs, EInputs.organizationGroup, getOrganizationGroupOptions);
	const { setIds: setUserIds, isLoading: isLoadingUsers } = useInitSearchValues(setInputs, EInputs.blockedUsers, getUserOptions);

	useEffect(() => {
		initForm(setInputs, {
			...organization,
			organizationGroup: [],
			blockedUsers: [],
			organizationDurations: durationsToOptions(organization.organizationDurations),
			vatRegistered: organization.vatRegistered === "true" ? "YES" : organization.vatRegistered, // oi miksi???
		});
		if (organization.organizationGroup) setOrganizationGroupId(organization.organizationGroup.id);
		if (organization.blockedUsers) setUserIds(organization.blockedUsers.map((user) => user.id));
		if (organization.salaryInfos) setSalaryInfos(organization.salaryInfos ?? []);
	}, [organization, setInputs, setOrganizationGroupId, setUserIds, setSalaryInfos])

	useEffect(() => {
		const { type } = getInputData<IOrganization>(inputs);
		setOrganizationRole(type)
	}, [inputs.type, inputs])

	const testSave = () => {
		if (!validateInputs(inputs)) return;
		const inputData = getInputData<IOrganizationInputData>(inputs);
		const data: IOrganization = {
			...inputData,
			tags: organizationTags,
			costCenters: editableCostCenter,
			salaryInfos,
		}
		saveOrganization(data);
	}

	useEffect(() => {
		if (!organization.costCenters) return;
		setEditableCostCenter(organization.costCenters);
	}, [organization]);

	const addEditableCostCenter = () => {
		setEditableCostCenter([...editableCostCenter, {
			id: new Date().getTime().toString(),
			name: "",
			descriptionText: "",
		}]);
	};

	const deleteHandler = () => {
		if (organization) {
			dispatch(actions.deleteOrganization(organization.id));
		}
	};

	return (
		<>
			<Accordion openDefault={true} title={t(ETranslation.USER_ACCORDION_BASIC)}>
				{createInput(EInputs.name)}
				{createInput(EInputs.type)}
				{createInput(EInputs.organizationGroup, { fetchOptions: searchOrganizationGroups, isLoading: isLoadingOrganizationGroups })}
				{createInput(EInputs.description)}
				{createInput(EInputs.businessId)}
				{createInput(EInputs.industryCode)}
				<p>
					{t(ETranslation.ORGANIZATION_COMPANY_SEARCH)}
					<a href="https://tietopalvelu.ytj.fi/">{t(ETranslation.ORGANIZATION_COMPANY_TIETOPALVELU)} </a>
					{t(ETranslation.ORGANIZATION_COMPANY_SEARCH_COMPANIES)}
				</p>
				{createInput(EInputs.www)}
				{createInput(EInputs.phoneNumber)}
				{createInput(EInputs.email)}
				{createInput(EInputs.bookingEmail)}
				<ITagElement
					tags={organization?.tags ?? []}
					onUpdate={(tags) => {
						setOrganizationTags(tags);
					}}
				/>
				{organizationRole === "CUSTOMER" && (
					<>
						{createInput(EInputs.vatRegistered)}
						{createInput(EInputs.defaultOrganizationDuration)}
						{createInput(EInputs.organizationDurations)}
						{createInput(EInputs.defaultInterpretationType, {
							options: getSiteInterpretationTypes(),
						})}
						<SiteOnlyContent sites={[ESitePrefix.valitysklinikka]}>
							{createInput(EInputs.customerApprovalRequired)}
						</SiteOnlyContent>
						{createInput(EInputs.costCenterMandatory)}
						<SiteOnlyContent sites={[ESitePrefix.valitysklinikka]}>
							{createInput(EInputs.customerReferenceHidden)}
						</SiteOnlyContent>
						{createInput(EInputs.customerReferenceMandatory)}
						{createInput(EInputs.customerReferenceMandatoryInReview)}
						{createInput(EInputs.customerReference2Mandatory)}
						{createInput(EInputs.customerReference2MandatoryInReview)}
						<YoupretOnlyContent>
							{createInput(EInputs.onlySecurityCleared)}
							{createInput(EInputs.allowAttachments)}
							{createInput(EInputs.allowAutoReminders)}
							{createInput(EInputs.showLanguageAvailability)}
						</YoupretOnlyContent>
					</>
				)}
			</Accordion>
			<SiteOnlyContent sites={[ESitePrefix.valitysklinikka]}>
				{organizationRole === "CUSTOMER" && (
					<Accordion title={"Palkka"}>
						<SalaryInfoAttach salaryInfos={salaryInfos} onAdd={salaryInfosAddHandler} onDelete={salaryInfosDeleteHandler} onEdit={salaryInfosEditHandler} />
					</Accordion>
				)}
			</SiteOnlyContent>
			<YoupretOnlyContent>
				{organizationRole === "CUSTOMER" && (
					<Accordion title={t(ETranslation.ORGANIZATION_COMPANY_CONTACT_METHOD)}>
						{createInput(EInputs.allowInterpreterCallToCustomer)}
						{createInput(EInputs.allowCustomerCallToInterpreter)}
						{createInput(EInputs.allowContactCallToInterpreter)}
						{createInput(EInputs.allowInterpreterCallToContact)}
						{createInput(EInputs.allowVideoConference)}
						{createInput(EInputs.allowMaskedNumber)}
						{createInput(EInputs.allowConnectionTypeSelectForInstant)}
					</Accordion>
				)}
			</YoupretOnlyContent>
			<Accordion title={t(ETranslation.USER_ACCORDION_LOCATION)}>
				{createInput(EInputs.streetName)}
				<InputGroup>
					{createInput(EInputs.zip)} {createInput(EInputs.city)}
				</InputGroup>
				{createInput(EInputs.country)}
			</Accordion>
			<YoupretOnlyContent>
				{organizationRole === "CUSTOMER" && (
					<>
						<Accordion title={t(ETranslation.ORGANIZATION_COMPANY_PRICE_INFORMATION)}>
							{createInput(EInputs.priceWithoutVatPerMinuteForInstant)}
							{createInput(EInputs.minBillingPeriodInMinutesForInstant)}
							{createInput(EInputs.priceWithoutVatPerMinuteForInstantExtended)}
							{createInput(EInputs.priceWithoutVatPerMinuteForBooking)}
							{createInput(EInputs.minBillingPeriodInMinutesForBooking)}
							{createInput(EInputs.priceWithoutVatPerMinuteForBookingExtended)}
							{createInput(EInputs.priceWithoutVatPerMinuteForOnSite)}
							{createInput(EInputs.minBillingPeriodInMinutesForOnSite)}
							{createInput(EInputs.priceWithoutVatPerMinuteForOnSiteExtended)}
							{createInput(EInputs.termsOfCancelTypeBooking)}
							{createInput(EInputs.priceVatP)}
						</Accordion>
						<Accordion title="Laskutus ja maksutiedot">
							{createInput(EInputs.customerReferenceTitle)}
							{createInput(EInputs.customerReferenceInfoText)}
							{createInput(EInputs.customerReference2Title)}
							{createInput(EInputs.customerReference2InfoText)}
							{createInput(EInputs.customerNotesTitle)}
							{createInput(EInputs.customerNotesInfoText)}
							{createInput(EInputs.customerNotesMessageTitle)}
							{createInput(EInputs.customerNotesMessageInfoText)}
							{createInput(EInputs.billingChargeWithoutVat)}
							{createInput(EInputs.billingReference)}
							{createInput(EInputs.reference)}
							{createInput(EInputs.agreementIdentifier)}
							{createInput(EInputs.billingPaymentTerm)}
						</Accordion>
						<Accordion title={t(ETranslation.COMMON_PAYMENTS)}>
							{createInput(EInputs.interpreterFeeWithoutVatPerMinuteForInstant)}
							{createInput(EInputs.interpreterFeeWithoutVatPerMinuteForBooking)}
							{createInput(EInputs.interpreterFeeWithoutVatPerMinuteForOnSite)}
							{createInput(EInputs.interpreterFeeVatP)}
						</Accordion>
						<Accordion title={t(ETranslation.COMMON_BLOCKED_INTERPRETERS)}>
							{createInput(EInputs.blockedUsers, {
								fetchOptions: searchInterpreters,
								isLoading: isLoadingUsers
							})}
						</Accordion>
					</>
				)}
			</YoupretOnlyContent>
			{organizationRole === "CUSTOMER" && (
				<Accordion title={t(ETranslation.COMMON_COST_CENTERS)}>
					<Button onClick={addEditableCostCenter}>{t(ETranslation.COMMON_ADD_NEW)} </Button>
					<div>
						{editableCostCenter?.map((costCenter, i) => (
							<OrganizationCostCenters
								key={costCenter.id}
								costCenter={costCenter}
								number={i + 1}
								onDelete={() => {
									const centersToAlter = [...editableCostCenter];
									const centersForAlter = centersToAlter.filter(
										(organization) => organization !== costCenter
									);
									setEditableCostCenter(centersForAlter);
								}}
								onUpdate={(data, parentId) => {
									const newEditableCostCenter = editableCostCenter.map((organizations) => {
										if (organizations.id !== parentId) {
											return organizations;
										} else {
											return (costCenter = data);
										}
									});
									setEditableCostCenter(newEditableCostCenter);
								}}
							/>
						))}
					</div>
				</Accordion>
			)}
			<YoupretOnlyContent>
				{organizationRole === "CUSTOMER" && (
					<Accordion title={t(ETranslation.COMMON_NETVISOR)}>
						{createInput(EInputs.netvisorInvoicingCustomerIdentifier)}
						{createInput(EInputs.eInvoiceAddress)}
						{createInput(EInputs.eInvoiceOperatorId)}
					</Accordion>
				)}
			</YoupretOnlyContent>
			<EditButtons
				onSave={testSave}
				onCancel={navigateToList}
				onDelete={deleteHandler}
				deleteText={t(
					ETranslation.PAGES_ORGANIZATION_DELETE_CONFIRM
				)}
				onDeleteClose={() =>
					dispatch(actions.deleteOrganizationClear())
				}
				onDeleteDone={navigateToList}
				deleteOk={deleteOk}
				isAdd={false}
				deleteError={deleteError}
				deleteLoading={deleteLoading}
			/>
		</>
	);
};
export default OrganizationEdit;
