import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EUserRole, IUser } from "../../classes/User";
import { EFetchMethod, customFetch } from "../../custom-fetch";
import { useConfirmModal } from "../../hooks/useConfirmModal";
import { useCreateInput } from "../../hooks/useCreateInput";
import { useInterpretation } from "../../hooks/useInterpretation";
import { usePaginationNew } from "../../hooks/usePaginationNew";
import { ESitePrefix, ISite } from "../../interfaces/ISite";
import IAppState from "../../interfaces/store/IAppState";
import IUserGroupState from "../../interfaces/store/IUserGroupState";
import { searchOrganizations } from "../../services/searchServices";
import { searchInterpreters } from "../../services/userServices";
import { findReservedDates } from "../../store/actions";
import { findLogItemsClear } from "../../store/actions/logItemActions";
import { findReservedDatesClear } from "../../store/actions/reservedDateActions";
import { getTags } from "../../store/actions/tagsActions";
import { createNewNetvisorUser } from "../../store/actions/userActions";
import { ETranslation } from "../../translations/translation-keys";
import { createOptions } from "../../utils/option-utils";
import AttributesAttach from "../Attributes/AttributesAttach";
import { useAttributesAttach } from "../Attributes/useAttributesAttach";
import LogItemList from "../LogItem/LogItemList/LogItemList";
import ReservedDateUserList from "../ReservedDate/ReservedDateUserList/ReservedDateUserList";
import UserContractSettingsAttach from "../UserContractSettings/UserContractSettingsAttach/UserContractSettingsAttach";
import { useEditUserContractSettings } from "../UserContractSettings/UserContractSettingsAttach/useEditUserContractSettings";
import Accordion from "../ui/Accordion/Accordion";
import Button, { EButtonColor } from "../ui/Button/Button";
import { IOption } from "../ui/Input/Input";
import { getInputData } from "../ui/Input/input-utils";
import InputGroup from "../ui/InputGroup/InputGroup";
import SiteOnlyContent from "../ui/SiteOnlyContent/SiteOnlyContent";
import YoupretOnlyContent from "../ui/SiteOnlyContent/YoupretOnlyContent";
import Spinner from "../ui/Spinner/Spinner";
import UserEditHeader from "./UserEditHeader/UserEditHeader";
import { useUserEditInputs } from "./UserEditUtils/UserEditInputs";
import UserFavouriteWorkersList from "./UserFavouriteWorkers/UserFavouriteWorkersList";
import UserLanguageItemsList from "./UserLanguageItems/UserLanguageItemsList";
import { useCountryCodeOptions } from "../../hooks/useCountryCodeOptions";

interface IProps {
	user: IUser;
	saveUser: (user: IUser) => void;
	openComments: (user: IUser, openModalOnClose: boolean) => void;
	onCancel: () => void;
	removeUser: () => void;
	isNewUser: boolean;
	saveUserLoading: boolean;
}

/**
 * WIP, but data should be mapped correctly now
 *
 * @param param0 Full IUser object so we can show and edit user data
 * @returns React.FC element
 */
const UserEdit: React.FC<IProps> = ({ user, openComments, saveUser, onCancel, saveUserLoading, removeUser, isNewUser }) => {

	const { t } = useTranslation();
	const { attributes, attributesDeleteHandler, attributesEditHandler, initAttributes, attributesAddHandler } = useAttributesAttach();
	const { editContractSettings, editContractSettingsDeleteHandler, editContractSettingsEditHandler, setEditContractSettings } = useEditUserContractSettings();
	const [sites, setSites] = useState<IOption[]>([]);
	const { userGroups } = useSelector<IAppState, IUserGroupState>(state => state.userGroups);
	const [userLanguageItems, setUserLanguageItems] = useState(user.userLanguageItems ?? []);
	// const { userLanguagePairs, userLanguagePairChangeHandler, userLanguagePairRemoveHandler, userLanguagePairAddHandler, setUserLanguagePairs } = useUserLanguagePairs();

	const [favouriteIntepreters, setFavouriteInterpretes] = useState(user.userFavoriteInterpreters ?? []);
	const { currentUser, userToNetvisorLoading } = useSelector((state: IAppState) => ({
		currentUser: state.auth.user,
		userToNetvisorLoading: state.user.userToNetvisorLoading,
	}));
	const { countryCodeOptions } = useCountryCodeOptions();
	const { tags } = useSelector((state: IAppState) => state.tags);

	const { loaderRef, items, loading, searchHandler, hasMore } = usePaginationNew();
	const { getSiteInterpretationTypes } = useInterpretation();

	const loaderEl = <p ref={loaderRef} style={{ color: hasMore ? "#ccc" : "#fff" }}>{hasMore ? t(ETranslation.COMMON_SHOW_MORE_ROWS) : ""}</p>;

	const getSites = async () => {
		if (!currentUser?.isYoupretAdmin) return;
		const sites = await customFetch<ISite[] | string>(
			"/sites/list",
			EFetchMethod.GET,
			undefined,
		);
		if (sites === "NO_RESULTS") return [];
		const options = createOptions(sites as ISite[]);
		setSites(options);
	};
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentUser?.isYoupretAdmin) getSites();
		dispatch(getTags());
		if (!isNewUser) dispatch(findReservedDates(user.id))
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return () => {
			dispatch(findLogItemsClear());
			dispatch(findReservedDatesClear());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, user]);


	useEffect(() => {
		setEditContractSettings(user.contractSettings ?? []);
	}, [user, setEditContractSettings])

	/*
	useEffect(() => {
		setUserLanguagePairs(user.userLanguagePairs ?? []);
	}, [user, setUserLanguagePairs]);
	*/

	useEffect(() => {
		initAttributes(user.attributes);
	}, [user, initAttributes]);

	const { inputs, setInputs, EInputs } = useUserEditInputs(user);

	const saveUserData = () => {
		const data = getInputData<IUser>(inputs);
		data.userFavoriteInterpreters = favouriteIntepreters;
		data.userLanguageItems = userLanguageItems;
		// data.userLanguagePairs = userLanguagePairs;
		data.contractSettings = editContractSettings;
		data.attributes = attributes;
		for (let favInter of data.userFavoriteInterpreters) {
			if (favInter.id.length < 5) {
				favInter.id = favInter.user?.toString() ?? "";
			}
		}
		if (!user.site) {
			if (!currentUser?.isYoupretAdmin) {
				user.site = currentUser?.site;
			}
		}
		saveUser(data);
	}

	const removeUserLanguageItem = (index: number) => {
		setUserLanguageItems(userLanguageItems.filter((o, i) => index !== i));
	};

	const createInput = useCreateInput(inputs, setInputs, { labelStyles: { fontWeight: 'bold' } });

	const openConfirmModal = useConfirmModal();

	const removeUserHandler = async () => {
		const success = await openConfirmModal(t(ETranslation.USER_CONFIRM_DELETE));
		if (success) {
			removeUser();
		}
	}

	// TODO(Joonas): IUserInputs, organisaatiot esim täällä stringinä.
	const exportNetvisorHandler = async () => {
		const success = await openConfirmModal(t(ETranslation.USER_CONFIRM_NETVISOR_EXPORT));
		if (success) {
			const data = getInputData<IUser>(inputs);
			const netvisorData = {
				netvisorProductIdentifier: data.netvisorProductIdentifier,
				netvisorProductName: data.netvisorProductName,
				organizations: data.organizations.length < 1 ? "no-org" : data.organizations[0].toString(),
				netvisorCostCenter: data.netvisorDimensionName
			};
			dispatch(createNewNetvisorUser(netvisorData))
		}
	}

	return (
		<div>
			<UserEditHeader user={user} />
			<Accordion openDefault={true} title={t(ETranslation.USER_ACCORDION_BASIC)}>
				<InputGroup maxItems={2} showBorder>
					{createInput(EInputs.firstName, { showValidation: true })}
					{createInput(EInputs.lastName, { showValidation: true })}
					{createInput(EInputs.email, { showValidation: true })}
					{createInput(EInputs.phoneNumber)}
					{createInput(EInputs.password)}
					{createInput(EInputs.passwordAgain)}
					<YoupretOnlyContent>
						{createInput(EInputs.userName)}
						{createInput(EInputs.altPhoneNumber)}
						{createInput(EInputs.tags, { options: createOptions(tags ?? []) })}
					</YoupretOnlyContent>
					{createInput(EInputs.role)}
					{createInput(EInputs.emailCC)}
					{createInput(EInputs.emailBCC)}
					{user.role === "INTERPRETER" && createInput(EInputs.interpretationTypes, { options: getSiteInterpretationTypes() })}
					{(currentUser?.isYoupretAdmin && (user.role === "INTERPRETER" || user.role === "CUSTOMER")) && createInput(EInputs.userLevel)}
					{createInput(EInputs.organizations, { fetchOptions: searchOrganizations })}
					<YoupretOnlyContent>
						{createInput(EInputs.userGroupIds, { options: userGroups ? createOptions(userGroups) : [] })}
						{createInput(EInputs.site, { options: sites })}
					</YoupretOnlyContent>
				</InputGroup>
				{createInput(EInputs.description)}
				{user.role === EUserRole.CUSTOMER && createInput(EInputs.reference)}
				{/* Kommentoitu pois, koska ei oo hajua miten tämän saa laitettua laskulle, kun tämä tieto varmaan lähetetään kerralla yrityskohtaisesti ja näitä tietoja on vaan 1.
					{createInput(EInputs.billingReference)}
				*/}
			</Accordion>
			<Accordion title={t(ETranslation.RESERVED_DATE_OPTIONS)} openDefault={true}>
				<InputGroup maxItems={1} showBorder>
					{createInput(EInputs.gender)}
					<YoupretOnlyContent>
						{user.role === "INTERPRETER" && createInput(EInputs.feeType)}
						{user.role === "CUSTOMER" && createInput(EInputs.paymentMethodType)}
					</YoupretOnlyContent>
					{user.role === "INTERPRETER" && createInput(EInputs.status)}
				</InputGroup>
			</Accordion>
			{([EUserRole.INTERPRETER].includes(user.role)) && <Accordion title={"Ominaisuudet"} openDefault={true}>
				<AttributesAttach attributes={attributes} onAdd={attributesAddHandler} onDelete={attributesDeleteHandler} onEdit={attributesEditHandler} />
			</Accordion>}

			<Accordion title={t(ETranslation.USER_ACCORDION_ACCESS)}>
				<InputGroup maxItems={2} showBorder>
					{createInput(EInputs.allowLogin)}
					{createInput(EInputs.sendEmailBookingConfirmed)}
					{user.role === EUserRole.CUSTOMER && createInput(EInputs.allowFavoriteInterpreters)}
					<YoupretOnlyContent>
						{user.role === "INTERPRETER" && createInput(EInputs.allowJobOffers)}
						{createInput(EInputs.allowCMS)}
						{createInput(EInputs.showTestLanguages)}
						{createInput(EInputs.showPayments)}
						{user.role !== "INTERPRETER" && createInput(EInputs.allowBooking)}
						{createInput(EInputs.allowFastLane)}
						{createInput(EInputs.allowPhoneCall)}
						{createInput(EInputs.allowMultiDeviceSimultaneousUse)}
						{createInput(EInputs.allowVideo)}
						{createInput(EInputs.privatePerson)}
						{createInput(EInputs.allowLogs)}
						{createInput(EInputs.autoPayment)}
						{createInput(EInputs.allowInstant)}
						{createInput(EInputs.allowOnSite)}
						{createInput(EInputs.allowMachine)}
						{createInput(EInputs.allowInternetCall)}
						{createInput(EInputs.allowMarketing)}
						{createInput(EInputs.allowSummaries)}
						{createInput(EInputs.allowReport)}
						{createInput(EInputs.allowAutomaticOfferBookings)}
						{createInput(EInputs.allowFees)}
						{createInput(EInputs.allowEezy)}
						{createInput(EInputs.allowBank)}
					</YoupretOnlyContent>
					{createInput(EInputs.allowWorkingHoursDate)}
					<SiteOnlyContent sites={[ESitePrefix.valitysklinikka]}>
						{createInput(EInputs.allowWorkingHoursDateEdit)}
					</SiteOnlyContent>
					{createInput(EInputs.showOrganizationGroupInterpretations)}
					{createInput(EInputs.showOrganizationInterpretations)}
					{createInput(EInputs.phoneNumberConfirmed)}
					{createInput(EInputs.manageOrganizationUsers)}
				</InputGroup>
			</Accordion>
			<Accordion title={t(ETranslation.USER_ACCORDION_LOCATION)}>
				<InputGroup maxItems={1} showBorder>
					{createInput(EInputs.streetName)}
				</InputGroup>
				<InputGroup maxItems={2} showBorder>
					{createInput(EInputs.zip)}
					{createInput(EInputs.city)}
				</InputGroup>
				<InputGroup maxItems={1} showBorder>
					{createInput(EInputs.country,{ options: countryCodeOptions })}
				</InputGroup>
				<YoupretOnlyContent>
					<InputGroup maxItems={1} showBorder>
						{createInput(EInputs.locations)}
					</InputGroup>
				</YoupretOnlyContent>
			</Accordion>

			{user.role === EUserRole.INTERPRETER &&
				<Accordion title={t(ETranslation.USER_INTERPRETER)}>
					<InputGroup maxItems={1} showBorder>
						<YoupretOnlyContent>
							{createInput(EInputs.contractNo)}
							{createInput(EInputs.securityCleared)}
							{createInput(EInputs.isLegalInterpreter)}
							{createInput(EInputs.isInterpreterTrained)}
						</YoupretOnlyContent>
						{createInput(EInputs.socialSecurityNumber)}
						{createInput(EInputs.iban)}
						{createInput(EInputs.swift)}
						<YoupretOnlyContent>
							{createInput(EInputs.workerBankingReferenceNumber)}
							{createInput(EInputs.educationDescription)}
							{createInput(EInputs.workExperienceDescription)}
						</YoupretOnlyContent>
					</InputGroup>
				</Accordion>
			}
			<YoupretOnlyContent>
				{[EUserRole.INTERPRETER, EUserRole.ADMIN, EUserRole.CUSTOMER].includes(user.role) &&
					<Accordion title={t(ETranslation.USER_CONTRACT_SETTINGS_LIST_TITLE)}>
						<UserContractSettingsAttach editSettings={editContractSettings} onDelete={editContractSettingsDeleteHandler} onEdit={editContractSettingsEditHandler} />
					</Accordion>
				}
			</YoupretOnlyContent>
			<YoupretOnlyContent>
				{user.role === "INTERPRETER" && <Accordion title={t(ETranslation.USER_CUSTOMER_FEES)}>
					<InputGroup maxItems={1} showBorder>
						{createInput(EInputs.interpreterFeeVatP)}
						{createInput(EInputs.interpreterFeeWithoutVatPerMinuteForBooking)}
						{createInput(EInputs.interpreterFeeWithoutVatPerMinuteForInstant)}
						{createInput(EInputs.interpreterFeeWithoutVatPerMinuteForOnSite)}
						{createInput(EInputs.hideInterpreterFees)}
						{createInput(EInputs.eezySalaryPaymentRule)}
					</InputGroup>
				</Accordion>}
				{/*
					<Accordion title={t("Kieliparit")}>
						<UserLanguagePairs userLanguagePairs={userLanguagePairs} onAdd={userLanguagePairAddHandler} onRemove={userLanguagePairRemoveHandler} onChange={userLanguagePairChangeHandler} />
					</Accordion>
				*/}

				<Accordion title={t(ETranslation.USER_ACCORDION_LANGUAGES)}>
					<UserLanguageItemsList items={userLanguageItems} onUpdate={setUserLanguageItems} onDelete={removeUserLanguageItem} />
				</Accordion>
		</YoupretOnlyContent>
		{user.role === EUserRole.CUSTOMER && (
			<>
				<Accordion title={t(ETranslation.USER_ACCORDION_FAVOURITES)}>
					<UserFavouriteWorkersList favourites={favouriteIntepreters} setFavouriteInterpretes={setFavouriteInterpretes} />
				</Accordion>
				<Accordion title={t(ETranslation.USER_ACCORDION_BLOCKED)}>
					{createInput(EInputs.blockedUsers, { fetchOptions: searchInterpreters })}
				</Accordion>
			</>
		)}
		<YoupretOnlyContent>
				{currentUser?.isShowNetvisor && (
					<Accordion title={t(ETranslation.COMMON_NETVISOR)}>
						<InputGroup maxItems={1} showBorder>
							{createInput(EInputs.netvisorProductIdentifier)}
							{createInput(EInputs.netvisorProductName)}
							{createInput(EInputs.netvisorDimensionName)}
						</InputGroup>
					</Accordion>
				)}

				{!isNewUser && <Accordion title={t(ETranslation.MENU_RESERVED_DATES)}>
					<ReservedDateUserList />
				</Accordion>}
			</YoupretOnlyContent>
			<YoupretOnlyContent>
				{(!isNewUser && currentUser !== null) &&
					<Accordion title={t(ETranslation.MENU_LOG_ITEMS)}>
						<>
							{(items === null && !loading) ? <Button onClick={() => { searchHandler({ entityId: user.id }) }}>Load logs</Button> :
								<LogItemList inputless={true} logItems={items} loading={false} error={null} onClick={() => { }} user={currentUser} />
							}
							{loading ? <Spinner /> : loaderEl}
						</>
					</Accordion>}
			</YoupretOnlyContent>
			<InputGroup style={{ margin: 0}}>
				<Button onClick={saveUserData} loading={saveUserLoading}>{t(ETranslation.UI_SAVE)}</Button>
				<Button onClick={onCancel} loading={saveUserLoading} color={EButtonColor.DEFAULT} >{t(ETranslation.UI_CANCEL)}</Button>
				<YoupretOnlyContent>
					{user.id !== "addUser" &&
						<>
							<Button onClick={() => { openComments(user, true) }} color={EButtonColor.PRIMARY}>{t(ETranslation.USER_SHOW_COMMENTS)} </Button>
							{currentUser?.isShowNetvisor && (
								<Button onClick={exportNetvisorHandler} color={EButtonColor.PRIMARY} loading={userToNetvisorLoading} >{t(ETranslation.COMMON_MOVE_TO_NETVISOR)}</Button>
							)}
							<Button onClick={removeUserHandler} color={EButtonColor.DANGER} loading={saveUserLoading}>{t(ETranslation.UI_DELETE)}</Button>
						</>
					}
				</YoupretOnlyContent>
			</InputGroup>

		</div >
	)
}

export default UserEdit;