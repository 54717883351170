import React, { useState } from "react";
import { IInterpretation } from "../../../interfaces/IInterpretation";
import { useInterpretation } from "../../../hooks/useInterpretation";
import { EInterpretationStatus, EInterpretationType } from "../../../shared/interpretation-data";
import { useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import { ETranslation } from "../../../translations/translation-keys";
import * as interpretationUtils from '../../../utils/interpretation-utils';
import Button from "../../ui/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faExclamationTriangle, faUser } from "@fortawesome/free-solid-svg-icons";
import InterpretationStatus from "../InterpretationStatus/InterpretationStatus";
import classes from "./InterpretationsList.module.scss";
import InterpretationInfoBox from "./InterpretationInfoBox";
import { adminUiUrl } from "../../../config";
import { useTranslation } from "react-i18next";
import { useLanguageName } from "../../../hooks/useLanguageName";


interface IInterpretationListItemProps {
	interpretation: IInterpretation;
}

const IntrepretationListItem: React.FC<IInterpretationListItemProps> = ({ interpretation }) => {
	const { t } = useTranslation();
	const [interpretationInfo, setInterpretationInfo] = useState<string[]>([]);
	const { getInterpretationTypeString } = useInterpretation();

	const { user } = useSelector((state: IAppState) => ({
		user: state.auth.user,
	}));
	const fromLanguage = useLanguageName(interpretation.fromLanguage);
	const toLanguage = useLanguageName(interpretation.toLanguage);

	let marked = (interpretation.status === EInterpretationStatus.CUSTOMER_CANCEL &&
		(interpretation.type === EInterpretationType.BOOKING || interpretation.type === EInterpretationType.ON_SITE) &&
		(interpretation.price > 0 || (interpretation.interpreterFee && interpretation.interpreterFee > 0)))
		||
		(interpretation.adminLog1 && interpretation.adminLog1.length > 1);

	let badRev = false;
	if ((!interpretation.reclamationText || interpretation.reclamationText.length < 1) && interpretation.interpretationReviews) {
		for (let review of interpretation.interpretationReviews) {
			if (review.rating <= 2 && review.userRole === "CUSTOMER") {
				badRev = true;
			}
		}
	}
	// Nollatut veloitukselliset, merkkaus pois
	if (marked && interpretation.adminNotesText?.indexOf("Lasku ja palkkio")) marked = false;

	const openHandler = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		id?: string
	) => {
		event.stopPropagation();
		if (!id) return;
		const url = `${adminUiUrl}/interpretations/${id}`;
		window.open(url, "_blank", "noopener, noreferrer");
	};

	return (
		user &&
		<React.Fragment key={interpretation.id}>
			<tr
				onClick={() => {
					interpretationInfo.indexOf(interpretation.id) > -1
						?
						setInterpretationInfo(interpretationInfo.filter(id => id !== interpretation.id))
						:
						setInterpretationInfo([...interpretationInfo, interpretation.id])
				}}
				className={user?.isYoupretAdmin ? marked ? classes.markedRow : badRev ? classes.badReview : "" : ""}
			>
				<td colSpan={2} className={classes.badgeContainer}>
					<div className={classes.badgeTdFlex}>
						<div>
							{interpretation.status ? <InterpretationStatus status={interpretation.status} /> : ""}
							<div className={classes.typeString}>
								{getInterpretationTypeString(t, interpretation.type)}
								{(interpretation.adminLog1 && interpretation.adminLog1.length > 1) && <FontAwesomeIcon color={"red"} icon={faExclamationTriangle} />}
							</div>
							{interpretation.subtypes ? interpretation.subtypes.map(type => type.name).join(', ') : ''}
						</div>
						<div>
							{(interpretation.interpretationUserRows && interpretation.interpretationUserRows.filter((i) => i.type === "INTERPRETER" || i.type === "INTERPRETER_CANDIDATE").length > 0) ?
								<div style={{ fontSize: "16px" }}>
									<FontAwesomeIcon icon={faUser} />
									{interpretation.interpretationUserRows.filter((i) => i.type === "INTERPRETER" || i.type === "INTERPRETER_CANDIDATE").length}
								</div> : ""
							}
						</div>
						<div>
							{interpretation.emailMessages ? <div style={{ fontSize: "16px" }}><FontAwesomeIcon icon={faEnvelope} /> {interpretation.emailMessages.length} </div> : ""}
						</div>
					</div>
				</td>
				<td>{interpretation.createdDateTime}</td>
				<td>{t(ETranslation.COMMON_DURATION)} <b>{interpretationUtils.formatDuration(t, interpretation.type, interpretation.durationInMinutes)}</b><br />{t(ETranslation.COMMON_APPOINTMENT)}: <b>{interpretationUtils.formatDuration(t, interpretation.type, interpretation.bookingInMinutes)}</b></td>
				{user?.isYoupretAdmin && <td>
					{fromLanguage.languageName} - {toLanguage.languageName}
				</td>}
				<td>{interpretationUtils.formatDate(interpretation)}</td>
				<td className={interpretation.interpreter ? "" : classes.missingInterpreter}>{interpretation.interpreter ? `${interpretation.interpreter?.firstName} ${interpretation.interpreter?.lastName}` : t(ETranslation.INTERPRETATION_NO_WORKER)}</td>
				<td className={interpretation.customer ? "" : classes.missingInterpreter}>{interpretation.customer ? `${interpretation.customer?.firstName} ${interpretation.customer?.lastName}` : t(ETranslation.INTERPRETATION_NO_CUSTOMER)}</td>
				{user?.isYoupretAdmin &&
					<>
						<td title={interpretation.interpreterInfoText} className={classes.adminNotesText} colSpan={1}>{interpretation.interpreterInfoText}</td>
						<td title={interpretation.adminNotesText} className={classes.adminNotesText} colSpan={1}>{interpretation.adminNotesText}</td>
					</>
				}
				{user?.role === "ADMIN" &&
					<td>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<Button className={classes.openButton} onClick={(e) => { e.stopPropagation(); window.open("/interpretations/" + interpretation.id, "_blank", "noopener noreferrer") }}>{t(ETranslation.COMMON_OPEN_INFO)}</Button>
							{user?.isYoupretAdmin &&
								<Button className={classes.openButton} onClick={(e) => openHandler(e, interpretation.id)}>old admin</Button>
							}
						</div>
					</td>
				}
			</tr>
			{interpretationInfo.indexOf(interpretation.id) > -1 ? <tr className={classes.infoRow}>
				<td colSpan={user?.isYoupretAdmin ? 11 : 8}><InterpretationInfoBox interpretation={interpretation} user={user} /></td>
			</tr> : null}
		</React.Fragment>
	)
}

export default IntrepretationListItem;