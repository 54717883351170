import { useState } from "react";
import { IOrganization } from "../../../../interfaces/IOrganization";
import { ECommonValue, EInputType, IInputField } from "../../../ui/Input/Input";
import { ETranslation } from "../../../../translations/translation-keys";
import { DURATIONS } from "../../durations-data";
import { PRICE_SEASON_RULE_TERMS_OF_CANCEL } from "../../../../shared/price-season-data";

export const useOrganizationEditInputs = (organization: IOrganization) => {

	const EInputs = {
		id: "id",
		name: "name",
		type: "type",
		organizationGroup: "organizationGroup",
		description: "description",
		businessId: "businessId",
		industryCode: "industryCode",
		www: "www",
		phoneNumber: "phoneNumber",
		email: "email",
		bookingEmail: "bookingEmail",
		tags: "tags",
		vatRegistered: "vatRegistered",
		organizationDurations: "organizationDurations",
		organizationDefaultDurations: "organizationDefaultDurations",
		defaultOrganizationDuration: "defaultOrganizationDuration",
		defaultInterpretationType: "defaultInterpretationType",
		customerApprovalRequired: "customerApprovalRequired",
		costCenterMandatory: "costCenterMandatory",
		customerReferenceHidden: "customerReferenceHidden",
		customerReferenceMandatory: "customerReferenceMandatory",
		customerReferenceMandatoryInReview: "customerReferenceMandatoryInReview",
		customerReference2Mandatory: "customerReference2Mandatory",
		customerReference2MandatoryInReview: "customerReference2MandatoryInReview",
		onlySecurityCleared: "onlySecurityCleared",
		allowAttachments: "allowAttachments",
		allowAutoReminders: "allowAutoReminders",
		showLanguageAvailability: "showLanguageAvailability",
		allowInterpreterCallToCustomer: "allowInterpreterCallToCustomer",
		allowCustomerCallToInterpreter: "allowCustomerCallToInterpreter",
		allowContactCallToInterpreter: "allowContactCallToInterpreter",
		allowInterpreterCallToContact: "allowInterpreterCallToContact",
		allowVideoConference: "allowVideoConference",
		allowMaskedNumber: "allowMaskedNumber",
		allowConnectionTypeSelectForInstant: "allowConnectionTypeSelectForInstant",
		streetName: "streetName",
		zip: "zip",
		city: "city",
		country: "country",
		priceWithoutVatPerMinuteForInstant: "priceWithoutVatPerMinuteForInstant",
		minBillingPeriodInMinutesForInstant: "minBillingPeriodInMinutesForInstant",
		priceWithoutVatPerMinuteForInstantExtended: "priceWithoutVatPerMinuteForInstantExtended",
		priceWithoutVatPerMinuteForBooking: "priceWithoutVatPerMinuteForBooking",
		minBillingPeriodInMinutesForBooking: "minBillingPeriodInMinutesForBooking",
		priceWithoutVatPerMinuteForBookingExtended: "priceWithoutVatPerMinuteForBookingExtended",
		priceWithoutVatPerMinuteForOnSite: "priceWithoutVatPerMinuteForOnSite",
		minBillingPeriodInMinutesForOnSite: "minBillingPeriodInMinutesForOnSite",
		priceWithoutVatPerMinuteForOnSiteExtended: "priceWithoutVatPerMinuteForOnSiteExtended",
		termsOfCancelTypeBooking: "termsOfCancelTypeBooking",
		priceVatP: "priceVatP",
		customerReferenceTitle: "customerReferenceTitle",
		customerReferenceInfoText: "customerReferenceInfoText",
		customerReference2Title: "customerReference2Title",
		customerReference2InfoText: "customerReference2InfoText",
		customerNotesTitle: "customerNotesTitle",
		customerNotesInfoText: "customerNotesInfoText",
		customerNotesMessageTitle: "customerNotesMessageTitle",
		customerNotesMessageInfoText: "customerNotesMessageInfoText",
		billingChargeWithoutVat: "billingChargeWithoutVat",
		reference: "reference",
		agreementIdentifier: "agreementIdentifier",
		billingReference: "billingReference",
		billingPaymentTerm: "billingPaymentTerm",
		interpreterFeeWithoutVatPerMinuteForInstant: "interpreterFeeWithoutVatPerMinuteForInstant",
		interpreterFeeWithoutVatPerMinuteForBooking: "interpreterFeeWithoutVatPerMinuteForBooking",
		interpreterFeeWithoutVatPerMinuteForOnSite: "interpreterFeeWithoutVatPerMinuteForOnSite",
		interpreterFeeVatP: "interpreterFeeVatP",
		blockedUsers: "blockedUsers",
		netvisorInvoicingCustomerIdentifier: "netvisorInvoicingCustomerIdentifier",
		eInvoiceAddress: "eInvoiceAddress",
		eInvoiceOperatorId: "eInvoiceOperatorId",
	}

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.id]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: null,
		},
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_DESCRIPTION,
			value: "",
		},
		[EInputs.businessId]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_COMPANY_BUSINESSID,
			value:"",
		},
		[EInputs.industryCode]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_INDUSTRY_CODE,
			value: "",
		},
		[EInputs.www]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_WEBSITE,
			value:"",
		},
		[EInputs.phoneNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_PHONENUMBER,
			value:"",
		},
		[EInputs.email]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_EMAIL,
			value: "",
		},
		[EInputs.bookingEmail]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_BOOKINGEMAIL,
			value:"",
		},
		[EInputs.organizationDurations]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_DURATIONS,
			value: organization.organizationDurations?.map((item) => {
				return {
					value:
						item.durationInMinutes?.toString(),
					label:
						item.durationInMinutes?.toString(),
				};
			}),
			multiple: true,
			options: DURATIONS,
		},
		[EInputs.defaultOrganizationDuration]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.ORGANIZATION_DEFAULT_DURATIONS,
			value: "",
			multiple: false,
			options: DURATIONS,
		},
		[EInputs.streetName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ADDRESS,
			value: "",
		},
		[EInputs.zip]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ZIP,
			value:"",
		},
		[EInputs.city]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_LOCATION,
			value:"",
		},
		[EInputs.country]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_COUNTRY,
			value: "",
		},
		[EInputs.priceWithoutVatPerMinuteForInstant]: {
			type: EInputType.number,
			labelTranslation: ETranslation.COMMON_INSTANT_WITHOUT_VAT,
			value: "",
		},
		[EInputs.minBillingPeriodInMinutesForInstant]: {
			type: EInputType.number,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_INSTANT,
			value: "",
		},
		[EInputs.priceWithoutVatPerMinuteForInstantExtended]: {
			type: EInputType.number,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_INSTANT_EXTENDED,
			value:
				"",
		},
		[EInputs.priceWithoutVatPerMinuteForBooking]: {
			type: EInputType.number,
			labelTranslation:ETranslation.COMMON_BOOKING_WITHOUT_VAT,
			value:"",
		},
		[EInputs.minBillingPeriodInMinutesForBooking]: {
			type: EInputType.number,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_BOOKING,
			value: "",
		},
		[EInputs.priceWithoutVatPerMinuteForBookingExtended]: {
			type: EInputType.number,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_BOOKING_EXTENDED,
			value:"",
		},
		[EInputs.priceWithoutVatPerMinuteForOnSite]: {
			type: EInputType.number,
			labelTranslation:ETranslation.COMMON_ON_SITE_WITHOUT_VAT,
			value: "",
		},
		[EInputs.minBillingPeriodInMinutesForOnSite]: {
			type: EInputType.number,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_ON_SITE,
			value:"",
		},
		[EInputs.priceWithoutVatPerMinuteForOnSiteExtended]: {
			type: EInputType.number,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_ON_SITE_EXTENDED,
			value:"",
		},
		[EInputs.termsOfCancelTypeBooking]: {
			type: EInputType.select,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_TERMS_OF_CANCEL_TYPE_BOOKING,
			value: "",
			options: [
				{
					value: "",
					labelTranslation: ETranslation.ORGANIZATION_COMPANY_TERMS_OF_CANCEL_TYPE_BOOKING,
				},
				...PRICE_SEASON_RULE_TERMS_OF_CANCEL,
			],
		},
		[EInputs.priceVatP]: {
			type: EInputType.number,
			labelTranslation: ETranslation.COMMON_VAT,
			value: "",
		},
		[EInputs.customerReferenceTitle]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_TITLE,
			value: "",
		},
		[EInputs.customerReferenceInfoText]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_INFO_TEXT,
			value: "",
		},
		[EInputs.customerReference2Title]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE2_TITLE,
			value: "",
		},
		[EInputs.customerReference2InfoText]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE2_INFO_TEXT,
			value: "",
		},
		[EInputs.customerNotesTitle]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_TITLE,
			value: "",
		},
		[EInputs.customerNotesInfoText]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_INFO_TEXT,
			value: "",
		},
		[EInputs.customerNotesMessageTitle]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_MESSAGE_TITLE,
			value: "",
		},
		[EInputs.customerNotesMessageInfoText]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_MESSAGE_INFO_TEXT,
			value: "",
		},
		[EInputs.billingChargeWithoutVat]: {
			type: EInputType.number,
			labelTranslation:
				ETranslation.ORGANIZATION_COMPANY_BILLING_CHARGE_WITHOUT_VAT,
			value: "",
		},
		[EInputs.billingReference]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_BILLING_REFERENCE,
			value: "",
		},
		[EInputs.agreementIdentifier]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_AGREEMENT_IDENTIFIER,
			value: "",
		},
		[EInputs.reference]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_REFERENCE,
			value: "",
		},
		[EInputs.interpreterFeeWithoutVatPerMinuteForInstant]: {
			type: EInputType.number,
			labelTranslation: ETranslation.COMMON_INSTANT_WITHOUT_VAT,
			value: "",
		},
		[EInputs.interpreterFeeWithoutVatPerMinuteForBooking]: {
			type: EInputType.number,
			labelTranslation:
				ETranslation.COMMON_BOOKING_WITHOUT_VAT,
			value: "",
		},
		[EInputs.interpreterFeeWithoutVatPerMinuteForOnSite]: {
			type: EInputType.number,
			labelTranslation:
				ETranslation.COMMON_ON_SITE_WITHOUT_VAT,
			value: "",
		},
		[EInputs.interpreterFeeVatP]: {
			type: EInputType.number,
			labelTranslation:
				ETranslation.COMMON_VAT,
			value: "",
		},
		[EInputs.netvisorInvoicingCustomerIdentifier]: {
			type: EInputType.text,

			labelTranslation: ETranslation.ORGANIZATION_COMPANY_CUSTOMER_CODE,
			value: "",
		},
		[EInputs.eInvoiceAddress]: {
			type: EInputType.text,

			labelTranslation:
				ETranslation.ORGANIZATION_COMPANY_EINVOICE_ADDRESS,
			value: "",
		},
		[EInputs.eInvoiceOperatorId]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_OPERATOR_CODE,
			value: "",
		},
		[EInputs.blockedUsers]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_BLOCKED_INTERPRETERS,
			value: [],
			multiple: true,
		},
		[EInputs.organizationGroup]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_ORGANIZATION_GROUP,
			value: "",
			multiple: false,
		},
		[EInputs.customerApprovalRequired]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.ORGANIZATION_CUSTOMER_APPROVAL_REQUIRED,
			value: organization?.customerApprovalRequired ?? false,
		},
		[EInputs.costCenterMandatory]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_COST_CENTER_MANDATORY,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.customerReferenceHidden]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_HIDDEN,
			value: organization?.customerReferenceHidden ?? false,
		},
		[EInputs.customerReferenceMandatory]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_MANDATORY,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.customerReferenceMandatoryInReview]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_MANDATORY_IN_REVIEW,
			value:false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.customerReference2Mandatory]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_2MANDATORY,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.customerReference2MandatoryInReview]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_2MANDATORY_IN_REVIEW,
			value:false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.onlySecurityCleared]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_ONLY_SECURITY_CLEARED_INTERPRETERS,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.allowAttachments]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_ALLOW_ATTACHMENTS,
			value:false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.allowAutoReminders]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_ALLOW_AUTO_REMINDERS,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.showLanguageAvailability]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_SHOW_LANGUAGE_AVAILABILITY,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.allowInterpreterCallToCustomer]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CUSTOMER,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.allowCustomerCallToInterpreter]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_ALLOW_CUSTOMER_CALL_TO_INTERPRETER,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.allowContactCallToInterpreter]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_ALLOW_CONTACT_CALL_TO_INTERPRETER,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.allowInterpreterCallToContact]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CONTACT,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.allowVideoConference]: {
			type: EInputType.boolean,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_ALLOW_VIDEO_CONFERENCE,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.allowMaskedNumber]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_ALLOW_MASKED_NUMBER,
			value:false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.allowConnectionTypeSelectForInstant]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_ALLOW_CONNECTION_TYPE_SELECT_FOR_INSTANT,
			value: false,
			multiple: false,
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.vatRegistered]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_IN_VAT_REGISTRY,
			options: [
				{
					value: "UNKNOWN",
					labelTranslation: ETranslation.COMMON_UNKOWN,
				},
				{
					value: "YES",
					labelTranslation: ETranslation.COMMON_YES,
				},
				{
					value: "NO",
					labelTranslation: ETranslation.COMMON_NO,
				},
			],
			value: "",
		},

		[EInputs.defaultInterpretationType]: {
			type: EInputType.reactSelect,
			labelTranslation:ETranslation.ORGANIZATION_COMPANY_DEFAULT_INTERPRETATION_TYPE,
			value: "",
			multiple: false,
		},
		[EInputs.billingPaymentTerm]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_BILLING_PAYMENT_TERM,
			options: [
				{
					value: "NOW",
					labelTranslation: ETranslation.COMMON_NOW,
				},
				{
					value: "DAYS_7",
					labelTranslation: ETranslation.ORGANIZATION_COMPANY_DAYS_7,
				},
				{
					value: "DAYS_14",
					labelTranslation: ETranslation.ORGANIZATION_COMPANY_DAYS_14,
				},
				{
					value: "DAYS_21",
					labelTranslation: ETranslation.ORGANIZATION_COMPANY_DAYS_21,
				},
				{
					value: "DAYS_30",
					labelTranslation: ETranslation.ORGANIZATION_COMPANY_DAYS_30,
				},
				{
					value: "LAST_DAY_OF_MONTH",
					labelTranslation: ETranslation.ORGANIZATION_COMPANY_LAST_DAY_OF_MONTH,
				},
			],
			value: "",
		},
		[EInputs.type]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_TYPE,
			options: [
				{
					value: "UNKNOWN",
					labelTranslation: ETranslation.COMMON_UNKOWN,
				},
				{
					value: "CUSTOMER",
					labelTranslation: ETranslation.COMMON_CUSTOMER,
				},
				{
					value: "INTERPRETER",
					labelTranslation: ETranslation.COMMON_INTERPRETER,
				},
			],
			value: "",
		},
	})

	return { inputs, setInputs, EInputs }
}