import React, { ReactNode } from 'react';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';
import Button, { EButtonColor } from '../ui/Button/Button';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../translations/translation-keys';


interface IProps {
	children: ReactNode;
	onAccept: () => void;
	onCancel: () => void;
}

const ConfirmModal: React.FC<IProps> = ({ children, onAccept, onCancel }) => {
	const { t } = useTranslation()
	return (
		<>
			<ModalBody>
				{children}
			</ModalBody>
			<ModalFooter>
				<Button onClick={onAccept} style={{ marginRight: '.5rem' }}>{t(ETranslation.COMMON_CONFIRM)}</Button>
				<Button onClick={onCancel} color={EButtonColor.DANGER}>{t(ETranslation.COMMON_CANCEL)}</Button>
			</ModalFooter>
		</>
	)
}

export default ConfirmModal;