import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Heading from "../../components/Heading/Heading";

import Container from "../../components/ui/Container/Container";
import { ETranslation } from "../../translations/translation-keys";
import SummariesList from "../../components/Summary/SummariesList";
import { Routes, ERoute } from "../../classes/Routes";
import { RouteComponentProps } from "react-router-dom";
import { SummariesPagination } from "../../hooks/UseSummaries";
import Spinner from "../../components/ui/Spinner/Spinner";
import { getSummariesClear, ISummary, ISummarySearch } from "../../store/actions/summariesActions";
import SummarySearch from "../../components/Summary/SummariesSearch/SummarySearch";
import { createListSelectContext } from "../../hooks/useListSelectContext";
import { useDispatch } from "react-redux";

interface IProps extends RouteComponentProps { }

export const { SelectedItemsContext: SelectedSummariesContext } = createListSelectContext<ISummary>();

const SummariesPage: React.FC<IProps> = ({ history }) => {
	const { t } = useTranslation();

	const { summaries, error, loading, searchHandler, loaderEl, clearSummaries } = SummariesPagination();

	const searchHandlerCallBack = (search: ISummarySearch) => {
		clearSummaries();
		searchHandler(search);
	}

	// Cleanup
	const dispatch = useDispatch();
	useEffect(()=>{
		return ()=>{
			dispatch(getSummariesClear())
		}
	}, [dispatch])

	const openHandler = (id: string) => {
		history.push(Routes.withParams(ERoute.SUMMARY_EDIT, { id }));
	};

	const [selectedRows, setSelectedRows] = useState<ISummary[]>([]);
	const [selectAll, setSelectAll] = useState(false);

	return (
		<Container>
			<SelectedSummariesContext.Provider value={{ selectedRows, setSelectedRows, selectAll, setSelectAll }} >
				<Heading>{t(ETranslation.MENU_SUMMARIES)}</Heading>{" "}
				<SummarySearch searchHandler={searchHandlerCallBack} />
				<hr />
				{summaries && <SummariesList
					summaries={summaries}
					error={error}
					openHandler={openHandler}
				/>}
				{loading ? <Spinner /> : loaderEl}
			</SelectedSummariesContext.Provider>
		</Container>
	);
};
export default SummariesPage;
