import { useState } from "react";
import { EInputType, IInputField } from "../ui/Input/Input";
import { GENDER_OPTIONS } from "../../shared/common-data";
import { ETranslation } from "../../translations/translation-keys";
import { IJobApplicationDefaults } from "../../pages/JobApplications/JobApplicationEditPage";

export enum EJobApplicationBasicInput {
	firstName = "firstName",
	lastName = "lastName",
	email = "email",
	phoneNumber = "phoneNumber",
	gender = "gender",
	address = "address",
	zip = "zip",
	city = "city",
	country = "country",
	description = "description",
	socialSecurityNumber = "socialSecurityNumber",
	isOrganization = "isOrganization",
	subtypeIds = "subtypeIds",
	interestIds = "interestIds",
	registrationNumber = 'registrationNumber'
}

export const useJobApplicationBasicInputs = (defaults: IJobApplicationDefaults) => {
	const [basicInputs, setBasicInputs] = useState<IInputField>({
		[EJobApplicationBasicInput.firstName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_FIRST_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.lastName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_LAST_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.email]: {
			type: EInputType.email,
			labelTranslation: ETranslation.COMMON_EMAIL,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.phoneNumber]: {
			type: EInputType.tel,
			labelTranslation: ETranslation.COMMON_PHONENUMBER,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.gender]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_GENDER,
			value: "",
			options: GENDER_OPTIONS,
		},
		[EJobApplicationBasicInput.address]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ADDRESS,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.zip]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ZIP,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.city]: {
			type: EInputType.text,
			labelTranslation: ETranslation.SITE_CITY,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.country]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_COUNTRY,
			value: defaults.country,
			isOptionGroup: true,
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.USER_ADDITIONAL_DETAILS,
			value: "",
			validation: {
				required: false,
			},
		},
		[EJobApplicationBasicInput.socialSecurityNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_SOCIAL_SECURITY_NUMBER,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.registrationNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.APPLICATIONS_REGISTRATION_NUMBER,
			value: "",
			validation: {
				required: false,
			},
		},
		[EJobApplicationBasicInput.subtypeIds]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.APPLICATIONS_FIELD_OF_WORK,
			value: [],
			multiple: true,
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.interestIds]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.APPLICATIONS_IM_INTERESTED_IN,
			value: [],
			multiple: true,
			isOptionGroup: true,
			validation: {
				required: false,
			},
		},
		[EJobApplicationBasicInput.isOrganization]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.APPLICATIONS_I_HAVE_COMPANY,
			value: false,
		},
	});
	return { basicInputs, setBasicInputs };
}