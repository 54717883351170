import React, { useContext, useEffect, useState } from "react";
import ModalBody from "../../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../../ui/Modal/ModalFooter/ModalFooter";
import Button, { EButtonColor } from "../../ui/Button/Button";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import ModalContext from "../../ui/Modal/ModalContext";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { getInputData, validateInputs } from "../../ui/Input/input-utils";
import { useCreateInput } from "../../../hooks/useCreateInput";
import {  User } from "../../../classes/User";

interface IProps {
	onConfirm: (emails: string) => void;
	user: User;
}

enum EInputs {
	emails = "emails",
}

const InterpretationConfirmationEmailModal: React.FC<IProps> = ({ onConfirm, user }) => {
	const { t } = useTranslation();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.emails]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_EMAIL,
			value: user.getEmails(),
			validation: {
				required: true,
				minLength: 5
			}
		},
	})

	const [isValid, setIsvalid] = useState(false);

	useEffect(() => {
		const valid = validateInputs(inputs);
		setIsvalid(valid);
	}, [inputs])

	const submit = () => {
		const data = getInputData<{ emails: string }>(inputs);
		onConfirm(data.emails);
	}

	const { closeModal } = useContext(ModalContext);
	const createInput = useCreateInput(inputs, setInputs);

	return (
		<>
			<ModalBody>
				<label style={{ marginBottom: '.25rem', fontWeight: 'bold', display: 'block' }}>Vastaanottajien sähköpostiosoitteet pilkulla eroiteltuna</label>
				{createInput(EInputs.emails)}
			</ModalBody>
			<ModalFooter>
				<Button disabled={!isValid} onClick={() => submit()} color={EButtonColor.SUCCESS}>
					{t(ETranslation.COMMON_YES)}
				</Button>
				<Button onClick={closeModal} color={EButtonColor.DEFAULT}>
					{t(ETranslation.COMMON_RETURN)}
				</Button>
			</ModalFooter>
		</>
	);
};

export default InterpretationConfirmationEmailModal;
