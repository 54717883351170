import React from "react";
import { useTranslation } from "react-i18next";
import { IInterpretation } from "../../../interfaces/IInterpretation";
import { ETranslation } from "../../../translations/translation-keys";
import Alert from "../../ui/Alert/Alert";
import Table from "../../ui/Table/Table";
import { IUser } from "../../../classes/User";
import Spinner from "../../ui/Spinner/Spinner";
import { useTableSort } from "../../ui/Table/useTableSort";
import TableSortContext from "../../ui/Table/TableSortContext";
import TableTh from "../../ui/Table/TableTh/TableTh";
import classes from "./InterpretationsList.module.scss";
import { RouteComponentProps } from "react-router-dom";
import IntrepretationListItem from "./InterpretationListItem";
import { useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";

interface IProps extends RouteComponentProps {
	interpretations: IInterpretation[];
	error: string | null;
	user: IUser;
}

const InterpretationsList: React.FC<IProps> = ({ interpretations, error, history }) => {
	const { t } = useTranslation();
	const { onSort, sort, items } = useTableSort<IInterpretation>({ data: interpretations });

	const { user } = useSelector((state: IAppState) => ({
		user: state.auth.user,
	}));

	if (error) {
		return <Alert>{error}</Alert>;
	}
	if (!interpretations) {
		return <Spinner centerAbsolute />;
	}
	if (interpretations.length === 0) {
		return <p>{t(ETranslation.INTERPRETATIONS_NOT_FOUND)}</p>;
	}

	return (
		<>
			<Table className={classes.TableStyle} hover={true}>
				<thead className={classes.tableHeader}>
					<TableSortContext.Provider value={{ onSort, sort }}>
						<tr>
							<TableTh colSpan={2} sort="status">{t(ETranslation.COMMON_STATUS)} ja {t(ETranslation.COMMON_TYPE).toLowerCase()}</TableTh>
							<TableTh sort="createdDateTime" type="date">{t(ETranslation.COMMON_CREATED)}</TableTh>
							<th>{t(ETranslation.COMMON_DURATION)}</th>
							{user?.isYoupretAdmin && <TableTh sort="type">{t(ETranslation.COMMON_LANGUAGE)}</TableTh>}
							<TableTh sort="startDateTime" type="date">{t(ETranslation.COMMON_DATE)}</TableTh>
							<TableTh sort="interpreterDetail.firstName|interpreterDetail.lastName">{t(ETranslation.INTERPRETATION_INTERPRETER_NAME)}</TableTh>
							<TableTh sort="customerDetail.firstName|customerDetail.lastName">{t(ETranslation.COMMON_CUSTOMER)}</TableTh>
							{user?.isYoupretAdmin &&
								<>
									<TableTh colSpan={1} sort="interpreterInfoText" >{t(ETranslation.COMMON_INFO_TO_INTERPRETER)}</TableTh>
									<TableTh colSpan={1} sort="adminNotesText">{t(ETranslation.COMMON_ADMIN_NOTES)}</TableTh>
								</>
							}
							{user?.role === "ADMIN" &&
								<th>{t(ETranslation.COMMON_FUNCTIONS)}</th>
							}
						</tr>
					</TableSortContext.Provider>
				</thead>
				<tbody className={classes.tBody}>
					{items.map((interpretation, key) => {
						return <IntrepretationListItem key={key} interpretation={interpretation} />
					})}
				</tbody>
			</Table>
		</>
	);
};

export default InterpretationsList;
