import EActionTypes from "../../interfaces/store/EActionTypes";
import IInterpretationAction from "../../interfaces/store/IInterpretationAction";
import IInterpretationState from "../../interfaces/store/IInterpretationState";
import { ETranslation } from "../../translations/translation-keys";

type ReducerAction = IInterpretationAction;
type ReducerState = IInterpretationState;
type ReducerSignature = (
	state: ReducerState,
	action: ReducerAction
) => ReducerState;

const initialState: ReducerState = {
	interpretation: null,
	loading: false,
	error: null,

	interpretations: null,
	interpretationsLoading: false,
	interpretationsError: null,
	paginationCursor: null,
	hasMoreInterpretations: false,
	averageResponseTime: null,

	interpretationSaveOrUpdateOk: false,
	interpretationSaveOrUpdateError: null,
	interpretationSaveOrUpdateLoading: false,

	interpretationDeleteError: null,
	interpretationDeleteLoading: false,
	interpretationDeleteOk: false,

	interpretationInterruptError: null,
	interpretationInterruptLoading: false,
	interpretationInterruptOk: false,

	interpretationReview: null,
	interpretationReviewOk: false,
	interpretationReviewError: null,
	interpretationReviewLoading: false,

	addFavoriteInterpreterOk: false,
	addFavoriteInterpreterError: null,
	addFavoriteInterpreterLoading: false,

	instantInterpretation: null,

	updateLoading: false,
	updateError: null,
	updateLoadingProperty: null,

	actionError: null,
	actionLoading: false,
	actionMessage: null,
	actionOk: false,

	resetLoading: false,
	resetError: null,
	resetDone: false,

	confirmationEmailDone: false,
	confirmationEmailError: null,
	confirmationEmailLoading: false,

	generatePaymentDataLoading: false,
	generatePaymentDataError: null,

	exportBillingSheetLoading: false,
	exportBillingSheetError: null,

	generateSummariesLoading: false,
	generateSummariesError: null,
	generateSummariesDone: false,

	sendFeedbackSurveyLoading: false,
	sendFeedbackSurveyError: null,
	sendFeedbackSurveyDone: false,

	deleteInterpretationPriceRowError: null,
	deleteInterpretationPriceRowLoading: false,

	interpretationsToNetvisorLoading: false,
	interpretationsToNetvisorError: null,

	addVideoRoomLoading: false,
	addVideoRoomSuccess: false,
	removeVideoRoomLoading: false,
	removeVideoRoomSuccess: false,
	videoRoomError: null,

	offerConfirmId: null,
	offerConfirmLoading: false,
	offerConfirmError: null,

	userAvailabilities: null,
	userAvailabilitiesError: null,
	userAvailabilitiesLoading: false,
};

const findInterpretationsStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretationsError: null,
		interpretationsLoading: true,
		hasMoreInterpretations: false,
	};
};

const findInterpretationsSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	const interpretations = action.searchAll ? action.interpretations || null : action.interpretations ? [...(state.interpretations ?? []), ...action.interpretations] : null;
	const averageResponseTime = action.averageResponseTime ? action.averageResponseTime : null;
	const hasMoreInterpretations = action.searchAll ? false : action.hasMoreInterpretations;
	return {
		...state,
		interpretations,
		averageResponseTime,
		interpretationsError: null,
		interpretationsLoading: false,
		paginationCursor: action.paginationCursor || null,
		hasMoreInterpretations: hasMoreInterpretations ?? false,
	};
};

const findInterpretationsFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretations: null,
		interpretationsLoading: false,
		interpretationsError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
		paginationCursor: null,
		hasMoreInterpretations: false,
	};
};

const findInterpretationsClear: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretations: null,
		interpretationsLoading: false,
		interpretationsError: null,
	};
};

const getInterpretationStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return { ...state, error: null, loading: true };
};

const getInterpretationSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretation: action.interpretation || null,
		averageResponseTime: action.averageResponseTime ?? null,
		error: null,
		loading: false,
	};
};

const getInterpretationFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretation: null,
		loading: false,
		error: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const getInterpretationClear: ReducerSignature = (
	state,
	action
): ReducerState => {
	return { ...state, interpretation: null, loading: false, error: null };
};

const deleteInterpretationStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretationDeleteError: null,
		interpretationDeleteLoading: true,
		interpretationDeleteOk: false,
	};
};

const deleteInterpretationSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretationDeleteError: null,
		interpretationDeleteLoading: false,
		interpretationDeleteOk: true,
	};
};

const deleteInterpretationFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretationDeleteLoading: false,
		interpretationDeleteError: action.error as string,
		interpretationDeleteOk: false,
	};
};

const deleteInterpretationClear: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretationDeleteError: null,
		interpretationDeleteLoading: false,
		interpretationDeleteOk: false,
	};
};

const saveOrUpdateInterpretationStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretationSaveOrUpdateError: null,
		interpretationSaveOrUpdateLoading: true,
	};
};

const saveOrUpdateInterpretationSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretations: null,
		interpretationSaveOrUpdateError: null,
		interpretationSaveOrUpdateLoading: false,
		interpretationSaveOrUpdateOk: true,
	};
};

const saveOrUpdateInterpretationFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretationSaveOrUpdateLoading: false,
		interpretationSaveOrUpdateError:
			action.error || ETranslation.REDUCER_SAVE_ERROR,
	};
};

const saveOrUpdateInterpretationClear: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretationSaveOrUpdateLoading: false,
		interpretationSaveOrUpdateError: null,
		interpretationSaveOrUpdateOk: false,
	};
};

const requestInterpretationStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return { ...state, error: null, loading: true };
};

const requestInterpretationSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		instantInterpretation: action.instantInterpretation || null,
		error: null,
		loading: false,
	};
};

const requestInterpretationFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		instantInterpretation: null,
		loading: false,
		error: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const requestInterpretationClear: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		instantInterpretation: null,
		loading: false,
		error: null,
	};
};

const interruptInterpretationStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return { ...state, error: null, loading: true };
};

const interruptInterpretationSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretation: action.interpretation || null,
		error: null,
		loading: false,
	};
};

const interruptInterpretationFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretation: null,
		loading: false,
		error: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const interruptInterpretationClear: ReducerSignature = (
	state,
	action
): ReducerState => {
	return { ...state, interpretation: null, loading: false, error: null };
};

const completeInterpretationReviewStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretationReviewError: null,
		interpretationReviewLoading: true,
	};
};

const completeInterpretationReviewSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretationReview: action.interpretationReview || null,
		interpretationReviewError: null,
		interpretationReviewLoading: false,
		interpretationReviewOk: true,
	};
};

const completeInterpretationReviewFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		interpretationReviewLoading: false,
		interpretationReviewError:
			action.error || ETranslation.REDUCER_SAVE_ERROR,
	};
};

const addFavoriteInterpreterStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		addFavoriteInterpreterError: null,
		addFavoriteInterpreterLoading: true,
	};
};

const addFavoriteInterpreterSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		addFavoriteInterpreterError: null,
		addFavoriteInterpreterLoading: false,
		addFavoriteInterpreterOk: true,
	};
};

const addFavoriteInterpreterFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		addFavoriteInterpreterLoading: false,
		addFavoriteInterpreterError:
			action.error || ETranslation.REDUCER_SAVE_ERROR,
	};
};

const getInterpretationMessageGroupStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return { ...state, error: null, loading: true, interpretation: null };
};

const getInterpretationMessageGroupSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		error: null,
		loading: false,
		interpretation: action.interpretation || null,
	};
};

const getInterpretationMessageGroupFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		loading: false,
		error: action.error || ETranslation.REDUCER_ERROR,
		interpretation: null,
	};
};

const getInterpretationMessageGroupClear: ReducerSignature = (
	state,
	action
): ReducerState => {
	return { ...state, loading: false, error: null, interpretation: null };
};

const updateInterpretationPropertyStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		updateError: null,
		updateLoading: true,
		updateLoadingProperty: action.propertyName || null,
	};
};

const updateInterpretationPropertySuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	const { interpretation, interpretations } = state;
	const { id, propertyName, value } = action;
	if (interpretation && propertyName && value) {
		(interpretation as any)[propertyName] = value;
	}

	if (interpretations && propertyName && value && id) {
		const idx = interpretations.findIndex((i) => i.id === id);
		if (typeof idx !== "undefined") {
			(interpretations[idx] as any)[propertyName] = value;
		}
	}

	return {
		...state,
		interpretation: interpretation || null,
		interpretations: interpretations || null,
		updateError: null,
		updateLoading: false,
		updateLoadingProperty: null,
	};
};

const updateInterpretationPropertyFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		updateLoading: false,
		updateError: action.error || ETranslation.REDUCER_SAVE_ERROR,
		updateLoadingProperty: null,
	};
};

const actionStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return { ...state, actionMessage: null, actionLoading: true, actionOk: false };
};

const actionSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		actionMessage: action.message || null,
		actionOk: true,
		actionLoading: false,
	};
};

const actionFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		actionLoading: false,
		actionError: action.error || ETranslation.REDUCER_ERROR,
	};
};

const actionClear: ReducerSignature = (
	state, action
): ReducerState => {
	return {
		...state,
		actionLoading: false,
		actionError: null,
		actionMessage: null,
		actionOk: false
	}
}

const resetStart: ReducerSignature = (
	state, action
): ReducerState => {
	return {
		...state,
		resetLoading: true,
		resetError: null,
		resetDone: false,
	}
}

const resetSuccess: ReducerSignature = (
	state, action
): ReducerState => {
	const editId = action.interpretation ? action.interpretation.id : "";
	const copy = state.interpretations?.map((item) => {
		if (action.interpretation) {
			if (editId !== item.id) {
				return item;
			} else {
				return action.interpretation;
			}
		} else {
			return item;
		}
	});
	const interpretation = state.interpretation?.id === action.interpretation?.id ? action.interpretation ?? null : state.interpretation ?? null;
	return {
		...state,
		interpretation,
		interpretations: copy ?? null,
		resetLoading: false,
		resetError: null,
		resetDone: true,
	}
}

const resetError: ReducerSignature = (
	state, action
): ReducerState => {
	return {
		...state,
		resetLoading: false,
		resetError: action.error ?? null,
		resetDone: false,
	}
}

const resetClear: ReducerSignature = (
	state, action
): ReducerState => {
	return {
		...state,
		resetLoading: false,
		resetError: null,
		resetDone: false,
	}
}

const confirmationStart: ReducerSignature = (
	state, action
): ReducerState => {
	return {
		...state,
		confirmationEmailLoading: true,
		confirmationEmailDone: false,
		confirmationEmailError: null,
	}
}

const confirmationDone: ReducerSignature = (
	state, action
): ReducerState => {
	const editId = action.interpretation ? action.interpretation.id : "";
	const copy = state.interpretations?.map((item) => {
		if (action.interpretation) {
			if (editId !== item.id) {
				return item;
			} else {
				return action.interpretation;
			}
		} else {
			return item;
		}
	});
	return {
		...state,
		interpretations: copy ?? null,
		confirmationEmailLoading: false,
		confirmationEmailDone: true,
		confirmationEmailError: null,
	}
}

const confirmationError: ReducerSignature = (
	state, action
): ReducerState => {
	return {
		...state,
		confirmationEmailLoading: false,
		confirmationEmailDone: false,
		confirmationEmailError: action.error ?? null,
	}
}

const confirmationClear: ReducerSignature = (
	state, action
): ReducerState => {
	return {
		...state,
		confirmationEmailLoading: false,
		confirmationEmailDone: false,
		confirmationEmailError: null,
	}
}

// Generate Payment Data
const sendEventStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, generatePaymentDataError: null, generatePaymentDataLoading: true };
};

const sendEventSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, generatePaymentDataError: null, generatePaymentDataLoading: false };
};

const sendEventFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, generatePaymentDataLoading: false, generatePaymentDataError: action.error || ETranslation.REDUCER_ERROR };
};

// Export billing sheet
const exportBillingSheetStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, exportBillingSheetError: null, exportBillingSheetLoading: true };
};

const exportBillingSheetSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, exportBillingSheetError: null, exportBillingSheetLoading: false };
};

const exportBillingSheetFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, exportBillingSheetLoading: false, exportBillingSheetError: action.error || ETranslation.REDUCER_ERROR };
};

// Generate summaries
const generateSummariesStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, generateSummariesError: null, generateSummariesLoading: true, generateSummariesDone: false };
};

const generateSummariesSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, generateSummariesError: null, generateSummariesLoading: false, generateSummariesDone: true };
};

const generateSummariesFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, generateSummariesLoading: false, generateSummariesError: action.error || ETranslation.REDUCER_ERROR, generateSummariesDone: true };
};

const generateSummariesClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, generateSummariesLoading: false, generateSummariesError: null, generateSummariesDone: false };
};

// Send feedback survey
const sendFeedBackSurveyStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, sendFeedbackSurveyError: null, sendFeedbackSurveyLoading: true, sendFeedbackSurveyDone: false, };
};

const sendFeedBackSurveySuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, sendFeedbackSurveyError: null, sendFeedbackSurveyLoading: false, sendFeedbackSurveyDone: true };
};

const sendFeedBackSurveyError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, sendFeedbackSurveyLoading: false, sendFeedbackSurveyError: action.error || ETranslation.REDUCER_ERROR, sendFeedbackSurveyDone: false };

};

const sendFeedBackSurveyClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, sendFeedbackSurveyLoading: false, sendFeedbackSurveyDone: false, sendFeedbackSurveyError: null, }
}


// Delete interpretation price row
const deleteInterpretationPriceRowStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, deleteInterpretationPriceRowError: null, deleteInterpretationPriceRowLoading: true};
};

const deleteInterpretationPriceRowSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, deleteInterpretationPriceRowLoading: false, deleteInterpretationPriceRowError: null };
};

const deleteInterpretationPriceRowError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, deleteInterpretationPriceRowLoading: false,  deleteInterpretationPriceRowError: action.error || ETranslation.REDUCER_ERROR }
}


// Move Interpretations to netvisor
const interpretationsToNetvisorStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, interpretationsToNetvisorError: null, interpretationsToNetvisorLoading: true};
};

const interpretationsToNetvisorSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, interpretationsToNetvisorLoading: false, interpretationsToNetvisorError: null };
};

const interpretationsToNetvisorError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, interpretationsToNetvisorLoading: false,  interpretationsToNetvisorError: action.error || ETranslation.REDUCER_ERROR }
}

const addVideoRoomStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, videoRoomError: null, addVideoRoomLoading: true, addVideoRoomSuccess: false};
};

const addVideoRoomSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, addVideoRoomLoading: false, addVideoRoomSuccess: true, interpretation: action.interpretation! };
};

const addVideoRoomError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, addVideoRoomLoading: false, videoRoomError: action.error!, addVideoRoomSuccess: false }
}

const addVideoRoomClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, addVideoRoomLoading: false, videoRoomError: null, addVideoRoomSuccess: false }
}

const removeVideoRoomStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, videoRoomError: null, removeVideoRoomLoading: true, removeVideoRoomSuccess: false};
};

const removeVideoRoomSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, removeVideoRoomLoading: false, removeVideoRoomSuccess: true, interpretation: action.interpretation! };
};

const removeVideoRoomError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, removeVideoRoomLoading: false, videoRoomError: action.error!, removeVideoRoomSuccess: false }
}

const removeVideoRoomClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, removeVideoRoomLoading: false, videoRoomError: null, removeVideoRoomSuccess: false }
}


const confirmOfferUserStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, offerConfirmId: action.id!, offerConfirmLoading: true, offerConfirmError: null };
};

const confirmOfferUserSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, offerConfirmId: null, offerConfirmLoading: false, interpretation: action.interpretation! };
};

const confirmOfferUserError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, offerConfirmId: null, offerConfirmLoading: false, offerConfirmError: action.error ?? null }
}

const getAvailableInterpretersStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, userAvailabilitiesLoading: true, userAvailabilitiesError: null, userAvailabilities: null };
};

const getAvailableInterpretersSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, userAvailabilitiesLoading: false, userAvailabilities: action.userAvailabilities! };
};

const getAvailableInterpretersError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, userAvailabilitiesLoading: false, userAvailabilitiesError: action.error ?? null }
}


const reducer = (
	state: ReducerState = initialState,
	action: ReducerAction
): ReducerState => {
	switch (action.type) {
		case EActionTypes.INTERPRETATION_FIND_START:
			return findInterpretationsStart(state, action);
		case EActionTypes.INTERPRETATION_FIND_SUCCESS:
			return findInterpretationsSuccess(state, action);
		case EActionTypes.INTERPRETATION_FIND_FAIL:
			return findInterpretationsFail(state, action);
		case EActionTypes.INTERPRETATION_FIND_CLEAR:
			return findInterpretationsClear(state, action);
		case EActionTypes.INTERPRETATION_GET_START:
			return getInterpretationStart(state, action);
		case EActionTypes.INTERPRETATION_GET_SUCCESS:
			return getInterpretationSuccess(state, action);
		case EActionTypes.INTERPRETATION_GET_FAIL:
			return getInterpretationFail(state, action);
		case EActionTypes.INTERPRETATION_GET_CLEAR:
			return getInterpretationClear(state, action);
		case EActionTypes.INTERPRETATION_DELETE_START:
			return deleteInterpretationStart(state, action);
		case EActionTypes.INTERPRETATION_DELETE_SUCCESS:
			return deleteInterpretationSuccess(state, action);
		case EActionTypes.INTERPRETATION_DELETE_FAIL:
			return deleteInterpretationFail(state, action);
		case EActionTypes.INTERPRETATION_DELETE_CLEAR:
			return deleteInterpretationClear(state, action);
		case EActionTypes.INTERPRETATION_SAVE_OR_UPDATE_START:
			return saveOrUpdateInterpretationStart(state, action);
		case EActionTypes.INTERPRETATION_SAVE_OR_UPDATE_SUCCESS:
			return saveOrUpdateInterpretationSuccess(state, action);
		case EActionTypes.INTERPRETATION_SAVE_OR_UPDATE_FAIL:
			return saveOrUpdateInterpretationFail(state, action);
		case EActionTypes.INTERPRETATION_SAVE_OR_UPDATE_CLEAR:
			return saveOrUpdateInterpretationClear(state, action);
		case EActionTypes.INTERPRETATION_REQUEST_START:
			return requestInterpretationStart(state, action);
		case EActionTypes.INTERPRETATION_REQUEST_SUCCESS:
			return requestInterpretationSuccess(state, action);
		case EActionTypes.INTERPRETATION_REQUEST_FAIL:
			return requestInterpretationFail(state, action);
		case EActionTypes.INTERPRETATION_REQUEST_CLEAR:
			return requestInterpretationClear(state, action);
		case EActionTypes.INTERPRETATION_INTERRUPT_START:
			return interruptInterpretationStart(state, action);
		case EActionTypes.INTERPRETATION_INTERRUPT_SUCCESS:
			return interruptInterpretationSuccess(state, action);
		case EActionTypes.INTERPRETATION_INTERRUPT_FAIL:
			return interruptInterpretationFail(state, action);
		case EActionTypes.INTERPRETATION_INTERRUPT_CLEAR:
			return interruptInterpretationClear(state, action);
		case EActionTypes.INTERPRETATION_REVIEW_START:
			return completeInterpretationReviewStart(state, action);
		case EActionTypes.INTERPRETATION_REVIEW_SUCCESS:
			return completeInterpretationReviewSuccess(state, action);
		case EActionTypes.INTERPRETATION_REVIEW_FAIL:
			return completeInterpretationReviewFail(state, action);
		case EActionTypes.ADD_FAVORITE_INTERPRETER_START:
			return addFavoriteInterpreterStart(state, action);
		case EActionTypes.ADD_FAVORITE_INTERPRETER_SUCCESS:
			return addFavoriteInterpreterSuccess(state, action);
		case EActionTypes.ADD_FAVORITE_INTERPRETER_FAIL:
			return addFavoriteInterpreterFail(state, action);
		case EActionTypes.INTERPRETATION_MESSAGEGROUP_GET_START:
			return getInterpretationMessageGroupStart(state, action);
		case EActionTypes.INTERPRETATION_MESSAGEGROUP_GET_SUCCESS:
			return getInterpretationMessageGroupSuccess(state, action);
		case EActionTypes.INTERPRETATION_MESSAGEGROUP_GET_FAIL:
			return getInterpretationMessageGroupFail(state, action);
		case EActionTypes.INTERPRETATION_MESSAGEGROUP_GET_CLEAR:
			return getInterpretationMessageGroupClear(state, action);
		case EActionTypes.INTERPRETATION_UPDATE_PROPERTY_START:
			return updateInterpretationPropertyStart(state, action);
		case EActionTypes.INTERPRETATION_UPDATE_PROPERTY_SUCCESS:
			return updateInterpretationPropertySuccess(state, action);
		case EActionTypes.INTERPRETATION_UPDATE_PROPERTY_FAIL:
			return updateInterpretationPropertyFail(state, action);
		case EActionTypes.INTERPRETATION_ACTION_START:
			return actionStart(state, action);
		case EActionTypes.INTERPRETATION_ACTION_SUCCESS:
			return actionSuccess(state, action);
		case EActionTypes.INTERPRETATION_ACTION_FAIL:
			return actionFail(state, action);
		case EActionTypes.INTERPRETATION_ACTION_CLEAR:
			return actionClear(state, action);
		case EActionTypes.INTERPRETATION_RESET_START:
			return resetStart(state, action);
		case EActionTypes.INTERPRETATION_RESET_SUCCESS:
			return resetSuccess(state, action);
		case EActionTypes.INTERPRETATION_RESET_ERROR:
			return resetError(state, action);
		case EActionTypes.INTERPRETATION_RESET_CLEAR:
			return resetClear(state, action);
		case EActionTypes.INTERPRETATION_CONFIRMATION_EMAIL_START:
			return confirmationStart(state, action);
		case EActionTypes.INTERPRETATION_CONFIRMATION_EMAIL_SUCCESS:
			return confirmationDone(state, action);
		case EActionTypes.INTERPRETATION_CONFIRMATION_EMAIL_ERROR:
			return confirmationError(state, action);
		case EActionTypes.INTERPRETATION_CONFIRMATION_EMAIL_RESET:
			return confirmationClear(state, action);
		case EActionTypes.INTERPRETATION_GENERATE_PAYMENT_DATA_START:
			return sendEventStart(state, action);
		case EActionTypes.INTERPRETATION_GENERATE_PAYMENT_DATA_SUCCESS:
			return sendEventSuccess(state, action);
		case EActionTypes.INTERPRETATION_GENERATE_PAYMENT_DATA_ERROR:
			return sendEventFail(state, action);
		case EActionTypes.INTERPRETATION_GENERATE_PAYMENT_SHEET_START:
			return exportBillingSheetStart(state, action);
		case EActionTypes.INTERPRETATION_GENERATE_PAYMENT_SHEET_SUCCESS:
			return exportBillingSheetSuccess(state, action);
		case EActionTypes.INTERPRETATION_GENERATE_PAYMENT_SHEET_ERROR:
			return exportBillingSheetFail(state, action);
		case EActionTypes.INTERPRETATION_GENERATE_SUMMARIES_START:
			return generateSummariesStart(state, action);
		case EActionTypes.INTERPRETATION_GENERATE_SUMMARIES_SUCCESS:
			return generateSummariesSuccess(state, action);
		case EActionTypes.INTERPRETATION_GENERATE_SUMMARIES_ERROR:
			return generateSummariesFail(state, action);
		case EActionTypes.INTERPRETATION_GENERATE_SUMMARIES_CLEAR:
			return generateSummariesClear(state, action);
		case EActionTypes.INTERPRETATION_FEEDBACK_SURVEY_START:
			return sendFeedBackSurveyStart(state, action);
		case EActionTypes.INTERPRETATION_FEEDBACK_SURVEY_SUCCESS:
			return sendFeedBackSurveySuccess(state, action);
		case EActionTypes.INTERPRETATION_FEEDBACK_SURVEY_ERROR:
			return sendFeedBackSurveyError(state, action);
		case EActionTypes.INTERPRETATION_FEEDBACK_SURVEY_CLEAR:
			return sendFeedBackSurveyClear(state, action);
		case EActionTypes.INTERPRETATION_DELETE_PRICE_ROW_START:
			return deleteInterpretationPriceRowStart(state, action);
		case EActionTypes.INTERPRETATION_DELETE_PRICE_ROW_SUCCESS:
			return deleteInterpretationPriceRowSuccess(state, action);
		case EActionTypes.INTERPRETATION_DELETE_PRICE_ROW_ERROR:
			return deleteInterpretationPriceRowError(state, action);
		case EActionTypes.INTERPRETATION_MOVE_TO_NETVISOR_START:
			return interpretationsToNetvisorStart(state, action);
		case EActionTypes.INTERPRETATION_MOVE_TO_NETVISOR_SUCCESS:
			return interpretationsToNetvisorSuccess(state, action);
		case EActionTypes.INTERPRETATION_MOVE_TO_NETVISOR_ERROR:
			return interpretationsToNetvisorError(state, action);
		case EActionTypes.INTERPRETATION_ADD_VIDEO_ROOM_START:
			return addVideoRoomStart(state, action);
		case EActionTypes.INTERPRETATION_ADD_VIDEO_ROOM_SUCCESS:
			return addVideoRoomSuccess(state, action);
		case EActionTypes.INTERPRETATION_ADD_VIDEO_ROOM_ERROR:
			return addVideoRoomError(state, action);
		case EActionTypes.INTERPRETATION_ADD_VIDEO_ROOM_CLEAR:
			return addVideoRoomClear(state, action);
		case EActionTypes.INTERPRETATION_REMOVE_VIDEO_ROOM_START:
			return removeVideoRoomStart(state, action);
		case EActionTypes.INTERPRETATION_REMOVE_VIDEO_ROOM_SUCCESS:
			return removeVideoRoomSuccess(state, action);
		case EActionTypes.INTERPRETATION_REMOVE_VIDEO_ROOM_ERROR:
			return removeVideoRoomError(state, action);
		case EActionTypes.INTERPRETATION_REMOVE_VIDEO_ROOM_CLEAR:
			return removeVideoRoomClear(state, action);
		case EActionTypes.INTERPRETATION_CONFIRM_OFFER_USER_START:
			return confirmOfferUserStart(state, action);
		case EActionTypes.INTERPRETATION_CONFIRM_OFFER_USER_SUCCESS:
			return confirmOfferUserSuccess(state, action);
		case EActionTypes.INTERPRETATION_CONFIRM_OFFER_USER_FAIL:
			return confirmOfferUserError(state, action);
		case EActionTypes.INTERPRETATION_AVAILABLE_INTERPRETERS_START:
			return getAvailableInterpretersStart(state, action);
		case EActionTypes.INTERPRETATION_AVAILABLE_INTERPRETERS_SUCCESS:
			return getAvailableInterpretersSuccess(state, action);
		case EActionTypes.INTERPRETATION_AVAILABLE_INTERPRETERS_FAIL:
			return getAvailableInterpretersError(state, action);
		default:
			return state;

	}
};

export default reducer;
