import React from "react";
import { ISentMail } from "../../interfaces/ISentMail";
import classes from "./SentMail.module.scss"

interface IProps {
	sentMail: ISentMail;
}

const SentMail: React.FC<IProps> = ({ sentMail }) => {
	return <div className={classes.infoItem}>
		<span>Temaplte name : {sentMail.mailName}</span>
		<span>Sent : {sentMail.dateSent}</span>
		<span>Sender: {sentMail.sender.firstName}</span>
	</div>
}

export default SentMail;