import { Dispatch } from 'redux';
import { customFetch, EFetchMethod } from '../../custom-fetch';
import EActionTypes from '../../interfaces/store/EActionTypes';
import ILeadsAction from '../../interfaces/store/ILeadsAction';
import { ILead, ILeadNew } from '../../interfaces/ILead';
import { ILeadsFetchOptions } from '../../hooks/useLeadsList';
import { ISendgridEmailTemplate } from '../../interfaces/ISendgridTemplate';

type TAction = ILeadsAction;

const getLeadsStart = (): TAction => {
	return {
		type: EActionTypes.LEADS_GET_START,
	};
};

const getLeadsSuccess = (leads: ILead[], count: number): TAction => {
	return {
		type: EActionTypes.LEADS_GET_SUCCESS,
		leads,
		count
	};
};

const getLeadsError = (error: string): TAction => {
	return {
		type: EActionTypes.LEADS_GET_ERROR,
		error,
	};
};

export const getLeadsClear = () => {
	return {
		type: EActionTypes.LEADS_GET_CLEAR,
	};
}

export const getLeads = (params: ILeadsFetchOptions) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getLeadsStart());
			customFetch<{ data: ILead[], count: number }>(`/competitions`, EFetchMethod.POST, JSON.stringify(params)).then((result) => {
				dispatch(getLeadsSuccess(result.data, result.count));
			}).catch((err) => {
				dispatch(getLeadsError((err as Error).message));
			});
		} catch (error) {
			dispatch(getLeadsError((error as Error).message));
		}
	};
};


const updateLeadsStart = (): TAction => {
	return {
		type: EActionTypes.LEADS_UPDATE_START,
	};
};

const updateLeadsSuccess = (leads: ILead[]): TAction => {
	return {
		type: EActionTypes.LEADS_UPDATE_SUCCESS,
		leads,
	};
};

const updateLeadsError = (error: string): TAction => {
	return {
		type: EActionTypes.LEADS_UPDATE_ERROR,
		error,
	};
};

export const updateSentMails = (leads: ILead[], template: ISendgridEmailTemplate) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(updateLeadsStart());
			customFetch<{ data: ILead[] }>(`/competitions/updatemails`, EFetchMethod.POST, JSON.stringify({ leads, templateId: template.id, templateName: template.name })).then((result) => {
				dispatch(updateLeadsSuccess(result.data));
			}).catch((err) => {
				dispatch(updateLeadsError((err as Error).message));
			});
		} catch (error) {
			dispatch(updateLeadsError((error as Error).message));
		}
	};
}

const createNewLeadsStart = (): TAction => {
	return {
		type: EActionTypes.LEADS_CREATE_NEW_LEADS_START,
	};
};

const createNewLeadsSuccess = (): TAction => {
	return {
		type: EActionTypes.LEADS_CREATE_NEW_LEADS_SUCCESS,
	};
};

const createNewLeadsError = (error: string): TAction => {
	return {
		type: EActionTypes.LEADS_CREATE_NEW_LEADS_ERROR,
		error,
	};
};

export const createNewLeadsClear = (): TAction => {
	return {
		type: EActionTypes.LEADS_CREATE_NEW_LEADS_CLEAR,
	};
};

export const createNewLeads = (leads: ILeadNew[]) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(createNewLeadsStart());
			customFetch<{ data: any }>(`/competitions/addleadsinternal`, EFetchMethod.POST, JSON.stringify({ leads })).then((result) => {
				dispatch(createNewLeadsSuccess());
			}).catch((err) => {
				dispatch(createNewLeadsError((err as Error).message));
			});
		} catch (error) {
			dispatch(createNewLeadsError((error as Error).message));
		}
	};
}


const deleteLeadsStart = (): TAction => {
	return {
		type: EActionTypes.LEADS_DELETE_LEADS_START,
	};
};

const deleteLeadsSuccess = (): TAction => {
	return {
		type: EActionTypes.LEADS_DELETE_LEADS_SUCCESS,
	};
};

const deleteLeadsError = (error: string): TAction => {
	return {
		type: EActionTypes.LEADS_DELETE_LEADS_ERROR,
		error,
	};
};

export const deleteLeadsClear = (): TAction => {
	return {
		type: EActionTypes.LEADS_DELETE_LEADS_CLEAR,
	};
};

export const deleteLeads = (keys: string[]) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(deleteLeadsStart());
			customFetch<{ data: any }>(`/competitions/delete`, EFetchMethod.DELETE, JSON.stringify({ leads: keys })).then((result) => {
				dispatch(deleteLeadsSuccess());
			}).catch((err) => {
				dispatch(deleteLeadsError((err as Error).message));
			});
		} catch (error) {
			dispatch(deleteLeadsError((error as Error).message));
		}
	};
}

