import React, { useState } from "react";
import { ISummary } from "../../../store/actions/summariesActions";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useCreateInput } from "../../../hooks/useCreateInput";
import InputGroup from "../../ui/InputGroup/InputGroup";
import Button, { EButtonColor } from "../../ui/Button/Button";
import { getInputData } from "../../ui/Input/input-utils";
import { useHistory } from "react-router-dom";
import { ERoute } from "../../../classes/Routes";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions";


interface IProps {
	summary: ISummary;
	updateAdminNotes: (adminNotes: string) => void;
}

enum EInputs {
	adminNotes = "adminNotes",
}

const SummaryNotes: React.FC<IProps> = ({ summary, updateAdminNotes }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.adminNotes]: {
			type: EInputType.textarea,
			value: summary.adminNotes,
			labelTranslation: ETranslation.COMMON_NOTES
		},
	});

	const createInput = useCreateInput(inputs, setInputs, { labelStyles: { fontWeight: 'bold' }});

	const saveAdminNotes = ()=>{
		const data = getInputData<{adminNotes: string}>(inputs);
		updateAdminNotes(data.adminNotes);
	}

	const handleCancel = () =>{
		history.push(ERoute.SUMMARIES_LIST);
		dispatch(actions.updateSummaryClear());
	}

	const { t } = useTranslation();
	return (
		<>
			{createInput(EInputs.adminNotes)}
			<InputGroup>
				<Button onClick={saveAdminNotes}>
					{t(ETranslation.UI_SAVE)}
				</Button>
				<Button color={EButtonColor.DEFAULT} onClick={handleCancel}>
					{t(ETranslation.COMMON_CANCEL)}
				</Button>
			</InputGroup>
		</>
	);
};

export default SummaryNotes;
