import React, { useCallback, useContext } from "react";
import ModalContext, { EModalSize } from "../../ui/Modal/ModalContext";
import InterpretationUserAvailabilityModal from "./InterpretationUserAvailabilityModal";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";


export const useInterpretationUserAvailabilityModal = () => {
	const { setModal } = useContext(ModalContext);

	const { t } = useTranslation();

	const handler = useCallback(
		async (onAdd: (id: string) => void) => {
			setModal({
				isOpen: true,
				size: EModalSize.MEDIUM,
				title: t(ETranslation.COMMON_FAVOURITES),
				content: (
					<InterpretationUserAvailabilityModal
						onAdd={onAdd}
					/>
				),
			});
		},
		[setModal, t]
	);

	return handler;
};
