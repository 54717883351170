import React from "react";
import { EInterpreterFeeType, feeTypesForEventGeneration, INTERPRETER_FEE_TYPE_ALL } from "../../../shared/interpretation-data";
import InputGroup from "../../ui/InputGroup/InputGroup";
import Button from "../../ui/Button/Button";
import { useTranslation } from "react-i18next";


interface IProps {
	generateWorkerPayments: () => void;
	feeType: EInterpreterFeeType;
}

const GenerateWorkerFees: React.FC<IProps> = ({ generateWorkerPayments, feeType }) => {
	const { t } = useTranslation();

	return <InputGroup>
		{feeType && feeTypesForEventGeneration.includes(feeType) && (
			<Button onClick={generateWorkerPayments}>
				Luo {t(INTERPRETER_FEE_TYPE_ALL.find(option => option.value === feeType)?.labelTranslation ?? '')} aineisto
			</Button>
		)}
	</InputGroup>
}

export default GenerateWorkerFees;