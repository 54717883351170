import React, { useContext, useEffect, useState } from "react";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { ISummary } from "../../../store/actions/summariesActions";
import { IInputField, EInputType } from "../../ui/Input/Input";
import { SelectedSummariesContext } from "../../../pages/Summaries/SummariesPage";
import { getInputData } from "../../ui/Input/input-utils";

export enum EInputs {
	itemSelected = "itemSelected",
}

interface IProps {
	summary: ISummary;
	openHandler: (id: string) => void;
}

const SummaryRowItem: React.FC<IProps> = ({ summary, openHandler }) => {
	const { selectedRows, setSelectedRows, selectAll } = useContext(SelectedSummariesContext);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.itemSelected]: {
			type: EInputType.boolean,
			label: "",
			labelId: summary.id,
			value: false,
		},
	})

	const updateInput = (value: boolean) => {
		setInputs(inputs => {
			return {
				...inputs,
				[EInputs.itemSelected]: {
					...inputs[EInputs.itemSelected],
					value: value,
				},
			}
		})
	}

	useEffect(() => {
		if (selectAll) {
			updateInput(true)
		} else {
			updateInput(false);
		}
	}, [selectAll, setInputs])

	const createInput = useCreateInput(inputs, setInputs);

	// TODO(Joonas): De-uglify
	useEffect(() => {
		const { itemSelected } = getInputData<{ itemSelected: boolean }>(inputs);
		const inList = selectedRows.find((item) => item.id === summary.id) ? true : false;
		if (inList && itemSelected) return;
		if (inList && !itemSelected) setSelectedRows(selectedRows.filter((item) => item.id !== summary.id));
		if (!inList && itemSelected) setSelectedRows([...selectedRows, summary]);
	}, [selectedRows, inputs, summary, setSelectedRows]);

	return (
		<tr onClick={() => openHandler(summary.id)}>
			<td onClick={(e) => { e.stopPropagation() }}>{createInput(EInputs.itemSelected)}</td>
			<td>{summary.status}</td>
			<td>{summary.created}</td>
			<td>{summary.startDate}-{summary.endDate}</td>
			<td>{summary.user.name}</td>
			<td>{summary.totals.ratingAverage}</td>
			<td>{summary.totals.interpretationsCount}</td>
			<td>{summary.totals.durationInMinutes}</td>
			<td>
				<table>
					<tbody>
						{summary.summaryRows &&
							summary.summaryRows.map((row, id) => (
								<tr key={id}>
									<td>{row.title}</td>
									<td>{row.value}</td>
								</tr>
							))
						}
						<tr>
							<td>{summary.summaryRowsTotal.title}</td>
							<td>{summary.summaryRowsTotal.value}</td>
						</tr>
					</tbody>
				</table>
			</td>
			<td>{summary.adminNotes}</td>
		</tr>
	);

};
export default SummaryRowItem;
