import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "../../components/ui/Container/Container";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../../components/Heading/Heading";
import { ETranslation } from "../../translations/translation-keys";
import EezyTrusterEvent from "../../components/Eezy/EezyTrusterEvent/EezyTrusterEvent";
import IAppState from "../../interfaces/store/IAppState";
import Spinner from "../../components/ui/Spinner/Spinner";
import { getEezyTrusterEvent, getEventClear } from "../../store/actions";
import Alert, { EAlertColor } from "../../components/ui/Alert/Alert";
import classes from "../../components/Eezy/EezyTrusterEvent/EezyTrusterEvent.module.scss"
import { ERoute } from "../../classes/Routes";
import { getEezyTrusterEventTypeString } from "../../interfaces/IEezyTruster";

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

const EezyTrusterEventPage: React.FC<IProps> = ({ history, match }) => {

	const { id } = match.params;
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		event,
		eventLoading,
		error,
	} = useSelector((state: IAppState) => state.eezyTruster);

	useEffect(() => {
		if (!id) return;
		dispatch(getEezyTrusterEvent(id))
		return () => {
			dispatch(getEventClear());
		}
	}, [id, dispatch])

	const goBack = () => {
		history.push(ERoute.EEZY_TRUSTER_EVENTS);
	}

	return (
		<Container>
			<Heading className={classes.dividerHeader}>{t(ETranslation.EEZY_TRUSTER_EVENT)} | {event && getEezyTrusterEventTypeString(t, event.type)}</Heading>
			{error && <Alert color={EAlertColor.DANGER}>{error}</Alert>}
			{eventLoading && <Spinner />}
			{event && <EezyTrusterEvent event={event} goBack={goBack} />}
		</Container>
	);
};

export default EezyTrusterEventPage;
