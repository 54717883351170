import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { IConnectionDetail, IInterpretation, ILocationDetail } from "../../../interfaces/IInterpretation";
import IAppState from "../../../interfaces/store/IAppState";
import { searchInterpreters } from "../../../services/userServices";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { EInputType, IInputField, IOption, TInputValue } from "../../ui/Input/Input";
import InputGroup from "../../ui/InputGroup/InputGroup";
import Spinner from "../../ui/Spinner/Spinner";
import { EInterpretation } from "../InterpretationDetails/InterpretationDetails";
import InterpretationOfferedUsers from "../InterpretationDetails/InterpretationOfferedList";
import { useInterpretationUserAvailabilityModal } from "../InterpretationUserAvailabilityModal/useInterpretationUserAvailabilityModal";
import IInterpretationState from "../../../interfaces/store/IInterpretationState";
import { EInterpreterOfferStatus } from "../../../shared/interpretation-data";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import * as actions from '../../../store/actions';
import classes from "./interpretersOffered.module.scss";
import * as interpretationService from '../../../services/interpretationServices';
import { useInterpretationInterpreters } from "../../../hooks/useInterpretationInterpreters";

interface IProps {
	interpretation: IInterpretation;
	editHandler: (
		propertyName: EInterpretation,
		value: TInputValue | ILocationDetail | IConnectionDetail,
		secondaryValue?: TInputValue
	) => Promise<void>;
}

const InterpretersOffered: React.FC<IProps> = ({ interpretation, editHandler }) => {
	const [edit, setEdit] = useState(false);
	const [isSendingDrafts, setIsSendingDrafts] = useState(false);


	const { t } = useTranslation();
	const { updateLoading } = useSelector<IAppState, IInterpretationState>((state) => state.interpretation);
	const openAvailabilityModal = useInterpretationUserAvailabilityModal();
	const openConfirmModal = useConfirmModal();
	const dispatch = useDispatch();

	const interpreters = useInterpretationInterpreters(interpretation);

	const includesDraft = useMemo(
		() => interpreters?.some((user) => user.offerStatus === EInterpreterOfferStatus.DRAFT),
		[interpreters]
	);
	const includesOpen = useMemo(
		() => interpreters?.some((user) => user.offerStatus === EInterpreterOfferStatus.OPEN),
		[interpreters]
	);

	const addInterpreterHandler = (id: string) => {
		editHandler(EInterpretation.interpreterForInterpretationUserRows, id);
	};

	const sendAutoOffersHandler = async () => {
		const success = await openConfirmModal(t(ETranslation.INTREPRETATION_CONFIRM_SEND_AUTOMATIC_OFFERS));
		if (success) {
			editHandler(EInterpretation.addInterpretationUserRows, "");
		}
	};

	const removeOpenInterpretersHandler = async () => {
		const success = await openConfirmModal(t(ETranslation.INTREPRETATION_CONFIRM_DELETE_OPEN_OFFERS));
		if (success) {
			editHandler(EInterpretation.deleteInterpretersInterpretationUserRow, "");
		}
	};


	// Bad implemenation do not copy.
	// :D
	const sendDraftInterpretersHandler = async () => {
		const success = await openConfirmModal(
			t(ETranslation.INTREPRETATION_CONFIRM_SEND_OPEN_OFFERS)
		);
		if (success) {
			const draftUsers = interpreters!.filter(user => user.offerStatus === EInterpreterOfferStatus.DRAFT);
			if (draftUsers) {
				setIsSendingDrafts(true);
				await Promise.all(draftUsers.map(row => interpretationService.updateInterpretation(interpretation.id, "offerStatus_" + row.user.id, EInterpreterOfferStatus.OPEN)));
				setIsSendingDrafts(false);
				dispatch(actions.getInterpretation(interpretation.id));
			}
		}
	};

	const openUserAvailabilityHandler = () => {
		openAvailabilityModal((id) => {
			editHandler(EInterpretation.interpreterForInterpretationUserRows, id);
		});
	};


	return (
		<>
			<InterpretationOfferedUsers interpretation={interpretation} users={interpreters} />
			<div className={classes.ButtonGroupStyles}>
				<Button disabled={!includesDraft} loading={isSendingDrafts} size={EButtonSize.SMALL} onClick={sendDraftInterpretersHandler}>
					{t(ETranslation.INTREPRETATION_SEND_DRAFTS)}
				</Button>
				<Button
					disabled={!includesOpen}
					size={EButtonSize.SMALL}
					onClick={removeOpenInterpretersHandler}
					color={EButtonColor.DANGER}
				>
					{t(ETranslation.INTERPRETATION_REMOVE_OPEN_OFFERS)}
				</Button>
			</div>
			<div className={classes.ButtonGroupStyles}>
				{
					<Button
						color={edit ? EButtonColor.DEFAULT : EButtonColor.SUCCESS}
						size={EButtonSize.SMALL}
						onClick={() => setEdit(!edit)}
					>
						{edit ? t(ETranslation.INTREPRETATION_CLOSE_OFFER_ADD) : t(ETranslation.INTERPRETATION_ADD_INTERPRETER)}
					</Button>
				}
				<Button size={EButtonSize.SMALL} onClick={sendAutoOffersHandler}>
					{t(ETranslation.INTERPRETATION_SEND_AUTOMATIC_OFFERS)}
				</Button>
				<Button size={EButtonSize.SMALL} onClick={openUserAvailabilityHandler}>
					{t(ETranslation.COMMON_FAVOURITES)}
				</Button>
			</div>
			{edit && (
				<InterpretersOfferedAdd
					loading={updateLoading}
					onAdd={addInterpreterHandler}
					onClose={() => setEdit(false)}
				/>
			)}
		</>
	);
};

export enum EInputs {
	interpreter = "interpreter",
}

interface IAddProps {
	loading: boolean;
	onAdd: (id: string) => void;
	onClose: VoidFunction;
}

const InterpretersOfferedAdd: React.FC<IAddProps> = ({ loading, onAdd, onClose }) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.interpreter]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_INTERPRETER,
			value: null,
			multiple: false,
			isClearable: true,
			disabled: loading,
		},
	});

	const { t } = useTranslation();

	const interpreter = inputs[EInputs.interpreter].value as IOption | null;

	const createInput = useCreateInput(inputs, setInputs);

	return (
		<div>
			{loading ? (
				<Spinner />
			) : (
				<>
					{createInput(EInputs.interpreter, {
						fetchOptions: searchInterpreters,
					})}
					<InputGroup style={{ margin: "0" }}>
						<Button
							size={EButtonSize.SMALL}
							loading={loading}
							disabled={interpreter === null}
							onClick={() => onAdd(interpreter!.value)}
						>
							{t(ETranslation.COMMON_ADD_NEW)}
						</Button>
						<Button size={EButtonSize.SMALL} color={EButtonColor.DEFAULT} onClick={onClose}>
							{t(ETranslation.COMMON_CLOSE)}
						</Button>
					</InputGroup>
				</>
			)}
		</div>
	);
};

export default InterpretersOffered;
