import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { IUserGroup } from '../../interfaces/IUserGroup';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IUserGroupAction from '../../interfaces/store/IUserGroupAction';

type TAction = IUserGroupAction;

const findUserGroupsStart = (): TAction => {
  return {
    type: EActionTypes.USER_GROUP_FIND_SUCCESS,
  };
};

const findUserGroupsSuccess = (userGroups: IUserGroup[]): TAction => {
  return {
    type: EActionTypes.USER_GROUP_FIND_SUCCESS,
    userGroups,
  };
};

const findUserGroupsFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_GROUP_FIND_FAIL,
    error,
  };
};

export const findUserGroupsClear = (): TAction => {
	return {
		type: EActionTypes.USER_GROUP_FIND_CLEAR,
	};
};

export const findUserGroups = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findUserGroupsStart());
      const userGroups = await customFetch<IUserGroup[]>("/usergroups/list");
      dispatch(findUserGroupsSuccess(userGroups));
    } catch (error) {
      dispatch(findUserGroupsFail((error as Error).message));
    }
  };
};

const getUserGroupStart = (): TAction => {
  return {
    type: EActionTypes.USER_GROUP_GET_START,
  };
};

const getUserGroupSuccess = (userGroup: IUserGroup): TAction => {
  return {
    type: EActionTypes.USER_GROUP_GET_SUCCESS,
    userGroup,
  };
};

const getUserGroupFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_GROUP_GET_FAIL,
    error,
  };
};

export const getUserGroupClear = (): TAction => {
  return {
    type: EActionTypes.USER_GROUP_GET_CLEAR,
  };
};

export const getUserGroup = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getUserGroupStart());
      const priceSeason = await customFetch<IUserGroup>(`/usergroups/get?id=${id}`);
      dispatch(getUserGroupSuccess(priceSeason));
    } catch (error) {
      dispatch(getUserGroupFail((error as Error).message));
    }
  };
};

const deleteUserGroupStart = (): TAction => {
  return {
    type: EActionTypes.USER_GROUP_DELETE_START,
  };
};

const deleteUserGroupSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.USER_GROUP_DELETE_SUCCESS,
    id,
  };
};

const deleteUserGroupFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_GROUP_DELETE_FAIL,
    error,
  };
};

export const deleteUserGroupClear = (): TAction => {
  return {
    type: EActionTypes.USER_GROUP_DELETE_CLEAR,
  };
};

export const deleteUserGroup = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteUserGroupStart());
      await customFetch<boolean>(`/usergroups/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteUserGroupSuccess(id));
    } catch (error) {
      dispatch(deleteUserGroupFail((error as Error).message));
    }
  };
};

const saveUserGroupStart = (): TAction => {
  return {
    type: EActionTypes.USER_GROUP_SAVE_START,
  };
};

const saveUserGroupSuccess = (userGroup: IUserGroup): TAction => {
  return {
    type: EActionTypes.USER_GROUP_SAVE_SUCCESS,
    userGroup
  };
};

const saveUserGroupFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_GROUP_SAVE_FAIL,
    error,
  };
};

export const saveUserGroup = (userGroup: IUserGroup) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveUserGroupStart());
      userGroup = await customFetch<IUserGroup>("/usergroups/add", EFetchMethod.POST, JSON.stringify(userGroup));
      dispatch(saveUserGroupSuccess(userGroup));
    } catch (error) {
      dispatch(saveUserGroupFail((error as Error).message));
    }
  };
};

const updateUserGroupStart = (): TAction => {
  return {
    type: EActionTypes.USER_GROUP_UPDATE_START,
  };
};

const updateUserGroupSuccess = (userGroup: IUserGroup): TAction => {
  return {
    type: EActionTypes.USER_GROUP_UPDATE_SUCCESS,
    userGroup
  };
};

const updateUserGroupFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_GROUP_UPDATE_FAIL,
    error,
  };
};

export const updateUserGroup = (userGroup: IUserGroup) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateUserGroupStart());
      userGroup = await customFetch<IUserGroup>("/usergroups/update", EFetchMethod.PUT, JSON.stringify(userGroup));
      dispatch(updateUserGroupSuccess(userGroup));
    } catch (error) {
      dispatch(updateUserGroupFail((error as Error).message));
    }
  };
};

export const saveOrUpdateUserGroupClear = (): TAction => {
  return {
    type: EActionTypes.USER_GROUP_SAVE_OR_UPDATE_CLEAR
  }
}

