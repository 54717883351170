import React, { useCallback } from 'react';
import Button, { EButtonColor } from '../../../ui/Button/Button';
import { IInputField } from '../../../ui/Input/Input';
import InputGroup from '../../../ui/InputGroup/InputGroup';
import { EInterpretationSearchInputs } from '../InterpretationsSearch';
import * as dateFns from 'date-fns';
import { updateInputHandler } from '../../../ui/Input/input-utils';


interface IProps {
	setInputs: React.Dispatch<React.SetStateAction<IInputField>>
}

const months = Array.from({ length: 12 }, (_, i) => i + 1);

const SearchMonthSelector: React.FC<IProps> = ({ setInputs }) => {

	const updateMonthHandler = useCallback((month: number) => {
		updateInputHandler(EInterpretationSearchInputs.startDate, dateFns.format(dateFns.setMonth(new Date(), month), 'yyyy-MM-01'), setInputs);
		updateInputHandler(EInterpretationSearchInputs.endDate, dateFns.format(dateFns.lastDayOfMonth(dateFns.setMonth(new Date(), month)), 'yyyy-MM-dd'), setInputs);
		updateInputHandler(EInterpretationSearchInputs.startTime, '00:00', setInputs);
		updateInputHandler(EInterpretationSearchInputs.endTime, '23:59', setInputs);
	}, [setInputs]);

	return (
		<InputGroup style={{ margin: 0 }}>
			{months.map((number, key) => <Button key={key} color={EButtonColor.WHITE} onClick={() => updateMonthHandler(number - 1)}>{number}</Button>)}
		</InputGroup>
	)
}

export default SearchMonthSelector;