import React, { useEffect } from "react";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import FeesList from "../../components/Fee/FeesList";
import { ERoute, Routes } from "../../classes/Routes";
import Button from "../../components/ui/Button/Button";
import FeeSearchInputs, { IFeeSearchParams } from "../../components/Fee/FeeSearchInputs";
import { FeesPagination } from "../../hooks/feesPagination";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IProps extends RouteComponentProps { }

const FeesListPage: React.FC<IProps> = ({history}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch();

	const { loaderRef, fees, loading, searchHandler, hasMoreFees, error } = FeesPagination();

	useEffect(()=>{
		if(!fees && !loading){
			const searchOptions: IFeeSearchParams = {
				page: 1,
			}
			searchHandler(searchOptions)
		}
	}, [dispatch, searchHandler, fees, loading])




	const addFee = () =>{
		history.push(Routes.withParams(ERoute.FEE_EDIT, { id: "add" }));
	}

	const openFee = (feeId: string) =>{
		history.push(Routes.withParams(ERoute.FEE_EDIT, { id: feeId }));
	}
	return (
		<Container>
			<Heading>{t(ETranslation.MENU_FEES)} </Heading>
			<Button onClick={addFee}>{t(ETranslation.COMMON_ADD_NEW)}</Button>
			<FeeSearchInputs loading={loading} searchHandler={searchHandler} />
			<br />
			<FeesList fees={fees} loading={loading} error={error} openFee={openFee} />
			<p
				ref={loaderRef}
			>
				{hasMoreFees ? t(ETranslation.COMMON_SHOW_MORE_ROWS) : ""}
			</p>
		</Container>
	)
}

export default FeesListPage;