import { ETranslation } from "../../translations/translation-keys";
import { EInputType } from "../ui/Input/Input";
import * as dateFns from 'date-fns';

const useWorkingHoursEventsInputs = () => {
	enum EInputs {
		userId = "userId",
		organizationId = "organizationId",
		organizationGroupId = "organizationGroupId",
		startDate = "startDate",
		endDate = "endDate",
		projectId = "projectId",
	}

	const initialInputs = {
		[EInputs.startDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: dateFns.formatISO(dateFns.startOfMonth(new Date()), { representation: "date" }),
			validation: {
				required: true,
			},
		},
		[EInputs.endDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: dateFns.formatISO(dateFns.endOfMonth(new Date()), { representation: "date" }),
			validation: {
				required: true,
			},
		},
		[EInputs.userId]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_USER,
			value: "",
			validation: {
				required: false,
			},
		},
		[EInputs.organizationId]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_CUSTOMER_ORGANIZATION,
			value: "",
			validation: {
				required: false,
			},
		},
		[EInputs.organizationGroupId]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_CUSTOMER_ORGANIZATION_GROUP,
			value: "",
			validation: {
				required: false,
			},
		},
		[EInputs.projectId]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_PROJECT,
			value: "",
			isClearable: true,
			validation: {
				required: false,
			},
		},
	}

	return { initialInputs, EInputs }
}

export default useWorkingHoursEventsInputs;