import React, { CSSProperties, useEffect, useState } from "react";

import { IInterpretation, IInterpretationUserRows } from "../../../interfaces/IInterpretation";
import InterpreterStatus from "../InterpreterStatus/InterpreterStatus";
import { faCheck, faEdit, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { ETranslation } from "../../../translations/translation-keys";
import {
	EInterpretationStatus,
	EInterpreterOfferStatus,
	INTERPRETER_OFFER_STATUS_SELECT,
} from "../../../shared/interpretation-data";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { getInputData } from "../../ui/Input/input-utils";
import { useDispatch, useSelector } from "react-redux";
import { getInterpretation, updateInterpretationProperty } from "../../../store/actions";
import IAppState from "../../../interfaces/store/IAppState";
import Spinner, { ESpinnerSize } from "../../ui/Spinner/Spinner";
import InputGroup from "../../ui/InputGroup/InputGroup";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import * as actions from "../../../store/actions";
import IInterpretationState from "../../../interfaces/store/IInterpretationState";
import Table from "../../ui/Table/Table";
import { useTranslation } from "react-i18next";
import classes from "./interpretationDetails.module.scss";

interface IListProps {
	interpretation: IInterpretation;
	users: IInterpretationUserRows[];
	noControlls?: boolean;
}

const InterpretationOfferedUsers: React.FC<IListProps> = ({ interpretation, users, noControlls }) => {
	const { t } = useTranslation();


	if (!users || users.length === 0) {
		return <p>{t(ETranslation.INTERPRETATION_NO_WORKERS)}.</p>;
	}

	return (
		<Table containerClassName={classes.OfferedUsersTableContainer}>
			<tbody>
				{users.map((user, key) => (
					<InterpretationOfferRow key={key} row={user} interpretation={interpretation} noControlls={noControlls} />
				))}
			</tbody>
		</Table>
	);
};

interface IOfferRowProps {
	row: IInterpretationUserRows;
	interpretation: IInterpretation;
	noControlls?: boolean;
}

enum EInputs {
	offerStatus = "offerStatus",
}

// Tehdään Lähetä luonnokset -painike

const InterpretationOfferRow: React.FC<IOfferRowProps> = ({ row, interpretation, noControlls }) => {
	const [editMode, setEditMode] = useState(false);

	const openConfirmModal = useConfirmModal();

	const offerStatus = row.offerStatus;
	const interpretationId = interpretation.id;
	const interpretationStatus = interpretation.status;
	const editable = noControlls ? false : true;

	//INTERPRETER_OFFER_STATUS_SELECT

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.offerStatus]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_STATUS,
			value: offerStatus,
			options: INTERPRETER_OFFER_STATUS_SELECT(),
			multiple: false,
			isClearable: false,
		},
	});

	const dispatch = useDispatch();

	const { updateLoading, offerConfirmId, offerConfirmLoading } = useSelector<
		IAppState,
		IInterpretationState
	>((state) => state.interpretation);

	const [updatedOrDeleted, setuod] = useState(false);

	const [load, setLoad] = useState(false);
	useEffect(() => {
		if (!updatedOrDeleted) return;
		if (!load && !updateLoading) return;
		if (!load && updateLoading) {
			setLoad(true);
			return;
		}
		if (load && !updateLoading) {
			setEditMode(false);
			dispatch(getInterpretation(interpretationId));
			setLoad(false);
			return;
		}
		// prevent inf loop. TODO, usePrevious state to compare these and cleanup
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateLoading]);

	const update = () => {
		setuod(true);
		const data = getInputData<{ offerStatus: string }>(inputs);
		dispatch(updateInterpretationProperty(interpretationId, "offerStatus_" + row.user.id, data.offerStatus));
	};

	const deleteRow = () => {
		setuod(true);
		dispatch(updateInterpretationProperty(interpretationId, "deleteInterpretationUserRow", row.id));
	};

	const confirmOfferUserHandler = async () => {
		const success = await openConfirmModal("Haluatko varmasti vahvistaa työntekijän?");
		if (success) {
			dispatch(actions.confirmOfferUser(interpretationId, row.id));
		}
	};

	const actionStyles: CSSProperties = { cursor: "pointer", color: "initial" };

	const createInput = useCreateInput(inputs, setInputs);

	return (
		<tr>
			<td className={editMode ? classes.OfferedUsersBadgeColumnEdit : classes.OfferedUsersBadgeColumn}>
				{editMode ? (
					<InputGroup>
						{createInput(EInputs.offerStatus)}
						{load ? (
							<Spinner />
						) : (
							<FontAwesomeIcon
								icon={faCheck}
								onClick={update}
								className={classes.ConfirmEditButton}
							/>
						)}
					</InputGroup>
				) : (
					<>
						<InterpreterStatus status={offerStatus} />
						<InterpretationOfferRowTime row={row} />
					</>
				)}
			</td>
			<td>
				<a href={`/users/edit/${row.user.id}`} target="_blank" rel="noopener noreferrer">
					<b>{`${row.user.firstName} ${row.user.lastName} ${row.user.phoneNumber}`}</b>
				</a>
			</td>
			<td style={{ width: 1 }}>
				{load ? (
					<Spinner />
				) : (
					editable && (
						<div className={classes.EditActions}>
							{offerStatus === EInterpreterOfferStatus.ACCEPTED &&
								interpretationStatus === EInterpretationStatus.WAITING_CUSTOMER_APPROVAL && (
									<>
										{offerConfirmLoading ? (
											offerConfirmId === row.id ? (
												<Spinner size={ESpinnerSize.SMALL} />
											) : null
										) : (
											<FontAwesomeIcon
												icon={faCheck}
												onClick={confirmOfferUserHandler}
												style={actionStyles}
											/>
										)}
									</>
								)}
							<FontAwesomeIcon
								icon={editMode ? faTimes : faEdit}
								onClick={() => setEditMode((editMode) => !editMode)}
								style={actionStyles}
							/>
							<FontAwesomeIcon icon={faTrash} onClick={() => deleteRow()} style={actionStyles} />
						</div>
					)
				)}
			</td>
		</tr>
	);
};

interface IOfferRowTimeProps {
	row: IInterpretationUserRows;
}

const InterpretationOfferRowTime: React.FC<IOfferRowTimeProps> = ({ row }) => {

	return <span className={classes.OfferRowDateTime}>{row.updated}</span>
}

export default InterpretationOfferedUsers;
