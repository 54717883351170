import React from "react";
import classes from "../UserStyles.module.scss";
import { faEdit, faEnvelope, faFileContract, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EUserRole, IUser } from "../../../classes/User";
import {
	resetSendPassowrdClear,
	resetEezyTrusterClear,
	sendNewPassword,
	resetEezyTrusterId,
} from "../../../store/actions/userActions";
import { ETranslation } from "../../../translations/translation-keys";
import ActionButton from "../../ui/ActionButton/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ERoute, Routes } from "../../../classes/Routes";
import useFeeTypeChangeModal from "../UserUpdateTools/WorkerPaymentTypeUpdateModal";
import Button from "../../ui/Button/Button";

interface IProps {
	user: IUser;
}

const UserEditActions: React.FC<IProps> = ({ user }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		passwordResetLoading,
		passwordResetError,
		passwordResetSuccess,
		message,
		resetEezyTrusterLoading,
		resetEezyTrusterError,
		resetEezyTrusterSuccess,
	} = useSelector((state: IAppState) => state.user);

	const resetAndSendPassword = () => {
		dispatch(sendNewPassword(user));
	};

	const resetEezyId = () => {
		dispatch(resetEezyTrusterId(user.id));
	};

	const openFeeTypeChangeModal = useFeeTypeChangeModal()

	const jobApplicationId = user.jobApplicationId;
	return (
		<div className={classes.headerActionsCont}>
			<ActionButton
				onClick={resetAndSendPassword}
				actionLoading={passwordResetLoading}
				actionSuccess={passwordResetSuccess}
				actionError={passwordResetError}
				successMessage={message ? `${t(ETranslation.USER_NEW_PASSWORD_IS)}: ` + message : null}
				onReset={() => dispatch(resetSendPassowrdClear())}
			>
				<FontAwesomeIcon icon={faEnvelope} /> {t(ETranslation.USER_RESET_AND_SEND_PASSWORD)}
			</ActionButton>
			{user.eezyId && (
				<ActionButton
					onClick={resetEezyId}
					actionLoading={resetEezyTrusterLoading}
					actionSuccess={resetEezyTrusterSuccess}
					actionError={resetEezyTrusterError}
					successMessage={resetEezyTrusterSuccess ? `Käyttäjän Eezy/Truster ID on nollattu` : null}
					onReset={() => dispatch(resetEezyTrusterClear())}
				>
					<FontAwesomeIcon icon={faTrash} /> {t(ETranslation.USER_RESET_EEZY_TRUSTER_ID)}
				</ActionButton>
			)}
			{jobApplicationId && (
				<ActionButton
					onClick={() => history.push(Routes.withParams(ERoute.JOB_APPLICATION_EDIT, { id: jobApplicationId, tab: 'basic' }))}
					actionLoading={false}
					actionSuccess={false}
					actionError={null}
					successMessage={null}
					requireConfirm={false}
					onReset={() => { }}
				>
					<FontAwesomeIcon icon={faFileContract} /> Näytä hakemus
				</ActionButton>
			)}
			{user.role === EUserRole.INTERPRETER &&
				<Button className={classes.actionButton} onClick={() => openFeeTypeChangeModal(user)} >
					<FontAwesomeIcon icon={faEdit} />
					{t(ETranslation.EDIT_USER_FEE_TYPE_BUTTON)}
				</Button>
			}
		</div>
	);
};

export default UserEditActions;
