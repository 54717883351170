import React from 'react';
import Table from '../../ui/Table/Table';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';
import { getEezyTrusterEventTypeString, IEezyTrusterEvent } from '../../../interfaces/IEezyTruster';

interface IProps {
	events: IEezyTrusterEvent[];
	onClick: (id: string) => void;
}

const EezyTrusterEventsList: React.FC<IProps> = ({ events, onClick }) => {
	const { t } = useTranslation()

	return (
		<>
			<Table>
				<thead>
					<tr>
						<th>{t(ETranslation.COMMON_TYPE)}</th>
						<th>{t(ETranslation.COMMON_CREATED)}</th>
						<th>{t(ETranslation.COMMON_SENT)}</th>
						<th>{t(ETranslation.COMMON_TIMESPAN)}</th>
					</tr>
				</thead>
				<tbody>
					{events.map((event) => {
						return <tr key={event.id} onClick={() => onClick(event.id)}>
							<td>
								{getEezyTrusterEventTypeString(t, event.type)}
							</td>
							<td>
								{event.created}
							</td>
							<td>
								{event.sentDate ?? ""}
							</td>
							<td>
								{event.startDate} - {event.endDate}
							</td>
						</tr>
					})}
				</tbody>
			</Table>
		</>
	);
};

export default EezyTrusterEventsList;
