import { customFetch, EFetchMethod } from "../custom-fetch";
import { IOrganization } from "../interfaces/IOrganization";
import { createOptions } from "../utils/option-utils";

export const searchOrganizations = async (term: string, signal: AbortSignal) => {
	const organizations = await customFetch<IOrganization[] | string>(
		"/organizations/search?q=" + term,
		EFetchMethod.GET,
		undefined,
		signal
	);
	if (organizations === "NO_RESULTS") {
		return [];
	}
	return createOptions(organizations as IOrganization[]);
};

export const getOrganizationOptions = async (ids: string[]) => {
	try {
		const organizations = await Promise.all(
			ids.map((id) => {
				return customFetch<IOrganization>("/organizations?id=" + id);
			})
		);
		return createOptions(organizations as IOrganization[]);
	} catch (e) {
		return [];
	}
};
