export enum ETranslation {

	// Commissions
	COMMISSIONS_GENERAL = "COMMISSIONS_GENERAL",

	// Menu
	MENU_PRICES = "MENU_PRICES",
	MENU_MESSAGES = "MENU_MESSAGES",

	// Contact Reqeust
	CONTACT_REQUEST_STATUS = "CONTACT_REQUEST_STATUS",
	CONTACT_REQUEST_CREATED = "CONTACT_REQUEST_CREATED",
	CONTACT_REQUEST_LANGUAGE = "CONTACT_REQUEST_LANGUAGE",
	CONTACT_REQUEST_PHONE_NUMBER = "CONTACT_REQUEST_PHONE_NUMBER",
	CONTACT_REQUEST_COMMENTS = "CONTACT_REQUEST_COMMENTS",

	// Message
	MESSAGE_TYPE = "MESSAGE_TYPE",
	MESSAGE_ATTACHMENT = "MESSAGE_ATTACHMENT",
	MESSAGE_NAME = "MESSAGE_NAME",
	MESSAGE_ORGANIZATIONS = "MESSAGE_ORGANIZATIONS",
	MESSAGE_CREATION = "MESSAGE_CREATION",
	MESSAGE_FILTER_BY_NAME = "MESSAGE_FILTER_BY_NAME",
	MESSAGE_MORE_INFORMATION = "MESSAGE_MORE_INFORMATION",
	MESSAGE_INFORMATION = "MESSAGE_INFORMATION",

	// NewsItem
	NEWS_FILTER_BY_NAME = "NEWS_FILTER_BY_NAME",
	NEWS_PUBLISHED = "NEWS_PUBLISHED",
	NEWS_CHOOSE_LANGUAGES = "NEWS_CHOOSE_LANGUAGES",
	NEWS_LANGUAGES = "NEWS_LANGUAGES",

	// Organization Group
	ORGANIZATION_GROUP_FILTER_BY_NAME = "ORGANIZATION_GROUP_FILTER_BY_NAME",
	ORGANIZATION_GROUP_NAME = "ORGANIZATION_GROUP_NAME",
	ORGANIZATION_GROUP_DESCRIPTION = "ORGANIZATION_GROUP_DESCRIPTION",

	// Price Seasons
	PRICE_SEASON_TYPE = "PRICE_SEASON_TYPE",
	PRICE_SEASON_FROM_LANGUAGE = "PRICE_SEASON_FROM_LANGUAGE",
	PRICE_SEASON_TO_LANGUAGE = "PRICE_SEASON_TO_LANGUAGE",
	PRICE_SEASON_TAGS = "PRICE_SEASON_TAGS",
	PRICE_SEASON_ALV = "PRICE_SEASON_ALV",
	PRICE_SEASON_INSTANT_INTERPRETATION = "PRICE_SEASON_INSTANT_INTERPRETATION",
	PRICE_SEASON_APPOINTMENT = "PRICE_SEASON_APPOINTMENT",
	PRICE_SEASON_ATTENDANCE = "PRICE_SEASON_ATTENDANCE",
	PRICE_SEASON_DESCRIPTION = "PRICE_SEASON_DESCRIPTION",
	PRICE_SEASON_START_TIME = "PRICE_SEASON_START_TIME",
	PRICE_SEASON_END_TIME = "PRICE_SEASON_END_TIME",
	PRICE_SEASON_CHOOSE_LANGUAGES = "PRICE_SEASON_CHOOSE_LANGUAGES",
	PRICE_SEASON_INSTANT_INTERPRETATION_2 = "PRICE_SEASON_INSTANT_INTERPRETATION_2",
	PRICE_SEASON_APPOINTMENT_2 = "PRICE_SEASON_APPOINTMENT_2",
	PRICE_SEASON_ATTENDANCE_2 = "PRICE_SEASON_ATTENDANCE_2",
	PRICE_SEASON_START_DAY = "PRICE_SEASON_START_DAY",
	PRICE_SEASON_END_DAY = "PRICE_SEASON_END_DAY",
	SITE_DESCRIPTION = "SITE_DESCRIPTION",
	SITE_ADDRESS_TITLE = "SITE_ADDRESS_TITLE",
	SITE_ADDRESS = "SITE_ADDRESS",
	SITE_ZIP = "SITE_ZIP",
	SITE_CITY = "SITE_CITY",
	SITE_PHONE_NUMBER = "SITE_PHONE_NUMBER",
	SITE_ORGANIZATION_NAME = "SITE_ORGANIZATION_NAME",
	SITE_BUSINESS_ID = "SITE_BUSINESS_ID",

	// TextMessage
	TEXTMESSAGE_SEND = "TEXTMESSAGE_SEND",
	TEXTMESSAGE_CANCEL = "TEXTMESSAGE_CANCEL",

	//UI
	UI_ACTIVEBADGE_ACTIVE = "UI_ACTIVEBADGE_ACTIVE",
	UI_ACTIVEBADGE_PASSIVE = "UI_ACTIVEBADGE_PASSIVE",
	UI_EDITBUTTONS_CONFIRM_DELETE = "UI_EDITBUTTONS_CONFIRM_DELETE",
	UI_REACT_SEARCH_MIN_LETTERS = "UI_REACT_SEARCH_MIN_LETTERS",
	UI_REACT_SEARCH_NO_RESULTS = "UI_REACT_SEARCH_NO_RESULTS",
	UI_INPUT_PRESS_OR_DROP_IMAGE = "UI_INPUT_PRESS_OR_DROP_IMAGE",
	UI_INPUT_DROP_IMAGE = "UI_INPUT_DROP_IMAGE",
	UI_SAVE = "UI_SAVE",
	UI_CANCEL = "UI_CANCEL",
	UI_DELETE = "UI_DELETE",
	UI_SEND = "UI_SEND",
	UI_CLEAR = "UI_CLEAR",
	UI_MAKE_ORDER = "UI_MAKE_ORDER",
	UI_LOADING = "UI_LOADING",
	UI_SAVING = "UI_SAVING",
	UI_SHOW_DELETED = "UI_SHOW_DELETED",
	REACT_SELECT_ADD_NEW = "REACT_SELECT_ADD_NEW",
	REACT_SELECT_PLACEHOLDER = "REACT_SELECT_PLACEHOLDER",
	NOT_FOUND_ERROR = "NOT_FOUND_ERROR",


	INTERPRETATION_CANCELED_CUSTOMER = "INTERPRETATION_CANCELED_CUSTOMER",
	INTERPRETATION_CANCELED_WORKER = "INTERPRETATION_CANCELED_WORKER",

	INTERPRETATION_DATA_TYPE_INSTANT = "INTERPRETATION_DATA_TYPE_INSTANT",
	INTERPRETATION_DATA_TYPE_BOOKING = "INTERPRETATION_DATA_TYPE_BOOKING",
	INTERPRETATION_DATA_TYPE_MESSAGE = "INTERPRETATION_DATA_TYPE_MESSAGE",
	INTERPRETATION_DATA_TYPE_DAYCARE_TEACHER = "INTERPRETATION_DATA_TYPE_DAYCARE_TEACHER",
	INTERPRETATION_DATA_TYPE_DAYCARE_SPECIAL_TEACHER = "INTERPRETATION_DATA_TYPE_DAYCARE_SPECIAL_TEACHER",
	INTERPRETATION_DATA_TYPE_DAYCARE_NURSE = "INTERPRETATION_DATA_TYPE_DAYCARE_NURSE",
	INTERPRETATION_DATA_TYPE_DAYCARE_ASSISTANT = "INTERPRETATION_DATA_TYPE_DAYCARE_ASSISTANT",
	INTERPRETATION_DATA_TYPE_DAYCARE_SOCIAL_WORKER = "INTERPRETATION_DATA_TYPE_DAYCARE_SOCIAL_WORKER",
	INTERPRETATION_DATA_TYPE_SUBSTITUTE_ON_SITE = "INTERPRETATION_DATA_TYPE_SUBSTITUTE_ON_SITE",
	INTERPRETATION_DATA_TYPE_SHIFT_ON_SITE = "INTERPRETATION_DATA_TYPE_SHIFT_ON_SITE",
	//menuTranslations
	MENU_ORDER_INTERPRETER = "MENU_ORDER_INTERPRETER",
	MENU_INTERPRETATIONS = "MENU_INTERPRETATIONS",
	MENU_INTERPRETATIONS_LIST = "MENU_INTERPRETATIONS_LIST",
	MENU_ORDERS_PAGE = "MENU_ORDERS_PAGE",
	MENU_ORDER_INTERPRETER_NEW = "MENU_ORDER_INTERPRETER_NEW",
	MENU_PRICE_SEASONS = "MENU_PRICE_SEASONS",
	MENU_SITES = "MENU_SITES",
	MENU_ORGANIZATIONS = "MENU_ORGANIZATIONS",
	MENU_PRICE_SEASON_RULES = "MENU_PRICE_SEASON_RULES",
	MENU_ORGANIZATION_GROUPS = "MENU_ORGANIZATION_GROUPS",
	MENU_ORGANIZATION_COMPANIES = "MENU_ORGANIZATION_COMPANIES",
	MENU_TEXTMESSAGES = "MENU_TEXTMESSAGES",
	MENU_EMAILS_LOGS = "MENU_EMAILS_LOGS",
	MENU_EMAIL_ORDERS_PAGE = "MENU_EMAIL_ORDERS_PAGE",
	MENU_NEWS = "MENU_NEWS",
	MENU_RECORDINGS_AND_TRANSLATIONS = "MENU_RECORDINGS_AND_TRANSLATIONS",
	MENU_CONTACT_REQUESTS = "MENU_CONTACT_REQUESTS",
	MENU_SETTINGS = "MENU_SETTINGS",
	MENU_CUSTOMER_REPORT = "MENU_CUSTOMER_REPORT",
	MENU_ADMIN = "MENU_ADMIN",
	MENU_EEZY_TRUSTER_EVENTS = "MENU_EEZY_TRUSTER_EVENTS",
	MENU_LOG_ITEMS = "MENU_LOG_ITEMS",
	MENU_RESERVED_DATES = "MENU_RESERVED_DATES",
	MENU_USERS = "MENU_USERS",
	MENU_USERS_INTERPRETERS = "MENU_USERS_INTERPRETERS",
	MENU_MANAGEMENT = "MENU_MANAGEMENT",
	MENU_TAGS = "MENU_TAGS",
	MENU_CATEGORIES = "MENU_CATEGORIES",
	MENU_ATTRIBUTES = "MENU_ATTRIBUTES",
	MENU_COMISSIONS = "MENU_COMISSIONS",
	MENU_JOB_APPLICATIONS = 'MENU_JOB_APPLICATIONS',
	MENU_SUMMARIES = "MENU_SUMMARIES",
	MENU_TASKS = "MENU_TASKS",
	MENU_FEES = "MENU_FEES",
	MENU_USERS_CUSTOMERS = "MENU_USERS_CUSTOMERS",
	MENU_USERS_ADMINS = "MENU_USERS_ADMINS",
	MENU_COMMENTS = "MENU_COMMENTS",
	MENU_CALENDAR = "MENU_CALENDAR",
	MENU_WORK_HOURS = "MENU_WORK_HOURS",
	MENU_WORK_HOURS_SUMMARIES = "MENU_WORK_HOURS_SUMMARIES",
	MENU_LANGUAGE_CODES = "MENU_LANGUAGE_CODES",
	MENU_LANGUAGES = "MENU_LANGUAGES",
	MENU_FI = "MENU_FI",
	MENU_EN = "MENU_EN",
	MENU_USER_GROUPS = "MENU_USER_GROUPS",
	MENU_USER_CONTRACT_SETTINGS = "MENU_USER_CONTRACT_SETTINGS",
	MENU_USER_SHIFTS = "MENU_USER_SHIFTS",
	MENU_RESOURCES = "MENU_RESOURCES",

	//usersTranslations
	USER_CONFIRM_DELETE = "USER_CONFIRM_DELETE",
	USER_CONFIRM_NETVISOR_EXPORT = "USER_CONFIRM_NETVISOR_EXPORT",
	USER_ALLOW_ATTACHMENTS = "USER_ALLOW_ATTACHMENTS",
	USER_PAYMENT_INVOICE = "USER_PAYMENT_INVOICE",
	USER_LAST_SEEN = "USER_LAST_SEEN",
	USER_ALLOWED_TYPES = "USER_ALLOWED_TYPES",
	USER_ALLOW_INSTANT = "USER_ALLOW_INSTANT",
	USER_ALLOW_BOOKING = "USER_ALLOW_BOOKING",
	USER_ALLOW_MACHINE = "USER_ALLOW_MACHINE",
	USER_ALLOW_MULTIPLE_DEVICE = "USER_ALLOW_MULTIPLE_DEVICE",
	USER_MODIFY_USER = "USER_MODIFY_USER",
	USER_USERNAME = "USER_USERNAME",
	USER_PASSWORD = "USER_PASSWORD",
	USER_PASSWORD_AGAIN = "USER_PASSWORD_AGAIN",
	USER_COPY_OF_EMAIL = "USER_COPY_OF_EMAIL",
	USER_ADDITIONAL_DETAILS = "USER_ADDITIONAL_DETAILS",
	USER_REFERENCE = "USER_REFERENCE",
	USER_BILLING_REFERENCE = "USER_BILLING_REFERENCE",
	USER_BCC_ADDRESSES = "USER_BCC_ADDRESSES",
	USER_ALLOW_LOGIN = "USER_ALLOW_LOGIN",
	USER_DISPLAY_TEST_LANGUAGES = "USER_DISPLAY_TEST_LANGUAGES",
	USER_DISPLAY_PRICES_AND_PAYMENTS = "USER_DISPLAY_PRICES_AND_PAYMENTS",
	USER_ALLOW_FAVORITE_INTERPRETER = "USER_ALLOW_FAVORITE_INTERPRETER",
	USER_ALLOW_FASTLANE = "USER_ALLOW_FASTLANE",
	USER_EMAIL_WHEN_RESERVATION_CONFIRMED = "USER_EMAIL_WHEN_RESERVATION_CONFIRMED",
	USER_ALLOW_PHONE_CALLS = "USER_ALLOW_PHONE_CALLS",
	USER_ALLOW_MULTIPLE_CLIENTS = "USER_ALLOW_MULTIPLE_CLIENTS",
	USER_ALLOW_VIDEO = "USER_ALLOW_VIDEO",
	USER_PRIVATE_USER = "USER_PRIVATE_USER",
	USER_ALLOW_LOGS = "USER_ALLOW_LOGS",
	USER_BILLING_METHOD = "USER_BILLING_METHOD",
	USER_ALLOW_WORKING_HOURS_DATE = "USER_ALLOW_WORKING_HOURS_DATE",
	USER_ALLOW_WORKING_HOURS_DATE_EDIT = "USER_ALLOW_WORKING_HOURS_DATE_EDIT",
	USER_CREDITCARD_BILLING = "USER_CREDITCARD_BILLING",
	USER_SHOW_ALL_COMPANY_INTERPRETATIONS = "USER_SHOW_ALL_COMPANY_INTERPRETATIONS",
	USER_PHONE_CONFIRMED = "USER_PHONE_CONFIRMED",
	USER_ALLOW_ON_SITE = "USER_ALLOW_ON_SITE",
	USER_SHOW_COMPANY_USERS = "USER_SHOW_COMPANY_USERS",
	USER_ALLOW_VOIP = "USER_ALLOW_VOIP",
	USER_ALLOW_MARKETING = "USER_ALLOW_MARKETING",
	USER_ALLOW_REPORTING = "USER_ALLOW_REPORTING",
	USER_ALLOW_SUMMARIES = "USER_ALLOW_SUMMARIES",
	USER_ALLOW_AUTOMATIC_RESERVATION_PROCESSING = "USER_ALLOW_AUTOMATIC_RESERVATION_PROCESSING",
	USER_SHOW_ALL_GROUP_INTERPRETATIONS = "USER_SHOW_ALL_GROUP_INTERPRETATIONS",
	USER_LOCATION = "USER_LOCATION",
	USER_EMAIL_BCC = "USER_EMAIL_BCC",
	USER_ALTERNATE_PHONENUMBER = "USER_ALTERNATE_PHONENUMBER",
	USER_PAYMENT_BY_INVOICE = "USER_PAYMENT_BY_INVOICE",
	USER_PAYMENT_STRIPE = "USER_PAYMENT_STRIPE",
	USER_PAYMENT_NO_CHARGE = "USER_PAYMENT_NO_CHARGE",
	USER_PAYMENT_ZETTLE = "USER_PAYMENT_ZETTLE",
	USER_STATUS_OF_INTERPRETER = "USER_STATUS_OF_INTERPRETER",
	USER_CONTRACT = "USER_CONTRACT",
	USER_CONTRACT_NOT_MADE = "USER_CONTRACT_NOT_MADE",
	USER_CONTRACT_SEND = "USER_CONTRACT_SEND",
	USER_CONTRACT_COMPLETED = "USER_CONTRACT_COMPLETED",
	USER_AUTOMATIC_PAYMENT = "USER_AUTOMATIC_PAYMENT",
	USER_ALLOW_AUTOMATIC_PAYMENT = "USER_ALLOW_AUTOMATIC_PAYMENT",
	USER_SHOW_PAYMENT = "USER_SHOW_PAYMENT",
	USER_MANAGE_ORGANIZATION_USERS = "USER_MANAGE_ORGANIZATION_USERS",
	USER_ALLOW_ADMIN_FEES = "USER_ALLOW_ADMIN_FEES",
	USER_ALLOW_ADMIN_EEZY_TRUSTER = "USER_ALLOW_ADMIN_EEZY_TRUSTER",
	USER_SHOW_ORGANIZATION_INTERPRETATIONS = "USER_SHOW_ORGANIZATION_INTERPRETATIONS",
	USER_ALLOW_AUTOMATIC_OFFER_BOOKINGS = "USER_ALLOW_AUTOMATIC_OFFER_BOOKINGS",
	USER_ALLOW_JOB_OFFERS = "USER_ALLOW_JOB_OFFERS",
	USER_NETVISOR_DIMENSION_NAME = "USER_NETVISOR_DIMENSION_NAME",
	USER_NETVISOR_PRODUCT_IDENTIFIER = "USER_NETVISOR_PRODUCT_IDENTIFIER",
	USER_NETVISOR_PRODUCT_NAME = "USER_NETVISOR_PRODUCT_NAME",
	USER_SWIFT = "USER_SWIFT",
	USER_HIDE_INTERPRETER_FEE = "USER_HIDE_INTERPRETER_FEE",
	USER_TRAINED_INTERPRETER = "USER_TRAINED_INTERPRETER",
	USER_LEGAL_INTERPRETER = "USER_LEGAL_INTERPRETER",
	USER_LEGAL_INTERPRETER_EDIT_COPY = "USER_LEGAL_INTERPRETER_EDIT_COPY",
	USER_SALARY_PAYMENT_RULE = "USER_SALARY_PAYMENT_RULE",
	USER_SALARY_IMMEDIATELY = "USER_SALARY_IMMEDIATELY",
	USER_SALARY_FIRST_DAY_OF_THE_MONTH = "USER_SALARY_FIRST_DAY_OF_THE_MONTH",
	USER_SALARY_MIDDLE_OF_MONTH = "USER_SALARY_MIDDLE_OF_MONTH",
	USER_SALARY_LAST_DAY_OF_THE_MONTH = "USER_SALARY_LAST_DAY_OF_THE_MONTH",
	USER_ACCORDION_BASIC = "USER_ACCORDION_BASIC",
	USER_ACCORDION_ACCESS = "USER_ACCORDION_ACCESS",
	USER_ACCORDION_LOCATION = "USER_ACCORDION_LOCATION",
	USER_ACCORDION_LANGUAGES = "USER_ACCORDION_LANGUAGES",
	USER_ACCORDION_FAVOURITES = "USER_ACCORDION_FAVOURITES",
	USER_ACCORDION_BLOCKED = "USER_ACCORDION_BLOCKED",
	USER_ACCORDION_NETVISOR = "USER_ACCORDION_NETVISOR",
	USER_ADD_FAVOURITE_INTERPRETER = "USER_ADD_FAVOURITE_INTERPRETER",
	USER_SHOW_COMMENTS = "USER_SHOW_COMMENTS",
	USER_COMMENTS = "USER_COMMENTS",
	USER_USER_LEVEL = "USER_USER_LEVEL",
	USER_INTERPRETER = "USER_INTERPRETER",
	USER_CUSTOMER_FEES = "USER_CUSTOMER_FEES",
	USER_LOGOUT = "USER_LOGOUT",
	USER_RESET_AND_SEND_PASSWORD = "USER_RESET_AND_SEND_PASSWORD",
	USER_RESET_EEZY_TRUSTER_ID = "USER_RESET_EEZY_TRUSTER_ID",
	USER_NEW_PASSWORD_IS = "USER_NEW_PASSWORD_IS",
	INTERPRETATION_BOOKING_ENDED = "INTERPRETATION_BOOKING_ENDED",
	INTERPRETATION_BOOKING_STARTED = "INTERPRETATION_BOOKING_STARTED",
	INTERPRETATION_CUSTOMER_PAYMENT_TYPE = "INTERPRETATION_CUSTOMER_PAYMENT_TYPE",
	INTERPRETATTION_RECLAMATION = "INTERPRETATTION_RECLAMATION",
	INTERPRETATION_ATTRIBUTES = "INTERPRETATION_ATTRIBUTES",
	INTERPRETATION_ADD_ATTRIBUTE = 'INTERPRETATION_ADD_ATTRIBUTE',
	INTERPRETATION_DELETE_ATTRIBUTE = "INTERPRETATION_DELETE_ATTRIBUTE",
	EDIT_USER_FEE_TYPE_BUTTON = "EDIT_USER_FEE_TYPE_BUTTON",
	EDIT_USER_FEE_TYPE = "EDIT_USER_FEE_TYPE",
	EDIT_USER_FEE_TYPE_HELPER_1 = "EDIT_USER_FEE_TYPE_HELPER_1",
	EDIT_USER_FEE_TYPE_HELPER_2 = "EDIT_USER_FEE_TYPE_HELPER_2",

	//commonTranslations
	COMMON_HOURS = "COMMON_HOURS",
	COMMON_PAGES = "COMMON_PAGES",
	COMMON_YOU_CAN_CHOOSE_MULTIPLE_OPTIONS = "COMMON_YOU_CAN_CHOOSE_MULTIPLE_OPTIONS",
	COMMON_EMAIL_SENT = "COMMON_EMAIL_SENT",
	COMMON_EMAIL_SENDER = "COMMON_EMAIL_SENDER",
	COMMON_EMAIL_RECIPIENT = "COMMON_EMAIL_RECIPIENT",
	COMMON_EMAIL_TITLE = "COMMON_EMAIL_TITLE",
	COMMON_EMAIL_CONTENT = "COMMON_EMAIL_CONTENT",
	COMMON_EMAILS = "COMMON_EMAILS",
	COMMON_ROLE = "COMMON_ROLE",
	COMMON_BILL_SENT_DATE = "COMMON_BILL_SENT_DATE",
	COMMON_MOVE_TO_NETVISOR = "COMMON_MOVE_TO_NETVISOR",
	COMMON_FEE_TYPE = "COMMON_FEE_TYPE",
	COMMON_LOST_MINUTES = "COMMON_LOST_MINUTES",
	COMMON_SALARY_PER_HOUR = "COMMON_SALARY_PER_HOUR",
	COMMON_DONE = "COMMON_DONE",
	COMMON_SENT = "COMMON_SENT",
	COMMON_TIMESPAN = "COMMON_TIMESPAN",
	COMMON_BACK = "COMMON_BACK",
	COMMON_RESET = "COMMON_RESET",
	COMMON_REVIEWS = "COMMON_REVIEWS",
	COMMON_PAYMENT_INFORMATION = "COMMON_PAYMENT_INFORMATION",
	COMMON_USER_AGENT = "COMMON_USER_AGENT",
	COMMON_ACTION = "COMMON_ACTION",
	COMMON_ACTIONS = "COMMON_ACTIONS",
	COMMON_DOESNT_MATTER = "COMMON_DOESNT_MATTER",
	COMMON_PRODUCT_NAME = "COMMON_PRODUCT_NAME",
	COMMON_MINUTES_IN_TOTAL = "COMMON_MINUTES_IN_TOTAL",
	COMMON_PRICE_OF_ONE = "COMMON_PRICE_OF_ONE",
	COMMON_PAYMENT_AMMOUNT = "COMMON_PAYMENT_AMMOUNT",
	COMMON_SEND_ALL = "COMMON_SEND_ALL",
	COMMON_SUBTYPES = "COMMON_SUBTYPES",
	COMMON_PROJECT = "COMMON_PROJECT",
	COMMON_SHOW_MORE_ROWS = "COMMON_SHOW_MORE_ROWS",
	COMMON_FILTER_BY_NAME = "COMMON_FILTER_BY_NAME",
	COMMON_NAME = "COMMON_NAME",
	COMMON_ADD_NEW = "COMMON_ADD_NEW",
	COMMON_EDIT = "COMMON_EDIT",
	COMMON_DELETE = "COMMON_DELETE",
	COMMON_PCS = "COMMON_PCS",
	COMMON_RECORDING = "COMMON_RECORDING",
	COMMON_TRANSLATION = "COMMON_TRANSLATION",
	COMMON_ATTACHMENT = "COMMON_ATTACHMENT",
	COMMON_AUDIO = "COMMON_AUDIO",
	COMMON_TEXT = "COMMON_TEXT",
	COMMON_FILE = "COMMON_FILE",
	COMMON_SOURCE = "COMMON_SOURCE",
	COMMON_TARGET = "COMMON_TARGET",
	COMMON_LINK = "COMMON_LINK",
	COMMON_SEARCH = "COMMON_SEARCH",
	COMMON_CLEAR = "COMMON_CLEAR",
	COMMON_MONDAY = "COMMON_MONDAY",
	COMMON_TUESDAY = "COMMON_TUESDAY",
	COMMON_WEDNESDAY = "COMMON_WEDNESDAY",
	COMMON_THURSDAY = "COMMON_THURSDAY",
	COMMON_FRIDAY = "COMMON_FRIDAY",
	COMMON_SATURDAY = "COMMON_SATURDAY",
	COMMON_SUNDAY = "COMMON_SUNDAY",
	COMMON_YES = "COMMON_YES",
	COMMON_NO = "COMMON_NO",
	COMMON_RETURN = "COMMON_RETURN",
	COMMON_EMAIL = "COMMON_EMAIL",
	COMMON_GENDER = "COMMON_GENDER",
	COMMON_ALLOWED = "COMMON_ALLOWED",
	COMMON_NOT_ALLOWED = "COMMON_NOT_ALLOWED",
	COMMON_PHONENUMBER = "COMMON_PHONENUMBER",
	COMMON_ALLOW = "COMMON_ALLOW",
	COMMON_END_DATE = "COMMON_END_DATE",
	COMMON_START_DATE = "COMMON_START_DATE",
	COMMON_END_TIME = "COMMON_END_TIME",
	COMMON_START_TIME = "COMMON_START_TIME",
	COMMON_DURATION = "COMMON_DURATION",
	COMMON_SEND = "COMMON_SEND",
	COMMON_GROUPS = "COMMON_GROUPS",
	COMMON_FIRST_NAME = "COMMON_FIRST_NAME",
	COMMON_LAST_NAME = "COMMON_LAST_NAME",
	COMMON_STATUS = "COMMON_STATUS",
	COMMON_CANCEL = "COMMON_CANCEL",
	COMMON_SUMMARY = "COMMON_SUMMARY",
	COMMON_SAVE = "COMMON_SAVE",
	COMMON_USERS = "COMMON_USERS",
	COMMON_USER = "COMMON_USER",
	COMMON_CREATED = "COMMON_CREATED",
	COMMON_LANGUAGE = "COMMON_LANGUAGE",
	COMMON_COMMENTS = "COMMON_COMMENTS",
	COMMON_TYPE = "COMMON_TYPE",
	COMMON_ADDITIONAL_INFO = "COMMON_ADDITIONAL_INFO",
	COMMON_FROM_LANGUAGE = "COMMON_FROM_LANGUAGE",
	COMMON_TO_LANGUAGE = "COMMON_TO_LANGUAGE",
	COMMON_INTERPRETATION_DONE = "COMMON_INTERPRETATION_DONE",
	COMMON_INTERPRETATIONS = "COMMON_INTERPRETATIONS",
	COMMON_ORDERS = "COMMON_ORDERS",
	COMMON_UNKOWN = "COMMON_UNKOWN",
	COMMON_GENDER_MALE = "COMMON_GENDER_MALE",
	COMMON_GENDER_FEMALE = "COMMON_GENDER_FEMALE",
	COMMON_ZIP_AND_LOCATION = "COMMON_ZIP_AND_LOCATION",
	COMMON_COUNTRY = "COMMON_COUNTRY",
	COMMON_ADDRESS = "COMMON_ADDRESS",
	COMMON_CUSTOMER_ORGANIZATION_GROUP = "COMMON_CUSTOMER_ORGANIZATION_GROUP",
	COMMON_CUSTOMER_ORGANIZATION = "COMMON_CUSTOMER_ORGANIZATION",
	COMMON_ORGANIZATION_GROUP = "COMMON_ORGANIZATION_GROUP",
	COMMON_ORGANIZATION = "COMMON_ORGANIZATION",
	COMMON_CREATION = "COMMON_CREATION",
	COMMON_DURATION_IN_MINUTES = "COMMON_DURATION_IN_MINUTES",
	COMMON_BOOKING_IN_MINUTES = "COMMON_BOOKING_IN_MINUTES",
	COMMON_UPDATED = "COMMON_UPDATED",
	COMMON_OTHER = "COMMON_OTHER",
	COMMON_OTHERS = 'COMMON_OTHERS',
	COMMON_IN_VAT_REGISTRY = "COMMON_IN_VAT_REGISTRY",
	COMMON_COMPANY_BUSINESSID = "COMMON_COMPANY_BUSINESSID",
	COMMON_NOTES = "COMMON_NOTES",
	COMMON_ADMIN_NOTES = "COMMON_ADMIN_NOTES",
	COMMON_NEW = "COMMON_NEW",
	COMMON_OPEN = "COMMON_OPEN",
	COMMON_ACCEPTED = "COMMON_ACCEPTED",
	COMMON_REJECTED = "COMMON_REJECTED",
	COMMON_INSTANT = "COMMON_INSTANT",
	COMMON_BUSY = "COMMON_BUSY",
	COMMON_CLOSE = "COMMON_CLOSE",
	COMMON_CLOSED = "COMMON_CLOSED",
	COMMON_CONFIRMED = "COMMON_CONFIRMED",
	COMMON_CANCELLED = "COMMON_CANCELLED",
	COMMON_COMPLETED = "COMMON_COMPLETED",
	COMMON_CALLED = "COMMON_CALLED",
	COMMON_BOOKING = "COMMON_BOOKING",
	COMMON_ATTENDANCE = "COMMON_ATTENDANCE",
	COMMON_VAT = "COMMON_VAT",
	COMMON_PAYMENT_METHOD = "COMMON_PAYMENT_METHOD",
	COMMON_COMPLETE = "COMMON_COMPLETE",
	COMMON_IN_PROGRESS = "COMMON_IN_PROGRESS",
	COMMON_CUSTOMER = "COMMON_CUSTOMER",
	COMMON_INTERPRETER = "COMMON_INTERPRETER",
	COMMON_NOW = "COMMON_NOW",
	COMMON_DESCRIPTION = "COMMON_DESCRIPTION",
	COMMON_ORGANIZATIONS = "COMMON_ORGANIZATIONS",
	COMMON_SECURITY_CLEARED = "COMMON_SECURITY_CLEARED",
	COMMON_AVAILABLE = "COMMON_AVAILABLE",
	COMMON_NOT_AVAILABLE = "COMMON_NOT_AVAILABLE",
	COMMON_RESERVED = "COMMON_RESERVED",
	COMMON_OFFLINE = "COMMON_OFFLINE",
	COMMON_BLOCKED_INTERPRETERS = "COMMON_BLOCKED_INTERPRETERS",
	COMMON_SOCIAL_SECURITY_NUMBER = "COMMON_SOCIAL_SECURITY_NUMBER",
	COMMON_IBAN = "COMMON_IBAN",
	COMMON_SHOW = "COMMON_SHOW",
	COMMON_CATEGORY = "COMMON_CATEGORY",
	COMMON_ALL = "COMMON_ALL",
	COMMON_CHOOSE_LANGUAGES = "COMMON_CHOOSE_LANGUAGES",
	COMMON_CHOOSE_LANGUAGE = "COMMON_CHOOSE_LANGUAGE",
	COMMON_PUBLISHED = "COMMON_PUBLISHED",
	COMMON_DRAFT = "COMMON_DRAFT",
	COMMON_INSTANT_WITHOUT_VAT = "COMMON_INSTANT_WITHOUT_VAT",
	COMMON_BOOKING_WITHOUT_VAT = "COMMON_BOOKING_WITHOUT_VAT",
	COMMON_ON_SITE_WITHOUT_VAT = "COMMON_ON_SITE_WITHOUT_VAT",
	COMMON_EXPORT = "COMMON_EXPORT",
	COMMON_ZIP = "COMMON_ZIP",
	COMMON_LOCATION = "COMMON_LOCATION",
	COMMON_TAGS = "COMMON_TAGS",
	COMMON_NETVISOR = "COMMON_NETVISOR",
	COMMON_SITE = "COMMON_SITE",
	COMMON_PAYMENTS = "COMMON_PAYMENTS",
	COMMON_ORDER_NUMBER = "COMMON_ORDER_NUMBER",
	COMMON_PRICE = "COMMON_PRICE",
	COMMON_MESSAGE = "COMMON_MESSAGE",
	COMMON_LANGUAGES = "COMMON_LANGUAGES",
	COMMON_FUNCTIONS = "COMMON_FUNCTIONS",
	COMMON_DATE = "COMMON_DATE",
	COMMON_MIN = "COMMON_MIN",
	COMMON_APPOINTMENT = "COMMON_APPOINTMENT",
	COMMON_OPEN_INFO = "COMMON_OPEN_INFO",
	COMMON_CONNECTION_DETAIL = "COMMON_CONNECTION_DETAIL",
	COMMON_INFORMATION = "COMMON_INFORMATION",
	COMMON_COST_CENTERS = "COMMON_COST_CENTERS",
	COMMON_CUSTOMER_REFERENCE = "COMMON_CUSTOMER_REFERENCE",
	COMMON_CUSTOMER_REFERENCE_2 = "COMMON_CUSTOMER_REFERENCE_2",
	COMMON_INTERPRETATION_INFORMATION = "COMMON_INTERPRETATION_INFORMATION",
	COMMON_INFO_TO_INTERPRETER = "COMMON_INFO_TO_INTERPRETER",
	COMMON_INFO_TO_BOTH_PARTIES = "COMMON_INFO_TO_BOTH_PARTIES",
	COMMON_REVIEW_FROM_INTERPRETER = "COMMON_REVIEW_FROM_INTERPRETER",
	COMMON_REVIEW_FROM_CUSTOMER = "COMMON_REVIEW_FROM_CUSTOMER",
	COMMON_ADMIN = "COMMON_ADMIN",
	COMMON_ARE_YOU_SURE = "COMMON_ARE_YOU_SURE",
	COMMON_COPY = "COMMON_COPY",
	COMMON_COPY_CONFIRM_TEXT = "COMMON_COPY_CONFIRM_TEXT",
	COMMON_CONFIRM_EMAIL = "COMMON_CONFIRM_EMAIL",
	COMMON_RETURN_TO_FRONT_PAGE = "COMMON_RETURN_TO_FRONT_PAGE",
	COMMON_ERROR = "COMMON_ERROR",
	COMMON_TODAY = "COMMON_TODAY",
	COMMON_CURRENT_MONTH = "COMMON_CURRENT_MONTH",
	COMMON_TYPES = "COMMON_TYPES",
	COMMON_MANDATORY = "COMMON_MANDATORY",

	ATTRIBUTES_NO_ATTRIBUTES = "ATTRIBUTES_NO_ATTRIBUTES",
	ATTRIBUTES_EDIT_ATTRIBUTE = "ATTRIBUTES_EDIT_ATTRIBUTE",
	ATTRIBUTES_DELETE_ATTRIBUTE = "ATTRIBUTES_DELETE_ATTRIBUTE",
	ATTRIBUTES_ADD_ATTRIBUTE = "ATTRIBUTES_ADD_ATTRIBUTE",
	FILTER_BY_ATTRIBUTE = 'FILTER_BY_ATTRIBUTE',
	SELECT_ATTRIBUTE_FILTER = "SELECT_ATTRIBUTE_FILTER",
	ATTRIBUTES_FILTER_BY_CATEGORY = "ATTRIBUTES_FILTER_BY_CATEGORY",

	CATEGORY_EDIT_CATEGORY = "CATEGORY_EDIT_CATEGORY",
	CATEGORY_DELETE_CATEGORY = "CATEGORY_DELETE_CATEGORY",
	CATEGORY_ADD_CATEGORY = "CATEGORY_ADD_CATEGORY",
	CATEGORY_SETTINGS = "CATEGORY_SETTINGS",
	CATEGORY_TYPES = "CATEGORY_TYPES",
	CATEGORY_EXPIRATION_DATE = "CATEGORY_EXPIRATION_DATE",
	CATEGORY_NO_CATEGORIES = "CATEGORY_NO_CATEGORIES",

	SALARYINFO_NO_SALARIES = "SALARYINFO_NO_SALARIES",

	//Base64DropzoneTranslations
	BASE64_DROPZONE_TOO_LARGE = "BASE64_DROPZONE_TOO_LARGE",
	BASE64_DROPZONE_NOT_IMAGE = "BASE64_DROPZONE_NOT_IMAGE",

	//customDropZoneTranslations
	DROPZONE_FILE_TOO_LARGE = "DROPZONE_FILE_TOO_LARGE",
	DROPZONE_FILE_TYPE_INVALID = "DROPZONE_FILE_TYPE_INVALID",
	DROPZONE_FILE_DRAG_DROP = "DROPZONE_FILE_DRAG_DROP",
	DROPZONE_FILE_CLICK_OR_DROP = "DROPZONE_FILE_CLICK_OR_DROP",
	DROPZONE_ATTACHMENT_UPDATE_FAILED = "DROPZONE_ATTACHMENT_UPDATE_FAILED",
	DROPZONE_ATTACHMENT_UPLOAD_FAILED = "DROPZONE_ATTACHMENT_UPLOAD_FAILED",
	DROPZONE_ATTACHMENT_CREATION_FAILED = "DROPZONE_ATTACHMENT_CREATION_FAILED",

	//deleteModalTranslations
	DELETEMODAL_CONFIRMATION = "DELETEMODAL_CONFIRMATION",
	DELETEMODAL_DELETE = "DELETEMODAL_DELETE",
	DELETEMODAL_CANCEL = "DELETEMODAL_CANCEL",

	//infoContactRequestTranslations
	CONTACT_REQUEST_NO_REQUESTS = "CONTACT_REQUEST_NO_REQUESTS",
	CONTACT_REQUEST_SUBJECT = "CONTACT_REQUEST_SUBJECT",
	CONTACT_REQUEST_MUNICIPALITY = "CONTACT_REQUEST_MUNICIPALITY",
	CONTACT_REQUEST_NEED_INTERPRETER = "CONTACT_REQUEST_NEED_INTERPRETER",
	CONTACT_REQUEST_TO_LANGUAGE = "CONTACT_REQUEST_TO_LANGUAGE",
	CONTACT_REQUEST_FILTER = "CONTACT_REQUEST_FILTER",
	CONTACT_REQUEST_OTHER_SUBJECT = "CONTACT_REQUEST_OTHER_SUBJECT",
	CONTACT_REQUEST_NOTES = "CONTACT_REQUEST_NOTES",
	CONTACT_REQUEST_CANCELED_DATE = "CONTACT_REQUEST_CANCELED_DATE",

	//messageTranslations
	MESSAGE_MESSAGE_TRANSLATION = "MESSAGE_MESSAGE_TRANSLATION",
	MESSAGE_RECORDINGBANK = "MESSAGE_RECORDINGBANK",
	MESSAGE_MESSAGES = "MESSAGE_MESSAGES",
	MESSAGE_ADD_MESSAGE = "MESSAGE_ADD_MESSAGE",
	MESSAGE_NO_RECORDINGS_OR_TRANSLATIONS = "MESSAGE_NO_RECORDINGS_OR_TRANSLATIONS",
	MESSAGE_CONTENT = "MESSAGE_CONTENT",
	MESSAGE_COPY_FAILED = "MESSAGE_COPY_FAILED",

	//newsItemTranslations
	NEWS_NO_NEWS = "NEWS_NO_NEWS",
	NEWS_HEADLINE = "NEWS_HEADLINE",
	NEWS_PUBLISH_DATE = "NEWS_PUBLISH_DATE",
	NEWS_SHOWN_TO_LANGUAGES = "NEWS_SHOWN_TO_LANGUAGES",
	NEWS_SHOWN_IN_PLACES = "NEWS_SHOWN_IN_PLACES",
	NEWS_REVIEW = "NEWS_REVIEW",
	NEWS_NOT_PUBLISHED = "NEWS_NOT_PUBLISHED",
	NEWS_ISREVIEW = "NEWS_ISREVIEW",
	NEWS_ISNOTREVIEW = "NEWS_ISNOTREVIEW",
	NEWS_IMAGE = "NEWS_IMAGE",
	NEWS_IN_BRIEF = "NEWS_IN_BRIEF",
	NEWS_CONTENT = "NEWS_CONTENT",
	NEWS_PUBLISH_TIME = "NEWS_PUBLISH_TIME",
	NEWS_SHOW_PUBLISH_DATE = "NEWS_SHOW_PUBLISH_DATE",
	NEWS_RATING = "NEWS_RATING",
	NEWS_REVIEW_PUBLISH_TEXT = "NEWS_REVIEW_PUBLISH_TEXT",
	NEWS_REVIEW_PUBLISH_DONE = "NEWS_REVIEW_PUBLISH_DONE",
	NEWS_MAKE_REVIEW = "NEWS_MAKE_REVIEW",
	NEWS_CUSTOMER_REVIEW = "NEWS_CUSTOMER_REVIEW",
	NEWS_INTERPRETER_REVIEW = "NEWS_INTERPRETER_REVIEW",
	NEWS_MAKE_REVIEW_BUTTON = "NEWS_MAKE_REVIEW_BUTTON",
	NEWS_NO_REVIEW = "NEWS_NO_REVIEW",

	//organizationGroupTranslations
	ORGANIZATION_GROUP_NO_GROUPS = "ORGANIZATION_GROUP_NO_GROUPS",
	ORGANIZATION_GROUP_ORDER_ID = "ORGANIZATION_GROUP_ORDER_ID",
	ORGANIZATION_GROUP_PRICE_SEASONS_IDS_LABEL = "ORGANIZATION_GROUP_PRICE_SEASONS_IDS_LABEL",
	ORGANIZATION_GROUP_PRICE_SEASONS_IDS_PLACEHOLDER = "ORGANIZATION_GROUP_PRICE_SEASONS_IDS_PLACEHOLDER",

	//priceSeasonsTranslations
	PRICE_SEASON_NO_PRICE_SEASONS = "PRICE_SEASON_NO_PRICE_SEASONS",
	PRICE_SEASON_PERIOD = "PRICE_SEASON_PERIOD",
	PRICE_SEASON_PERIOD_2 = "PRICE_SEASON_PERIOD_2",
	PRICE_SEASON_ACTIVE = "PRICE_SEASON_ACTIVE",
	PRICE_SEASON_PRICERULES_TITLE = "PRICE_SEASON_PRICERULES_TITLE",
	PRICE_SEASON_NO_PRICE_RULES = "PRICE_SEASON_NO_PRICE_RULES",
	PRICE_SEASON_PRICES = "PRICE_SEASON_PRICES",
	PRICE_SEASON_TAGS_DAYRULES = "PRICE_SEASON_TAGS_DAYRULES",
	PRICE_SEASON_CHARGE = "PRICE_SEASON_CHARGE",
	PRICE_SEASON_MIN_BILLING_PERIOD = "PRICE_SEASON_MIN_BILLING_PERIOD",
	PRICE_SEASON_MIN_ROUNDING_PERIOD = "PRICE_SEASON_MIN_ROUNDING_PERIOD",
	PRICE_SEASON_TERMS_OF_CANCEL = "PRICE_SEASON_TERMS_OF_CANCEL",
	PRICE_SEASON_CHOOSE_TERMS_OF_CANCEL = "PRICE_SEASON_CHOOSE_TERMS_OF_CANCEL",
	PRICE_SEASON_MIN_DURATION = "PRICE_SEASON_MIN_DURATION",
	PRICE_SEASON_MAX_DURATION = "PRICE_SEASON_MAX_DURATION",
	PRICE_SEASON_MINUTES_BEFORE_START = "PRICE_SEASON_MINUTES_BEFORE_START",
	PRICE_SEASON_CHOOSE_TYPE = "PRICE_SEASON_CHOOSE_TYPE",
	PRICE_SEASON_DAYRULES = "PRICE_SEASON_DAYRULES",
	PRICE_SEASON_EDIT_PRICERULES = "PRICE_SEASON_EDIT_PRICERULES",
	PRICE_SEASON_ADD_PRICERULE = "PRICE_SEASON_ADD_PRICERULE",

	//siteTranslations
	SITE_NO_SITES = "SITE_NO_SITES",
	SITE_PREFIX = "SITE_PREFIX",
	SITE_TIMEZONE = "SITE_TIMEZONE",
	SITE_CHOOSE_TIMEZONE = "SITE_CHOOSE_TIMEZONE",
	SITE_ACTIVE = "SITE_ACTIVE",
	SITE_TARGET_MARGIN = "SITE_TARGET_MARGIN",
	SITE_MIN_REWARD = "SITE_MIN_REWARD",
	SITE_MAX_REWARD = "SITE_MAX_REWARD",
	SITE_APPOINTMENT_REWARDS = "SITE_APPOINTMENT_REWARDS",
	SITE_TYPES = "SITE_TYPES",

	PAGES_LANGUAGE_CODE_EDIT_TITLE = "PAGES_LANGUAGE_CODE_EDIT_TITLE",
	PAGES_LANGUAGE_CODE_EDIT_INFO = "PAGES_LANGUAGE_CODE_EDIT_INFO",
	PAGES_LANGUAGE_CODE_EDIT_DELETE_CONFIRM = "PAGES_LANGUAGE_CODE_EDIT_DELETE_CONFIRM",
	PAGES_LANGUAGE_CODES_LIST_TITLE = "PAGES_LANGUAGE_CODES_LIST_TITLE",
	PAGES_LANGUAGE_CODES_LIST_ADD_NEW = "PAGES_LANGUAGE_CODES_LIST_ADD_NEW",

	PAGES_LANGUAGE_EDIT_TITLE = "PAGES_LANGUAGE_EDIT_TITLE",
	PAGES_LANGUAGE_EDIT_INFO = "PAGES_LANGUAGE_EDIT_INFO",
	PAGES_LANGUAGE_EDIT_DELETE_CONFIRM = "PAGES_LANGUAGE_EDIT_DELETE_CONFIRM",
	PAGES_LANGUAGES_LIST_TITLE = "PAGES_LANGUAGES_LIST_TITLE",
	PAGES_LANGUAGES_LIST_ADD_NEW = "PAGES_LANGUAGES_LIST_ADD_NEW",

	//textMessageTranslations
	TEXTMESSAGE_CONFIRM_SEND = "TEXTMESSAGE_CONFIRM_SEND",
	TEXTMESSAGE_ARE_YOU_SURE_SEND = "TEXTMESSAGE_ARE_YOU_SURE_SEND",
	TEXTMESSAGE_NO_MESSAGES = "TEXTMESSAGE_NO_MESSAGES",
	TEXTMESSAGE_TRANSMISSION = "TEXTMESSAGE_TRANSMISSION",
	TEXTMESSAGE_MESSAGE_TEXT = "TEXTMESSAGE_MESSAGE_TEXT",
	TEXTMESSAGE_RECEIVER_NUMBER = "TEXTMESSAGE_RECEIVER_NUMBER",

	LANGUAGE_CODE_NO_LANGUAGE_CODES = "LANGUAGE_CODE_NO_LANGUAGE_CODES",

	LANGUAGE_NO_LANGUAGES = "LANGUAGE_NO_LANGUAGES",
	LANGUAGE_LANGUAGE_CODE = "LANGUAGE_LANGUAGE_CODE",
	LANGUAGE_TO_LANGUAGES = "LANGUAGE_TO_LANGUAGES",
	LANGUAGE_EDIT_LANGUAGES = "LANGUAGE_EDIT_LANGUAGES",
	LANGUAGE_CONFIRM_DELETE = "LANGUAGE_CONFIRM_DELETE",
	LANGUAGE_EDIT_TRANSLATION = "LANGUAGE_EDIT_TRANSLATION",
	LANGUAGE_TRANSLATION = "LANGUAGE_TRANSLATION",
	LANGUAGE_EDIT_TRANSLATIONS = "LANGUAGE_EDIT_TRANSLATIONS",
	LANGUAGE_TRANSLATIONS = "LANGUAGE_TRANSLATIONS",
	LANGUAGE_TRANSLATION_CONFIRM_DELETE = "LANGUAGE_TRANSLATION_CONFIRM_DELETE",
	LANGUAGE_IS_TEST_LANGUAGE = "LANGUAGE_IS_TEST_LANGUAGE",
	LANGUAGE_NATIVE_TRANSLATION = "LANGUAGE_NATIVE_TRANSLATION",
	LANGUAGE_ACTIVE_CONFIRM = "LANGUAGE_ACTIVE_CONFIRM",
	LANGUAGE_ADD_LANGUAGE = "LANGUAGE_ADD_LANGUAGE",

	//pagesFileUploadTranslations
	PAGES_FILEUPLOAD_FILES = "PAGES_FILEUPLOAD_FILES",
	PAGES_FILEUPLOAD_UPLOAD_DEMO = "PAGES_FILEUPLOAD_UPLOAD_DEMO",
	PAGES_FILEUPLOAD_DROPZONE_ALERT = "PAGES_FILEUPLOAD_DROPZONE_ALERT",

	//pagesContactRequestTranslations
	PAGES_CONTACTREQUEST_EDIT_TITLE = "PAGES_CONTACTREQUEST_EDIT_TITLE",
	PAGES_CONTACTREQUEST_EDIT_DELETE = "PAGES_CONTACTREQUEST_EDIT_DELETE",

	//pagesMessagesTranslations
	PAGES_MESSAGE_EDIT_CHOOSE_ORDERED_WORKS = "PAGES_MESSAGE_EDIT_CHOOSE_ORDERED_WORKS",
	PAGES_MESSAGE_EDIT_RECORDING = "PAGES_MESSAGE_EDIT_RECORDING",
	PAGES_MESSAGE_EDIT_TRANSLATION = "PAGES_MESSAGE_EDIT_TRANSLATION",
	PAGES_MESSAGE_EDIT_CHOOSE_RECORDING_TRANSLATION = "PAGES_MESSAGE_EDIT_CHOOSE_RECORDING_TRANSLATION",
	PAGES_MESSAGE_EDIT_CHOOSE_SOURCE_TYPE = "PAGES_MESSAGE_EDIT_CHOOSE_SOURCE_TYPE",
	PAGES_MESSAGE_EDIT_ADD_FILE = "PAGES_MESSAGE_EDIT_ADD_FILE",
	PAGES_MESSAGE_EDIT_ADD_TEXT = "PAGES_MESSAGE_EDIT_ADD_TEXT",
	PAGES_MESSAGE_EDIT_ADD_MORE_INFORMATION = "PAGES_MESSAGE_EDIT_ADD_MORE_INFORMATION",
	PAGES_MESSAGE_EDIT_ALLOW_SHARING = "PAGES_MESSAGE_EDIT_ALLOW_SHARING",
	PAGES_MESSAGE_GROUP_EDIT_TITLE = "PAGES_MESSAGE_GROUP_EDIT_TITLE",
	PAGES_MESSAGE_GROUP_EDIT_DELETE_CONFIRM = "PAGES_MESSAGE_GROUP_EDIT_DELETE_CONFIRM",
	PAGES_MESSAGE_SHOW_PAGE_DELETE_MESSAGE = "PAGES_MESSAGE_SHOW_PAGE_DELETE_MESSAGE",
	PAGES_MESSAGE_SHOW_PAGE_DELETE_MESSAGE_CONFIRM = "PAGES_MESSAGE_SHOW_PAGE_DELETE_MESSAGE_CONFIRM",
	PAGES_MESSAGE_SHOW_PAGE_ANWSER = "PAGES_MESSAGE_SHOW_PAGE_ANWSER",

	//pagesNewsTranslations
	PAGES_NEWS_INSTRUCTIONS_TITLE = "PAGES_NEWS_INSTRUCTIONS_TITLE",
	PAGES_NEWS_INSTRUCTIONS_CONTENT_1 = "PAGES_NEWS_INSTRUCTIONS_CONTENT_1",
	PAGES_NEWS_INSTRUCTIONS_CONTENT_2 = "PAGES_NEWS_INSTRUCTIONS_CONTENT_2",
	PAGES_NEWS_DELETE_CONFIRM = "PAGES_NEWS_DELETE_CONFIRM",
	PAGES_ADD_NEWS = "PAGES_ADD_NEWS",

	//pagesOrganizationsTranslations
	PAGES_ORGANIZATION_INFO = "PAGES_ORGANIZATION_INFO",
	PAGES_ORGANIZATION_DELETE_CONFIRM = "PAGES_ORGANIZATION_DELETE_CONFIRM",

	//organizationCompaniesTranslations
	PAGES_ORGANIZATION_COMPANY_INFO = "PAGES_ORGANIZATION_COMPANY_INFO",
	PAGES_ORGANIZATION_COMPANY_DELETE_CONFIRM = "PAGES_ORGANIZATION_COMPANY_DELETE_CONFIRM",
	ORGANIZATION_COMPANY_NO_COMPANIES = "ORGANIZATION_COMPANY_NO_COMPANIES",
	ORGANIZATION_COMPANY_FEE_INSTANT_INTERPRETATION = "ORGANIZATION_COMPANY_FEE_INSTANT_INTERPRETATION",
	ORGANIZATION_COMPANY_FEE_APPOINTMENT = "ORGANIZATION_COMPANY_FEE_APPOINTMENT",
	ORGANIZATION_COMPANY_ORGANIZATIONGROUP = "ORGANIZATION_COMPANY_ORGANIZATIONGROUP",
	ORGANIZATION_COMPANY_INDUSTRY_CODE = "ORGANIZATION_COMPANY_INDUSTRY_CODE",
	ORGANIZATION_COMPANY_INDUSTRY = "ORGANIZATION_COMPANY_INDUSTRY",
	ORGANIZATION_COMPANY_TIETOPALVELU = "ORGANIZATION_COMPANY_TIETOPALVELU",
	ORGANIZATION_COMPANY_SEARCH = "ORGANIZATION_COMPANY_SEARCH",
	ORGANIZATION_COMPANY_SEARCH_COMPANIES = "ORGANIZATION_COMPANY_SEARCH_COMPANIES",
	ORGANIZATION_COMPANY_WEBSITE = "ORGANIZATION_COMPANY_WEBSITE",
	ORGANIZATION_COMPANY_BOOKINGEMAIL = "ORGANIZATION_COMPANY_BOOKINGEMAIL",
	ORGANIZATION_DURATIONS = "ORGANIZATION_DURATIONS",
	ORGANIZATION_COMPANY_DURATIONS = "ORGANIZATION_COMPANY_DURATIONS",
	ORGANIZATION_DEFAULT_DURATIONS = "ORGANIZATION_DEFAULT_DURATIONS",
	ORGANIZATION_COMPANY_DEFAULT_INTERPRETATION_TYPE = "ORGANIZATION_COMPANY_DEFAULT_INTERPRETATION_TYPE",
	ORGANIZATION_CUSTOMER_APPROVAL_REQUIRED = "ORGANIZATION_CUSTOMER_APPROVAL_REQUIRED",
	ORGANIZATION_COMPANY_COST_CENTER_MANDATORY = "ORGANIZATION_COMPANY_COST_CENTER_MANDATORY",
	ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_HIDDEN = "ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_HIDDEN",
	ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_MANDATORY = "ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_MANDATORY",
	ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_MANDATORY_IN_REVIEW = "ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_MANDATORY_IN_REVIEW",
	ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_2MANDATORY = "ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_2MANDATORY",
	ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_2MANDATORY_IN_REVIEW = "ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_2MANDATORY_IN_REVIEW",
	ORGANIZATION_COMPANY_ONLY_SECURITY_CLEARED_INTERPRETERS = "ORGANIZATION_COMPANY_ONLY_SECURITY_CLEARED_INTERPRETERS",
	ORGANIZATION_COMPANY_ALLOW_ATTACHMENTS = "ORGANIZATION_COMPANY_ALLOW_ATTACHMENTS",
	ORGANIZATION_COMPANY_ALLOW_AUTO_REMINDERS = "ORGANIZATION_COMPANY_ALLOW_AUTO_REMINDERS",
	ORGANIZATION_COMPANY_SHOW_LANGUAGE_AVAILABILITY = "ORGANIZATION_COMPANY_SHOW_LANGUAGE_AVAILABILITY",
	ORGANIZATION_COMPANY_CONTACT_METHOD = "ORGANIZATION_COMPANY_CONTACT_METHOD",
	ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CUSTOMER = "ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CUSTOMER",
	ORGANIZATION_COMPANY_ALLOW_CUSTOMER_CALL_TO_INTERPRETER = "ORGANIZATION_COMPANY_ALLOW_CUSTOMER_CALL_TO_INTERPRETER",
	ORGANIZATION_COMPANY_ALLOW_CONTACT_CALL_TO_INTERPRETER = "ORGANIZATION_COMPANY_ALLOW_CONTACT_CALL_TO_INTERPRETER",
	ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CONTACT = "ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CONTACT",
	ORGANIZATION_COMPANY_ALLOW_VIDEO_CONFERENCE = "ORGANIZATION_COMPANY_ALLOW_VIDEO_CONFERENCE",
	ORGANIZATION_COMPANY_ALLOW_MASKED_NUMBER = "ORGANIZATION_COMPANY_ALLOW_MASKED_NUMBER",
	ORGANIZATION_COMPANY_ALLOW_CONNECTION_TYPE_SELECT_FOR_INSTANT = "ORGANIZATION_COMPANY_ALLOW_CONNECTION_TYPE_SELECT_FOR_INSTANT",
	ORGANIZATION_COMPANY_LOCATION_INFORMATION = "ORGANIZATION_COMPANY_LOCATION_INFORMATION",
	ORGANIZATION_COMPANY_PRICE_INFORMATION = "ORGANIZATION_COMPANY_PRICE_INFORMATION",
	ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_INSTANT = "ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_INSTANT",
	ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_INSTANT_EXTENDED = "ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_INSTANT_EXTENDED",
	ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_BOOKING = "ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_BOOKING",
	ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_BOOKING_EXTENDED = "ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_BOOKING_EXTENDED",
	ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_ON_SITE = "ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_ON_SITE",
	ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_ON_SITE_EXTENDED = "ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_ON_SITE_EXTENDED",
	ORGANIZATION_COMPANY_TERMS_OF_CANCEL_TYPE_BOOKING = "ORGANIZATION_COMPANY_TERMS_OF_CANCEL_TYPE_BOOKING",
	ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_TITLE = "ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_TITLE",
	ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_INFO_TEXT = "ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_INFO_TEXT",
	ORGANIZATION_COMPANY_CUSTOMER_REFERENCE2_TITLE = "ORGANIZATION_COMPANY_CUSTOMER_REFERENCE2_TITLE",
	ORGANIZATION_COMPANY_CUSTOMER_REFERENCE2_INFO_TEXT = "ORGANIZATION_COMPANY_CUSTOMER_REFERENCE2_INFO_TEXT",
	ORGANIZATION_COMPANY_CUSTOMER_NOTES_TITLE = "ORGANIZATION_COMPANY_CUSTOMER_NOTES_TITLE",
	ORGANIZATION_COMPANY_CUSTOMER_NOTES_INFO_TEXT = "ORGANIZATION_COMPANY_CUSTOMER_NOTES_INFO_TEXT",
	ORGANIZATION_COMPANY_CUSTOMER_NOTES_MESSAGE_TITLE = "ORGANIZATION_COMPANY_CUSTOMER_NOTES_MESSAGE_TITLE",
	ORGANIZATION_COMPANY_CUSTOMER_NOTES_MESSAGE_INFO_TEXT = "ORGANIZATION_COMPANY_CUSTOMER_NOTES_MESSAGE_INFO_TEXT",
	ORGANIZATION_COMPANY_BILLING_CHARGE_WITHOUT_VAT = "ORGANIZATION_COMPANY_BILLING_CHARGE_WITHOUT_VAT",
	ORGANIZATION_COMPANY_BILLING_REFERENCE = "ORGANIZATION_COMPANY_BILLING_REFERENCE",
	ORGANIZATION_COMPANY_REFERENCE = "ORGANIZATION_COMPANY_REFERENCE",
	ORGANIZATION_AGREEMENT_IDENTIFIER = "ORGANIZATION_AGREEMENT_IDENTIFIER",
	ORGANIZATION_COMPANY_BILLING_PAYMENT_TERM = "ORGANIZATION_COMPANY_BILLING_PAYMENT_TERM",
	ORGANIZATION_COMPANY_INTERPRETER_FEE_SETTINGS = "ORGANIZATION_COMPANY_INTERPRETER_FEE_SETTINGS",
	ORGANIZATION_COMPANY_CUSTOMER_CODE = "ORGANIZATION_COMPANY_CUSTOMER_CODE",
	ORGANIZATION_COMPANY_EINVOICE_ADDRESS = "ORGANIZATION_COMPANY_EINVOICE_ADDRESS",
	ORGANIZATION_COMPANY_OPERATOR_CODE = "ORGANIZATION_COMPANY_OPERATOR_CODE",
	ORGANIZATION_COMPANY_DAYS_7 = "ORGANIZATION_COMPANY_DAYS_7",
	ORGANIZATION_COMPANY_DAYS_14 = "ORGANIZATION_COMPANY_DAYS_14",
	ORGANIZATION_COMPANY_DAYS_21 = "ORGANIZATION_COMPANY_DAYS_21",
	ORGANIZATION_COMPANY_DAYS_30 = "ORGANIZATION_COMPANY_DAYS_30",
	ORGANIZATION_COMPANY_LAST_DAY_OF_MONTH = "ORGANIZATION_COMPANY_LAST_DAY_OF_MONTH",
	ORGANIZATION_COMPANY_COST_CENTER_NAME = "ORGANIZATION_COMPANY_COST_CENTER_NAME",
	ORGANIZATION_COMPANY_COST_CENTER_DESCRIPTION = "ORGANIZATION_COMPANY_COST_CENTER_DESCRIPTION",

	//pagesPriceSeasonsTranslations
	PAGES_PRICE_SEASON_TITLE = "PAGES_PRICE_SEASON_TITLE",
	PAGES_PRICE_SEASON_INFO = "PAGES_PRICE_SEASON_INFO",
	PAGES_PRICE_SEASON_CONFIRM_DELETE = "PAGES_PRICE_SEASON_CONFIRM_DELETE",
	PAGES_PRICE_SEASONS_ADD_NEW = "PAGES_PRICE_SEASONS_ADD_NEW",

	//pagesPriceRulesTranslations
	PAGES_PRICE_RULES_TITLE = "PAGES_PRICE_RULES_TITLE",
	PAGES_PRICE_RULES_INFO_1 = "PAGES_PRICE_RULES_INFO_1",
	PAGES_PRICE_RULES_INFO_2 = "PAGES_PRICE_RULES_INFO_2",
	PAGES_PRICE_RULE_DELETE_CONFIRM = "PAGES_PRICE_RULE_DELETE_CONFIRM",
	PAGES_PRICE_RULES_LIST_TITLE = "PAGES_PRICE_RULES_LIST_TITLE",
	PAGES_PRICE_RULES_LIST_ADD_NEW = "PAGES_PRICE_RULES_LIST_ADD_NEW",
	PAGES_PRICE_RULES_EDIT_TITLE = "PAGES_PRICE_RULES_EDIT_TITLE",
	PAGES_PRICE_RULES_EDIT_INFO_1 = "PAGES_PRICE_RULES_EDIT_INFO_1",
	PAGES_PRICE_RULES_EDIT_INFO_2 = "PAGES_PRICE_RULES_EDIT_INFO_2",
	PAGES_PRICE_RULES_EDIT_DELETE_CONFIRM = "PAGES_PRICE_RULES_EDIT_DELETE_CONFIRM",

	//pagesSiteTranslations
	PAGES_SITE_EDIT_INFO = "PAGES_SITE_EDIT_INFO",
	PAGES_SITE_EDIT_DELETE_CONFIRM = "PAGES_SITE_EDIT_DELETE_CONFIRM",
	PAGES_SITES_LIST_ADD_NEW = "PAGES_SITES_LIST_ADD_NEW",

	//pagesTextMessagesTranslations
	PAGES_TEXTMESSAGE_TITLE = "PAGES_TEXTMESSAGE_TITLE",
	PAGES_TEXTMESSAGE_INFO = "PAGES_TEXTMESSAGE_INFO",
	PAGES_TEXTMESSAGE_DELETE_CONFIRM = "PAGES_TEXTMESSAGE_DELETE_CONFIRM",
	PAGES_TEXTMESSAGES_LIST_TITLE = "PAGES_TEXTMESSAGES_LIST_TITLE",
	PAGES_TEXTMESSAGES_LIST_ADD_NEW = "PAGES_TEXTMESSAGES_LIST_ADD_NEW",

	//pagesCustomerReportsTranslations
	PAGES_CUSTOMER_REPORTS_CHOOSE_ORGANIZATION = "PAGES_CUSTOMER_REPORTS_CHOOSE_ORGANIZATION",
	PAGES_CUSTOMER_REPORTS_ALL_ORGANIZATIONS = "PAGES_CUSTOMER_REPORTS_ALL_ORGANIZATIONS",
	PAGES_CUSTOMER_REPORTS_CHOOSE_SUBSCRIBER = "PAGES_CUSTOMER_REPORTS_CHOOSE_SUBSCRIBER",
	PAGES_CUSTOMER_REPORTS_ALL_SUBSCRIBERS = "PAGES_CUSTOMER_REPORTS_ALL_SUBSCRIBERS",
	PAGES_CUSTOMER_REPORTS_ALL_LANGUAGES = "PAGES_CUSTOMER_REPORTS_ALL_LANGUAGES",
	PAGES_CUSTOMER_REPORTS_CHOOSE_CUSTOMER_ORGANIZATION = "PAGES_CUSTOMER_REPORTS_CHOOSE_CUSTOMER_ORGANIZATION",
	PAGES_CUSTOMER_REPORTS_SUBSCRIBER_A = "PAGES_CUSTOMER_REPORTS_SUBSCRIBER_A",
	PAGES_CUSTOMER_REPORTS_SUBSCRIBER_B = "PAGES_CUSTOMER_REPORTS_SUBSCRIBER_B",
	COMMON_REFERENCE = "COMMON_REFERENCE",
	COMMON_BANKING_REFERENCE = "COMMON_BANKING_REFERENCE",

	//dataContactRequestTranslations
	DATA_CONTACT_REQUEST_IMMIGRATION = "DATA_CONTACT_REQUEST_IMMIGRATION",
	DATA_CONTACT_REQUEST_PERMIT_MIGRI = "DATA_CONTACT_REQUEST_PERMIT_MIGRI",
	DATA_CONTACT_REQUEST_CLARIFICATION_AND_FORMS = "DATA_CONTACT_REQUEST_CLARIFICATION_AND_FORMS",
	DATA_CONTACT_REQUEST_STUDYING = "DATA_CONTACT_REQUEST_STUDYING",
	DATA_CONTACT_REQUEST_WORK = "DATA_CONTACT_REQUEST_WORK",
	DATA_CONTACT_REQUEST_CANCELED = "DATA_CONTACT_REQUEST_CANCELED",

	//dataPriceSeasonsTranslations
	DATA_PRICE_SEASON_PRICE_PER_MINUTE = "DATA_PRICE_SEASON_PRICE_PER_MINUTE",
	DATA_PRICE_SEASON_PRICE_PER_INTERPRETATION = "DATA_PRICE_SEASON_PRICE_PER_INTERPRETATION",
	DATA_PRICE_SEASON_PRICE_P_PER_INTERPRETATION = "DATA_PRICE_SEASON_PRICE_P_PER_INTERPRETATION",
	DATA_PRICE_SEASON_DISCOUNT_PER_INTERPRETATION = "DATA_PRICE_SEASON_DISCOUNT_PER_INTERPRETATION",
	DATA_PRICE_SEASON_DISCOUNT_P_PER_INTERPRETATION = "DATA_PRICE_SEASON_DISCOUNT_P_PER_INTERPRETATION",
	DATA_PRICE_SEASON_BEFORE_START_48 = "DATA_PRICE_SEASON_BEFORE_START_48",
	DATA_PRICE_SEASON_BEFORE_START_24 = "DATA_PRICE_SEASON_BEFORE_START_24",
	DATA_PRICE_SEASON_BEFORE_START_12 = "DATA_PRICE_SEASON_BEFORE_START_12",
	DATA_PRICE_SEASON_BEFORE_START_6 = "DATA_PRICE_SEASON_BEFORE_START_6",
	DATA_PRICE_SEASON_PREVIOUS_BUSINESS_DAY_AT_17 = "DATA_PRICE_SEASON_PREVIOUS_BUSINESS_DAY_AT_17",
	DATA_PRICE_SEASON_PREVIOUS_DAY_AT_15_30 = "DATA_PRICE_SEASON_PREVIOUS_DAY_AT_15_30",
	DATA_PRICE_SEASON_PREVIOUS_DAY_AT_16 = "DATA_PRICE_SEASON_PREVIOUS_DAY_AT_16",
	DATA_PRICE_SEASON_PREVIOUS_DAY_AT_12 = "DATA_PRICE_SEASON_PREVIOUS_DAY_AT_12",
	DATA_PRICE_SEASON_PUBLIC_HOLIDAY = "DATA_PRICE_SEASON_PUBLIC_HOLIDAY",
	DATA_PRICE_SEASON_VIDEO = "DATA_PRICE_SEASON_VIDEO",

	//storeTranslations
	AUTH_ACTIONS_USER_NOT_IDENTIFIED = "AUTH_ACTIONS_USER_NOT_IDENTIFIED",
	REDUCER_ERROR = "REDUCER_ERROR",
	REDUCER_SEARCH_ERROR = "REDUCER_SEARCH_ERROR",
	REDUCER_SAVE_ERROR = "REDUCER_SAVE_ERROR",

	//appTranslations
	LOGIN = "LOGIN",
	LOGIN_SUCCESS = "LOGIN_SUCCESS",
	NO_ACCESS = "NO_ACCESS",

	//logItemTranslations
	PAGES_LOG_ITEM_EDIT_TITLE = "PAGES_LOG_ITEM_EDIT_TITLE",
	PAGES_LOG_ITEM_EDIT_INFO = "PAGES_LOG_ITEM_EDIT_INFO",
	PAGES_LOG_ITEMS_LIST_ADD_NEW = "PAGES_LOG_ITEMS_LIST_ADD_NEW",
	LOG_ITEM_LIST_NO_LOGS = "LOG_ITEM_LIST_NO_LOGS",
	LOG_ITEM_LIST_BROWSER = "LOG_ITEM_LIST_BROWSER",
	LOG_ITEM_LIST_CITY = "LOG_ITEM_LIST_CITY",
	LOG_ITEM_LIST_COUNTRY = "LOG_ITEM_LIST_COUNTRY",
	LOG_ITEM_LIST_EVENT = "LOG_ITEM_LIST_EVENT",
	LOG_ITEM_LIST_FULLURL = "LOG_ITEM_LIST_FULLURL",
	LOG_ITEM_LIST_INTERPRETATION = "LOG_ITEM_LIST_INTERPRETATION",
	LOG_ITEM_LIST_IPADDRESS = "LOG_ITEM_LIST_IPADDRESS",
	LOG_ITEM_LIST_OPERATINGSYSTEM = "LOG_ITEM_LIST_OPERATINGSYSTEM",
	LOG_ITEM_LIST_REFERER = "LOG_ITEM_LIST_REFERER",
	LOG_ITEM_LIST_REGION = "LOG_ITEM_LIST_REGION",
	LOG_ITEM_LIST_UPDATED = "LOG_ITEM_LIST_UPDATED",
	LOG_ITEM_LIST_USERAGENT = "LOG_ITEM_LIST_USERAGENT",
	LOG_ITEM_LIST_SUMMARY = "LOG_ITEM_LIST_SUMMARY",
	LOG_ITEM_LIST_FILTER = "LOG_ITEM_LIST_FILTER",

	//interpretationTranslations
	INTERPRETATION_GENDER_IN_ORDER = "INTERPRETATION_GENDER_IN_ORDER",
	INTERPRETATION_CUSTOMER_ESTIMATE = "INTERPRETATION_CUSTOMER_ESTIMATE",
	INTERPRETATION_GENDER = "INTERPRETATION_GENDER",
	INTERPRETATION_TAGS = "INTERPRETATION_TAGS",
	INTERPRETATION_CUSTOMER_NOTES = "INTERPRETATION_CUSTOMER_NOTES",
	INTERPRETATION_CUSTOMER_COST_CENTER = "INTERPRETATION_CUSTOMER_COST_CENTER",
	INTERPRETATION_AVAILABLE_INTERPRETERS = "INTERPRETATION_AVAILABLE_INTERPRETERS",
	INTERPRETATION_VIDEO_URL = "INTERPRETATION_VIDEO_URL",
	INTERPRETATION_VIDEO_INFO = "INTERPRETATION_VIDEO_INFO",
	INTERPRETATION_ON_SITE_INFO = "INTERPRETATION_ON_SITE_INFO",
	INTERPRETATION_ORDER_MULTIPLE = "INTERPRETATION_ORDER_MULTIPLE",
	INTERPRETATION_NO_MATCH_CONNECTION_DETAIL = "INTERPRETATION_NO_MATCH_CONNECTION_DETAIL",
	INTERPRETATION_ADD_ALTERNATIVE = "INTERPRETATION_ADD_ALTERNATIVE",
	INTERPRETATION_INTERPRETER_REVIEW = "INTERPRETATION_INTERPRETER_REVIEW",
	INTERPRETATION_INTERPRETER_RATING = "INTERPRETATION_INTERPRETER_RATING",
	INTERPRETATION_CUSTOMER_REVIEW = "INTERPRETATION_CUSTOMER_REVIEW",
	INTERPRETATION_CUSTOMER_RATING = "INTERPRETATION_CUSTOMER_RATING",
	INTERPRETATION_INTERPRETER_NAME = "INTERPRETATION_INTERPRETER_NAME",
	INTERPRETATION_SEARCH_ALL = "INTERPRETATION_SEARCH_ALL",
	SEARCH_ALL_OCCURANCES = "SEARCH_ALL_OCCURANCES",
	INTERPRETATION_AMOUNT_OF_INTERPRETATIONS = "INTERPRETATION_AMOUNT_OF_INTERPRETATIONS",
	INTERPRETATION_AVG_DURATION = "INTERPRETATION_AVG_DURATION",
	INTERPRETATION_CUSTOMER_NOTES_TEXT = "INTERPRETATION_CUSTOMER_NOTES_TEXT",
	INTERPRETATION_ADD_INTERPRETATION = "INTERPRETATION_ADD_INTERPRETATION",
	INTERPRETATION_ADD_INTERPRETER = "INTERPRETATION_ADD_INTERPRETER",
	INTERPRETATION_SEND_AUTOMATIC_OFFERS = "INTERPRETATION_SEND_AUTOMATIC_OFFERS",
	INTERPRETATION_ADD_CUSTOMER = "INTERPRETATION_ADD_CUSTOMER",
	INTERPRETATION_REMOVE_OPEN_OFFERS = "INTERPRETATION_REMOVE_OPEN_OFFERS",
	INTERPRETERS_OFFERED = "INTERPRETERS_OFFERED",

	INTERPRETATION_EXPORT_TO_YOUPRET_PAY_TITLE = "INTERPRETATION_EXPORT_TO_YOUPRET_PAY_TITLE",
	INTERPRETATION_EXPORT_TO_YOUPRET_PAY_TEXT = "INTERPRETATION_EXPORT_TO_YOUPRET_PAY_TEXT",
	INTERPRETATION_PAYMENT_DETAILS_TITLE = "INTERPRETATION_PAYMENT_DETAILS_TITLE",
	INTERPRETATION_PAYMENT_DETAILS_TEXT = "INTERPRETATION_PAYMENT_DETAILS_TEXT",
	INTERPRETATION_PAYMENT_STATUS = "INTERPRETATION_PAYMENT_STATUS",

	INTREPRETATION_FEEDBACK_SEND_TO_WORKER = "INTREPRETATION_FEEDBACK_SEND_TO_WORKER",
	INTREPRETATION_FEEDBACK_SEND_TO_CUSTOMER = "INTREPRETATION_FEEDBACK_SEND_TO_CUSTOMER",
	INTREPRETATION_FEEDBACK_CONFIRM = "INTREPRETATION_FEEDBACK_CONFIRM",
	INTREPRETATION_FEEDBACK_RECIPENTS = "INTREPRETATION_FEEDBACK_RECIPENTS",

	INTREPRETATION_FEEDBACK_SURVEY = "INTREPRETATION_FEEDBACK_SURVEY",

	PAYMENT_STATUS_PAID = "PAYMENT_STATUS_PAID",
	PAYMENT_STATUS_UNPAID = "PAYMENT_STATUS_UNPAID",
	PAYMENT_STATUS_NO_PAYMENT_REQUIRED = "PAYMENT_STATUS_NO_PAYMENT_REQUIRED",

	CONNECTION_TYPE_INTERPRETER_CALL_TO_CUSTOMER = "CONNECTION_TYPE_INTERPRETER_CALL_TO_CUSTOMER",
	CONNECTION_TYPE_CUSTOMER_CALL_TO_INTERPRETER = "CONNECTION_TYPE_CUSTOMER_CALL_TO_INTERPRETER",
	CONNECTION_TYPE_CONTACT_CALL_TO_INTERPRETER = "CONNECTION_TYPE_CONTACT_CALL_TO_INTERPRETER",
	CONNECTION_TYPE_INTERPRETER_CALL_TO_CONTACT = "CONNECTION_TYPE_INTERPRETER_CALL_TO_CONTACT",
	CONNECTION_TYPE_VIDEO_CONFERENCE = "CONNECTION_TYPE_VIDEO_CONFERENCE",
	CONNECTION_TYPE_VIDEO_CONFERENCE_TWILIO = "CONNECTION_TYPE_VIDEO_CONFERENCE_TWILIO",

	INTREPRETATION_CONNECTION_TYPE = "INTREPRETATION_CONNECTION_TYPE",
	INTREPRETATION_CHANGE_CONNECTION_TYPE = "INTREPRETATION_CHANGE_CONNECTION_TYPE",
	INTREPRETATION_CONNECTION_SENT_VIA = "INTREPRETATION_CONNECTION_SENT_VIA",
	INTREPRETATION_CONNECTION_NOTES_TO_CONTACT = "INTREPRETATION_CONNECTION_NOTES_TO_CONTACT",
	INTREPRETATION_CONNECTION_NOTES_TO_WORKER = "INTREPRETATION_CONNECTION_NOTES_TO_WORKER",
	INTREPRETATION_CONNECTION_REFERENCE_TO_CONTACT = "INTREPRETATION_CONNECTION_REFERENCE_TO_CONTACT",
	INTREPRETATION_CONNECTION_REFERENCE_TO_WORKER = "INTREPRETATION_CONNECTION_REFERENCE_TO_WORKER",

	INTREPRETATION_CONNECTION_FIRSTNAME = "INTREPRETATION_CONNECTION_FIRSTNAME",
	INTREPRETATION_CONNECTION_LASTNAME = "INTREPRETATION_CONNECTION_LASTNAME",
	INTREPRETATION_CONNECTION_ORGANIZATION = "INTREPRETATION_CONNECTION_ORGANIZATION",
	INTREPRETATION_CONNECTION_PHONE = "INTREPRETATION_CONNECTION_PHONE",
	INTREPRETATION_CONNECTION_EMAIL = "INTREPRETATION_CONNECTION_EMAIL",
	INTREPRETATION_CONNECTION_LANGUAGE = "INTREPRETATION_CONNECTION_LANGUAGE",
	INTREPRETATION_CONNECTION_INFO_VIA_MAIL = "INTREPRETATION_CONNECTION_INFO_VIA_MAIL",
	INTREPRETATION_CONNECTION_INFO_VIA_PHONE = "INTREPRETATION_CONNECTION_INFO_VIA_PHONE",
	INTREPRETATION_WORK_STARTED_AT = "INTREPRETATION_WORK_STARTED_AT",
	INTREPRETATION_WORK_ENDED_AT = "INTREPRETATION_WORK_ENDED_AT",
	INTERPRETATION_LENGTH_FEES_PAYMENTS = "INTERPRETATION_LENGTH_FEES_PAYMENTS",

	//interpretationInfoBoxTranslation
	INTERPRETATION_INFO_CONTACT = "INTERPRETATION_INFO_CONTACT",
	INTERPRETATION_INFO_RESERVATION_IN_MIN = "INTERPRETATION_INFO_RESERVATION_IN_MIN",
	INTERPRETATION_INFO_INVOICE = "INTERPRETATION_INFO_INVOICE",
	INTERPRETATION_INTERPRETER_PAYMENT = "INTERPRETATION_INTERPRETER_PAYMENT",
	INTERPRETATION_CUSTOMER_PAYMENT = "INTERPRETATION_CUSTOMER_PAYMENT",
	INTERPRETATION_INFO_INTERPRETER_INVOICE = "INTERPRETATION_INFO_INTERPRETER_INVOICE",
	INTERPRETATION_INFO_CUSTOMER_INVOICE = "INTERPRETATION_INFO_CUSTOMER_INVOICE",
	INTERPRETATION_INTERPRETERS_PAYMENT_TYPE = "INTERPRETATION_INTERPRETERS_PAYMENT_TYPE",
	INTERPRETATION_CHOSEN_ONE = "INTERPRETATION_CHOSEN_ONE",
	INTERPRETATION_CANDIDATES = "INTERPRETATION_CANDIDATES",
	INTERPRETATION_INVOICE_DETAILS = "INTERPRETATION_INVOICE_DETAILS",
	INTERPRETATION_RATINGS = "INTERPRETATION_RATINGS",
	INTERPRETATION_NO_INVOICE = "INTERPRETATION_NO_INVOICE",
	INTERPRETATION_YES_INVOICE = "INTERPRETATION_YES_INVOICE",
	INTERPRETATION_SEARCH = "INTERPRETATION_SEARCH",
	INTERPRETATION_CREATE_ORDER_NUMBER = "INTERPRETATION_CREATE_ORDER_NUMBER",
	INTERPRETATION_SEND_ORDER_CONFIRMATION = "INTERPRETATION_SEND_ORDER_CONFIRMATION",
	INTERPRETATION_SEND_UPDATED_ORDER_CONFIRMATION = "INTERPRETATION_SEND_UPDATED_ORDER_CONFIRMATION",
	INTERPRETATION_NULL_INTERPRETATION_CONFIRMATION_TEXT = "INTERPRETATION_NULL_INTERPRETATION_CONFIRMATION_TEXT",
	INTERPRETATION_NULL_INTERPRETATION_WITH_FEES = "INTERPRETATION_NULL_INTERPRETATION_WITH_FEES",
	INTERPRETATION_NULL_INTERPRETATION_WITHOUT_FEES = "INTERPRETATION_NULL_INTERPRETATION_WITHOUT_FEES",

	INTERPRETATION_PRICE_WITOUTH_VAT_PER_MIN = "INTERPRETATION_PRICE_WITOUTH_VAT_PER_MIN",
	INTERPRETATION_CUSTOMER_CANCELLED = "INTERPRETATION_CUSTOMER_CANCELLED",
	INTERPRETATION_CANCELLATION_TERM = "INTERPRETATION_CANCELLATION_TERM",
	INTERPRETATION_NO_WORKER = "INTERPRETATION_NO_WORKER",
	INTERPRETATION_NO_WORKERS = "INTERPRETATION_NO_WORKERS",
	INTERPRETATION_NO_CUSTOMER = "INTERPRETATION_NO_CUSTOMER",
	INTREPRETATION_CONFIRM_SEND_AUTOMATIC_OFFERS = "INTREPRETATION_CONFIRM_SEND_AUTOMATIC_OFFERS",
	INTREPRETATION_CONFIRM_DELETE_OPEN_OFFERS = "INTREPRETATION_CONFIRM_DELETE_OPEN_OFFERS",
	INTREPRETATION_CONFIRM_SEND_OPEN_OFFERS = "INTREPRETATION_CONFIRM_SEND_OPEN_OFFERS",
	INTREPRETATION_CLOSE_OFFER_ADD = "INTREPRETATION_CLOSE_OFFER_ADD",
	INTREPRETATION_NO_WORKER_TO_SELECT = "INTREPRETATION_NO_WORKER_TO_SELECT",
	COMMON_FAVOURITES = "COMMON_FAVOURITES",
	INTREPRETATION_SEND_DRAFTS = "INTREPRETATION_SEND_DRAFTS",
	INTERPRETATION_CUSTOMER_ESTIMATED = "INTERPRETATION_CUSTOMER_ESTIMATED",


	//interpretationDataTranslations
	INTERPRETATION_DATA_TYPE_ON_SITE = "INTERPRETATION_DATA_TYPE_ON_SITE",
	INTERPRETATION_DATA_TYPE_ASSIGNMENT = "INTERPRETATION_DATA_TYPE_ASSIGNMENT",
	INTERPRETATION_DATA_TAGS_VIDEO = "INTERPRETATION_DATA_TAGS_VIDEO",
	INTERPRETATION_DATA_GENDER_MALE = "INTERPRETATION_DATA_GENDER_MALE",
	INTERPRETATION_DATA_GENDER_FEMALE = "INTERPRETATION_DATA_GENDER_FEMALE",
	INTERPRETATION_DATA_CONNECTION_DETAIL_TO_CUSTOMER = "INTERPRETATION_DATA_CONNECTION_DETAIL_TO_CUSTOMER",
	INTERPRETATION_DATA_CONNECTION_DETAIL_TO_INTERPRETER = "INTERPRETATION_DATA_CONNECTION_DETAIL_TO_INTERPRETER",
	INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE_TWILIO = "INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE_TWILIO",
	INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE = "INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE",
	INTERPRETATION_DATA_STATUS_CONFIRMED_BOOKING_SELECT = "INTERPRETATION_DATA_STATUS_CONFIRMED_BOOKING_SELECT",
	INTERPRETATION_DATA_STATUS_PRELIMINARY_BOOKING_SELECT = "INTERPRETATION_DATA_STATUS_PRELIMINARY_BOOKING_SELECT",
	INTERPRETATION_DATA_STATUS_WAITING_SELECT = "INTERPRETATION_DATA_STATUS_WAITING_SELECT",
	INTERPRETATION_DATA_STATUS_OFFER_BOOKING_SELECT = "INTERPRETATION_DATA_STATUS_OFFER_BOOKING_SELECT",
	INTERPRETATION_DATA_STATUS_WAITING_REVIEWS_SELECT = "INTERPRETATION_DATA_STATUS_WAITING_REVIEWS_SELECT",
	INTERPRETATION_DATA_STATUS_COMPLETE_SELECT = "INTERPRETATION_DATA_STATUS_COMPLETE_SELECT",
	INTERPRETATION_DATA_STATUS_CONFIRMED_BOOKING = "INTERPRETATION_DATA_STATUS_CONFIRMED_BOOKING",
	INTERPRETATION_DATA_STATUS_PRELIMINARY_BOOKING = "INTERPRETATION_DATA_STATUS_PRELIMINARY_BOOKING",
	INTERPRETATION_DATA_STATUS_WAITING = "INTERPRETATION_DATA_STATUS_WAITING",
	INTERPRETATION_DATA_STATUS_OFFER_BOOKING = "INTERPRETATION_DATA_STATUS_OFFER_BOOKING",
	INTERPRETATION_DATA_STATUS_OFFER_BOOKING_WAITING = "INTERPRETATION_DATA_STATUS_OFFER_BOOKING_WAITING",
	INTERPRETATION_DATA_STATUS_WAITING_REVIEWS = "INTERPRETATION_DATA_STATUS_WAITING_REVIEWS",
	INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER_REVIEW = "INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER_REVIEW",
	INTERPRETATION_STATUS_WAITING_CUSTOMER_APPROVAL = 'INTERPRETATION_STATUS_WAITING_CUSTOMER_APPROVAL',
	INTERPRETATION_DATA_STATUS_DUPLICATE = "INTERPRETATION_DATA_STATUS_DUPLICATE",
	INTERPRETATION_DATA_STATUS_IN_PROGRESS = "INTERPRETATION_DATA_STATUS_IN_PROGRESS",
	INTERPRETATION_DATA_STATUS_WAITING_REVIEW = "INTERPRETATION_DATA_STATUS_WAITING_REVIEW",
	INTERPRETATION_DATA_STATUS_WAITING_CUSTOMER_REVIEW = "INTERPRETATION_DATA_STATUS_WAITING_CUSTOMER_REVIEW",
	INTERPRETATION_DATA_STATUS_CUSTOMER_CANCEL = "INTERPRETATION_DATA_STATUS_CUSTOMER_CANCEL",
	INTERPRETATION_DATA_STATUS_INTERPRETERS_NOT_FOUND = "INTERPRETATION_DATA_STATUS_INTERPRETERS_NOT_FOUND",
	INTERPRETATION_DATA_STATUS_FAVORITES_NOT_FOUND = "INTERPRETATION_DATA_STATUS_FAVORITES_NOT_FOUND",
	INTERPRETATION_DATA_STATUS_INTERPRETER_CANCEL = "INTERPRETATION_DATA_STATUS_INTERPRETER_CANCEL",
	INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER = "INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER",
	INTERPRETATION_DATA_STATUS_WAITING_CUSTOMER = "INTERPRETATION_DATA_STATUS_WAITING_CUSTOMER",
	INTERPRETATION_DATA_STATUS_WAITING_REVIEW_SINGLE = "INTERPRETATION_DATA_STATUS_WAITING_REVIEW_SINGLE",
	INTERPRETATION_DATA_STATUS_WAITING_REVIEW_MULTIPLE = "INTERPRETATION_DATA_STATUS_WAITING_REVIEW_MULTIPLE",
	INTERPRETATION_DATA_CUSTOMER_ESTIMATE_15 = "INTERPRETATION_DATA_CUSTOMER_ESTIMATE_15",
	INTERPRETATION_DATA_CUSTOMER_ESTIMATE_30 = "INTERPRETATION_DATA_CUSTOMER_ESTIMATE_30",
	INTERPRETATION_DATA_CUSTOMER_ESTIMATE_45 = "INTERPRETATION_DATA_CUSTOMER_ESTIMATE_45",
	INTERPRETATION_DATA_CUSTOMER_ESTIMATE_MT_45 = "INTERPRETATION_DATA_CUSTOMER_ESTIMATE_MT_45",
	INTERPRETATION_ORDER_BY = "INTERPRETATION_ORDER_BY",
	INTERPRETATION_ORDER_BY_CREATED = "INTERPRETATION_ORDER_BY_CREATED",
	INTERPRETATION_ORDER_BY_START_DATE = "INTERPRETATION_ORDER_BY_START_DATE",

	//Mail type translations
	BOOKING_CONFIRMATION = "BOOKING_CONFIRMATION",
	FEEDBACK_SURVEY = "FEEDBACK_SURVEY",
	MODIFY_FORM = "MODIFY_FORM",

	//interpretationProcessingTranslations
	INTERPRETATION_PROCESSING_IN_A_MOMENT = "INTERPRETATION_PROCESSING_IN_A_MOMENT",
	INTERPRETATION_PROCESSING_INTERPRETER_WILL_CALL = "INTERPRETATION_PROCESSING_INTERPRETER_WILL_CALL",
	INTERPRETATION_PROCESSING_CALL_INTERPRETER = "INTERPRETATION_PROCESSING_CALL_INTERPRETER",
	INTERPRETATION_PROCESSING_VIDEO_CONFERENCE = "INTERPRETATION_PROCESSING_VIDEO_CONFERENCE",
	INTERPRETATION_PROCESSING_WAITING_REVIEW = "INTERPRETATION_PROCESSING_WAITING_REVIEW",
	INTERPRETATION_PROCESSING_INTERPRETERS_NOT_FOUND = "INTERPRETATION_PROCESSING_INTERPRETERS_NOT_FOUND",
	INTERPRETATION_PROCESSING_FAVORITES_NOT_FOUND = "INTERPRETATION_PROCESSING_FAVORITES_NOT_FOUND",
	INTERPRETATION_PROCESSING_OPEN_VIDEO_CONNECTION = "INTERPRETATION_PROCESSING_OPEN_VIDEO_CONNECTION",
	INTERPRETATION_PROCESSING_OPEN_INTERPRETATION = "INTERPRETATION_PROCESSING_OPEN_INTERPRETATION",
	INTERPRETATION_PROCESSING_CLOSE = "INTERPRETATION_PROCESSING_CLOSE",
	INTERPRETATION_PROCESSING_INTERRUPT = "INTERPRETATION_PROCESSING_INTERRUPT",
	INTERPRETATION_PROCESSING_TITLE = "INTERPRETATION_PROCESSING_TITLE",

	//interpretationReviewTranslations
	INTERPRETATION_RATING = "INTERPRETATION_RATING",
	INTERPRETATION_REVIEW_TEXT = "INTERPRETATION_REVIEW_TEXT",
	INTERPRETATION_START_REVIEW = "INTERPRETATION_START_REVIEW",
	INTERPRETATION_REVIEW_ADD_FAVORITE_INTERPRETER = "INTERPRETATION_REVIEW_ADD_FAVORITE_INTERPRETER",
	INTERPRETATION_REVIEW_INTERPRETER = "INTERPRETATION_REVIEW_INTERPRETER",
	INTERPRETATION_REVIEW_INTERPRETER_IS_FAVORITE = "INTERPRETATION_REVIEW_INTERPRETER_IS_FAVORITE",

	//interpretationsTranslations
	INTERPRETATIONS_NOT_FOUND = "INTERPRETATIONS_NOT_FOUND",
	INTERPRETATIONS_CLEAR_ALL = "INTERPRETATIONS_CLEAR_ALL",
	INTERPRETATION_PRICE = "INTERPRETATION_PRICE",
	INTERPRETATION_RESPONSE_TIME = "INTERPRETATION_RESPONSE_TIME",

	// Email order translations
	EMAIL_ORDER_NO_ORDERS = "EMAIL_ORDER_NO_ORDERS",

	//videoTranslations
	VIDEO_TOKEN_EXPIRED = "VIDEO_TOKEN_EXPIRED",

	//infoTextTranslations
	INFO_TEXT_BOOKING = "INFO_TEXT_BOOKING",
	INFO_TEXT_ON_SITE = "INFO_TEXT_ON_SITE",
	INFO_TEXT_INSTANT = "INFO_TEXT_INSTANT",

	//modalTranslations
	MODAL_ORDER_CONFIRMED_TITLE = "MODAL_ORDER_CONFIRMED_TITLE",
	MODAL_CONFIRMATION_TITLE = "MODAL_CONFIRMATION_TITLE",
	MODAL_CONFIRMATION_INTERRUPT = "MODAL_CONFIRMATION_INTERRUPT",
	MODAL_ORDER_CONFIRMED = "MODAL_ORDER_CONFIRMED",
	MODAL_ORDER_RECEIVED = "MODAL_ORDER_RECEIVED",
	MODAL_ORDER_TRACK = "MODAL_ORDER_TRACK",
	MODAL_ARE_YOU_SURE_CLEAR_INTERPRETATIONS = "MODAL_ARE_YOU_SURE_CLEAR_INTERPRETATIONS",
	MODAL_ARE_YOU_SURE_ORDER = "MODAL_ARE_YOU_SURE_ORDER",
	MODAL_AMOUNT_OF_TRANSLATIONS = "MODAL_AMOUNT_OF_TRANSLATIONS",
	MODAL_AMOUNT_ONE_TRANSLATION = "MODAL_AMOUNT_ONE_TRANSLATION",

	//calendarTranslations
	CALENDAR_MODAL_TITLE = "CALENDAR_MODAL_TITLE",
	CALENDAR_NO_SELECTION_MESSAGE = "CALENDAR_NO_SELECTION_MESSAGE",
	CALENDAR_NO_USERS_MESSAGE = "CALENDAR_NO_USERS_MESSAGE",

	//cancelationFormTranslations
	CANCEL_FORM_MODIFY_TEXT = "CANCEL_FORM_MODIFY_TEXT",

	//commentTranslations
	COMMENTS_ADD_NEW_COMMENT = "COMMENTS_ADD_NEW_COMMENT",
	COMMENTS_COMMENTED = "COMMENTS_COMMENTED",
	COMMENTS_COMMENTER = "COMMENTS_COMMENTER",
	COMMENTS_COMMENT_GENERAL = "COMMENTS_COMMENT_GENERAL",

	//commissionTranslations
	COMMISSION_GENERAL = "COMMISSION_GENERAL",
	COMMISSIONS_SKILLS = "COMMISSIONS_SKILLS",
	COMMISSIONS_LANGUAGES_DESCRIPTION_TEXT = "COMMISSIONS_LANGUAGES_DESCRIPTION_TEXT",
	COMMISSIONS_CONTRACTS_DESCRIPTION_TEXT = "COMMISSIONS_CONTRACTS_DESCRIPTION_TEXT",
	COMMISSIONS_CV_PARSE = "COMMISSIONS_CV_PARSE",
	COMMISSIONS_CV_PARSE_PROBABILITY = "COMMISSIONS_CV_PARSE_PROBABILITY",
	COMMISSIONS_CV_PARSE_SKILLS = "COMMISSIONS_CV_PARSE_SKILLS",
	COMMISSIONS_CV_PARSE_EDUCATION = "COMMISSIONS_CV_PARSE_EDUCATION",
	COMMISSIONS_CV_PARSE_WORKING_EXPERIENCE = "COMMISSIONS_CV_PARSE_WORKING_EXPERIENCE",
	COMMISSIONS_CV_PARSE_HEALTHCARE = "COMMISSIONS_CV_PARSE_HEALTHCARE",
	COMMISSIONS_CV_PARSE_LEGAL = "COMMISSIONS_CV_PARSE_LEGAL",
	COMMISSIONS_CV_PARSE_CHILDCARE = "COMMISSIONS_CV_PARSE_CHILDCARE",
	COMMISSIONS_CV_PARSE_LOCATION = "COMMISSIONS_CV_PARSE_LOCATION",
	COMMISSIONS_CV_PARSE_YEARS_OF_EXPERIENCE = "COMMISSIONS_CV_PARSE_YEARS_OF_EXPERIENCE",
	COMMISSIONS_CV_PARSE_LANGUAGE_CODE = "COMMISSIONS_CV_PARSE_LANGUAGE_CODE",
	COMMISSIONS_CV_PARSE_LANGUAGES = "COMMISSIONS_CV_PARSE_LANGUAGES",
	COMMISSIONS_TEST_CV = "COMMISSIONS_TEST_CV",
	COMMISSIONS_DEVICE = "COMMISSIONS_DEVICE",
	COMMISSIONS_IOS = "COMMISSIONS_IOS",
	COMMISSIONS_ANDROID = "COMMISSIONS_ANDROID",
	COMMISSIONS_DEVICE_DETAILS = "COMMISSIONS_DEVICE_DETAILS",
	COMMISSIONS_SKILL_LEVEL = "COMMISSIONS_SKILL_LEVEL",
	COMMISSIONS_EVERYDAY = "COMMISSIONS_EVERYDAY",
	COMMISSIONS_PROFESSIONAL = "COMMISSIONS_PROFESSIONAL",
	COMMISSIONS_EDUCATION_EXPERIENCE = "COMMISSIONS_EDUCATION_EXPERIENCE",
	COMMISSIONS_WORK_EXPERIENCE = "COMMISSIONS_WORK_EXPERIENCE",
	COMMISSIONS_INTERVIEW_DATE = "COMMISSIONS_INTERVIEW_DATE",
	COMMISSIONS_INTERVIEW_DETAILS = "COMMISSIONS_INTERVIEW_DETAILS",
	COMMISSIONS_ADDED_AS_INTERPRETER = "COMMISSIONS_ADDED_AS_INTERPRETER",
	COMMISSIONS_STATUS_OF_SECURITY_CHECK = "COMMISSIONS_STATUS_OF_SECURITY_CHECK",
	COMMISSIONS_NO_UP_TO_DATE = "COMMISSIONS_NO_UP_TO_DATE",
	COMMISSIONS_SECURITY_CHECK_DETAILS = "COMMISSIONS_SECURITY_CHECK_DETAILS",
	COMMISSIONS_SECURITY_CHECK_DATE = "COMMISSIONS_SECURITY_CHECK_DATE",
	COMMISSIONS_CONTRACT_SEND = "COMMISSIONS_CONTRACT_SEND",
	COMMISSIONS_CONTRACT_OPEN = "COMMISSIONS_CONTRACT_OPEN",
	COMMISSIONS_CONTRACT_COMPLETED = "COMMISSIONS_CONTRACT_COMPLETED",
	COMMISSIONS_CV = "COMMISSIONS_CV",
	COMMISSIONS_EDUCATION_ATTACHMENTS = "COMMISSIONS_EDUCATION_ATTACHMENTS",
	COMMISSIONS_MESSAGE_TO_APPLICANT = "COMMISSIONS_MESSAGE_TO_APPLICANT",
	COMMISSIONS_VERIFYING_INFO = "COMMISSIONS_VERIFYING_INFO",
	COMMISSION_SEARCH_KEYWORD = "COMMISSION_SEARCH_KEYWORD",
	COMMISSION_SEARCH_PREDEFINED_SKILL = "COMMISSION_SEARCH_PREDEFINED_SKILL",
	COMMISSION_SEARCH_ANY_SKILL = "COMMISSION_SEARCH_ANY_SKILL",
	COMMISSION_SEARCH_DESCRIPTION_SKILL = "COMMISSION_SEARCH_DESCRIPTION_SKILL",
	COMMISSION_SEARCH_CANDIDATES = "COMMISSION_SEARCH_CANDIDATES",
	COMMISSION_RECRUITING_TOOL = "COMMISSION_RECRUITING_TOOL",
	COMMISSION_LISTING = "COMMISSION_LISTING",
	COMMISSION_HEALTHCARE_SKILLS = "COMMISSION_HEALTHCARE",
	COMMISSION_LEGAL_SKILLS = "COMMISSION_LEGAL_SKILLS",
	COMMISSION_CHILDCARE_SKILLS = "COMMISSION_CHILDCARE_SKILLS",
	COMMISSION_ANY_SECTOR = "COMMISSION_ANY_SECTOR",


	//summaryTranslations
	SUMMARIES_TIME_INTERVAL = "SUMMARIES_TIME_INTERVAL",
	SUMMARIES_GRADE = "SUMMARIES_GRADE",
	SUMMARY_YOUPRET_NAME = "SUMMARY_YOUPRET_NAME",
	SUMMARY_YOUPRET_ADDRESS = "SUMMARY_YOUPRET_ADDRESS",
	SUMMARY_YOUPRET_CITY = "SUMMARY_YOUPRET_CITY",
	SUMMARY_YOUPRET_INFO = "SUMMARY_YOUPRET_INFO",
	SUMMARY_OF_SALES = "SUMMARY_OF_SALES",
	SUMMARY_TOTAL_COMPENSATION = "SUMMARY_TOTAL_COMPENSATION",
	SUMMARY_COMPENSATION_TO_ACCOUNT = "SUMMARY_COMPENSATION_TO_ACCOUNT",
	SUMMARY_PAYMENT_DATE = "SUMMARY_PAYMENT_DATE",
	SUMMARY_NET_AMOUNT_AFTER_DEDUCTIONS = "SUMMARY_NET_AMOUNT_AFTER_DEDUCTIONS",
	SUMMARY_GRADING_AVERAGE = "SUMMARY_GRADING_AVERAGE",
	SUMMARY_GRADING_POINTS = "SUMMARY_GRADING_POINTS",
	SUMMARY_QUESTIONS = "SUMMARY_QUESTIONS",
	SUMMARY_EMAIL = "SUMMARY_EMAIL",
	SUMMARY_CONFIRM_ACTION = "SUMMARY_CONFIRM_ACTION",
	SUMMARY_PUBLISHED = "SUMMARY_PUBLISHED",
	SUMMARY_DRAFT = "SUMMARY_DRAFT",

	//tagTranslations
	TAGS_LANGUAGE_VERSION = "TAGS_LANGUAGE_VERSION",

	//orderTranslations
	ORDERS_ORGANIZATION = "ORDERS_ORGANIZATION",
	ORDERS_LANGUAGE_CODE = "ORDERS_LANGUAGE_CODE",

	//tasksTranslations
	TASKS_ORDER_TASK = "TASKS_ORDER_TASK",
	TASKS_ORGANIZATION = "TASKS_ORGANIZATION",
	TASKS_COMMISSIONER = "TASKS_COMMISSIONER",
	TASKS_ORDERED_TASKITEMS = "TASKS_ORDERED_TASKITEMS",
	TASKS_NOTES = "TASKS_NOTES",
	TASKS_ADD_USER = "TASKS_ADD_USER",

	//feeTranslations
	PAYMENT = "PAYMENT",
	PAYMENT_DATE = "PAYMENT_DATE",
	PAYMENT_ROW = "PAYMENT_ROW",
	PAYMENT_INSTANT_ACCEPTANCE_FEE = "PAYMENT_INSTANT_ACCEPTANCE_FEE",
	PAYMENT_ADDITIONAL_FEE = "PAYMENT_ADDITIONAL_FEE",
	PAYMENT_TRAVEL_FEE = "PAYMENT_TRAVEL_FEE",
	PAYMENT_FEE_WITHOUT_VAT = "PAYMENT_FEE_WITHOUT_VAT",
	PAYMENT_AMOUNT = "PAYMENT_AMOUNT",
	PAYMENT_UNIT = "PAYMENT_UNIT",
	PAYMENT_MINUTES = "PAYMENT_MINUTES",
	PAYMENT_CURRENCY = "PAYMENT_CURRENCY",
	PAYMENT_EUR = "PAYMENT_EUR",
	PAYMENT_USD = "PAYMENT_USD",
	PAYMENT_DESCRIPTION = "PAYMENT_DESCRIPTION",
	PAYMENT_EEZY = "PAYMENT_EEZY",
	PAYMENT_TRUSTER = "PAYMENT_TRUSTER",
	PAYMENT_PURCHASE_INVOICE = "PAYMENT_PURCHASE_INVOICE",
	PAYMENT_BANK_TRANSFER = "PAYMENT_BANK_TRANSFER",
	PAYMENT_MANUAL = "PAYMENT_MANUAL",
	PAYMENT_NO_PAYMENT = "PAYMENT_NO_PAYMENT",

	//SEND GRID EMAILS
	SENDGRID_EMAILS_NO_EMAIL = "SENDGRID_EMAILS_NO_EMAIL",

	// VALIDATION_MESSAGES
	VALIDATION_ATLEAST_3_DIGITS = "VALIDATION_ATLEAST_3_DIGITS",
	VALIDATION_EMAIL = "VALIDATION_EMAIL",
	VALIDATION_MIN_LENGTH = "VALIDATION_MIN_LENGTH",
	VALIDATION_MAX_LENGTH = "VALIDATION_MAX_LENGTH",

	VALIDATION_MIN_AMOUNT = "VALIDATION_MIN_AMOUNT",
	VALIDATION_MAX_AMOUNT = "VALIDATION_MAX_AMOUNT",
	VALIDATION_FUTURE_TIME = "VALIDATION_FUTURE_TIME",
	VALIDATION_ERROR_EQUAL_EMAILS = "VALIDATION_ERROR_EQUAL_EMAILS",
	VALIDATION_EQUAL_FIELDS = "VALIDATION_EQUAL_FIELDS",
	//Twilio Video
	VIDEO_AUDIOINPUT = "VIDEO_AUDIOINPUT",
	VIDEO_VIDEOINPUT = "VIDEO_VIDEOINPUT",

	RESERVED_DATE_USER_NAME = "RESERVED_DATE_USER_NAME",
	RESERVED_DATE_DATE = "RESERVED_DATE_DATE",
	RESERVED_DATE_NO_RESULTS = "RESERVED_DATE_NO_RESULTS",
	RESERVED_DATE_DESCRIPTION = "RESERVED_DATE_DESCRIPTION",
	RESERVED_DATE_TYPE = "RESERVED_DATE_TYPE",
	RESERVED_DATE_OPTIONS = "RESERVED_DATE_OPTIONS",

	// UserGroup
	USER_GROUPS_LIST_TITLE = "USER_GROUPS_LIST_TITLE",
	USER_GROUPS_LIST_ADD = "USER_GROUPS_LIST_ADD",
	USER_GROUPS_LIST_NO_RESULTS = "USER_GROUPS_LIST_NO_RESULTS",
	USER_GROUP_EDIT_TITLE = "USER_GROUP_EDIT_TITLE",
	USER_GROUP_EDIT_DELETE = "USER_GROUP_EDIT_DELETE",

	// UserContractSettings
	USER_CONTRACT_SETTINGS_LIST_TITLE = "USER_CONTRACT_SETTINGS_LIST_TITLE",
	USER_CONTRACT_SETTINGS_LIST_ADD = "USER_CONTRACT_SETTINGS_LIST_ADD",
	USER_CONTRACT_SETTINGS_LIST_NO_RESULTS = "USER_CONTRACT_SETTINGS_LIST_NO_RESULTS",
	USER_CONTRACT_SETTINGS_EDIT_TITLE = "USER_CONTRACT_SETTINGS_EDIT_TITLE",
	USER_CONTRACT_SETTINGS_EDIT_DELETE = "USER_CONTRACT_SETTINGS_EDIT_DELETE",

	USER_CONTRACT_SETTINGS_TYPE_PERMANENT = "USER_CONTRACT_SETTINGS_TYPE_PERMANENT",
	USER_CONTRACT_SETTINGS_TYPE_TEMPORARY = "USER_CONTRACT_SETTINGS_TYPE_TEMPORARY",

	USER_CONTRACT_HOLIDAY_ACCURUAL_ONE_YEAR_TWO_AFTER_TWO_AND_HALF = "USER_CONTRACT_HOLIDAY_ACCURUAL_ONE_YEAR_TWO_AFTER_TWO_AND_HALF",

	USER_CONTRACT_SETTINGS_TRIAL_START_DATE = "USER_CONTRACT_SETTINGS_TRIAL_START_DATE",
	USER_CONTRACT_SETTINGS_TRIAL_END_DATE = "USER_CONTRACT_SETTINGS_TRIAL_END_DATE",
	USER_CONTRACT_SETTINGS_TRIAL_WEEKLY_HOURS_START = "USER_CONTRACT_SETTINGS_TRIAL_WEEKLY_HOURS_START",
	USER_CONTRACT_SETTINGS_TRIAL_WEEKLY_HOURS_END = "USER_CONTRACT_SETTINGS_TRIAL_WEEKLY_HOURS_END",
	USER_CONTRACT_SETTINGS_WORK_LENGTH_MIN = "USER_CONTRACT_SETTINGS_WORK_LENGTH_MIN",
	USER_CONTRACT_SETTINGS_WORK_LENGTH_MAX = "USER_CONTRACT_SETTINGS_WORK_LENGTH_MAX",
	USER_CONTRACT_SETTINGS_HOLIDAY_ACCRUAL = "USER_CONTRACT_SETTINGS_HOLIDAY_ACCRUAL",
	USER_CONTRACT_SETTINGS_BREAK_MINUTES = "USER_CONTRACT_SETTINGS_BREAK_MINUTES",
	USER_CONTRACT_SETTINGS_ATTACH_ADD = "USER_CONTRACT_SETTINGS_ATTACH_ADD",

	// Eezy Truster
	EEZY_TRUSTER_EVENT = "EEZY_TRUSTER_EVENT",
	EEZY_TRUSTER_SUMMARY_OF_JOBS = "EEZY_TRUSTER_SUMMARY_OF_JOBS",
	EEZY_TRUSTER_INFORMATION = "EEZY_TRUSTER_INFORMATION",
	EEZY_TRUSTER_STATUS_NO_EVENT = "EEZY_TRUSTER_STATUS_NO_EVENT",
	EEZY_TRUSTER_STATUS_READY_TO_SEND = "EEZY_TRUSTER_STATUS_READY_TO_SEND",
	EEZY_TRUSTER_STATUS_SENDING = "EEZY_TRUSTER_STATUS_SENDING",
	EEZY_TRUSTER_STATUS_SENT = "EEZY_TRUSTER_STATUS_SENT",
	EEZY_TRUSTER_STATUS_FAILED = "EEZY_TRUSTER_STATUS_FAILED",
	EEZY_TRUSTER_STATUS_FAILED_VALIDATION = "EEZY_TRUSTER_STATUS_FAILED_VALIDATION",
	EEZY_TRUSTER_EVENT_TYPE_EEZY = "EEZY_TRUSTER_EVENT_TYPE_EEZY",
	EEZY_TRUSTER_EVENT_TYPE_TRUSTER = "EEZY_TRUSTER_EVENT_TYPE_TRUSTER",
	EEZY_TRUSTER_EVENT_TYPE_MANUAL = "EEZY_TRUSTER_EVENT_TYPE_MANUAL",
	EEZY_TRUSTER_EVENT_TYPE_PURCHASE_INVOICE = "EEZY_TRUSTER_EVENT_TYPE_PURCHASE_INVOICE",
	EEZY_TRUSTER_EVENT_TYPE_BANK_TRANSFER = "EEZY_TRUSTER_EVENT_TYPE_BANK_TRANSFER",

	// Leads translation
	MENU_LEADS = "MENU_LEADS",
	COMPETITION_NAME = "COMPETITION_NAME",
	COMMON_CODE = "COMMON_CODE",
	COMMON_LEAD = "COMMON_LEAD",
	COMMON_SELECT_ALL = "COMMON_SELECT_ALL",
	COMMON_DE_SELECT_ALL = "COMMON_DE_SELECT_ALL",

	// Apply translations
	APPLY_VALITYS_BODY = "APPLY_VALITYS_BODY",
	APPLY_VALITYS_THANKS = "APPLY_VALITYS_THANKS",
	APPLY_VALITYS_TITLE = "APPLY_VALITYS_TITLE",
	APPLY_YP_TITLE = "APPLY_YP_TITLE",
	APPLY_YP_BODY = "APPLY_YP_BODY",
	APPLY_YP_THANKS = "APPLY_YP_THANKS",

	// Application translations
	APPLICATIONS_CONFIRM_SEND = "APPLICATIONS_CONFIRM_SEND",
	APPLICATIONS_CONFIRM_EDIT = "APPLICATIONS_CONFIRM_EDIT",
	APPLICATIONS_CONFIRM_EXPORT = "APPLICATIONS_CONFIRM_EXPORT",
	APPLICATIONS_NO_APPLICATIONS = "APPLICATIONS_NO_APPLICATIONS",
	APPLICATIONS_DEGREE = "APPLICATIONS_DEGREE",
	APPLICATIONS_DEGREE_CERTIFICATION = "APPLICATIONS_DEGREE_CERTIFICATION",
	APPLICATIONS_DEGREE_ADD_NEW = "APPLICATIONS_DEGREE_ADD_NEW",
	APPLICATIONS_WORK_EXP = "APPLICATIONS_WORK_EXP",
	APPLICATIONS_EXP = "APPLICATIONS_EXP",
	APPLICATIONS_EXP_CERTIFICATIONS = "APPLICATIONS_EXP_CERTIFICATIONS",
	APPLICATIONS_EXP_ADD_NEW = "APPLICATIONS_EXP_ADD_NEW",
	APPLICATIONS_EXPERT_CERT_NUMBER = "APPLICATIONS_EXPERT_CERT_NUMBER",
	APPLICATIONS_EDUCATION = "APPLICATIONS_EDUCATION",
	APPLICATIONS_COMPANY = "APPLICATIONS_COMPANY",
	APPLICATIONS_PAYMENT_INFO = "APPLICATIONS_PAYMENT_INFO",
	APPLICATIONS_GET_OLD_VERSIONS = "APPLICATIONS_GET_OLD_VERSIONS",
	APPLICATIONS_SEARCH_FROM_APPLICATIONS = "APPLICATIONS_SEARCH_FROM_APPLICATIONS",
	APPLICATIONS_SEARCH_FROM_APPLICATIONS_PLACEHOLDER = "APPLICATIONS_SEARCH_FROM_APPLICATIONS_PLACEHOLDER",
	APPLICATIONS_SECURITY_CLEARANCE = "APPLICATIONS_SECURITY_CLEARANCE",
	APPLICATIONS_EDIT_DISABLED = "APPLICATIONS_EDIT_DISABLED",
	APPLICATIONS_DISABLE_EDIT = "APPLICATIONS_DISABLE_EDIT",
	APPLICATIONS_ACCOUNT_CREATED_DATE = "APPLICATIONS_ACCOUNT_CREATED_DATE",
	APPLICATIONS_REGISTRATION_NUMBER = "APPLICATIONS_REGISTRATION_NUMBER",
	APPLICATIONS_FIELD_OF_WORK = "APPLICATIONS_FIELD_OF_WORK",
	APPLICATIONS_IM_INTERESTED_IN = "APPLICATIONS_IM_INTERESTED_IN",
	APPLICATIONS_I_HAVE_COMPANY = "APPLICATIONS_I_HAVE_COMPANY",
	APPLICATIONS_DESCRIPTION_OF_LANGUAGES = "APPLICATIONS_DESCRIPTION_OF_LANGUAGES",
	APPLICATIONS_SHOW_PROFILE = "APPLICATIONS_SHOW_PROFILE",
	APPLICATIONS_EXPORT_TO_WORKER = "APPLICATIONS_EXPORT_TO_WORKER",
	APPLICATIONS_TAB_BASIC_INFORMATION = "APPLICATIONS_TAB_BASIC_INFORMATION",
	APPLICATIONS_TAB_SKILLS = "APPLICATIONS_TAB_SKILLS",
	APPLICATIONS_TAB_MANAGEMENT = "APPLICATIONS_TAB_MANAGEMENT",
	APPLICATIONS_LANGUAGES_INFO = "APPLICATIONS_LANGUAGES_INFO",

	// Email templates
	EMAIL_TEMPLATE_SELECT_TEMPLATE = "EMAIL_TEMPLATE_SELECT_TEMPLATE",
	EMAIL_TEMPLATE_MODAL_TITLE = "EMAIL_TEMPLATE_MODAL_TITLE",
	EMAIL_TEMPLATE_SUCCESS_SENDING = "EMAIL_TEMPLATE_SUCCESS_SENDING",
	EMAIL_TEMPLATE_PREVIEW = "EMAIL_TEMPLATE_PREVIEW",
	EMAIL_TEMPLATE_STOP_PREVIEW = "EMAIL_TEMPLATE_STOP_PREVIEW",
	EMAIL_TEMPLATE_SHOW_TOKENS = "EMAIL_TEMPLATE_SHOW_TOKENS",
	EMAIL_TEMPLATE_SHOW_FILLED = "EMAIL_TEMPLATE_SHOW_FILLED",

	LEADS_SENT_MAILS = "LEADS_SENT_MAILS",
	LEADS_CREATE_NEW_LEADS = "LEADS_CREATE_NEW_LEADS",
	LEADS_CREATE_NEW_LEADS_HELPER_TEXT = "LEADS_CREATE_NEW_LEADS_HELPER_TEXT",
	LEADS_CREATE_NEW_LEADS_PRE_PART = "LEADS_CREATE_NEW_LEADS_PRE_PART",
	LEADS_CREATE_NEW_LEADS_AFTER_PART = "LEADS_CREATE_NEW_LEADS_AFTER_PART",
	LEADS_CREATE_NEW_LEADS_ADD_LEADS = "LEADS_CREATE_NEW_LEADS_ADD_LEADS",
	LEAD_CAMPAING_TITLE = "LEAD_CAMPAING_TITLE",
	LEADS_CREATE_NEW_LEADS_LEADS_ADDED = "LEADS_CREATE_NEW_LEADS_LEADS_ADDED",
	COMMON_DELETE_SELECTED = "COMMON_DELETE_SELECTED",
	COMMON_SEND_MAIL = "COMMON_SEND_MAIL",
	LEADS_DELETE_ARE_YOU_SURE = "LEADS_DELETE_ARE_YOU_SURE",
	COMMON_CONFIRM = "COMMON_CONFIRM",
	COMMON_SUCCESS = "COMMON_SUCCESS",

	SELECT_ENTER_SEARCH_TERM = "SELECT_ENTER_SEARCH_TERM",
	SELECT_ENTER_ATLEAST_CHARS = "SELECT_ENTER_ATLEAST_CHARS",
	SELECT_NO_RESULTS = "SELECT_NO_RESULTS",

	LANGUAGE_PAIR_SELECT = "LANGUAGE_PAIR_SELECT",
	LANGUAGE_PAIR_EXPERIENCE_IN_HOURS = "LANGUAGE_PAIR_EXPERIENCE_IN_HOURS",
	LANGUAGE_PAIR_EXPERIENCE_IN_PAGES = "LANGUAGE_PAIR_EXPERIENCE_IN_PAGES",
}
