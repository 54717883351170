import { TFunction } from "i18next";
import { IUser } from "../classes/User";
import { IOption } from "../components/ui/Input/Input";
import { EEezyTrusterPaymentStatus } from "../shared/eezy-truster-data";
import { ETranslation } from "../translations/translation-keys";
import { IInterpretation } from "./IInterpretation";

export interface IEezyTrusterEvent {
	id: string;
	created: string;
	updated?: string;
	eezyPayments: IEezyTrusterPayment[];
	startDate: string;
	endDate: string;
	sentDate?: string;
	type: EEezyTrusterEventType;
}

export enum EEezyTrusterEventType {
	EEZY = "EEZY",
	TRUSTER = "TRUSTER",
	MANUAL = "MANUAL",
	PURCHASE_INVOICE = "PURCHASE_INVOICE",
	BANK_TRANSFER = "BANK_TRANSFER",
};

export const EEZY_TRUSTER_EVENT_TYPE: IOption[] = [
	{ value: EEezyTrusterEventType.EEZY, labelTranslation: ETranslation.EEZY_TRUSTER_EVENT_TYPE_EEZY },
	{ value: EEezyTrusterEventType.TRUSTER, labelTranslation: ETranslation.EEZY_TRUSTER_EVENT_TYPE_TRUSTER },
	{ value: EEezyTrusterEventType.MANUAL, labelTranslation: ETranslation.EEZY_TRUSTER_EVENT_TYPE_MANUAL },
	{ value: EEezyTrusterEventType.PURCHASE_INVOICE, labelTranslation: ETranslation.EEZY_TRUSTER_EVENT_TYPE_PURCHASE_INVOICE },
	{ value: EEezyTrusterEventType.BANK_TRANSFER, labelTranslation: ETranslation.EEZY_TRUSTER_EVENT_TYPE_BANK_TRANSFER },
];

export const getEezyTrusterEventTypeString = (
	t: TFunction, status: EEezyTrusterEventType
): string => t(EEZY_TRUSTER_EVENT_TYPE.find(item => item.value === status)?.labelTranslation || "");

export enum ENetvisorTypes {
	MANUAL = "MANUAL",
	PURCHASE_INVOICE = "PURCHASE_INVOICE",
	BANK_TRANSFER = "BANK_TRANSFER"
}

export interface IEezyTrusterPayment {
	id: string;
	customTotals: IEezyTrusterPaymentTotal[];
	eezyPaymentRows: IEezyTrusterPaymentRow[];
	eezySalesOrderId: string;
	eezyTotal: IEezyTrusterPaymentTotal;
	eezyTotalBooking: IEezyTrusterPaymentTotal;
	minutesTotal: number;
	minutesTotalBooking: number;
	minutesTotalOnSite: number;
	priceTotal: number;
	priceVatTotal: number;
	priceWithVatTotal: number;
	spRows: IEezyTrusterPaymentRow[];
	status: EEezyTrusterPaymentStatus;
	statusMessage: string;
	managementTotal?: IEezyTrusterPaymentTotal;
	eezyTotalOnSite?: IEezyTrusterPaymentTotal;
	startTotal?: IEezyTrusterPaymentTotal;
	user: IUser;
	validationErrors?: string[];
}


export interface IEezyPaymentManagementTotal {

}

export interface IEezyTrusterPaymentTotal {
	endDate: string;
	fromServer: boolean;
	price: number;
	priceVat: number;
	priceWithVat: number;
	productName: string;
	quantity: number;
	quantityUnitPrice: number;
	startDate: string;
	vatPercent: number;
}

export interface IEezyTrusterPaymentRow {
	endDate: string;
	interpretation: IInterpretation;
	minutes: number;
	startDate: string;
}