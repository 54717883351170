import React, { useContext, useEffect, useState } from 'react';
import { ILead } from '../../../interfaces/ILead';
import { EInputType, IInputField } from '../../ui/Input/Input';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { getInputData } from '../../ui/Input/input-utils';
import { SelectedLeadsContext } from '../../../pages/Leads/LeadList/LeadsListPage';
import SentMailsColumn from '../../SentMailDisplay/SentMailsColumn';

interface IProps {
	lead: ILead;
	onClick: (lead: ILead) => void;
}

const EInputs = {
	leadSelected: "leadSelected",
}

const LeadRowItem: React.FC<IProps> = ({ lead, onClick }) => {

	const { selectedRows, setSelectedRows, selectAll } = useContext(SelectedLeadsContext);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.leadSelected]: {
			type: EInputType.boolean,
			label: "",
			labelId: lead.id,
			value: false,
		},
	})

	const updateInput = (value: boolean) => {
		setInputs(inputs => {
			return {
				...inputs,
				[EInputs.leadSelected]: {
					...inputs[EInputs.leadSelected],
					value: value,
				},
			}
		})
	}

	useEffect(() => {
		if (selectAll) {
			updateInput(true)
		} else {
			updateInput(false);
		}
	}, [selectAll, setInputs])

	useEffect(() => {
		const { leadSelected } = getInputData<{ leadSelected: boolean }>(inputs);
		const inList = selectedRows.find((item) => item.id === lead.id) ? true : false;
		if (inList && leadSelected) return;
		if (inList && !leadSelected) setSelectedRows(selectedRows.filter((item) => item.id !== lead.id));
		if (!inList && leadSelected) setSelectedRows([...selectedRows, lead]);
	}, [selectedRows, inputs, lead, setSelectedRows]);

	const createInput = useCreateInput(inputs, setInputs);

	return <tr onClick={() => onClick(lead)}>
		<td colSpan={1} onClick={(e) => { e.stopPropagation() }}>
			{createInput(EInputs.leadSelected)}
		</td>
		<td colSpan={1}>
			{lead.created} | {lead.updated}
		</td>
		<td colSpan={1}>
			{lead.firstName} {lead.lastName}
		</td>
		<td colSpan={2}>
			{lead.phoneNumber}
		</td>
		<td colSpan={2}>
			{lead.email}
		</td>
		<td colSpan={1}>
			{lead.organizationName}
		</td>
		<td colSpan={1}>
			{lead.competitionName}
		</td>
		<td colSpan={1}>
			{lead.additionalInformation}
		</td>
		<td colSpan={1}>
			{lead.uri}
		</td>
		<td colSpan={1}>
			{lead.languageCode}
		</td>
		<td colSpan={1}>
			{lead.code}
		</td>
		<SentMailsColumn colSpan={3} sentMails={lead.sentMails ?? []} />
	</tr>

};

export default LeadRowItem;
