import { customFetch, EFetchMethod } from '../custom-fetch';
import { IInterpretation } from '../interfaces/IInterpretation';



export const findInterpretations = async (
	startDate: string,
	endDate: string,
	userId: string,
) => {
	return await customFetch<IInterpretation[]>("/v2/interpretations/find", EFetchMethod.POST, JSON.stringify({ startDate, endDate, userId}));
};



export const updateInterpretation = async (id: string, name: string, value: string ) => {
	// Missing checks because server returns stupid response
	try {
		await customFetch<void>(`/interpretations/update`, EFetchMethod.POST, JSON.stringify({ id, name, value, sendEmail: false, sendPushMessage: false }));
		return true;
	} catch (e) {
		return false;
	}

}