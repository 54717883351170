import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '../../../components/ui/Container/Container';
import Heading from '../../../components/Heading/Heading';
import { ETranslation } from '../../../translations/translation-keys';
import { LeadsListPagination, PAGINATION_FETCH_SIZE } from '../../../hooks/useLeadsList';
import { getLeadsClear } from '../../../store/actions/leadsActions';
import Alert, { EAlertColor } from '../../../components/ui/Alert/Alert';
import Spinner from '../../../components/ui/Spinner/Spinner';
import LeadsListContent from '../../../components/Leads/LeadsList/LeadsListContent';
import { ILead } from '../../../interfaces/ILead';
import { createListSelectContext } from '../../../hooks/useListSelectContext';
import LeadsSendMailButton from '../../../components/Leads/LeadsList/LeadsSendMailButton';
import LeadsRemoveLeadsButton from '../../../components/Leads/LeadsList/LeadsRemoveLeadsButton';
import classes from "../../../components/Leads/LeadsList/leads.module.scss"
import { EInputType, IInputField } from '../../../components/ui/Input/Input';
import { useCreateInput } from '../../../hooks/useCreateInput';
import InputGroup from '../../../components/ui/InputGroup/InputGroup';
import Button from '../../../components/ui/Button/Button';
import { getInputData } from '../../../components/ui/Input/input-utils';


interface IProps extends RouteComponentProps { }
export const { SelectedItemsContext: SelectedLeadsContext } = createListSelectContext<ILead>();

enum EInputs {
	campaingName = "campaingName",
}

const LeadsListPage: React.FC<IProps> = ({ history }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { searchHandler, leads, changeSearchParams, leadsLoading, loaderRef, error, hasMoreLeads } = LeadsListPagination()

	useEffect(() => {
		searchHandler({ start: 0, fetchSize: PAGINATION_FETCH_SIZE })
		return () => {
			dispatch(getLeadsClear())
		}
	}, [dispatch, searchHandler])

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.campaingName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMPETITION_NAME,
			value: "",
		},
	})

	const createInput = useCreateInput(inputs, setInputs);

	const search = () => {
		const data = getInputData<{ campaingName: string }>(inputs)
		searchHandler({ start: 0, fetchSize: PAGINATION_FETCH_SIZE, campaingName: data.campaingName })
	}

	const [selectedRows, setSelectedRows] = useState<ILead[]>([]);
	const [selectAll, setSelectAll] = useState(false);

	return (
		<Container>
			<SelectedLeadsContext.Provider value={{ selectedRows, setSelectedRows, selectAll, setSelectAll }} >
				<Heading>
					{t(ETranslation.MENU_LEADS)}
					{(selectedRows.length > 0 && leads) &&
						<div className={classes.actionButtons}>
							<LeadsRemoveLeadsButton leads={selectedRows} />
							<LeadsSendMailButton leads={selectedRows} />
						</div>
					}
				</Heading>
				<div className={classes.infoContainer}>
					<span>total: {leads ? leads.length : 0} rows</span>
					<span>selected: {selectedRows ? selectedRows.length : 0} rows</span>
				</div>
				<InputGroup>
					{createInput(EInputs.campaingName, { onKeyup: changeSearchParams })}
					<Button onClick={search}>{t(ETranslation.COMMON_SEARCH)}</Button>
				</InputGroup>
				{error && <Alert color={EAlertColor.WARNING}>{error}</Alert>}
				{leads && <LeadsListContent leads={leads} onRowClick={(lead) => { console.log(lead); }} />}
				{leadsLoading && <Spinner />}
				<p
					ref={loaderRef}
					style={{ color: hasMoreLeads ? "#ccc" : "#fff" }}
				>
					{hasMoreLeads ? t(ETranslation.COMMON_SHOW_MORE_ROWS) : ""}
				</p>
			</SelectedLeadsContext.Provider>
		</Container>
	);
};

export default LeadsListPage;
