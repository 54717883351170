import { IInterpretation } from './../../interfaces/IInterpretation';
import { Dispatch } from 'redux';
import { IUser } from '../../classes/User';
import { IConnectionDetail, IInterpretationReview, ILocationDetail } from '../../interfaces/IInterpretation';

import { EFetchMethod, customFetch, customFetchWithResponse } from '../../custom-fetch';
import { IPaginationOptions, PAGINATION_FETCH_SIZE } from '../../hooks/usePagination';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IInterpretationAction from '../../interfaces/store/IInterpretationAction';
import { EInterpretation } from '../../components/Interpretation/InterpretationDetails/InterpretationDetails';
import { IOption, TInputValue } from '../../components/ui/Input/Input';
import * as dateFns from "date-fns";
import * as userService from '../../services/userServices';
import { defaultEndDate, defaultStartDate } from '../../components/Interpretation/InterpretationsSearch/SearchDateTools/SearchDateTools';
import { IInterpretationUserAvailability } from '../../interfaces/IInterpretationUserAvailability';

type TAction = IInterpretationAction;

export interface IInterpretationRequestOptions {
	fromLanguage: string;
	toLanguage: string;
	customerEstimate: string;
	customer: IUser;
	tags?: string[];
	customerReference?: string;
	gender?: string;
	connectionDetail?: IConnectionDetail;
	locationDetail?: ILocationDetail;
}
export interface IInstantInterpretation {
	interpretation: IInterpretation;
}

export interface IInterpretationResponse {
	interpretations: IInterpretation[];
	paginationCursor: string;
}

const findInterpretationsStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_FIND_START,
	};
};

const findInterpretationsSuccess = (interpretations: IInterpretation[], hasMoreInterpretations: boolean, paginationCursor: string, searchAll?: boolean, averageResponseTime?: string | null): TAction => {
	if (averageResponseTime) {
		return {
			type: EActionTypes.INTERPRETATION_FIND_SUCCESS,
			interpretations,
			paginationCursor,
			averageResponseTime,
			hasMoreInterpretations,
			searchAll,
		};
	} else {
		return {
			type: EActionTypes.INTERPRETATION_FIND_SUCCESS,
			interpretations,
			paginationCursor,
			hasMoreInterpretations,
			searchAll,
		};
	}
};

const resetInterpretationStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_RESET_START,
	};
};

const resetInterpretationSuccess = (interpretation: IInterpretation): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_RESET_SUCCESS,
		interpretation,
	};
};

const resetInterpretationError = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_RESET_ERROR,
		error,
	};
};

export const resetInterpretationClear = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_RESET_CLEAR,
	};
};

export const resetInterpretation = (payment: boolean, id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(resetInterpretationStart());
			const data = {
				id,
				name: payment ? "resetInterpretationWithPayment" : "resetInterpretationNoPayment",
			}
			customFetchWithResponse("/interpretations/update", EFetchMethod.POST, JSON.stringify(data)).then((res) => {
				customFetchWithResponse(`/interpretations?id=${id}&timezone=2&tzString=Europe%2FHelsinki`).then((result) => {
					result.json().then((interpretation: IInterpretation) => {
						dispatch(resetInterpretationSuccess(interpretation));
					});
				}).catch((err) => {
					dispatch(resetInterpretationError((err as Error).message))
				});
			}).catch((err) => {
				dispatch(resetInterpretationError((err as Error).message))
			})
		} catch (err) {
			dispatch(resetInterpretationError((err as Error).message));
		}

	}
}

const confirmationEmailStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_CONFIRMATION_EMAIL_START,
	};
};

const confirmationEmailSuccess = (interpretation: IInterpretation): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_CONFIRMATION_EMAIL_SUCCESS,
		interpretation
	};
};

const confirmationEmailError = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_CONFIRMATION_EMAIL_ERROR,
		error
	};
};

export const confirmationEmailReset = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_CONFIRMATION_EMAIL_RESET,
	};
};

export const confirmationEmailSend = (id: string, emails: string, update: boolean) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(confirmationEmailStart());
			const data = {
				id,
				isManual: true,
				email: emails,
			}
			const url = update ? "/interpretations/updatedorderconfirmations" : "/interpretations/orderconfirmations";
			customFetchWithResponse(url, EFetchMethod.POST, JSON.stringify(data)).then((res) => {
				customFetchWithResponse(`/interpretations?id=${id}&timezone=2&tzString=Europe%2FHelsinki`).then((result) => {
					result.json().then((interpretation: IInterpretation) => {
						dispatch(confirmationEmailSuccess(interpretation));
					});
				}).catch((err) => {
					dispatch(confirmationEmailError((err as Error).message))
				});
			}).catch((err) => {
				dispatch(confirmationEmailError((err as Error).message))
			})
		} catch (err) {
			dispatch(confirmationEmailError((err as Error).message));
		}

	}
};



const findInterpretationsFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_FIND_FAIL,
		error,
	};
};


export const findInterpretations = (searchParams: IPaginationOptions, signal: AbortSignal) => {
	return async (dispatch: Dispatch) => {
		try {
			// TODO(joonas): Ugly, clean this
			let searchParameters = {
				startDate: searchParams.startDate ?? dateFns.format(new Date(defaultStartDate), "dd.MM.yyyy HH:mm"),
				endDate: searchParams.endDate ?? dateFns.format(new Date(defaultEndDate), "dd.MM.yyyy HH:mm"),
				fetchSize: searchParams.searchAll ? 1999 : searchParams.fetchSize ?? PAGINATION_FETCH_SIZE,
				start: searchParams.start ?? 0,
				orderBy: searchParams.orderBy ?? "startDate",
				dateAccuracy: searchParams.dateAccuracy ?? "HOUR",
				startDateEnd: searchParams.endDate ?? dateFns.format(new Date(defaultEndDate), "dd.MM.yyyy HH:mm"),
				startDateStart: searchParams.startDate ?? dateFns.format(new Date(defaultStartDate), "dd.MM.yyyy HH:mm"),
				fromLanguage: searchParams.fromLanguage && searchParams.fromLanguage.length > 0 ? searchParams.fromLanguage : null,
				toLanguage: searchParams.toLanguage && searchParams.toLanguage.length > 0 ? searchParams.toLanguage : null,
				status: searchParams.status ?? null,
				type: searchParams.type ?? null,
				users: searchParams.users ?? null,
				fetchFromUserRows: searchParams.fetchFromUserRows,
				organizations: searchParams.organization ?? null,
				organizationGroups: searchParams.organizationGroup ?? null,
				feeType: (searchParams.feeType && searchParams.feeType !== null && searchParams.feeType?.length > 0) ? searchParams.feeType : null,
			};
			dispatch(findInterpretationsStart());
			const res = await customFetchWithResponse("/interpretations?data=" + JSON.stringify(searchParameters), EFetchMethod.GET, undefined, signal);
			const paginationCursor = res.headers.get("Pagination-Cursor") ?? "";
			const json = await res.json();
			const hasMoreInterpretations = json.count > (searchParameters.fetchSize + searchParameters.start) ? true : false;
			const averageResponseTime = res.headers.get("Avgtime");
			dispatch(findInterpretationsSuccess(json.data, hasMoreInterpretations, paginationCursor, searchParams?.searchAll, averageResponseTime));
		} catch (error) {
			dispatch(findInterpretationsFail((error as Error).message));
		}
	};
};

export const findInterpretationsClear = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_FIND_CLEAR,
	};
};

const getInterpretationStart = (id: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GET_START,
		id
	};
};

const getInterpretationSuccess = (interpretation: IInterpretation, averageResponseTime: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GET_SUCCESS,
		averageResponseTime,
		interpretation,
	};
};

const getInterpretationFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GET_FAIL,
		error,
	};
};

export const getInterpretationClear = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GET_CLEAR,
	};
};

export const getInterpretation = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getInterpretationStart(id));
			const res = await customFetchWithResponse(`/interpretations?id=${id}&timezone=2&tzString=Europe%2FHelsinki`);
			const averageResponseTime = res.headers.get("Avgtime") ?? "";
			const json = await res.json();
			dispatch(getInterpretationSuccess(json, averageResponseTime));
		} catch (error) {
			dispatch(getInterpretationFail((error as Error).message));
		}
	};
};

const deleteInterpretationStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_DELETE_START,
	};
};

const deleteInterpretationSuccess = (id: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_DELETE_SUCCESS,
		id,
	};
};

const deleteInterpretationFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_DELETE_FAIL,
		error,
	};
};

export const deleteInterpretationClear = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_DELETE_CLEAR,
	};
};

export const deleteInterpretation = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(deleteInterpretationStart());
			await customFetch<boolean>(`/interpretations/delete?id=${id}`, EFetchMethod.DELETE);
			dispatch(deleteInterpretationSuccess(id));
		} catch (error) {
			dispatch(deleteInterpretationFail((error as Error).message));
		}
	};
};

const saveOrUpdateInterpretationStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_SAVE_OR_UPDATE_START,
	};
};

const saveOrUpdateInterpretationSuccess = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_SAVE_OR_UPDATE_SUCCESS,
	};
};

const saveOrUpdateInterpretationFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_SAVE_OR_UPDATE_FAIL,
		error,
	};
};

export const saveOrUpdateInterpretationClear = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_SAVE_OR_UPDATE_CLEAR
	}
}

export const saveInterpretation = (interpretation: IInterpretation) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveOrUpdateInterpretationStart());
			// TODO(Joonas): TZ normalization
			await customFetch<{id:string}>("/interpretations/add?tzString=Europe%2FHelsinki", EFetchMethod.POST, JSON.stringify(interpretation)).then((res)=>{
				window.location.assign("/interpretations/"+res.id)
			}).catch((error)=>{
				dispatch(saveOrUpdateInterpretationFail((error as Error).message));
			})
		} catch (error) {
			dispatch(saveOrUpdateInterpretationFail((error as Error).message));
		}
	};
};

export const updateInterpretation = (interpretation: IInterpretation) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveOrUpdateInterpretationStart());
			await customFetch<IInterpretation>("/interpretations/update", EFetchMethod.POST, JSON.stringify(interpretation));
			dispatch(saveOrUpdateInterpretationSuccess());
		} catch (error) {
			dispatch(saveOrUpdateInterpretationFail((error as Error).message));
		}
	};
};


const addVideoRoomStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_ADD_VIDEO_ROOM_START,
	};
};

const addVideoRoomSuccess = (interpretation: IInterpretation): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_ADD_VIDEO_ROOM_SUCCESS,
		interpretation
	};
};

const addVideoRoomFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_ADD_VIDEO_ROOM_ERROR,
		error,
	};
};

export const addVideoRoomClear = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_ADD_VIDEO_ROOM_CLEAR
	}
}

export const addVideoRoom = (interpretationId: string, resourceId?: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(addVideoRoomStart());
			const interpretation = await customFetch<IInterpretation>("/v2/interpretations/videoroom/add", EFetchMethod.POST, JSON.stringify({ interpretationId, resourceId }));
			dispatch(addVideoRoomSuccess(interpretation));
		} catch (error) {
			dispatch(addVideoRoomFail((error as Error).message));
		}
	};
};

const removeVideoRoomStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_REMOVE_VIDEO_ROOM_START,
	};
};

const removeVideoRoomSuccess = (interpretation: IInterpretation): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_REMOVE_VIDEO_ROOM_SUCCESS,
		interpretation
	};
};

const removeVideoRoomFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_REMOVE_VIDEO_ROOM_ERROR,
		error,
	};
};

export const removeVideoRoomClear = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_REMOVE_VIDEO_ROOM_CLEAR
	}
}

export const removeVideoRoom = (interpretationId: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(removeVideoRoomStart());
			const interpretation = await customFetch<IInterpretation>(`/v2/interpretations/videoroom/remove?id=${interpretationId}`, EFetchMethod.DELETE);
			dispatch(removeVideoRoomSuccess(interpretation));
		} catch (error) {
			dispatch(removeVideoRoomFail((error as Error).message));
		}
	};
};

const requestInterpretationStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_REQUEST_START,
	};
};

const requestInterpretationSuccess = (instantInterpretation: IInstantInterpretation): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_REQUEST_SUCCESS,
		instantInterpretation,
	};
};

const requestInterpretationFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_REQUEST_FAIL,
		error,
	};
};

export const requestInterpretationClear = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_REQUEST_CLEAR
	}
}

export const requestInterpretation = (searchParams: IInterpretation) => {
	return async (dispatch: Dispatch) => {
		try {
			searchParams.connectionDetail = {
				connectionType: searchParams.connectionType,
				phoneNumber: searchParams.phoneNumber,
			}
			dispatch(requestInterpretationStart());
			const instantInterpretation = await customFetch<IInstantInterpretation>("/interpretations/request", EFetchMethod.POST, JSON.stringify(searchParams));
			dispatch(requestInterpretationSuccess(instantInterpretation));
		} catch (error) {
			dispatch(requestInterpretationFail((error as Error).message));
		}
	};
};

const interruptInterpretationStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_INTERRUPT_START,
	};
};

const interruptInterpretationSuccess = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_INTERRUPT_SUCCESS,
	};
};

const interruptInterpretationFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_INTERRUPT_FAIL,
		error,
	};
};

export const interruptInterpretation = (interpretation: IInterpretation) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(interruptInterpretationStart());
			await customFetch<string>("/interpretations/interrupt", EFetchMethod.POST, JSON.stringify(interpretation));
			dispatch(interruptInterpretationSuccess());
		} catch (error) {
			dispatch(interruptInterpretationFail((error as Error).message));
		}
	};
};

const completeInterpretationReviewStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_REVIEW_START,
	};
};

const completeInterpretationReviewSuccess = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_REVIEW_SUCCESS,
	};
};

const completeInterpretationReviewFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_REVIEW_FAIL,
		error,
	};
};

export const completeInterpretationReview = (interpretation: IInterpretationReview) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(completeInterpretationReviewStart());
			await customFetch<IInterpretation>("/interpretations/completereview", EFetchMethod.POST, JSON.stringify(interpretation));
			dispatch(completeInterpretationReviewSuccess());
		} catch (error) {
			dispatch(completeInterpretationReviewFail((error as Error).message));
		}
	};
};

const addFavoriteInterpreterStart = (): TAction => {
	return {
		type: EActionTypes.ADD_FAVORITE_INTERPRETER_START,
	};
};

const addFavoriteInterpreterSuccess = (): TAction => {
	return {
		type: EActionTypes.ADD_FAVORITE_INTERPRETER_SUCCESS,
	};
};

const addFavoriteInterpreterFail = (error: string): TAction => {
	return {
		type: EActionTypes.ADD_FAVORITE_INTERPRETER_FAIL,
		error,
	};
};

export const addFavoriteInterpreter = (interpreter: IUser) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(addFavoriteInterpreterStart());
			await customFetch("/users/favoriteinterpreters/add", EFetchMethod.POST, JSON.stringify(interpreter));
			dispatch(addFavoriteInterpreterSuccess());
		} catch (error) {
			dispatch(addFavoriteInterpreterFail((error as Error).message));
		}
	};
};

const getInterpretationMessageGroupStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_MESSAGEGROUP_GET_START,
	};
};

const getInterpretationMessageGroupSuccess = (interpretation: IInterpretation): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_MESSAGEGROUP_GET_SUCCESS,
		interpretation
	};
};

const getInterpretationMessageGroupFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_MESSAGEGROUP_GET_FAIL,
		error,
	};
};

export const getInterpretationMessageGroupClear = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_MESSAGEGROUP_GET_CLEAR,
	};
};

export const getInterpretationMessageGroup = (messageGroupId: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getInterpretationMessageGroupStart());
			const interpretation = await customFetch<IInterpretation>(`/interpretations?messageGroupId=${messageGroupId}`);
			dispatch(getInterpretationMessageGroupSuccess(interpretation));
		} catch (error) {
			dispatch(getInterpretationMessageGroupFail((error as Error).message));
		}
	};
}

const updateInterpretationPropertyStart = (propertyName: EInterpretation | string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_UPDATE_PROPERTY_START,
		propertyName,
	};
};

const updateInterpretationPropertySuccess = (id: string, propertyName: EInterpretation | string, value: TInputValue | ILocationDetail | IUser | IConnectionDetail): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_UPDATE_PROPERTY_SUCCESS,
		id,
		propertyName,
		value,
	};
};

export const updateInterpretationPropertyFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_UPDATE_PROPERTY_FAIL,
		error,
	}
}

interface IUpdatePropertyResponse {
	status: string,
	displayValue: string,
	value: TInputValue,
}


// Only works with interpreter or user, not userRows, since they are a wrapper for IUser
const IOptionList = {
	customer: "customer",
	interpreter: "interpreter",
}
// TODO Refactor and think logic again.
export const updateInterpretationProperty = (id: string, propertyName: EInterpretation | string, value: TInputValue | ILocationDetail | IConnectionDetail, sendEmail: boolean = false, sendPushMessage: boolean = false) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(updateInterpretationPropertyStart(propertyName));
			// resolve IOptions to values for backend (users, organizations etc. Should go trough this afaik.)
			let sendVal = value;
			if (Object.values(IOptionList).includes(propertyName)) {
				const val = value as IOption;
				if (val !== null && val !== undefined) {
					sendVal = val.value
				}
			}
			const name = propertyName === EInterpretation.durationInMinutes ? EInterpretation.duration : propertyName;
			const tzString = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const res = await customFetch<IUpdatePropertyResponse>(`/interpretations/update?tzString=${tzString}`, EFetchMethod.POST, JSON.stringify({ id, name, value: sendVal, sendEmail, sendPushMessage }));

			if (res && res.status === "success") {
				// we should resolve IOptions to user/org/etc object for proper display.
				if (Object.values(IOptionList).includes(propertyName)) {
					// Currently populated list only for users
					if (sendVal) {
						userService.getUser(sendVal as string).then((user) => {
							dispatch(updateInterpretationPropertySuccess(id, propertyName, user));
						}).catch((err) => {
							dispatch(updateInterpretationPropertyFail(res.status));
						})
					} else {
						dispatch(updateInterpretationPropertyFail(res.status));
					}
				} else {
					dispatch(updateInterpretationPropertySuccess(id, propertyName, value));
				}
			} else {
				dispatch(updateInterpretationPropertyFail(res.status));
			}
		} catch (error) {
			dispatch(updateInterpretationPropertyFail((error as Error).message));
		}
	};
};

const InterpretationsToNetvisorStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_MOVE_TO_NETVISOR_START,
	};
};

const InterpretationsToNetvisorSuccess = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_MOVE_TO_NETVISOR_SUCCESS,
	};
};

export const InterpretationsToNetvisorError = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_MOVE_TO_NETVISOR_ERROR,
		error,
	}
}

export const moveInterpretationsToNetvisor = (searchParams: IPaginationOptions) => {
	return async (dispatch: Dispatch) => {
		try {
			// TODO(joonas): Ugly, clean this
			dispatch(InterpretationsToNetvisorStart());
			let searchParameters = {
				startDate: searchParams.startDate ?? dateFns.format(new Date(defaultStartDate), "dd.MM.yyyy HH:mm"),
				endDate: searchParams.endDate ?? dateFns.format(new Date(defaultEndDate), "dd.MM.yyyy HH:mm"),
				fetchSize: searchParams.searchAll ? 1999 : searchParams.fetchSize ?? PAGINATION_FETCH_SIZE,
				start: searchParams.start ?? 0,
				orderBy: searchParams.orderBy ?? "startDate",
				dateAccuracy: searchParams.dateAccuracy ?? "HOUR",
				startDateEnd: searchParams.endDate ?? dateFns.format(new Date(defaultEndDate), "dd.MM.yyyy HH:mm"),
				startDateStart: searchParams.startDate ?? dateFns.format(new Date(defaultStartDate), "dd.MM.yyyy HH:mm"),
				fromLanguage: searchParams.fromLanguage && searchParams.fromLanguage.length > 0 ? searchParams.fromLanguage : null,
				toLanguage: searchParams.toLanguage && searchParams.toLanguage.length > 0 ? searchParams.toLanguage : null,
				status: searchParams.status ?? null,
				type: searchParams.type ?? null,
				users: searchParams.users ?? null,
				fetchFromUserRows: searchParams.fetchFromUserRows,
				organization: searchParams.organization ?? null,
				organizationGroup: searchParams.organizationGroup ?? null,
				exportTypes: searchParams.exportTypes,
				customerReference: searchParams.customerReference,
			};
			customFetch("/interpretations/shareinterpretationstosheet", EFetchMethod.POST, JSON.stringify(searchParameters)).then((res) => {
				dispatch(InterpretationsToNetvisorSuccess());
			}).catch((err) => {
				dispatch(InterpretationsToNetvisorError(err.message));
			})
		} catch (e) {
			dispatch(InterpretationsToNetvisorError((e as Error).message))
		}
	}
}


const actionStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_ACTION_START,
	};
};

const actionSuccess = (message: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_ACTION_SUCCESS,
		message
	};
};

const actionFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_ACTION_SUCCESS,
		error,
	};
};

export const interpretationActionClear = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_ACTION_CLEAR,
	};
};

export const createOrderNumbers = (ids: string[]) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(actionStart());
			const actionMessage = await customFetch<string>("/v2/interpretations/createordernumbers", EFetchMethod.POST, JSON.stringify({ ids }));
			dispatch(actionSuccess(actionMessage));
		} catch (error) {
			dispatch(actionFail((error as Error).message));
		}
	};
};

export const sendInterpretationConfirmationEmails = (ids: string[], emails: string[]) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(actionStart());
			const message = await customFetch<string>("/v2/interpretations/sendconfirmationemails", EFetchMethod.POST, JSON.stringify({ ids, emails }));
			dispatch(actionSuccess(message));
		} catch (error) {
			dispatch(actionFail((error as Error).message));
		}
	};
};


const generatePaymentDataStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GENERATE_PAYMENT_DATA_START,
	};
};

const generatePaymentDataSuccess = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GENERATE_PAYMENT_DATA_SUCCESS,
	};
};

const generatePaymentDataError = (message: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GENERATE_PAYMENT_DATA_ERROR,
		message
	};
};

export const generatePaymentData = (searchParams: IPaginationOptions) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(generatePaymentDataStart());
			if (!searchParams.feeType) {
				dispatch(generatePaymentDataError("No fee type selected"))
			} else {
				let url = "/eezy/generate";
				// Still ugly af
				let searchParameters = {
					startDate: searchParams.startDate ?? dateFns.format(new Date(defaultStartDate), "dd.MM.yyyy HH:mm"),
					endDate: searchParams.endDate ?? dateFns.format(new Date(defaultEndDate), "dd.MM.yyyy HH:mm"),
					fetchSize: searchParams.searchAll ? 1999 : searchParams.fetchSize ?? PAGINATION_FETCH_SIZE,
					start: searchParams.start ?? 0,
					orderBy: searchParams.orderBy ?? "startDate",
					dateAccuracy: searchParams.dateAccuracy ?? "HOUR",
					startDateEnd: searchParams.endDate ?? dateFns.format(new Date(defaultEndDate), "dd.MM.yyyy HH:mm"),
					startDateStart: searchParams.startDate ?? dateFns.format(new Date(defaultStartDate), "dd.MM.yyyy HH:mm"),
					fromLanguage: searchParams.fromLanguage && searchParams.fromLanguage.length > 0 ? searchParams.fromLanguage : null,
					toLanguage: searchParams.toLanguage && searchParams.toLanguage.length > 0 ? searchParams.toLanguage : null,
					status: searchParams.status ?? null,
					type: searchParams.type ?? null,
					users: searchParams.users ?? null,
					fetchFromUserRows: searchParams.fetchFromUserRows,
					organization: searchParams.organization ?? null,
					organizationGroup: searchParams.organizationGroup ?? null,
					exportTypes: searchParams.exportTypes,
					feeType: (searchParams.feeType && searchParams.feeType !== null && searchParams.feeType?.length > 0) ? searchParams.feeType : null,
				};
				customFetch(url, EFetchMethod.POST, JSON.stringify(searchParameters)).then(() => {
					dispatch(generatePaymentDataSuccess());
					alert("Palkkioaineiston generointi aloitettu. Uusi tapahtuma tulee näkyviin Eezy-osioon. Aineiston muodostus voi kestää muutaman minuutin.")
				}).catch((err) => {
					dispatch(generatePaymentDataError((err as Error).message));
				})
			}

		} catch (error) {
			dispatch(generatePaymentDataError((error as Error).message));
		}
	};
}

const exportToCSVStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GENERATE_PAYMENT_SHEET_START,
	};
};

const exportToCSVSuccess = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GENERATE_PAYMENT_SHEET_SUCCESS,
	};
};

const exportToCSVError = (message: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GENERATE_PAYMENT_SHEET_ERROR,
		message
	};
};

export const downloadMultiDayCSV = (searchParams: IPaginationOptions) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(exportToCSVStart());
			const url = "/interpretations/export";
			const startDate = dateFns.parse(searchParams.startDate ?? defaultStartDate, "dd.MM.yyyy HH:mm", new Date());;
			const endDate = dateFns.parse(searchParams.endDate ?? defaultEndDate, "dd.MM.yyyy HH:mm", new Date());

			const iterations = dateFns.differenceInDays(endDate, startDate);

			for(let i = 0; i < (iterations + 1); i++){
				const loopDate = dateFns.addDays(startDate, i);
				const titleDate = dateFns.format(dateFns.setMinutes(dateFns.setHours(loopDate, 0), 0), "dd.MM")

				let searchParameters = {
					startDate:  dateFns.format(dateFns.setMinutes(dateFns.setHours(loopDate, 0), 0), "dd.MM.yyyy HH:mm"),
					endDate: dateFns.format(dateFns.setMinutes(dateFns.setHours(loopDate, 23), 59), "dd.MM.yyyy HH:mm"),
					fetchSize: searchParams.searchAll ? 1999 : searchParams.fetchSize ?? PAGINATION_FETCH_SIZE,
					start: searchParams.start ?? 0,
					orderBy: searchParams.orderBy ?? "startDate",
					dateAccuracy: searchParams.dateAccuracy ?? "HOUR",
					startDateEnd: dateFns.format(dateFns.setMinutes(dateFns.setHours(loopDate, 23), 59), "dd.MM.yyyy HH:mm"),
					startDateStart: dateFns.format(dateFns.setMinutes(dateFns.setHours(loopDate, 0), 0), "dd.MM.yyyy HH:mm"),
					fromLanguage: searchParams.fromLanguage && searchParams.fromLanguage.length > 0 ? searchParams.fromLanguage : null,
					toLanguage: searchParams.toLanguage && searchParams.toLanguage.length > 0 ? searchParams.toLanguage : null,
					customerReference: searchParams.customerReference,
					status: searchParams.status ?? null,
					type: searchParams.type ?? null,
					users: searchParams.users ?? null,
					fetchFromUserRows: searchParams.fetchFromUserRows,
					organization: searchParams.organization ?? null,
					organizationGroup: searchParams.organizationGroup ?? null,
					exportTypes: searchParams.exportTypes,
					feeType: (searchParams.feeType && searchParams.feeType !== null && searchParams.feeType?.length > 0) ? searchParams.feeType : null,
				};
				customFetch<string>(url, EFetchMethod.POST, JSON.stringify(searchParameters)).then((data) => {
					if(data === "INTERPRETATIONS NOT FOUND")return;
					var blob = new Blob(["\ufeff", data], { type: 'attachment/csv'});
					var csvURL = window.URL.createObjectURL(blob);
					const tempLink = document.createElement('a');
					tempLink.href = csvURL;
					tempLink.setAttribute('download', titleDate + ' tulkkauset.csv');
					tempLink.click();
				}).catch((err) => {
					console.error(err);
					dispatch(exportToCSVError((err as Error).message));
				})
			}
			dispatch(exportToCSVSuccess());
		} catch (error) {
			console.error(error);
			dispatch(exportToCSVError((error as Error).message));
		}
	};
}

export const exportToCSV = (searchParams: IPaginationOptions) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(exportToCSVStart());
			const url = "/interpretations/export";
			const searchParameters = {
				startDate: searchParams.startDate ?? dateFns.format(new Date(defaultStartDate), "dd.MM.yyyy HH:mm"),
				endDate: searchParams.endDate ?? dateFns.format(new Date(defaultEndDate), "dd.MM.yyyy HH:mm"),
				fetchSize: searchParams.searchAll ? 1999 : searchParams.fetchSize ?? PAGINATION_FETCH_SIZE,
				start: searchParams.start ?? 0,
				orderBy: searchParams.orderBy ?? "startDate",
				dateAccuracy: searchParams.dateAccuracy ?? "HOUR",
				startDateEnd: searchParams.endDate ?? dateFns.format(new Date(defaultEndDate), "dd.MM.yyyy HH:mm"),
				startDateStart: searchParams.startDate ?? dateFns.format(new Date(defaultStartDate), "dd.MM.yyyy HH:mm"),
				fromLanguage: searchParams.fromLanguage && searchParams.fromLanguage.length > 0 ? searchParams.fromLanguage : null,
				toLanguage: searchParams.toLanguage && searchParams.toLanguage.length > 0 ? searchParams.toLanguage : null,
				customerReference: searchParams.customerReference,
				status: searchParams.status ?? null,
				type: searchParams.type ?? null,
				users: searchParams.users ?? null,
				fetchFromUserRows: searchParams.fetchFromUserRows,
				organization: searchParams.organization ?? null,
				organizationGroup: searchParams.organizationGroup ?? null,
				exportTypes: searchParams.exportTypes,
				feeType: (searchParams.feeType && searchParams.feeType !== null && searchParams.feeType?.length > 0) ? searchParams.feeType : null,
			};
			customFetch<string>(url, EFetchMethod.POST, JSON.stringify(searchParameters)).then((data) => {
				dispatch(exportToCSVSuccess());
				var blob = new Blob(["\ufeff", data], { type: 'attachment/csv'});
				var csvURL = window.URL.createObjectURL(blob);
				const tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', 'tulkkauset.csv');
				tempLink.click();
			}).catch((err) => {
				dispatch(exportToCSVError((err as Error).message));
			})

		} catch (error) {
			dispatch(exportToCSVError((error as Error).message));
		}
	};
}


const generateSummariesStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GENERATE_SUMMARIES_START,
	};
};

const generateSummariesSuccess = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GENERATE_SUMMARIES_SUCCESS,
	};
};

export const generateSummariesClear = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GENERATE_SUMMARIES_CLEAR,
	};
};

const generateSummariesError = (message: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_GENERATE_SUMMARIES_ERROR,
		error:message,
	};
};

export const generateSummaries = (searchParams: IPaginationOptions, paymentDate: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(generateSummariesStart());
			const url = "/summary/generate/start";
			// TODO(Joonas): Still ugly af -- Normalize
			const searchParameters = {
				startDate: searchParams.startDate ?? dateFns.format(new Date(defaultStartDate), "dd.MM.yyyy HH:mm"),
				endDate: searchParams.endDate ?? dateFns.format(new Date(defaultEndDate), "dd.MM.yyyy HH:mm"),
				fetchSize: searchParams.searchAll ? 1999 : searchParams.fetchSize ?? PAGINATION_FETCH_SIZE,
				start: searchParams.start ?? 0,
				orderBy: searchParams.orderBy ?? "startDate",
				dateAccuracy: searchParams.dateAccuracy ?? "HOUR",
				startDateEnd: searchParams.endDate ?? dateFns.format(new Date(defaultEndDate), "dd.MM.yyyy HH:mm"),
				startDateStart: searchParams.startDate ?? dateFns.format(new Date(defaultStartDate), "dd.MM.yyyy HH:mm"),
				fromLanguage: searchParams.fromLanguage && searchParams.fromLanguage.length > 0 ? searchParams.fromLanguage : null,
				toLanguage: searchParams.toLanguage && searchParams.toLanguage.length > 0 ? searchParams.toLanguage : null,
				status: searchParams.status ?? null,
				type: searchParams.type ?? null,
				users: searchParams.users ?? null,
				fetchFromUserRows: searchParams.fetchFromUserRows,
				organization: searchParams.organization ?? null,
				organizationGroup: searchParams.organizationGroup ?? null,
				exportTypes: searchParams.exportTypes,
				paymentDate,
				feeType: (searchParams.feeType && searchParams.feeType !== null && searchParams.feeType?.length > 0) ? searchParams.feeType : null,
			};
			customFetch(url, EFetchMethod.POST, JSON.stringify(searchParameters)).then((data) => {
				dispatch(generateSummariesSuccess());
			}).catch((err) => {
				console.log(err)
				dispatch(generateSummariesError((err as Error).message));
			})
		} catch (error) {
			dispatch(generateSummariesError((error as Error).message));
		}
	};
}


const sendFeedBackSurveyStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_FEEDBACK_SURVEY_START,
	};
};

const sendFeedBackSurveySuccess = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_FEEDBACK_SURVEY_SUCCESS,
	};
};

const sendFeedBackSurveyError = (message: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_FEEDBACK_SURVEY_ERROR,
		message
	};
};

export const sendFeedBackSurvey = (email: string, mailBody: string, id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(sendFeedBackSurveyStart());
			const url = "/interpretations/sendfeedbacksurvey";
			const body = {
				id,
				email,
				mailBody,
			};
			customFetch(url, EFetchMethod.POST, JSON.stringify(body)).then((data) => {
				dispatch(sendFeedBackSurveySuccess());
			}).catch((err) => {
				dispatch(sendFeedBackSurveyError((err as Error).message));
			})

		} catch (error) {
			dispatch(sendFeedBackSurveyError((error as Error).message));
		}
	};
}

const deleteInterpretationPriceRowStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_DELETE_PRICE_ROW_START,
	};
};

const deleteInterpretationPriceRowSuccess = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_DELETE_PRICE_ROW_SUCCESS,
	};
};

const deleteInterpretationPriceRowError = (message: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_DELETE_PRICE_ROW_ERROR,
		message
	};
};

export const deleteInterpretationPriceRow = (id: string, index: number) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(deleteInterpretationPriceRowStart());
			const url = `/interpretations/pricerow/delete?id=${id}&index=${index}`;
			customFetch(url, EFetchMethod.DELETE).then((data) => {
				dispatch(deleteInterpretationPriceRowSuccess());
			}).catch((err) => {
				dispatch(deleteInterpretationPriceRowError((err as Error).message));
			})
		} catch (error) {
			dispatch(deleteInterpretationPriceRowError((error as Error).message));
		}
	};
}


const confirmOfferUserStart = (userRowId: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_CONFIRM_OFFER_USER_START,
		id: userRowId
	};
};

const confirmOfferUserSuccess = (interpretation: IInterpretation): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_CONFIRM_OFFER_USER_SUCCESS,
		interpretation
	};
};

const confirmOfferUserFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_CONFIRM_OFFER_USER_FAIL,
		error,
	};
};

export const confirmOfferUser = (id: string, userRowId: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(confirmOfferUserStart(userRowId));
			const interpretation = await customFetch<IInterpretation>('/interpretations/offerconfirmuser', EFetchMethod.POST, JSON.stringify({ id, userRowId }));
			dispatch(confirmOfferUserSuccess(interpretation));
		} catch (error) {
			dispatch(confirmOfferUserFail((error as Error).message));
		}
	};
};


const getAvailableInterpretersStart = (): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_AVAILABLE_INTERPRETERS_START,
	};
};

const getAvailableInterpretersSuccess = (userAvailabilities: IInterpretationUserAvailability[]): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_AVAILABLE_INTERPRETERS_SUCCESS,
		userAvailabilities
	};
};

const getAvailableInterpretersFail = (error: string): TAction => {
	return {
		type: EActionTypes.INTERPRETATION_AVAILABLE_INTERPRETERS_FAIL,
		error,
	};
};

export const getAvailableInterpreters = (id: string ) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getAvailableInterpretersStart());
			const userAvailabilities = await customFetch<IInterpretationUserAvailability[]>(`/v2/interpretations/availableinterpreters?id=${id}`);
			dispatch(getAvailableInterpretersSuccess(userAvailabilities));
		} catch (error) {
			dispatch(getAvailableInterpretersFail((error as Error).message));
		}
	};
};