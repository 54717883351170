import React, { useCallback, useContext } from "react";
import { IUser } from "../../../classes/User";
import ModalContext, { EModalSize } from "../../ui/Modal/ModalContext";
import WorkerPaymentTypeUpdate from "./WorkerPaymentTypeUpdate";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import { useDispatch } from "react-redux";
import { changeWorkerFeeTypeClear } from "../../../store/actions/userActions";

const useFeeTypeChangeModal = () => {
	const { t } = useTranslation();
	const { setModal, closeModal } = useContext(ModalContext);
	const dispatch = useDispatch();

	const closeHandler = useCallback(() => {
		closeModal();
		dispatch(changeWorkerFeeTypeClear())
	}, [closeModal, dispatch])

	const openHandler = useCallback((user: IUser) => {
		user ? setModal({
			title: t(ETranslation.EDIT_USER_FEE_TYPE_HELPER_1) + `${user.firstName} ${user.lastName} ` + t(ETranslation.COMMON_FEE_TYPE).toLowerCase(),
			content: <WorkerPaymentTypeUpdate closeModal={closeHandler} user={user} />,
			isOpen: true,
			size: EModalSize.MEDIUM,
			onModalClose: closeHandler,
		}) : console.error("No User Set");
	}, [t, setModal, closeHandler]);


	return openHandler;

}

export default useFeeTypeChangeModal;