import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../translations/translation-keys';

import Alert, { EAlertColor } from '../ui/Alert/Alert';
import Button, { EButtonColor } from '../ui/Button/Button';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../ui/Modal/ModalContext';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';
import Spinner from '../ui/Spinner/Spinner';

interface IProps {
	deleteText?: string;
	hideText?: boolean;
	onDelete?: () => void;
	error?: string | null;
	loading?: boolean;
	success?: boolean;
}

const DeleteModal: React.FC<IProps> = ({
	deleteText,
	hideText,
	onDelete,
	error,
	loading,
	success,
}) => {
	const { closeModal } = useContext(ModalContext);
	const { t } = useTranslation();
	return (
		<>
			<ModalBody>
				{error && <Alert>{error}</Alert>}
				{loading && <Spinner />}
				{success && <Alert color={EAlertColor.SUCCESS}>{t(ETranslation.COMMON_SUCCESS)}</Alert>}
				{hideText ? "" : deleteText ? t(deleteText) : t(ETranslation.DELETEMODAL_CONFIRMATION)}
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => onDelete && onDelete()}
					color={EButtonColor.SUCCESS}
				>
					{t(ETranslation.COMMON_DELETE)}
				</Button>
				<Button onClick={closeModal} color={EButtonColor.DANGER}>
					{t(ETranslation.DELETEMODAL_CANCEL)}
				</Button>
			</ModalFooter>
		</>
	);
};

export default DeleteModal;
