import React from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../../translations/translation-keys";

interface IProps {
	errorString: string;
}

const InterpretationErrorString: React.FC<IProps> = ({ errorString }) => {
	const { t } = useTranslation();
	return <><span style={{ color: "red" }}><b>{t(ETranslation.REDUCER_ERROR)}:</b> {errorString}</span></>
}

export default InterpretationErrorString