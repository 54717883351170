import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../classes/User";
import Heading from "../../../components/Heading/Heading";
import InterpretationsList from "../../../components/Interpretation/InterpretationsList/InterpretationsList";
import InterpretationsSearch from "../../../components/Interpretation/InterpretationsSearch/InterpretationsSearch";
import Container from "../../../components/ui/Container/Container";
import Spinner from "../../../components/ui/Spinner/Spinner";
import { usePagination } from "../../../hooks/usePagination";
import IAppState from "../../../interfaces/store/IAppState";
import * as actions from "../../../store/actions";
import InterpretationListActions from "../../../components/Interpretation/InterpretationListActions/InterpretationListActions";
import { RouteComponentProps } from "react-router-dom";
import Accordion from "../../../components/ui/Accordion/Accordion";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../../../components/ui/Button/Button";
import classes from "./interpretationsListPage.module.scss";

const InterpretationsListPage: React.FC<RouteComponentProps> = ({ history, match, location }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		loaderRef,
		interpretations,
		loading,
		hasMoreInterpretations,
		getDurationAverage,
		searchHandler,
	} = usePagination();

	const { error, user } = useSelector((state: IAppState) => ({
		error: state.interpretation.interpretationsError,
		user: state.auth.user,
	}));

	useEffect(() => {
		return () => {
			dispatch(actions.findInterpretationsClear());
			dispatch(actions.interpretationActionClear());
		};
	}, [dispatch]);

	const createOrderNumbersHandler = (ids: string[]) => {
		dispatch(actions.createOrderNumbers(ids));
	};

	const [showExtraActions, setShowExtaActions] = useState(false);

	const sendConfirmationEmailsHandler = (ids: string[], emails: string[]) => {
		dispatch(actions.sendInterpretationConfirmationEmails(ids, emails));
	};

	const element = <p ref={loaderRef} style={{ color: hasMoreInterpretations ? "#ccc" : "#fff" }}>{hasMoreInterpretations ? t(ETranslation.COMMON_SHOW_MORE_ROWS) : ""}</p>;

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_INTERPRETATIONS)}
				{user?.role === "ADMIN" && <a href="/interpretation/add" target="_blank" rel="noopener noreferrer"><Button className={classes.addNewButton} onClick={() => {}}>{t(ETranslation.REACT_SELECT_ADD_NEW)}</Button></a>}
			</Heading>
			<Accordion title={t(ETranslation.INTERPRETATION_SEARCH)} openDefault={true}>
				<>
					<p>{t(ETranslation.INTERPRETATION_AMOUNT_OF_INTERPRETATIONS)} {interpretations?.length}</p>
					<InterpretationsSearch searchHandler={searchHandler} showExtraActions={showExtraActions} />
					{user?.isYoupretAdmin && (
						<>
							<InterpretationListActions
								interpretations={interpretations}
								onCreateOrderNumbers={createOrderNumbersHandler}
								onSendConfirmationEmails={sendConfirmationEmailsHandler}
							/>
							<Button onClick={() => setShowExtaActions(prevValue => !prevValue)}>{showExtraActions ? 'Piilota' : 'Näytä'} lisävalinnat</Button>
						</>
					)}
				</>
			</Accordion>
			{interpretations &&
				<InterpretationsList
					interpretations={interpretations}
					error={error}
					user={user as User}
					history={history}
					match={match}
					location={location}
				/>
			}
			{!loading && !hasMoreInterpretations && (
				<p>{t(ETranslation.INTERPRETATION_AVG_DURATION)} {getDurationAverage()} {t(ETranslation.COMMON_MIN)}</p>
			)}
			<p>{t(ETranslation.INTERPRETATION_AMOUNT_OF_INTERPRETATIONS)} {interpretations?.length}</p>
			{loading && (
				<div style={{ textAlign: "center", padding: "1rem 0" }}>
					<Spinner />
				</div>
			)}
			{element}
		</Container>
	);
};

export default InterpretationsListPage;
