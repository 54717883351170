import { createContext, Dispatch, SetStateAction } from "react";

export interface ISelectedItemsContext<T> {
	selectedRows: T[];
	setSelectedRows: Dispatch<SetStateAction<T[]>>;
	selectAll: boolean;
	setSelectAll: Dispatch<SetStateAction<boolean>>;
}

export const createListSelectContext = function <T>() {
	const defaultContext: ISelectedItemsContext<T> = {
		selectedRows: [],
		setSelectedRows: () => { },
		selectAll: false,
		setSelectAll: () => { },
	}

	const SelectedItemsContext = createContext<ISelectedItemsContext<T>>(defaultContext);

	return { SelectedItemsContext };
};
