import { useState } from "react";
import { ILeadNew } from "../../interfaces/ILead";
import { IUser } from "../../classes/User";
import { CreateLeadsModal } from "./CreateLeadsModal";


export interface IUseLeadableItems<ILeadable> {
	leadableItems: ILeadable[] | [];
	transform: (items: ILeadable[]) => ILeadNew[];
}

export type ILeadableEntity = IUser;
// Transformeja, muuttuvia valittavia ym listoja varten dummy tähän
// WIP(Joonas): needs some love, end of december 2024 wasn't the time
export const UseLeadableItems = function <ILeadable>({
	leadableItems,
	transform
}: IUseLeadableItems<ILeadable>) {
	const [leadItems] = useState<ILeadNew[]>(transform(leadableItems));
	const handler = CreateLeadsModal();

	return { leadItems, handler }
}

export default UseLeadableItems;