import { IUserGroup } from '../../interfaces/IUserGroup';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IUserGroupAction from '../../interfaces/store/IUserGroupAction';
import IUserGroupState from '../../interfaces/store/IUserGroupState';
import { ETranslation } from '../../translations/translation-keys';
import { removeOne } from '../../utils/reducer-utils';
import { insertFirst, updateOne } from '../../utils/reducer-utils';

type ReducerAction = IUserGroupAction;
type ReducerState = IUserGroupState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  userGroup: null,

  userGroups: null,
  loading: false,
  error: null,

  deletingError: null,
  deleting: false,
  deletingOk: false,

  saveOrUpdateOk: false
};

const findUserGroupStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const findUserGroupSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    userGroups: action.userGroups || null,
    error: null,
    loading: false,
  };
};

const findUserGroupFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, userGroups: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

const findUserGroupClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, userGroups: null, loading: false, error: null };
};

const getUserGroupStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getUserGroupSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    userGroup: action.userGroup || null,
    error: null,
    loading: false,
  };
};

const getUserGroupFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, userGroup: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getUserGroupClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, userGroup: null, loading: false, error: null};
};

const deleteUserGroupStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: true, deletingOk: false, };
};

const deleteUserGroupSuccess: ReducerSignature = (state, action): ReducerState => {
  const userGroups = removeOne<IUserGroup>(state.userGroups, action.id);
  return {
    ...state,
    userGroups,
    deletingError: null,
    deleting: false,
    deletingOk: true,
  };
};

const deleteUserGroupFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleting: false, deletingError: action.error as string, deletingOk: false, };
};

const deleteUserGroupClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: false, deletingOk: false, };
};


const saveUserGroupStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const saveUserGroupSuccess: ReducerSignature = (state, action): ReducerState => {
  const userGroups = insertFirst(state.userGroups, action.userGroup)
  return {
    ...state,
    userGroups,
    error: null,
    loading: false,
    saveOrUpdateOk: true
  };
};

const saveUserGroupFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const updateUserGroupStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const updateUserGroupSuccess: ReducerSignature = (state, action): ReducerState => {
  const userGroups = updateOne(state.userGroups, action.userGroup);
  return {
    ...state,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    userGroups
  };
};

const updateUserGroupFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateUserGroupClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: null, saveOrUpdateOk: false }
}

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.USER_GROUP_FIND_START:
      return findUserGroupStart(state, action);
    case EActionTypes.USER_GROUP_FIND_SUCCESS:
      return findUserGroupSuccess(state, action);
    case EActionTypes.USER_GROUP_FIND_FAIL:
      return findUserGroupFail(state, action);
	case EActionTypes.USER_GROUP_FIND_CLEAR:
		return findUserGroupClear(state, action);
    case EActionTypes.USER_GROUP_GET_START:
      return getUserGroupStart(state, action);
    case EActionTypes.USER_GROUP_GET_SUCCESS:
      return getUserGroupSuccess(state, action);
    case EActionTypes.USER_GROUP_GET_FAIL:
      return getUserGroupFail(state, action);
    case EActionTypes.USER_GROUP_GET_CLEAR:
      return getUserGroupClear(state, action);
    case EActionTypes.USER_GROUP_DELETE_START:
      return deleteUserGroupStart(state, action);
    case EActionTypes.USER_GROUP_DELETE_SUCCESS:
      return deleteUserGroupSuccess(state, action);
    case EActionTypes.USER_GROUP_DELETE_FAIL:
      return deleteUserGroupFail(state, action);
    case EActionTypes.USER_GROUP_DELETE_CLEAR:
      return deleteUserGroupClear(state, action);
    case EActionTypes.USER_GROUP_SAVE_START:
      return saveUserGroupStart(state, action);
    case EActionTypes.USER_GROUP_SAVE_SUCCESS:
      return saveUserGroupSuccess(state, action);
    case EActionTypes.USER_GROUP_SAVE_FAIL:
      return saveUserGroupFail(state, action);
    case EActionTypes.USER_GROUP_UPDATE_START:
      return updateUserGroupStart(state, action);
    case EActionTypes.USER_GROUP_UPDATE_SUCCESS:
      return updateUserGroupSuccess(state, action);
    case EActionTypes.USER_GROUP_UPDATE_FAIL:
      return updateUserGroupFail(state, action);
    case EActionTypes.USER_GROUP_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateUserGroupClear(state, action)
    default:
      return state;
  }
};

export default reducer;
