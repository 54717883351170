import React from 'react';

import { IEmailOrder } from '../../interfaces/IEmailOrder';
import { formatDateTime } from '../../utils/date-utils';
import Alert from '../ui/Alert/Alert';
import Spinner from '../ui/Spinner/Spinner';
import Table from '../ui/Table/Table';
import EmailOrderStatus from './EmailOrderStatus';
import { ETranslation } from '../../translations/translation-keys';
import { useTranslation } from 'react-i18next';
import Button, { EButtonColor } from '../ui/Button/Button';

interface IProps {
	emailOrders: IEmailOrder[] | null;
	loading: boolean;
	error: string | null;
	onClick: (id: string) => void;
	onDelete: (id: string) => void;
	deleting: boolean;
}

const EmailOrderList: React.FC<IProps> = ({ emailOrders, loading, error, onClick, onDelete, deleting }) => {
	const { t } = useTranslation();
	if (error) {
		return <Alert>{error}</Alert>;
	}

	if (!emailOrders || loading) {
		return <Spinner />;
	}

	if (emailOrders.length === 0) {
		return <p>{t(ETranslation.EMAIL_ORDER_NO_ORDERS)}</p>;
	}

	return (
		<>
			<Table hover>
				<thead>
					<tr>
						<th>{t(ETranslation.COMMON_STATUS)}</th>
						<th>{t(ETranslation.COMMON_CREATED)}</th>
						<th>{t(ETranslation.COMMON_FILE)}</th>
					</tr>
				</thead>
				<tbody>
					{emailOrders.map((emailOrder) => (
						<EmailOrderListItem key={emailOrder.id} emailOrder={emailOrder} onClick={onClick} deleting={deleting} onDelete={onDelete} />
					))}
				</tbody>
			</Table>
		</>
	);
};

interface IEmailOrderListItemProps {
	emailOrder: IEmailOrder;
	onClick: (id: string) => void;
	deleting: boolean;
	onDelete: (id: string) => void;
}

const EmailOrderListItem: React.FC<IEmailOrderListItemProps> = ({ emailOrder, onClick, onDelete, deleting }) => {
	return (
		<tr key={emailOrder.id} onClick={() => onClick(emailOrder.id)}>
			<td><EmailOrderStatus status={emailOrder.status} /></td>
			<td>{formatDateTime(emailOrder.created)}</td>
			<td>{emailOrder.attachment?.clientName}</td>
			<td onClick={(e)=>e.stopPropagation()}>{emailOrder.status !== "DONE" && <Button onClick={() => onDelete(emailOrder.id)} loading={deleting} color={EButtonColor.DANGER}>
				Poista
			</Button>}</td>
		</tr>
	);
};

export default EmailOrderList;
