import React, { useContext, useEffect, useState } from "react";
import { ISummary } from "../../store/actions/summariesActions";
import { IInputField, EInputType } from "../ui/Input/Input";
import { useCreateInput } from "../../hooks/useCreateInput";
import ModalContext, { EModalSize } from "../ui/Modal/ModalContext";
import SummaryCheckedOptionsModal from "./SummaryCheckedOptionsModal";
import { getInputData } from "../ui/Input/input-utils";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import SummariesTable from "./SummariesList/SummariesTable";
import { SelectedSummariesContext } from "../../pages/Summaries/SummariesPage";

export enum EInputs {
	checkAll = "checkAll",
	checkedOptions = "checkedOptions",
}

interface IProps {
	summaries: ISummary[];
	error: string | null;
	openHandler: (id: string) => void;
}

const SummariesList: React.FC<IProps> = ({ summaries, error, openHandler }) => {

	const { selectedRows } = useContext(SelectedSummariesContext);

	const { t } = useTranslation();
	const { setModal } = useContext(ModalContext);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.checkedOptions]: {
			type: EInputType.reactSelect,
			isClearable: true,
			labelTranslation: ETranslation.COMMON_FUNCTIONS,
			value: "",
			options: [
				{
					value: "PUBLISHED",
					labelTranslation: ETranslation.SUMMARY_PUBLISHED,
				},
				{
					value: "DRAFT",
					labelTranslation: ETranslation.SUMMARY_DRAFT,
				},
				{
					value: "DELETE",
					labelTranslation: ETranslation.COMMON_DELETE,
				},
			],
		},

	});

	const createInput = useCreateInput(inputs, setInputs);

	useEffect(() => {
		const data = getInputData<{ checkedOptions: "PUBLISHED" | "DRAFT" | "DELETE" | "" }>(inputs)
		if (data.checkedOptions === "" || selectedRows.length < 1) return;
		setModal({
			title: t(ETranslation.SUMMARY_CONFIRM_ACTION),
			content: (
				<SummaryCheckedOptionsModal
					selectedRows={selectedRows}
					selectedValue={data.checkedOptions}
					closeModal={() => {
						window.location.reload();
					}}
				/>
			),
			isOpen: true,
			size: EModalSize.MEDIUM,
			onModalClose: () => {
				window.location.reload();
			},
		});
	}, [inputs, selectedRows, setModal, t])

	return error ? (
		<>{error}</>
	) : (
		<>
			<div>
				{selectedRows.length > 0 ? (
					<div>
						{createInput(EInputs.checkedOptions)}
					</div>
				) : null}
			</div>
			<SummariesTable summaries={summaries} openHandler={openHandler} />
		</>
	);
};
export default SummariesList;
