import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import ModalContext, { EModalSize } from "../ui/Modal/ModalContext";
import { ILeadNew } from "../../interfaces/ILead";
import { ETranslation } from "../../translations/translation-keys";
import CreateLeadsModalContent from "./CreateLeadsModalContent";
import { useDispatch } from "react-redux";
import { createNewLeadsClear } from "../../store/actions/leadsActions";

export const CreateLeadsModal = () => {

	const { setModal, closeModal } = useContext(ModalContext);

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const modalClose = useCallback(() => {
		closeModal();
		dispatch(createNewLeadsClear());
	}, [closeModal, dispatch]);

	const handler = useCallback(async (items: ILeadNew[]) => {
		return new Promise<boolean>((resolve) => {
			setModal({
				isOpen: true,
				size: EModalSize.MEDIUM,
				title: t(ETranslation.LEADS_CREATE_NEW_LEADS),
				content: <CreateLeadsModalContent data={items} closeModal={modalClose} />,
				onModalClose: modalClose,
			});
		});
	}, [setModal, t, modalClose])

	return handler;
};
