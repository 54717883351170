import React, { useCallback } from 'react';
import Button, { EButtonColor } from '../../../ui/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { IInputField } from '../../../ui/Input/Input';
import { getInputData, updateInputHandler } from '../../../ui/Input/input-utils';
import { EInterpretationSearchInputs, IInterpretationSearchInputInterface } from '../InterpretationsSearch';
import * as dateFns from 'date-fns';
import InputGroup from '../../../ui/InputGroup/InputGroup';
import SearchMonthSelector from './SearchMonthSelector';
import { ETranslation } from '../../../../translations/translation-keys';
import { useTranslation } from 'react-i18next';


interface IProps {
	setInputs: React.Dispatch<React.SetStateAction<IInputField>>
	inputs: IInputField;
}

const today = dateFns.format(
	new Date(),
	"yyyy-MM-dd"
);

export const defaultStartDate = dateFns.format(
	dateFns.startOfYear(new Date()),
	"yyyy-MM-dd"
);

export const defaultEndDate = dateFns.format(
	dateFns.endOfYear(new Date()),
	"yyyy-MM-dd"
);

const SearchDateTools: React.FC<IProps> = ({ setInputs, inputs }) => {
	const { t } = useTranslation();

	const resetTimesHandler = useCallback(() => {
		updateInputHandler(EInterpretationSearchInputs.startTime, "00:00", setInputs);
		updateInputHandler(EInterpretationSearchInputs.endTime, "23:59", setInputs);
	}, [setInputs]);


	const updateDatesHandler = useCallback((startDate: string, endDate: string) => {
		updateInputHandler(EInterpretationSearchInputs.startDate, startDate, setInputs);
		updateInputHandler(EInterpretationSearchInputs.endDate, endDate, setInputs);
	}, [setInputs]);

	const setYesterday = () => {
		const data = getInputData<IInterpretationSearchInputInterface>(inputs);
		const startDate = dateFns.format(dateFns.subDays(dateFns.parse(data.startDate, "dd.MM.yyyy", new Date()), 1), "yyyy-MM-dd");
		const endDate = dateFns.format(dateFns.subDays(dateFns.parse(data.endDate, "dd.MM.yyyy", new Date()), 1), "yyyy-MM-dd");
		updateDatesHandler(startDate, endDate);
	}

	const setTomorrow = () => {
		const data = getInputData<IInterpretationSearchInputInterface>(inputs);
		const endDate = dateFns.format(dateFns.add(dateFns.parse(data.endDate, "dd.MM.yyyy", new Date()), { days: 1 }), "yyyy-MM-dd");
		const startDate = dateFns.format(dateFns.add(dateFns.parse(data.startDate, "dd.MM.yyyy", new Date()), { days: 1 }), "yyyy-MM-dd");
		updateDatesHandler(startDate, endDate);
	}

	const setToday = () => {
		updateDatesHandler(today, today);
		resetTimesHandler();
	}

	const setThisMonth = () => {
		const startDate = dateFns.format(new Date(), 'yyyy-MM-01');
		const endDate = dateFns.format(dateFns.lastDayOfMonth(new Date()), 'yyyy-MM-dd')
		updateDatesHandler(startDate, endDate);
		resetTimesHandler();
	}
	const resetDates = () => {
		updateInputHandler(EInterpretationSearchInputs.startDate, defaultStartDate, setInputs);
		updateInputHandler(EInterpretationSearchInputs.endDate, defaultEndDate, setInputs);
		resetTimesHandler();
	}

	return (
		<>
			<InputGroup style={{ margin: 0 }}>
				<Button color={EButtonColor.WHITE} onClick={setYesterday}><FontAwesomeIcon icon={faChevronLeft} /></Button>
				<Button color={EButtonColor.WHITE} onClick={setToday}>{t(ETranslation.COMMON_TODAY)}</Button>
				<Button color={EButtonColor.WHITE} onClick={setTomorrow}><FontAwesomeIcon icon={faChevronRight} /></Button>
				<Button color={EButtonColor.WHITE} onClick={setThisMonth}>{t(ETranslation.COMMON_CURRENT_MONTH)}</Button>
				<Button color={EButtonColor.WHITE} onClick={resetDates}>{t(ETranslation.COMMON_RESET)}</Button>
			</InputGroup>
			<SearchMonthSelector setInputs={setInputs} />
		</>
	)
}

export default SearchDateTools;