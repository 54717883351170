import React, { useState } from "react";
import { ILeadNew } from "../../interfaces/ILead";
import { EInputType, IInputField } from "../ui/Input/Input";
import { ETranslation } from "../../translations/translation-keys";
import { useCreateInput } from "../../hooks/useCreateInput";
import Button from "../ui/Button/Button";
import { getInputData } from "../ui/Input/input-utils";
import { createNewLeads } from "../../store/actions/leadsActions";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import ILeadsState from "../../interfaces/store/ILeadsState";
import Spinner from "../ui/Spinner/Spinner";
import Alert, { EAlertColor } from "../ui/Alert/Alert";
import { useTranslation } from "react-i18next";
import classes from "./CreateLeads.module.scss";

interface IProps {
	data: ILeadNew[];
	closeModal: () => void
}

enum EInputs {
	campaing = "campaing",
}

const CreateLeadsModalContent: React.FC<IProps> = ({ data, closeModal }) => {

	const { t } = useTranslation();

	const {
		creatingNewLeads,
		createNewLeadsError,
		createNewLeadsDone
	} = useSelector<IAppState, ILeadsState>((state) => state.leads)

	const notSent = (!creatingNewLeads && !createNewLeadsError && !createNewLeadsDone);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.campaing]: {
			type: EInputType.text,
			labelTranslation: ETranslation.LEAD_CAMPAING_TITLE,
			value: "",
			validation: {
				required: true,
			},
		},
	});

	const createInput = useCreateInput(inputs, setInputs);
	const dispatch = useDispatch();

	const submit = () => {
		const inputData = getInputData<{ campaing: string }>(inputs);
		const dataToSend = data.map((item) => {
			return { ...item, competitionName: inputData.campaing }
		})
		dispatch(createNewLeads(dataToSend));
		setTimeout(()=>{
			closeModal();
		}, 1000)
	}

	return <div className={classes.modalContainer}>
		{creatingNewLeads && <Spinner />}
		{createNewLeadsError && <Alert>{createNewLeadsError}</Alert>}
		{createNewLeadsDone && <Alert color={EAlertColor.SUCCESS}>{t(ETranslation.LEADS_CREATE_NEW_LEADS_LEADS_ADDED)}</Alert>}
		{notSent &&
			<>
				<span>{t(ETranslation.LEADS_CREATE_NEW_LEADS_PRE_PART)}{data.length}{t(ETranslation.LEADS_CREATE_NEW_LEADS_AFTER_PART)}</span>
				<span>{t(ETranslation.LEADS_CREATE_NEW_LEADS_HELPER_TEXT)}</span>
				{createInput(EInputs.campaing)}
				<Button onClick={submit}>{t(ETranslation.LEADS_CREATE_NEW_LEADS_ADD_LEADS)}</Button>
			</>
		}
	</div>

}

export default CreateLeadsModalContent;