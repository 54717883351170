import { Dispatch } from "redux";

import { IWorkingHoursSearchParams } from "../../components/WorkingHours/WorkingHoursSearch";
import { IWorkingHoursSummariesSearchParams } from "../../components/WorkingHoursSummaries/WorkingHoursSummariesSearch";
import { customFetch, customFetchWithResponse, EFetchMethod } from "../../custom-fetch";
import { IWorkingHoursDate, IWorkingHoursDateEvent } from "../../interfaces/IWorkingHoursDate";
import { IWorkingHoursMonthlySummary } from "../../interfaces/IWorkingHoursMonthlySummary";
import EActionTypes from "../../interfaces/store/EActionTypes";
import IWorkingHoursAction from "../../interfaces/store/IWorkingHoursAction";
import { objectToQueryString } from "./../../utils/query-string-utils";
import { IWorkingHourEventsSearchParams } from "../../components/WorkingHours/WorkingHoursEventsSearch";
import { IWorkingHoursDateEventsSummary } from "../../interfaces/IWorkingHoursDateEventsSummary";

type TAction = IWorkingHoursAction;

const findWorkingHoursStart = (): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_LIST_START,
	};
};

const findWorkingHoursSuccess = (workingHours: IWorkingHoursDate[]): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_LIST_SUCCESS,
		workingHours,
	};
};

const findWorkingHoursFail = (error: string): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_LIST_FAIL,
		error,
	};
};

export const findWorkingHoursClear = (error: string): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_LIST_CLEAR,
	};
};

export const findWorkingHours = (data: IWorkingHoursSearchParams) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(findWorkingHoursStart());
			const workingHours = await customFetch<IWorkingHoursDate[]>(
				"/workinghours/list" + objectToQueryString(data)
			);
			dispatch(findWorkingHoursSuccess(workingHours));
		} catch (error) {
			dispatch(findWorkingHoursFail((error as Error).message));
		}
	};
};


const findWorkingHourEventsStart = (): TAction => {
	return {
		type: EActionTypes.WORKING_HOUR_EVENTS_LIST_START,
	};
};

const findWorkingHourEventsSuccess = (workingHourEvents: IWorkingHoursDateEvent[], workingHourEventsSummary : IWorkingHoursDateEventsSummary): TAction => {
	return {
		type: EActionTypes.WORKING_HOUR_EVENTS_LIST_SUCCESS,
		workingHourEvents,
		workingHourEventsSummary
	};
};

const findWorkingHourEventsFail = (error: string): TAction => {
	return {
		type: EActionTypes.WORKING_HOUR_EVENTS_LIST_FAIL,
		error,
	};
};

export const findWorkingHourEventsClear = (): TAction => {
	return {
		type: EActionTypes.WORKING_HOUR_EVENTS_LIST_CLEAR,
	};
};

interface IFindData {
	events: IWorkingHoursDateEvent[];
	summary: IWorkingHoursDateEventsSummary;
}

export const findWorkingHourEvents = (data: IWorkingHourEventsSearchParams) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(findWorkingHourEventsStart());
			const result = await customFetch<IFindData>("/workinghours/events/list", EFetchMethod.POST, JSON.stringify(data));
			dispatch(findWorkingHourEventsSuccess(result.events, result.summary));
		} catch (error) {
			dispatch(findWorkingHourEventsFail((error as Error).message));
		}
	};
};

const getWorkingHoursDateStart = (): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_GET_START,
	};
};

const getWorkingHoursDateSuccess = (workingHoursDate?: IWorkingHoursDate): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_GET_SUCCESS,
		workingHoursDate,
	};
};

const getWorkingHoursDateFail = (error: string): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_GET_FAIL,
		error,
	};
};

export const getWorkingHoursDateClear = (): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_GET_CLEAR,
	};
};

export const getWorkingHoursDate = (date: string, userId: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getWorkingHoursDateStart());
			const resp = await customFetchWithResponse(`/workinghours/get?date=${date}&userId=${userId}`);
			if (resp.status === 204) {
				dispatch(getWorkingHoursDateSuccess());
			} else {
				const workingHoursDate = await resp.json();
				dispatch(getWorkingHoursDateSuccess(workingHoursDate));
			}
		} catch (error) {
			dispatch(getWorkingHoursDateFail((error as Error).message));
		}
	};
};

const saveWorkingHoursDateStart = (): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_SAVE_START,
	};
};

const saveWorkingHoursDateSuccess = (workingHoursDate: IWorkingHoursDate): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_SAVE_SUCCESS,
		workingHoursDate,
	};
};

const saveWorkingHoursDateFail = (error: string): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_SAVE_FAIL,
		error,
	};
};

export const saveWorkingHoursDate = (workingHoursDate: IWorkingHoursDate) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveWorkingHoursDateStart());
			workingHoursDate = await customFetch<IWorkingHoursDate>(
				"/workinghours/add",
				EFetchMethod.POST,
				JSON.stringify(workingHoursDate)
			);
			dispatch(saveWorkingHoursDateSuccess(workingHoursDate));
		} catch (error) {
			dispatch(saveWorkingHoursDateFail((error as Error).message));
		}
	};
};

const updateWorkingHoursDateStart = (): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_UPDATE_START,
	};
};

const updateWorkingHoursDateSuccess = (workingHoursDate: IWorkingHoursDate): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_UPDATE_SUCCESS,
		workingHoursDate,
	};
};

const updateWorkingHoursDateFail = (error: string): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_UPDATE_FAIL,
		error,
	};
};

export const updateWorkingHoursDate = (workingHoursDate: IWorkingHoursDate) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(updateWorkingHoursDateStart());
			workingHoursDate = await customFetch<IWorkingHoursDate>(
				"/workinghours/update",
				EFetchMethod.PUT,
				JSON.stringify(workingHoursDate)
			);
			dispatch(updateWorkingHoursDateSuccess(workingHoursDate));
		} catch (error) {
			dispatch(updateWorkingHoursDateFail((error as Error).message));
		}
	};
};

export const saveOrUpdateWorkingHoursDateClear = (): TAction => {
	return {
	  type: EActionTypes.WORKING_HOURS_SAVE_OR_UPDATE_CLEAR
	}
  }

const deleteWorkingHoursDateStart = (): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_DELETE_START,
	};
};

const deleteWorkingHoursDateSuccess = (id: string): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_DELETE_SUCCESS,
		id,
	};
};

const deleteWorkingHoursDateFail = (error: string): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_DELETE_FAIL,
		error,
	};
};

export const deleteWorkingHoursDateClear = (): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_DELETE_CLEAR,
	};
};

export const deleteWorkingHoursDate = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(deleteWorkingHoursDateStart());
			await customFetch<boolean>(`/workinghours/delete?id=${id}`, EFetchMethod.DELETE);
			dispatch(deleteWorkingHoursDateSuccess(id));
		} catch (error) {
			dispatch(deleteWorkingHoursDateFail((error as Error).message));
		}
	};
};


/** SUMMARY START */
const findWorkingHoursMonthlySummaryStart = (): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_MONTHLY_SUMMARY_LIST_START,
	};
};

const findWorkingHoursMonthlySummarySuccess = (summaries: IWorkingHoursMonthlySummary[]): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_MONTHLY_SUMMARY_LIST_SUCCESS,
		summaries,
	};
};

const findWorkingHoursMonthlySummaryFail = (error: string): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_MONTHLY_SUMMARY_LIST_FAIL,
		error,
	};
};

export const findWorkingHoursMonthlySummaryClear = (): TAction => {
	return {
		type: EActionTypes.WORKING_HOURS_MONTHLY_SUMMARY_LIST_CLEAR,
	};
};

export const findWorkingHoursMonthlySummary = (params: IWorkingHoursSummariesSearchParams) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(findWorkingHoursMonthlySummaryStart());
			const summaries = await customFetch<IWorkingHoursMonthlySummary[]>(
				`/workinghours/summaries/list${objectToQueryString(params)}`
			);
			dispatch(findWorkingHoursMonthlySummarySuccess(summaries));
		} catch (error) {
			dispatch(findWorkingHoursMonthlySummaryFail((error as Error).message));
		}
	};
};
