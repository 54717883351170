import React from 'react';
import { IEezyTrusterEvent, IEezyTrusterPayment } from '../../../interfaces/IEezyTruster';
import { NETVISOR_BANK_TRANSFER_URL, NETVISOR_PURCHASE_INVOICE_URL } from '../../../interfaces/INetvisorSettings';

interface IProps {
	payment: IEezyTrusterPayment;
	type: IEezyTrusterEvent["type"];
} //NETVISOR_PURCHASE_INVOICE_URL

const PaymentEventIdDisplay: React.FC<IProps> = ({ payment, type }) => {
	return (payment.eezySalesOrderId && (type === "MANUAL" || type === "BANK_TRANSFER")) ?
		<a href={NETVISOR_BANK_TRANSFER_URL + payment.eezySalesOrderId} target="_blank" rel="noopener noreferrer">{payment.eezySalesOrderId}</a>
		:
		(payment.eezySalesOrderId && (type === "PURCHASE_INVOICE")) ?
			<a href={NETVISOR_PURCHASE_INVOICE_URL + payment.eezySalesOrderId} target="_blank" rel="noopener noreferrer">{payment.eezySalesOrderId}</a>
			:
			<span>{payment.eezySalesOrderId}
			</span>

};

export default PaymentEventIdDisplay;
