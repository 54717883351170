import React, { useContext, useState } from 'react';
import { ILead } from "../../../interfaces/ILead";
import Table from '../../ui/Table/Table';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';
import LeadRowItem from './LeadRowItem';
import classes from './leads.module.scss';
import { SelectedLeadsContext } from '../../../pages/Leads/LeadList/LeadsListPage';
import { EInputType, IInputField } from '../../ui/Input/Input';
import { useCreateInput } from '../../../hooks/useCreateInput';

interface IProps {
	leads: ILead[];
}

const EInputs = {
	leadsSelected: "leadsSelected",
}

const LeadsListTable: React.FC<IProps> = ({ leads }) => {
	const { t } = useTranslation()

	const { selectAll, setSelectAll, setSelectedRows } = useContext(SelectedLeadsContext);

	const selectOrDeselctAll = () => {
		// Shortcut the list, so we dont get long freeze from dep of having every item re-render o^2
		!selectAll ? setSelectedRows(leads) : setSelectedRows([]);
		setSelectAll(!selectAll);
	}

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.leadsSelected]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.COMMON_SELECT_ALL,
			value: false,
		},
	})

	const createInput = useCreateInput(inputs, setInputs);

	return <Table className={classes.leadsTable}>
		<thead>
			<tr>
				<th colSpan={1}>{createInput(EInputs.leadsSelected, { onClick: selectOrDeselctAll, labelSwap: true })}</th>
				<th colSpan={1}>{t(ETranslation.COMMON_CREATED)} | {t(ETranslation.COMMON_UPDATED)}</th>
				<th colSpan={1}>{t(ETranslation.COMMON_NAME)}</th>
				<th colSpan={2}>{t(ETranslation.COMMON_PHONENUMBER)}</th>
				<th colSpan={2}>{t(ETranslation.COMMON_EMAIL)}</th>
				<th colSpan={1}>{t(ETranslation.SITE_ORGANIZATION_NAME)}</th>
				<th colSpan={1}>{t(ETranslation.COMPETITION_NAME)}</th>
				<th colSpan={1}>{t(ETranslation.COMMON_ADDITIONAL_INFO)}</th>
				<th colSpan={1}>URI</th>
				<th colSpan={1}>{t(ETranslation.COMMON_LANGUAGE)}</th>
				<th colSpan={1}>{t(ETranslation.COMMON_CODE)}</th>
				<th colSpan={3}>{t(ETranslation.LEADS_SENT_MAILS)}</th>
			</tr>
		</thead>
		<tbody>
			{leads.map((item) => {
				return <LeadRowItem key={item.id} lead={item} onClick={(lead: ILead) => { console.log(lead) }} />
			})}
		</tbody>
	</Table>
}

export default LeadsListTable;