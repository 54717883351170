import { TFunction } from "i18next";
import { IOption } from "../components/ui/Input/Input";
import { ETranslation } from "../translations/translation-keys";
import { useInterpretation } from "../hooks/useInterpretation";
import { useTranslation } from "react-i18next";

export enum EInterpretationGender {
	MALE = 'MALE',
	FEMALE = 'FEMALE',
};


export const INTERPRETATION_GENDER: IOption[] = [
	{ value: EInterpretationGender.MALE, labelTranslation: ETranslation.COMMON_GENDER_MALE },
	{ value: EInterpretationGender.FEMALE, labelTranslation: ETranslation.COMMON_GENDER_FEMALE },
];

export enum EInterpretationType {
	INSTANT = 'INSTANT',
	BOOKING = 'BOOKING',
	ON_SITE = 'ON_SITE',
	MESSAGE = 'MESSAGE',
	ASSIGNMENT = 'ASSIGNMENT',
	DAYCARE_TEACHER = 'DAYCARE_TEACHER',
	DAYCARE_SPECIAL_TEACHER = 'DAYCARE_SPECIAL_TEACHER',
	DAYCARE_NURSE = 'DAYCARE_NURSE',
	DAYCARE_ASSISTANT = 'DAYCARE_ASSISTANT',
	DAYCARE_SOCIAL_WORKER = 'DAYCARE_SOCIAL_WORKER',
	SUBSTITUTE_ON_SITE = 'SUBSTITUTE_ON_SITE',
	SHIFT_ON_SITE = 'SHIFT_ON_SITE',
};

export enum EInterpretationMailType {
	BOOKING_CONFIRMATION = "BOOKING_CONFIRMATION",
	FEEDBACK_SURVEY = "FEEDBACK_SURVEY",
	MODIFY_FORM = "MODIFY_FORM",
}

export enum EInterpretationStatus {
	DRAFT = "DRAFT",
	WAITING = "WAITING",
	IN_PROGRESS = 'IN_PROGRESS',
	CUSTOMER_CANCEL = 'CUSTOMER_CANCEL',
	INTERPRETER_CANCEL = 'INTERPRETER_CANCEL',
	INTERPRETERS_NOT_FOUND = 'INTERPRETERS_NOT_FOUND',
	FAVORITES_NOT_FOUND = 'FAVORITES_NOT_FOUND',
	WAITING_REVIEWS = 'WAITING_REVIEWS',
	WAITING_INTERPRETER_REVIEW = "WAITING_INTERPRETER_REVIEW",
	WAITING_CUSTOMER_REVIEW = 'WAITING_CUSTOMER_REVIEW',
	COMPLETE = 'COMPLETE',
	PRELIMINARY_BOOKING = 'PRELIMINARY_BOOKING',
	OFFER_BOOKING = 'OFFER_BOOKING',
	CONFIRMED_BOOKING = 'CONFIRMED_BOOKING',
	DUPLICATE = "DUPLICATE",
	WAITING_CUSTOMER_APPROVAL = 'WAITING_CUSTOMER_APPROVAL',
};

export enum EInterpreterOfferStatus {
	DRAFT = "DRAFT",
	OPEN = "OPEN",
	ACCEPTED = "ACCEPTED",
	BUSY = "BUSY",
	REJECTED = "REJECTED",
	CLOSED = "CLOSED",
	CONFIRMED = "CONFIRMED",
	CANCELLED = "CANCELLED",
	COMPLETED = "COMPLETED",
	CALLED = "CALLED",
}

export const INTERPRETER_OFFER_STATUS_SELECT = (): IOption[] => {
	const { getInterpreterStatusString } = useInterpretation();
	const { t } = useTranslation();
	return Object.values(EInterpreterOfferStatus).map((offerStatus) => {
		let text = getInterpreterStatusString(t, offerStatus);
		return { value: offerStatus, label: text }
	})
}

export enum EInterpreterFeeType {
	FEE_TYPE_MANUAL = "FEE_TYPE_MANUAL",
	FEE_TYPE_EEZY = "FEE_TYPE_EEZY",
	FEE_TYPE_TRUSTER = "FEE_TYPE_TRUSTER",
	FEE_TYPE_BANK_TRANSFER = "FEE_TYPE_BANK_TRANSFER",
	FEE_TYPE_PURCHASE_INVOICE = "FEE_TYPE_PURCHASE_INVOICE",
	FEE_TYPE_NO_PAYMENT = "FEE_TYPE_NO_PAYMENT"
}

// EInterpreterFeeType's array for generating events
export const feeTypesForEventGeneration = [
	EInterpreterFeeType.FEE_TYPE_TRUSTER,
	EInterpreterFeeType.FEE_TYPE_EEZY,
	EInterpreterFeeType.FEE_TYPE_BANK_TRANSFER,
	EInterpreterFeeType.FEE_TYPE_PURCHASE_INVOICE,
	EInterpreterFeeType.FEE_TYPE_MANUAL
]

export const INTERPRETATION_STATUS_SELECT: IOption[] = [
	{ value: EInterpretationStatus.CONFIRMED_BOOKING, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_CONFIRMED_BOOKING_SELECT },
	{ value: EInterpretationStatus.PRELIMINARY_BOOKING, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_PRELIMINARY_BOOKING_SELECT },
	{ value: EInterpretationStatus.WAITING, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_WAITING_SELECT },
	{ value: EInterpretationStatus.OFFER_BOOKING, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING_SELECT },
	{ value: EInterpretationStatus.WAITING_REVIEWS, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEWS_SELECT },
	{ value: EInterpretationStatus.COMPLETE, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_COMPLETE_SELECT },
];
export const INTERPRETATION_STATUS: IOption[] = [
	{ value: EInterpretationStatus.DRAFT, labelTranslation: ETranslation.COMMON_DRAFT },
	{ value: EInterpretationStatus.DUPLICATE, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_DUPLICATE },
	{ value: EInterpretationStatus.PRELIMINARY_BOOKING, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_PRELIMINARY_BOOKING },
	{ value: EInterpretationStatus.WAITING, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_WAITING },
	{ value: EInterpretationStatus.OFFER_BOOKING, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING },
	{ value: EInterpretationStatus.CONFIRMED_BOOKING, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_CONFIRMED_BOOKING },
	{ value: EInterpretationStatus.IN_PROGRESS, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_IN_PROGRESS },
	{ value: EInterpretationStatus.CUSTOMER_CANCEL, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_CUSTOMER_CANCEL },
	{ value: EInterpretationStatus.INTERPRETER_CANCEL, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETER_CANCEL },
	{ value: EInterpretationStatus.INTERPRETERS_NOT_FOUND, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETERS_NOT_FOUND },
	{ value: EInterpretationStatus.FAVORITES_NOT_FOUND, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_FAVORITES_NOT_FOUND },
	{ value: EInterpretationStatus.WAITING_REVIEWS, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEWS },
	{ value: EInterpretationStatus.WAITING_INTERPRETER_REVIEW, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER_REVIEW },
	{ value: EInterpretationStatus.WAITING_CUSTOMER_REVIEW, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_WAITING_CUSTOMER_REVIEW },
	{ value: EInterpretationStatus.COMPLETE, labelTranslation: ETranslation.COMMON_COMPLETE },
	{ value: EInterpretationStatus.WAITING_CUSTOMER_APPROVAL, labelTranslation: ETranslation.INTERPRETATION_STATUS_WAITING_CUSTOMER_APPROVAL },
];

export const INTERPRETATION_STATUS_FOR_CUSTOMER_SEARCH: IOption[] = [
	{ value: EInterpretationStatus.COMPLETE, labelTranslation: ETranslation.COMMON_COMPLETE },
	{ value: EInterpretationStatus.WAITING_CUSTOMER_REVIEW, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_WAITING_CUSTOMER_REVIEW },
	{ value: EInterpretationStatus.CUSTOMER_CANCEL, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_CUSTOMER_CANCEL },
	{ value: EInterpretationStatus.INTERPRETER_CANCEL, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETER_CANCEL },
	{ value: EInterpretationStatus.INTERPRETERS_NOT_FOUND, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETERS_NOT_FOUND },
	{ value: EInterpretationStatus.FAVORITES_NOT_FOUND, labelTranslation: ETranslation.INTERPRETATION_DATA_STATUS_FAVORITES_NOT_FOUND },
]

export enum ECustomerEstimates {
	CUSTOMER_ESTIMATE_LT15MIN = 'LT15MIN',
	CUSTOMER_ESTIMATE_LT30MIN = 'LT30MIN',
	CUSTOMER_ESTIMATE_LT45MIN = 'LT45MIN',
	CUSTOMER_ESTIMATE_MT45MIN = 'MT45MIN',
};

export const CUSTOMER_ESTIMATES: IOption[] = [
	{ value: ECustomerEstimates.CUSTOMER_ESTIMATE_LT15MIN, labelTranslation: ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_15 },
	{ value: ECustomerEstimates.CUSTOMER_ESTIMATE_LT30MIN, labelTranslation: ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_30 },
	{ value: ECustomerEstimates.CUSTOMER_ESTIMATE_LT45MIN, labelTranslation: ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_45 },
	{ value: ECustomerEstimates.CUSTOMER_ESTIMATE_MT45MIN, labelTranslation: ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_MT_45 },
];

export enum EInterpretationTags {
	INTERPRETATION_TAGS_VIDEO = 'connectionTypeVideo',
};

export const INTERPRETATION_TAGS: IOption[] = [
	{ value: EInterpretationTags.INTERPRETATION_TAGS_VIDEO, labelTranslation: ETranslation.INTERPRETATION_DATA_TAGS_VIDEO },

];

export enum EConnectionDetail {
	INTERPRETER_CALL_TO_CUSTOMER = 'INTERPRETER_CALL_TO_CUSTOMER',
	CUSTOMER_CALL_TO_INTERPRETER = 'CUSTOMER_CALL_TO_INTERPRETER',
	CONTACT_CALL_TO_INTERPRETER = 'CONTACT_CALL_TO_INTERPRETER',
	INTERPRETER_CALL_TO_CONTACT = 'INTERPRETER_CALL_TO_CONTACT',
	VIDEO_CONFERENCE = 'VIDEO_CONFERENCE',
	VIDEO_CONFERENCE_TWILIO = 'VIDEO_CONFERENCE_TWILIO',
};

export const CONNECTION_TYPES: IOption[] = [
	{ value: EConnectionDetail.INTERPRETER_CALL_TO_CUSTOMER, labelTranslation: ETranslation.CONNECTION_TYPE_INTERPRETER_CALL_TO_CUSTOMER },
	{ value: EConnectionDetail.CUSTOMER_CALL_TO_INTERPRETER, labelTranslation: ETranslation.CONNECTION_TYPE_CUSTOMER_CALL_TO_INTERPRETER },
	{ value: EConnectionDetail.CONTACT_CALL_TO_INTERPRETER, labelTranslation: ETranslation.CONNECTION_TYPE_CONTACT_CALL_TO_INTERPRETER },
	{ value: EConnectionDetail.INTERPRETER_CALL_TO_CONTACT, labelTranslation: ETranslation.CONNECTION_TYPE_INTERPRETER_CALL_TO_CONTACT },
	{ value: EConnectionDetail.VIDEO_CONFERENCE, labelTranslation: ETranslation.CONNECTION_TYPE_VIDEO_CONFERENCE },
	{ value: EConnectionDetail.VIDEO_CONFERENCE_TWILIO, labelTranslation: ETranslation.CONNECTION_TYPE_VIDEO_CONFERENCE_TWILIO },
];

export const getConnectionTypeString = (
	t: TFunction, status: EConnectionDetail
): string => t(CONNECTION_TYPES.find(item => item.value === status)?.labelTranslation || "");



export const getCustomerEstimateString = (
	t: TFunction, status: ECustomerEstimates
): string => t(CUSTOMER_ESTIMATES.find(item => item.value === status)?.labelTranslation || "");

export const getInterpreterGenderString = (
	t: TFunction, status: EInterpretationGender
): string => t(INTERPRETATION_GENDER.find(item => item.value === status)?.labelTranslation || "");

export const getInterpretationStatusString = (
	t: TFunction, status: EInterpretationStatus
): string => t(INTERPRETATION_STATUS.find(item => item.value === status)?.labelTranslation || "");


export const INTERPRETATION_CONNECTION_DETAIL_ALL: IOption[] = [
	{ value: EConnectionDetail.INTERPRETER_CALL_TO_CUSTOMER, labelTranslation: ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_TO_CUSTOMER },
	{ value: EConnectionDetail.CUSTOMER_CALL_TO_INTERPRETER, labelTranslation: ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_TO_INTERPRETER },
	{ value: EConnectionDetail.VIDEO_CONFERENCE_TWILIO, labelTranslation: ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE_TWILIO },
	{ value: EConnectionDetail.VIDEO_CONFERENCE, labelTranslation: ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE }
];

export const INTERPRETATION_EMAIL_TYPE_ALL: IOption[] = [
	{ value: EInterpretationMailType.BOOKING_CONFIRMATION, labelTranslation: ETranslation.BOOKING_CONFIRMATION },
	{ value: EInterpretationMailType.FEEDBACK_SURVEY, labelTranslation: ETranslation.FEEDBACK_SURVEY },
	{ value: EInterpretationMailType.MODIFY_FORM, labelTranslation: ETranslation.MODIFY_FORM },
];

export const INTERPRETER_FEE_TYPE_ALL: IOption[] = [
	{ value: EInterpreterFeeType.FEE_TYPE_MANUAL, labelTranslation: ETranslation.PAYMENT_MANUAL },
	{ value: EInterpreterFeeType.FEE_TYPE_EEZY, labelTranslation: ETranslation.PAYMENT_EEZY },
	{ value: EInterpreterFeeType.FEE_TYPE_TRUSTER, labelTranslation: ETranslation.PAYMENT_TRUSTER },
	{ value: EInterpreterFeeType.FEE_TYPE_PURCHASE_INVOICE, labelTranslation: ETranslation.PAYMENT_PURCHASE_INVOICE },
	{ value: EInterpreterFeeType.FEE_TYPE_BANK_TRANSFER, labelTranslation: ETranslation.PAYMENT_BANK_TRANSFER },
	{ value: EInterpreterFeeType.FEE_TYPE_NO_PAYMENT, labelTranslation: ETranslation.PAYMENT_NO_PAYMENT },
]

export const INTERPRETATION_INTERPRETER_OFFER_STATUS_ALL: IOption[] = [
	{ value: EInterpreterOfferStatus.DRAFT, labelTranslation: ETranslation.COMMON_DRAFT },
	{ value: EInterpreterOfferStatus.OPEN, labelTranslation: ETranslation.COMMON_OPEN },
	{ value: EInterpreterOfferStatus.ACCEPTED, labelTranslation: ETranslation.COMMON_ACCEPTED },
	{ value: EInterpreterOfferStatus.BUSY, labelTranslation: ETranslation.COMMON_BUSY },
	{ value: EInterpreterOfferStatus.REJECTED, labelTranslation: ETranslation.COMMON_REJECTED },
	{ value: EInterpreterOfferStatus.CLOSED, labelTranslation: ETranslation.COMMON_CLOSED },
	{ value: EInterpreterOfferStatus.CONFIRMED, labelTranslation: ETranslation.COMMON_CONFIRMED },
	{ value: EInterpreterOfferStatus.CANCELLED, labelTranslation: ETranslation.COMMON_CANCELLED },
	{ value: EInterpreterOfferStatus.COMPLETED, labelTranslation: ETranslation.COMMON_COMPLETED },
	{ value: EInterpreterOfferStatus.CALLED, labelTranslation: ETranslation.COMMON_CALLED },
]

export const JOHANNAREKRY_TYPES = [
	{ value: EInterpretationType.ASSIGNMENT, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_ASSIGNMENT },
	{ value: EInterpretationType.DAYCARE_NURSE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_NURSE },
	{ value: EInterpretationType.DAYCARE_TEACHER, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_TEACHER },
	{ value: EInterpretationType.DAYCARE_SPECIAL_TEACHER, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_SPECIAL_TEACHER },
	{ value: EInterpretationType.DAYCARE_ASSISTANT, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_ASSISTANT },
	{ value: EInterpretationType.DAYCARE_SOCIAL_WORKER, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_SOCIAL_WORKER },
];

export const VALITYSKLINIKKA_TYPES = [
	{ value: EInterpretationType.SUBSTITUTE_ON_SITE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_SUBSTITUTE_ON_SITE },
	{ value: EInterpretationType.SHIFT_ON_SITE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_SHIFT_ON_SITE },
];

export const YOUPRET_TYPES = [
	{ value: EInterpretationType.INSTANT, labelTranslation: ETranslation.COMMON_INSTANT },
	{ value: EInterpretationType.BOOKING, labelTranslation: ETranslation.COMMON_BOOKING },
	{ value: EInterpretationType.ON_SITE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_ON_SITE }
];

export const INTERPRETATION_PREFILLED_CUSTOMER_NOTES_OPTIONS: IOption[] = [
	{ value: "", label: "-" },
	{ value: "Ukraina ei saatavilla", label: "Ukraina ei saatavilla" },
	{ value: "VALITETTAVASTI VAPAATA TULKKIA EI OLE SAATAVILLA TÄLLE AJALLE", label: "VALITETTAVASTI VAPAATA TULKKIA EI OLE SAATAVILLA TÄLLE AJALLE" },
	{ value: "VALITETTAVASTI VENÄJÄN / UKRAINAN TULKKIA EI OLE SAATAVILLA TÄLLE AJALLE", label: "VALITETTAVASTI VENÄJÄN / UKRAINAN TULKKIA EI OLE SAATAVILLA TÄLLE AJALLE" },
	{ value: "Tulkille ei saatu korvaavaa työtä", label: "Tulkille ei saatu korvaavaa työtä" },
	{ value: "Ukrainska är inte tillgänglig", label: "Ukrainska är inte tillgänglig" },
	{ value: "TYVÄRR FINNS DET INGEN TOLK TILLGÄNGLIG FÖR DENNA TID", label: "TYVÄRR FINNS DET INGEN TOLK TILLGÄNGLIG FÖR DENNA TID" },
	{ value: "TYVÄRR FINNS DET INGEN RYSKA / UKRAINSKA TOLK TILLGÄNGLIG FÖR DENNA TID", label: "TYVÄRR FINNS DET INGEN RYSKA / UKRAINSKA TOLK TILLGÄNGLIG FÖR DENNA TID" },
	{ value: "Ersättande jobb hittades inte för tolken", label: "Ersättande jobb hittades inte för tolken" },

]