import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useCreateInput } from "../../hooks/useCreateInput";
import { getUserOptions, searchUsers } from "../../services/userServices";
import { ETranslation } from "../../translations/translation-keys";
import Button from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import { getInputData, initForm, validateInputs } from "../ui/Input/input-utils";
import InputGroup from "../ui/InputGroup/InputGroup";
import { useStoredSearchParams } from "../../hooks/useStoredSearchParams";
import { EStorageKeys } from "../../shared/storage-keys";
import { useInitSearchValues } from "../../hooks/useInitSearchValues";
import IWorkingHoursAction from "../../interfaces/store/IWorkingHoursAction";
import { useDispatch } from "react-redux";
import { findWorkingHoursMonthlySummaryClear } from "../../store/actions/workingHoursActions";

export interface IWorkingHoursSummariesSearchParams {
	userId: string;
}

interface IProps {
	onSearch: (params: IWorkingHoursSummariesSearchParams) => void;
}

enum EInputs {
	userId = "userId",
}

const WorkingHoursSummariesSearch: React.FC<IProps> = ({ onSearch: searchHandler }) => {

	const initialInputs = {
		[EInputs.userId]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_USER,
			value: "",
			validation: {
				required: false,
			},
		},
	}
	const [inputs, setInputs] = useState<IInputField>(initialInputs);

	const createInput = useCreateInput(inputs, setInputs, { showValidation: true });
	const [isValid, setIsValid] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { setIds: setUserIds, isLoading: isLoadingUsers } = useInitSearchValues(setInputs, EInputs.userId, getUserOptions);

	const { onSearch, clearInputs } = useStoredSearchParams<IWorkingHoursSummariesSearchParams, IWorkingHoursAction, IWorkingHoursSummariesSearchParams>({
		setInputs,
		initialInputs,
		storageKey: EStorageKeys.WORKING_HOURS_SUMMARIES_SEARCH_PARAMS,
		clearResults: useCallback(() => dispatch(findWorkingHoursMonthlySummaryClear()), [dispatch]),
		searchHandlerDataTransform: useCallback((data: IWorkingHoursSummariesSearchParams) => {
			return data;
		}, []),
		searchHandler,
		initIdInputs: useCallback((data: IWorkingHoursSummariesSearchParams) => {
			initForm(setInputs, { ...data, user: '' });
			if (data.userId) setUserIds([data.userId])
		}, [setUserIds])
	})

	useEffect(() => {
		setIsValid(validateInputs(inputs));
	}, [inputs]);

	return (
		<InputGroup>
			{createInput(EInputs.userId, { fetchOptions: searchUsers, isLoading: isLoadingUsers })}
			<Button onClick={() => onSearch(getInputData<IWorkingHoursSummariesSearchParams>(inputs))} disabled={!isValid}>
				{t(ETranslation.COMMON_SEARCH)}
			</Button>
			<Button onClick={clearInputs}>
				{t(ETranslation.UI_CLEAR)}
			</Button>

		</InputGroup>
	);
};

export default WorkingHoursSummariesSearch;
