import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import InterpretationDetails from "../../../components/Interpretation/InterpretationDetails/InterpretationDetails";
import Alert from "../../../components/ui/Alert/Alert";
import Container from "../../../components/ui/Container/Container";
import { ETranslation } from "../../../translations/translation-keys";
import IAppState from "../../../interfaces/store/IAppState";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { ERoute } from "../../../classes/Routes";
import Spinner from "../../../components/ui/Spinner/Spinner";
import Button, { EButtonColor } from "../../../components/ui/Button/Button";
import IInterpretationState from "../../../interfaces/store/IInterpretationState";

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const InterpretationPage: React.FC<IProps> = ({ match, history }) => {
	const { t } = useTranslation();
	const { id } = match.params;
	const dispatch = useDispatch();

	const { interpretation, loading, updateLoadingProperty } = useSelector<IAppState, IInterpretationState>(
		(state) => state.interpretation
	);

	useEffect(() => {
		dispatch(actions.getInterpretation(id));
	}, [id, dispatch]);

	return (
		<Container>
			{!id && <Alert>{t(ETranslation.NO_ACCESS)}</Alert>}
			{loading && <Spinner />}
			{!loading && interpretation && (
				<InterpretationDetails
					interpretation={interpretation}
					updateLoadingProperty={updateLoadingProperty}
				/>
			)}
			<Button
				color={EButtonColor.DEFAULT}
				onClick={() => {
					history.push(ERoute.INTERPRETATIONS_LIST);
				}}
			>
				{t(ETranslation.COMMON_BACK)}
			</Button>
		</Container>
	);
};

export default InterpretationPage;
