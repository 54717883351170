import React, { useEffect, useState } from 'react';
import Button, { EButtonColor } from '../../ui/Button/Button';
import ModalBody from '../../ui/Modal/ModalBody/ModalBody';
import ModalFooter from '../../ui/Modal/ModalFooter/ModalFooter';
import { EInputType, IInputField } from '../../ui/Input/Input';
import { ETranslation } from '../../../translations/translation-keys';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { getInputData } from '../../ui/Input/input-utils';
import { useSelector } from 'react-redux';
import IAppState from '../../../interfaces/store/IAppState';
import Alert, { EAlertColor } from '../../ui/Alert/Alert';
import { useTranslation } from 'react-i18next';
import Spinner from '../../ui/Spinner/Spinner';

enum EInputs {
	paymentDate = "paymentDate",
};

interface IInputInterface {
	paymentDate: string;
}

interface IProps {
	onAccept: (date: string) => void;
	onCancel: () => void;
	onComplete: () => void;
}

const GenerateSummariesModal: React.FC<IProps> = ({ onAccept, onCancel, onComplete }) => {
	const { t } = useTranslation();

	const { loading, done, fail } = useSelector((state: IAppState) => ({
		loading: state.interpretation.generateSummariesLoading,
		done: state.interpretation.generateSummariesDone,
		fail: state.interpretation.generateSummariesError,
	}));
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.paymentDate]: {
			type: EInputType.datepicker,
			isClearable: false,
			labelTranslation: ETranslation.PAYMENT_DATE,
			dateAsString: true,
			value: null,
		}
	});

	useEffect(() => {
		if (done && !fail) onComplete();
	}, [done, onComplete, fail])

	const createInput = useCreateInput(inputs, setInputs);

	const accept = () => {
		const data = getInputData<IInputInterface>(inputs);
		onAccept(data.paymentDate);
	}


	return (
		<>
			<ModalBody>
				{(!loading && !done) &&
					<>
						{t(ETranslation.SUMMARY_PAYMENT_DATE)}
						{createInput(EInputs.paymentDate)}
					</>
				}
				{loading && <Spinner />}
				{fail && <Alert color={EAlertColor.WARNING}>{fail}</Alert>}
				{(done && !fail) && <Alert color={EAlertColor.SUCCESS}>{t(ETranslation.COMMON_DONE)}</Alert>}
			</ModalBody>
			<ModalFooter>
				<Button onClick={accept} loading={loading} style={{ marginRight: '.5rem' }}>{t(ETranslation.COMMON_CONFIRM)}</Button>
				<Button onClick={onCancel} loading={loading} color={EButtonColor.DANGER}>{t(ETranslation.UI_CANCEL)}</Button>
			</ModalFooter>
		</>
	)
}

export default GenerateSummariesModal;