import React, { useState } from "react";
import { IUser } from "../../../classes/User";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { INTERPRETER_FEE_TYPE_ALL } from "../../../shared/interpretation-data";
import { useCreateInput } from "../../../hooks/useCreateInput";
import InputGroup from "../../ui/InputGroup/InputGroup";
import Button from "../../ui/Button/Button";
import { getInputData } from "../../ui/Input/input-utils";
import { useDispatch, useSelector } from "react-redux";
import { changeWorkerFeeType } from "../../../store/actions/userActions";
import IAppState from "../../../interfaces/store/IAppState";
import Spinner from "../../ui/Spinner/Spinner";
import Alert, { EAlertColor } from "../../ui/Alert/Alert";


interface IProps {
	user: IUser;
	closeModal: () => void;
}

enum EInputs {
	startDate = "startDate",
	feeType = "feeType",
}

interface IInputsData {
	startDate: string;
	feeType: string;
}

const WorkerPaymentTypeUpdate: React.FC<IProps> = ({ user, closeModal }) => {
	const { t } = useTranslation();

	const {
		userFeetypeChangeError,
		userFeetypeChangeLoading,
		userFeetypeChangeDone,
	} = useSelector((state: IAppState) => state.user);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.feeType]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.USER_BILLING_METHOD,
			options: INTERPRETER_FEE_TYPE_ALL,
			value: user.feeType,
		},
		[EInputs.startDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: "",
			dateAsString: true,
		}
	})

	const dispatch = useDispatch();

	const createInput = useCreateInput(inputs, setInputs);

	const onCancel = () => {
		closeModal()
	}

	const onSubmit = () => {
		const data = { ...getInputData<IInputsData>(inputs), userKeyString: user.id }
		dispatch(changeWorkerFeeType(data))
	}

	return <>
		{userFeetypeChangeLoading && <Spinner />}
		{userFeetypeChangeError && <Alert color={EAlertColor.DANGER}>{userFeetypeChangeError}</Alert>}
		{(userFeetypeChangeDone && !userFeetypeChangeError) && <Alert color={EAlertColor.SUCCESS}>Done</Alert>}
		{(!userFeetypeChangeDone && !userFeetypeChangeLoading) &&
			<>
				<span>{t(ETranslation.EDIT_USER_FEE_TYPE_HELPER_1)} {user.firstName} {user.lastName} {t(ETranslation.EDIT_USER_FEE_TYPE_HELPER_2)}</span>
				<InputGroup>{createInput(EInputs.feeType)}</InputGroup>
				<InputGroup>{createInput(EInputs.startDate)}</InputGroup>
			</>
		}
		<InputGroup>
			{!userFeetypeChangeDone ?
				<>
					<Button onClick={onSubmit} loading={userFeetypeChangeLoading}>{t(ETranslation.UI_SEND)}</Button>
					<Button onClick={onCancel} loading={userFeetypeChangeLoading}>{t(ETranslation.UI_CANCEL)}</Button>
				</> :
				<>
					<Button onClick={closeModal} loading={userFeetypeChangeLoading}>{t(ETranslation.COMMON_CLOSE)}</Button>
				</>
			}
		</InputGroup>
	</>
}

export default WorkerPaymentTypeUpdate;