import { TFunction } from 'i18next';
import { IOption } from '../components/ui/Input/Input';
import { IUserContractSettings } from '../interfaces/IUserContractSettings';
import { EInterpretationType } from '../shared/interpretation-data';
import { ITag } from '../store/actions/tagsActions';
import { ETranslation } from '../translations/translation-keys';
import { IOrganization } from '../interfaces/IOrganization';
import { ESitePrefix, ISite } from "./../interfaces/ISite";
import { ERoute, Routes } from "./Routes";
import { IAttribute } from '../interfaces/IAttribute';
import { IUserFavouriteInterpreter } from '../components/User/UserFavouriteWorkers/UserFavouriteInterpreter';
import { ILanguageItem } from '../components/User/UserLanguageItems/UserLanguageItem';
import { IOptionable } from '../utils/option-utils';
import { IUserLanguagePair } from '../interfaces/IUserLanguagePair';
import { LocalStorage } from '../utils/storage-utils';

export enum EUserRole {
	ADMIN = "ADMIN",
	INTERPRETER = "INTERPRETER",
	CUSTOMER = "CUSTOMER",
	APPLICANT = "APPLICANT",
}

export enum EUserStatus {
	ONLINE_AVAILABLE = "ONLINE_AVAILABLE",
	ONLINE_NOT_AVAILABLE = "ONLINE_NOT_AVAILABLE",
	ONLINE_RESERVED = "ONLINE_RESERVED",
	OFFLINE = "OFFLINE",
}


export interface IUser extends IOptionable {
	id: string;
	name: string;
	fullName: string;
	email: string;
	userName: string;
	status?: EUserStatus;
	gender?: string;
	phoneNumber: string;
	role: EUserRole;
	isYoupretAdmin: boolean;
	site?: ISite;
	firstName?: string;
	lastName: string;
	allowCMS: boolean;
	locations?: string[];
	allowReport: boolean;
	allowMessage: boolean;
	allowBank: boolean;
	allowLogs: boolean;
	allowInstant: boolean;
	allowBooking: boolean;
	allowOnSite: boolean;
	allowSummaries: boolean;
	allowVideo: boolean;
	allowFees: boolean;
	allowEezy: boolean;
	allowFavoriteInterpreters: boolean;
	showOrganizationInterpretations: boolean;
	isFavorite: boolean;
	description: string;
	reference: string;
	billingReference: string;
	organizations: IOrganization[];
	identity: string;
	showOrganizationGroupInterpretations: boolean;
	paymentMethodType?: string;
	lastSeenDate?: string;
	allowMachine: boolean;
	streetName: string;
	createdDate?: string;
	zip: string;
	city: string;
	privatePerson: boolean;
	manageOrganizationUsers: boolean;
	phoneNumberConfirmed: boolean;
	autoPayment: boolean;
	allowInternetCall: boolean;
	allowMarketing: boolean;
	allowAutomaticOfferBookings: boolean;
	allowMultiDeviceSimultaneousUse: boolean;
	allowPhoneCall: boolean;
	sendEmailBookingConfirmed: boolean;
	allowFastLane: boolean;
	showTestLanguages: boolean;
	showPayments: boolean;
	allowLogin: boolean;
	tags: ITag[];
	country: string;
	userLanguageItems?: ILanguageItem[]
	userLanguagePairs?: IUserLanguagePair[];
	userFavoriteInterpreters: IUserFavouriteInterpreter[];
	blockedUsers: IUser[];
	netvisorDimensionName: string;
	netvisorProductIdentifier: string;
	netvisorProductName: string;
	feeType: string;
	allowJobOffers: boolean;
	contractNo: string;
	contractStatus: string;
	securityCleared: boolean;
	socialSecurityNumber: string;
	iban: string;
	swift: string;
	workerBankingReferenceNumber: string;
	educationDescription: string;
	workExperienceDescription: string;
	altPhoneNumber: string;
	emailCC: string;
	emailBCC: string;
	interpreterFeeVatP: number;
	interpreterFeeWithoutVatPerMinuteForBooking: number;
	interpreterFeeWithoutVatPerMinuteForInstant: number;
	interpreterFeeWithoutVatPerMinuteForOnSite: number;
	hideInterpreterFees: boolean;
	isInterpreterTrained: boolean;
	eezySalaryPaymentRule: string;
	allowWorkingHoursDate: boolean;
	allowWorkingHoursDateEdit: boolean;
	userGroupIds: string[];
	jobApplicationId?: string;
	interpretationTypes: EInterpretationType[];
	contractSettings?: IUserContractSettings[];
	eezyId?: string;
	eezyIdCreated?: string;
	attributes: IAttribute[];
	userLevel: number;
	allowOthersWorkingHours: boolean;
	allowManageUserShifts: boolean;
	isLegalInterpreter: boolean;
}

export class User implements IUser {
	public id: string;
	public name: string;
	public fullName: string;
	public email: string;
	public userName: string;
	public phoneNumber: string;
	public role: EUserRole;
	public isYoupretAdmin: boolean;
	public site: ISite | undefined;
	public allowCMS: boolean;
	public firstName?: string;
	public lastName: string;
	public allowReport: boolean;
	public allowMessage: boolean;
	public allowBank: boolean;
	public allowLogs: boolean;
	public allowInstant: boolean;
	public allowBooking: boolean;
	public allowOnSite: boolean;
	public allowVideo: boolean;
	public allowFavoriteInterpreters: boolean;
	public showOrganizationInterpretations: boolean;
	public isFavorite: boolean;
	public description: string;
	public reference: string;
	public billingReference: string;
	public organizations: IOrganization[];
	public identity: string;
	public showOrganizationGroupInterpretations: boolean;
	public allowMachine: boolean;
	public streetName: string;
	public zip: string;
	public city: string;
	public allowSummaries: boolean;
	public privatePerson: boolean;
	public allowInternetCall: boolean;
	public allowFees: boolean;
	public locations?: string[];
	public allowEezy: boolean;
	public manageOrganizationUsers: boolean;
	public phoneNumberConfirmed: boolean;
	public autoPayment: boolean;
	public allowMarketing: boolean;
	public allowAutomaticOfferBookings: boolean;
	public allowMultiDeviceSimultaneousUse: boolean;
	public allowPhoneCall: boolean;
	public sendEmailBookingConfirmed: boolean;
	public allowFastLane: boolean;
	public showPayments: boolean;
	public showTestLanguages: boolean;
	public allowLogin: boolean;
	public tags: ITag[];
	public country: string;
	public userLanguagePairs?: IUserLanguagePair[];
	public userLanguageItems?: ILanguageItem[];
	public userFavoriteInterpreters: IUserFavouriteInterpreter[];
	public blockedUsers: IUser[];
	public netvisorDimensionName: string;
	public netvisorProductIdentifier: string;
	public netvisorProductName: string;
	public feeType: string;
	public allowJobOffers: boolean;
	public contractNo: string;
	public securityCleared: boolean;
	public socialSecurityNumber: string;
	public iban: string;
	public swift: string;
	public workerBankingReferenceNumber: string;
	public educationDescription: string;
	public workExperienceDescription: string;
	public altPhoneNumber: string;
	public emailCC: string;
	public emailBCC: string;
	public interpreterFeeVatP: number;
	public interpreterFeeWithoutVatPerMinuteForBooking: number;
	public interpreterFeeWithoutVatPerMinuteForInstant: number;
	public interpreterFeeWithoutVatPerMinuteForOnSite: number;
	public hideInterpreterFees: boolean;
	public isInterpreterTrained: boolean;
	public eezySalaryPaymentRule: string;
	public allowWorkingHoursDate: boolean;
	public allowWorkingHoursDateEdit: boolean;
	public userGroupIds: string[];
	public jobApplicationId?: string;
	public interpretationTypes: EInterpretationType[];
	public contractSettings?: IUserContractSettings[];
	public eezyId?: string;
	public eezyIdCreated?: string;
	public status?: EUserStatus;
	public attributes: IAttribute[];
	public userLevel: number;
	public contractStatus: string;
	public allowManageUserShifts: boolean;
	public allowOthersWorkingHours: boolean;
	public isLegalInterpreter: boolean;
	public paymentMethodType?: string;
	public storageHandler: LocalStorage;

	public constructor(user: IUser) {
		this.id = user.id;
		this.name = user.name;
		this.fullName = user.fullName;
		this.email = user.email;
		this.firstName = user.firstName;
		this.lastName = user.lastName;
		this.userName = user.userName;
		this.phoneNumber = user.phoneNumber;
		this.role = user.role;
		this.isYoupretAdmin = user.isYoupretAdmin;
		this.site = user.site;
		this.allowCMS = user.allowCMS;
		this.allowSummaries = user.allowSummaries;
		this.allowReport = user.allowReport;
		this.allowMessage = user.allowMessage;
		this.allowBank = user.allowBank;
		this.allowLogs = user.allowLogs;
		this.allowInstant = user.allowInstant;
		this.allowBooking = user.allowBooking;
		this.allowOnSite = user.allowOnSite;
		this.allowFees = user.allowFees;
		this.allowEezy = user.allowEezy;
		this.allowVideo = user.allowVideo;
		this.allowFavoriteInterpreters = user.allowFavoriteInterpreters;
		this.showOrganizationInterpretations = user.showOrganizationInterpretations;
		this.isFavorite = user.isFavorite;
		this.description = user.description;
		this.reference = user.reference;
		this.billingReference = user.billingReference;
		this.organizations = user.organizations;
		this.identity = user.identity;
		this.showOrganizationGroupInterpretations = user.showOrganizationGroupInterpretations;
		this.allowMachine = user.allowMachine;
		this.streetName = user.streetName;
		this.zip = user.zip;
		this.city = user.city;
		this.privatePerson = user.privatePerson;
		this.allowInternetCall = user.allowInternetCall;
		this.manageOrganizationUsers = user.manageOrganizationUsers;
		this.phoneNumberConfirmed = user.phoneNumberConfirmed;
		this.autoPayment = user.autoPayment;
		this.allowMarketing = user.allowMarketing;
		this.allowAutomaticOfferBookings = user.allowAutomaticOfferBookings;
		this.allowMultiDeviceSimultaneousUse = user.allowMultiDeviceSimultaneousUse;
		this.allowPhoneCall = user.allowPhoneCall;
		this.sendEmailBookingConfirmed = user.sendEmailBookingConfirmed;
		this.allowFastLane = user.allowFastLane;
		this.showPayments = user.showPayments;
		this.showTestLanguages = user.showTestLanguages;
		this.allowLogin = user.allowLogin;
		this.locations = user.locations;
		this.tags = user.tags;
		this.country = user.country;
		this.userLanguageItems = user.userLanguageItems;
		this.userLanguagePairs = user.userLanguagePairs;
		this.userFavoriteInterpreters = user.userFavoriteInterpreters;
		this.blockedUsers = user.blockedUsers;
		this.netvisorDimensionName = user.netvisorDimensionName;
		this.netvisorProductIdentifier = user.netvisorProductIdentifier;
		this.netvisorProductName = user.netvisorProductName;
		this.feeType = user.feeType;
		this.allowJobOffers = user.allowJobOffers;
		this.contractNo = user.contractNo;
		this.securityCleared = user.securityCleared;
		this.socialSecurityNumber = user.socialSecurityNumber;
		this.iban = user.iban;
		this.swift = user.swift;
		this.workerBankingReferenceNumber = user.workerBankingReferenceNumber;
		this.educationDescription = user.educationDescription;
		this.workExperienceDescription = user.workExperienceDescription;
		this.altPhoneNumber = user.altPhoneNumber;
		this.emailCC = user.emailCC;
		this.emailBCC = user.emailBCC;
		this.interpreterFeeVatP = user.interpreterFeeVatP;
		this.interpreterFeeWithoutVatPerMinuteForBooking = user.interpreterFeeWithoutVatPerMinuteForBooking;
		this.interpreterFeeWithoutVatPerMinuteForInstant = user.interpreterFeeWithoutVatPerMinuteForInstant;
		this.interpreterFeeWithoutVatPerMinuteForOnSite = user.interpreterFeeWithoutVatPerMinuteForOnSite;
		this.hideInterpreterFees = user.hideInterpreterFees;
		this.isInterpreterTrained = user.isInterpreterTrained;
		this.eezySalaryPaymentRule = user.eezySalaryPaymentRule;
		this.allowWorkingHoursDate = user.allowWorkingHoursDate;
		this.allowWorkingHoursDateEdit = user.allowWorkingHoursDateEdit;
		this.userGroupIds = user.userGroupIds;
		this.interpretationTypes = user.interpretationTypes;
		this.contractSettings = user.contractSettings;
		this.eezyId = user.eezyId;
		this.eezyIdCreated = user.eezyIdCreated;
		this.status = user.status;
		this.userLevel = user.userLevel;
		this.attributes = user.attributes;
		this.contractStatus = user.contractStatus;
		this.allowOthersWorkingHours = user.allowOthersWorkingHours;
		this.allowManageUserShifts = user.allowManageUserShifts;
		this.isLegalInterpreter = user.isLegalInterpreter;
		this.jobApplicationId = user.jobApplicationId;
		this.paymentMethodType = user.paymentMethodType;
		this.storageHandler = new LocalStorage(this);
	}

	filterRoutes(routes:ERoute[]): ERoute[] {
		if (!this.allowManageUserShifts) routes = routes.filter(route => ![ERoute.USER_SHIFTS_EDIT, ERoute.USER_SHIFTS_LIST].includes(route));
		if (!this.allowOthersWorkingHours) routes = routes.filter(route => ![ERoute.WORKING_HOURS_LIST_PAGE, ERoute.WORKING_HOURS_SUMMARIES_LIST_PAGE].includes(route));
		return routes;
	}

	public getEmails(): string {
		let mailsString = this.email;

		if(this.emailCC && this.emailCC.length > 0){
			mailsString += ", " + this.emailCC;
		}

		return mailsString;
	}

	public get routes(): ERoute[] {

		if (this.isYoupretAdmin) {
			let routes = Object.values(ERoute);
			routes = this.filterRoutes(routes);
			return routes;
		}

		const routes: ERoute[] = [];

		routes.push(ERoute.USER_LOGIN);
		if (this.hasRole(EUserRole.ADMIN)) {
			routes.push.apply(routes, [
				/* ERoute.ORDER_INTERPRETATION, */
				ERoute.PRICE_SEASONS_LIST,
				ERoute.PRICE_SEASON_EDIT,
				ERoute.PRICE_SEASON_RULES_LIST,
				ERoute.PRICE_SEASON_RULE_EDIT,
				ERoute.ORGANIZATION_GROUPS_LIST,
				ERoute.ORGANIZATION_GROUP_EDIT,
				ERoute.USERS_ADMINS_LIST,
				ERoute.USERS_CUSTOMERS_LIST,
				ERoute.USERS_INTERPRETERS_LIST,
				ERoute.USER_EDIT,
				ERoute.INTERPRETATION_ADD,
				ERoute.ORGANIZATIONS_LIST,
				ERoute.ORGANIZATION_EDIT,
				/* ERoute.SUMMARIES_LIST,
				ERoute.SUMMARY_EDIT, */
				/* ERoute.CHAT, */
				ERoute.INTERPRETATIONS_LIST,
				ERoute.INTERPRETATION_EDIT,
				/* 	ERoute.VIDEO, */
				/* ERoute.CUSTOMER_REPORTS_LIST, */
				ERoute.TAGS_LIST,
				ERoute.TAG_EDIT,
				ERoute.RESERVED_DATES_PAGE,
				ERoute.CATEGORIES_EDIT,
				ERoute.CATEGORIES_LIST,
				ERoute.WORKING_HOURS_EDIT_PAGE,
				ERoute.WORKING_HOURS_LIST_PAGE,
				ERoute.WORKING_HOURS_SUMMARIES_LIST_PAGE,
				ERoute.USER_SHIFTS_EDIT,
				ERoute.USER_SHIFTS_LIST,
				ERoute.LANGUAGE_CODES_LIST,
				ERoute.LANGUAGE_CODE_EDIT,
				ERoute.LANGUAGES_LIST,
				ERoute.LANGUAGE_EDIT,
				ERoute.JOB_APPLICATION_EDIT,
				ERoute.JOB_APPLICATION_LIST,
				/* ERoute.ORDERS_LIST, */
				/* ERoute.INFO_CONTACT_REQUESTS_LIST,
				ERoute.INFO_CONTACT_REQUEST_EDIT, */
			]);

			if (this.isShowAttributes) {
				routes.push.apply(routes, [
					ERoute.ATTRIBUTES_EDIT,
					ERoute.ATTRIBUTES_LIST,
				]);
			}

			if (this.isShowContractSettings) {
				routes.push.apply(routes, [
					ERoute.USER_CONTRACT_SETTINGS_EDIT,
					ERoute.USER_CONTRACT_SETTINGS_LIST
				]);
			}

			if (this.isShowSummaries) {
				routes.push.apply(routes, [
					ERoute.SUMMARIES_LIST,
					ERoute.SUMMARY_EDIT,
				])
			}
		}

		if (this.isYoupretFinland && (this.allowMessage || this.allowBank)) {
			routes.push.apply(routes, [
				ERoute.MESSAGE_GROUPS_LIST,
				ERoute.MESSAGE_GROUP_EDIT,
				ERoute.MESSAGE_SHOW,
				ERoute.MESSAGE_EDIT,
				ERoute.MESSAGE_ANSWER,
			]);
		}

		if (this.allowLogs && this.isYoupretAdmin) {
			routes.push.apply(routes, [
				ERoute.LOG_ITEMS_LIST
			]);
		}

		if (this.hasRole(EUserRole.CUSTOMER)) {
			if (this.showOrganizationInterpretations || this.showOrganizationGroupInterpretations) {
				routes.push.apply(routes, [
					ERoute.INTERPRETATIONS_LIST,
					ERoute.INTERPRETATION_EDIT,
				]);
			}
			routes.push.apply(routes, [
				ERoute.INTERPRETATION_REVIEW,
				ERoute.INTERPRETATION_PROCESSING,
				ERoute.VIDEO,
			]);
		}

		if (this.hasRole(EUserRole.APPLICANT, EUserRole.INTERPRETER)) {
			routes.push(ERoute.JOB_APPLICATION_EDIT);
		}

		return this.filterRoutes(routes);
	}

	/**
	 * @returns ERoute array of routes with params, for example "/:id" etc.
	 */
	public get paramRoutes(): ERoute[] {
		return this.routes.filter((route) => route.indexOf("/:") !== -1);
	}

	/**
	 * Handles redirect of a user. If user can access interpretations, he is redirected there. Else first in array,
	 * which will probably be login page if no user is set
	 *
	 * @returns ERoute for default redirect behaviour
	 */
	public get redirect(): ERoute {
		if (this.routes.indexOf(ERoute.INTERPRETATIONS_LIST) > -1) {
			return ERoute.INTERPRETATIONS_LIST;
		} else if (this.hasRole(EUserRole.APPLICANT, EUserRole.INTERPRETER)) {
			if (this.jobApplicationId) {
				return Routes.withParams(ERoute.JOB_APPLICATION_EDIT, { id: this.jobApplicationId, tab: 'basic' });
			}
		}
		return ERoute.PAGE_NOT_FOUND;
	}

	/**
	 * Some security conserns, say, multiparam routes etc.
	 * Works for now, so that we can access specific routes
	 *
	 * @param path Path that we are accessing. useLocation().pathname
	 * @returns boolean representing if user has acces to said path. These are set in User.routes
	 */
	public hasAccess(path: string): boolean {
		if (this.routes.indexOf(path as ERoute) > -1) return true;
		for (const route of this.paramRoutes) {
			const indexOfParams = route.indexOf("/:");
			if (path.slice(0, indexOfParams) === route.slice(0, indexOfParams)) {
				return true;
			}
		}
		return false;
	}

	public hasRole(...roles: EUserRole[]): boolean {
		return roles.includes(this.role);
	}

	// START Do not copy logic (Samu will fix this later)
	public get isYoupretFinland() {
		const sitePrefix = this.site?.prefix;
		return !!sitePrefix && [ESitePrefix.youpret].includes(sitePrefix);
	}

	public get isYoupretIceland() {
		const sitePrefix = this.site?.prefix;
		return !!sitePrefix && [ESitePrefix.youpret_iceland].includes(sitePrefix);
	}


	public get isJohannaOrValitys(): boolean {
		const sitePrefix = this.site?.prefix;
		return !!sitePrefix && [ESitePrefix.valitysklinikka, ESitePrefix.johannarekry].includes(sitePrefix);
	}

	public get isShowAttributes(): boolean {
		const sitePrefix = this.site?.prefix;
		return !!sitePrefix && [ESitePrefix.valitysklinikka, ESitePrefix.youpret_iceland].includes(sitePrefix);
	}

	public get isShowContractSettings(): boolean {
		return this.hasRole(EUserRole.ADMIN) && (this.isYoupretFinland || this.isYoupretIceland);
	}

	public get isShowSummaries(): boolean {
		return this.hasRole(EUserRole.ADMIN) && this.allowSummaries && (this.isYoupretFinland || this.isYoupretIceland);
	}

	public get isShowNetvisor(): boolean {
		return this.hasRole(EUserRole.ADMIN) && this.isYoupretFinland;
	}

	public get isInterpretationSite(): boolean {
		return this.isYoupretFinland || this.isYoupretIceland;
	}
	// END Do not copy logic (Samu will fix this later)
}


const dummyUser: IUser = {
	id: "addUser",
	name: "",
	fullName: "",
	email: "",
	userName: "",
	phoneNumber: "",
	role: EUserRole.ADMIN,
	isYoupretAdmin: false,
	firstName: "",
	lastName: "",
	allowCMS: false,
	allowFees: false,
	allowEezy: false,
	allowReport: false,
	allowMessage: false,
	allowBank: false,
	allowLogs: false,
	allowSummaries: false,
	allowInstant: true,
	allowBooking: true,
	allowOnSite: true,
	allowVideo: false,
	allowFavoriteInterpreters: true,
	showOrganizationInterpretations: false,
	isFavorite: false,
	description: "",
	reference: "",
	billingReference: "",
	organizations: [],
	identity: "",
	showOrganizationGroupInterpretations: false,
	allowMachine: false,
	streetName: "",
	zip: "",
	city: "",
	privatePerson: false,
	manageOrganizationUsers: false,
	phoneNumberConfirmed: false,
	autoPayment: false,
	allowInternetCall: false,
	allowMarketing: false,
	allowAutomaticOfferBookings: true,
	allowMultiDeviceSimultaneousUse: true,
	allowPhoneCall: false,
	sendEmailBookingConfirmed: true,
	allowFastLane: false,
	showTestLanguages: false,
	showPayments: false,
	allowLogin: true,
	tags: [],
	country: "",
	userLanguageItems: [],
	userLanguagePairs: [],
	userFavoriteInterpreters: [],
	blockedUsers: [],
	netvisorDimensionName: "",
	netvisorProductIdentifier: "",
	netvisorProductName: "",
	feeType: "",
	allowJobOffers: true,
	contractNo: "",
	securityCleared: false,
	socialSecurityNumber: "",
	iban: "",
	swift: "",
	workerBankingReferenceNumber: "",
	educationDescription: "",
	workExperienceDescription: "",
	altPhoneNumber: "",
	emailCC: "",
	emailBCC: "",
	interpreterFeeVatP: 0,
	interpreterFeeWithoutVatPerMinuteForBooking: 0,
	interpreterFeeWithoutVatPerMinuteForInstant: 0,
	interpreterFeeWithoutVatPerMinuteForOnSite: 0,
	hideInterpreterFees: false,
	isInterpreterTrained: false,
	eezySalaryPaymentRule: "",
	allowWorkingHoursDate: false,
	allowWorkingHoursDateEdit: false,
	interpretationTypes: [],
	userGroupIds: [],
	contractSettings: [],
	attributes: [],
	userLevel: 0,
	contractStatus: "",
	allowManageUserShifts: false,
	allowOthersWorkingHours: false,
	isLegalInterpreter: false,
}

export const newUserDummyAdmin: IUser = {
	...dummyUser,
	role: EUserRole.ADMIN
}

export const newUserDummyInterpreter: IUser = {
	...newUserDummyAdmin,
	role: EUserRole.INTERPRETER
}

/**
 * For the purpose of creating a new user on console.
 */
export const newUserDummyCustomer: IUser = {
	...newUserDummyAdmin,
	role: EUserRole.CUSTOMER,
	paymentMethodType: "INVOICE",
}

export enum ECustomerPaymentMethod {
	INVOICE = "INVOICE",
	STRIPE = "STRIPE",
	NO_CHARGE = "NO_CHARGE",
	ZETTLE = "ZETTLE"
}

export const CUSTOMER_PAYMENT_METHODS: IOption[] = [
	{ value: "INVOICE", labelTranslation: ETranslation.USER_PAYMENT_BY_INVOICE, },
	{ value: "STRIPE", labelTranslation: ETranslation.USER_PAYMENT_STRIPE, },
	{ value: "NO_CHARGE", labelTranslation: ETranslation.USER_PAYMENT_NO_CHARGE, },
	{ value: "ZETTLE", labelTranslation: ETranslation.USER_PAYMENT_ZETTLE, },
]

export const getPaymentMethodString = (
	t: TFunction, type: ECustomerPaymentMethod
): string => t(CUSTOMER_PAYMENT_METHODS.find(item => item.value === type)?.labelTranslation || "");