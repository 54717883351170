import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import DeleteModal from "../../DeleteModal/DeleteModal";
import IAppState from "../../../interfaces/store/IAppState";
import ILeadsState from "../../../interfaces/store/ILeadsState";

interface IProps {
	onDelete: () => void;
	onCloseModal: () => void;
}

const LeadsRemoveModalContent: React.FC<IProps> = ({ onDelete, onCloseModal }) => {
	const { t } = useTranslation()

	const {
		deleteLeadsError,
		deleteLeadsLoading,
		deleteLeadsDone
	} = useSelector<IAppState, ILeadsState>((state) => state.leads)

	useEffect(() => {
		if (!deleteLeadsDone || deleteLeadsError || deleteLeadsLoading) return;
		setTimeout(() => {
			window.location.reload()
		}, 1000);
	})


	return <>
		<DeleteModal
			deleteText={t(ETranslation.LEADS_DELETE_ARE_YOU_SURE)}
			hideText={(deleteLeadsLoading || deleteLeadsDone || deleteLeadsError !== null)}
			onDelete={onDelete}
			error={deleteLeadsError}
			loading={deleteLeadsLoading}
			success={deleteLeadsDone} />
	</>
}

export default LeadsRemoveModalContent;
