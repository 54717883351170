import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCreateInput } from '../../../hooks/useCreateInput';
import { ILanguage } from '../../../interfaces/ILanguage';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import Alert from '../../ui/Alert/Alert';
import Button, { EButtonColor } from '../../ui/Button/Button';
import { EInputType, IInputField, IOption } from '../../ui/Input/Input';
import { getInputData, updateInputHandler } from '../../ui/Input/input-utils';
import InputGroup from '../../ui/InputGroup/InputGroup';
import ModalBody from '../../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../../ui/Modal/ModalContext';
import ModalFooter from '../../ui/Modal/ModalFooter/ModalFooter';
import LanguageTable from '../LanguageTable/LanguageTable';

enum EInputs {
	languageCode = "languageCode",
}

interface IProps {
	onDelete: (languageCode: string) => void;
}

const ToLanguagesModal: React.FC<IProps> = ({ onDelete }) => {
	const dispatch = useDispatch();
	const { closeModal } = useContext(ModalContext);
	const { t } = useTranslation();
	const [languageOptions, setLanguageOptions] = useState<IOption[]>([]);
	const [toLanguages, setToLanguages] = useState<ILanguage[]>([]);

	const {
		language,
		languages,
		linkError,
		linkLoading,
		linkOk,
		unlinkError,
		unlinkLoading,
		unlinkOk,
	} = useSelector((state: IAppState) => ({
		language: state.language.language,
		languages: state.language.languages,
		linkError: state.language.linkError,
		linkLoading: state.language.linkLoading,
		linkOk: state.language.linkOk,
		unlinkError: state.language.unlinkError,
		unlinkLoading: state.language.unlinkLoading,
		unlinkOk: state.language.unlinkOk,
	}));

	useEffect(() => {
		if (!language) return;


		let toLanguagesOptions: IOption[] = [];
		if (language?.languageCode) {
			const alreadyAdded = language.toLanguages
				? language.toLanguages.map((lang) => lang.languageCode)
				: [];

			toLanguagesOptions = languages
				? [...languages]
						.filter(
							(languageCode) =>
								languageCode.languageCode !==
								language.languageCode
						)
						.filter(
							(languageCode) =>
								!alreadyAdded.includes(
									languageCode.languageCode
								)
						)
						.map((language) => ({
							value: language.languageCode,
							label: `${language.name} (${language.languageCode})`,
							active: language.active,
						}))
				: [];

		}

		setToLanguages(language?.toLanguages ?? []);
		setLanguageOptions(toLanguagesOptions);
	}, [language, languages, linkOk, unlinkOk]);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.languageCode]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.LANGUAGE_LANGUAGE_CODE,
			value: "",
			validation: {
				required: true,
			},
		},
	});

	const createInput = useCreateInput(inputs, setInputs);

	const addHandler = useCallback(() => {
		if (!language) return;
		const newLanguage = getInputData<ILanguage>(inputs);
		if (!newLanguage.languageCode) return;
		updateInputHandler(EInputs.languageCode, "", setInputs);
		dispatch(
			actions.linkLanguages(
				language.languageCode,
				newLanguage.languageCode
			)
		);
	}, [dispatch, inputs, language]);

	return (
		<>
			<ModalBody>
				{linkError && <Alert>{linkError}</Alert>}
				{unlinkError && <Alert>{unlinkError}</Alert>}
				<InputGroup
					style={{
						alignItems: "end",
					}}
				>
					{createInput(EInputs.languageCode, {
						options: languageOptions,
						isLoading: linkLoading || unlinkLoading,

					})}
					<div>
						<Button onClick={addHandler} loading={linkLoading}>
							{t(ETranslation.COMMON_ADD_NEW)}
						</Button>
					</div>
				</InputGroup>
				<LanguageTable toLanguages={toLanguages} onDelete={onDelete} />
			</ModalBody>
			<ModalFooter>
				<Button onClick={closeModal} color={EButtonColor.DEFAULT}>
					{t(ETranslation.COMMON_CLOSE)}
				</Button>
			</ModalFooter>
		</>
	);
};

export default ToLanguagesModal;
