import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateInput } from '../../hooks/useCreateInput';
import { getUserOptions, searchUsers } from '../../services/userServices';
import { ETranslation } from '../../translations/translation-keys';
import Button, { EButtonColor } from '../ui/Button/Button';
import { IInputField, IOption } from '../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';
import { searchOrganizations } from '../../services/searchServices';
import { getOrganizationGroupOptions, searchOrganizationGroups } from '../../services/organizationGroupService';
import { useAttributeOptions } from '../../hooks/useAttributeOptions';
import { ECategoryType } from '../../shared/category-data';
import useWorkingHoursEventsInputs from './WorkingHoursEventsSearchInputs';
import { useStoredSearchParams } from '../../hooks/useStoredSearchParams';
import { EStorageKeys } from '../../shared/storage-keys';
import { useDispatch } from 'react-redux';
import IWorkingHoursAction from '../../interfaces/store/IWorkingHoursAction';
import { findWorkingHourEventsClear } from '../../store/actions';
import { useInitSearchValues } from '../../hooks/useInitSearchValues';
import { getOrganizationOptions } from '../../services/organizationServices';

export interface IWorkingHourEventsSearchParams {
	userId: string;
	organizationId: string;
	organizationGroupId: string;
	startDate: Date;
	endDate: Date;
}

interface IProps {
	searchHandler: (params: IWorkingHourEventsSearchParams) => void;
}

const WorkingHoursEventsSearch: React.FC<IProps> = ({ searchHandler }) => {

	const { EInputs, initialInputs } = useWorkingHoursEventsInputs();

	const [inputs, setInputs] = useState<IInputField>(initialInputs);
	const dispatch = useDispatch();

	const { attributeOptions: projectOptions, attributesLoading: projectsLoading } = useAttributeOptions<IOption>([ECategoryType.PROJECT]);

	const { setId: setOrganizationIds, isLoading: isLoadingOrganizations } = useInitSearchValues(setInputs, EInputs.organizationId, getOrganizationOptions);
	const { setId: setOrganizationGroupIds, isLoading: isLoadingOrganizationGroups } = useInitSearchValues(setInputs, EInputs.organizationGroupId, getOrganizationGroupOptions);
	const { setId: setUserIds, isLoading: isLoadingUsers } = useInitSearchValues(setInputs, EInputs.userId, getUserOptions);

	const { onSearch, clearInputs } = useStoredSearchParams<IWorkingHourEventsSearchParams, IWorkingHoursAction, IWorkingHourEventsSearchParams>({
		setInputs,
		initialInputs,
		storageKey: EStorageKeys.WORKING_HOURS_EVENTS_SEARCH_PARAMS,
		clearResults: useCallback(() => dispatch(findWorkingHourEventsClear()), [dispatch]),
		searchHandlerDataTransform: (data: IWorkingHourEventsSearchParams) => {
			return data;
		},
		searchHandler,
		initIdInputs: useCallback((data: IWorkingHourEventsSearchParams) => {
			initForm(setInputs, { ...data, userId: '', organizationId: "", organizationGroupId: "" });
			if (data.userId) setUserIds(data.userId)
			if (data.organizationId) setOrganizationIds(data.organizationId)
			if (data.organizationGroupId) setOrganizationGroupIds(data.organizationGroupId)
		}, [setUserIds, setOrganizationIds, setOrganizationGroupIds])
	})

	const createInput = useCreateInput(inputs, setInputs, { showValidation: true });
	const [isValid, setIsValid] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setIsValid(validateInputs(inputs));
	}, [inputs]);

	return (
		<div>
			<InputGroup>
				{createInput(EInputs.startDate)}
				{createInput(EInputs.endDate)}
			</InputGroup>
			{createInput(EInputs.userId, { fetchOptions: searchUsers, isLoading: isLoadingUsers })}
			{createInput(EInputs.organizationGroupId, { fetchOptions: searchOrganizationGroups, isLoading: isLoadingOrganizationGroups })}
			{createInput(EInputs.organizationId, { fetchOptions: searchOrganizations, isLoading: isLoadingOrganizations })}
			{createInput(EInputs.projectId, { options: projectOptions, isLoading: projectsLoading })}
			<Button onClick={() => onSearch(getInputData<IWorkingHourEventsSearchParams>(inputs))} disabled={!isValid}>
				{t(ETranslation.COMMON_SEARCH)}
			</Button>
			<Button onClick={clearInputs} color={EButtonColor.DEFAULT}>
				{t(ETranslation.COMMON_CLEAR)}
			</Button>
		</div>
	);
};

export default WorkingHoursEventsSearch;
