import { useMemo } from "react";
import { IInterpretation } from "../interfaces/IInterpretation";


export const useInterpretationInterpreters = (interpretation: IInterpretation) => {

	const interpretationUsers = interpretation.interpretationUserRows;

	const users = useMemo(
		() => interpretationUsers?.filter((user) => ["INTERPRETER_CANDIDATE", "INTERPRETER"].includes(user.type)),
		[interpretationUsers]
	) ?? [];

	return users;
}