import React from "react";
import Table from "../../ui/Table/Table";
import { ISummary } from "../../../store/actions/summariesActions";
import SummaryTableHeader from "./SummariesHeading";
import SummaryTableBody from "./SummaryTableBody";

interface IProps {
	summaries: ISummary[];
	openHandler: (id: string) => void;
}

const SummariesTable: React.FC<IProps> = ({ summaries, openHandler }) => {

	return <Table>
		<SummaryTableHeader summaries={summaries} />
		{summaries && <SummaryTableBody summaries={summaries} openHandler={openHandler} />}
	</Table>
}

export default SummariesTable;