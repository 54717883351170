import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { SelectedSummariesContext } from "../../../pages/Summaries/SummariesPage";
import { ISummary } from "../../../store/actions/summariesActions";
import classes from "./SummariesList.module.scss"

const EInputs = {
	itemsSelected: "itemsSelected",
}

interface IProps {
	summaries: ISummary[];
}

const SummaryTableHeader: React.FC<IProps> = ({summaries}) => {
	const { t } = useTranslation()
	const { selectAll, setSelectAll, setSelectedRows } = useContext(SelectedSummariesContext);

	const selectOrDeselctAll = () => {
		// Shortcut the list, so we dont get long freeze from dep of having every item re-render o^2
		!selectAll ? setSelectedRows(summaries) : setSelectedRows([]);
		setSelectAll(!selectAll);
	}

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.itemsSelected]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.COMMON_SELECT_ALL,
			value: false,
		},
	})
	const createInput = useCreateInput(inputs, setInputs);
	return <thead>
		<tr>
			<th colSpan={1} className={classes.selectAll}>{createInput(EInputs.itemsSelected, { onClick: selectOrDeselctAll, labelSwap: true })}</th>
			<th>{t(ETranslation.COMMON_STATUS)}</th>
			<th>{t(ETranslation.COMMON_CREATED)}</th>
			<th>{t(ETranslation.SUMMARIES_TIME_INTERVAL)}</th>
			<th>{t(ETranslation.COMMON_USER)}</th>
			<th>{t(ETranslation.SUMMARIES_GRADE)}</th>
			<th>{t(ETranslation.COMMON_INTERPRETATIONS)}</th>
			<th>{t(ETranslation.COMMON_DURATION)}</th>
			<th></th>
			<th>{t(ETranslation.COMMON_NOTES)}</th>
		</tr>
	</thead>
}

export default SummaryTableHeader;