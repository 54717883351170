import React from "react";
import Container from "../../components/ui/Container/Container";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import IAppState from "../../interfaces/store/IAppState";
import Spinner from "../../components/ui/Spinner/Spinner";
import IWorkingHoursState from "../../interfaces/store/IWorkingHoursState";
import Button, { EButtonColor, EButtonSize } from "../../components/ui/Button/Button";
import { useWorkingHoursAddModal } from "../../components/WorkingHours/WorkingHoursAddModal";
import { ERoute, Routes } from "../../classes/Routes";
import * as actions from "../../store/actions";
import WorkingHoursEventsSearch, {
	IWorkingHourEventsSearchParams,
} from "../../components/WorkingHours/WorkingHoursEventsSearch";
import WorkingHoursEventsList from "../../components/WorkingHours/WorkingHoursEventsList";
import { IWorkingHoursDateEventsSummary } from "../../interfaces/IWorkingHoursDateEventsSummary";
import Fieldset from "../../components/ui/Fieldset/Fieldset";
import Table from "../../components/ui/Table/Table";
import { useTranslation } from "react-i18next";

interface IProps extends RouteComponentProps {}

const WorkingHoursListPage: React.FC<IProps> = ({ history }) => {
	const dispatch = useDispatch();

	const openAddModal = useWorkingHoursAddModal();

	const { workingHourEvents, workingHourEventsSummary, loading, error } = useSelector<IAppState, IWorkingHoursState>(
		(state) => state.workingHours
	);

	const onSearch = (data: IWorkingHourEventsSearchParams) => {
		dispatch(actions.findWorkingHourEvents(data));
	};

	const addHandler = async () => {
		const result = await openAddModal();
		if (result) {
			history.push(Routes.withParams(ERoute.WORKING_HOURS_EDIT_PAGE, { ...result }));
		}
	};

	return (
		<Container>
			<WorkingHoursEventsSearch searchHandler={onSearch} />
			<Button
				onClick={addHandler}
				style={{ marginTop: "1rem" }}
				color={EButtonColor.DEFAULT}
				size={EButtonSize.SMALL}
			>
				Avaa pvm
			</Button>
			{loading && <Spinner />}
			{error && <p>{error}</p>}
			{workingHourEvents && <WorkingHoursEventsList workingHourEvents={workingHourEvents} />}
			{workingHourEventsSummary && <WorkingHourEventsSummary summary={workingHourEventsSummary} />}
		</Container>
	);
};

interface IProps2 {
	summary: IWorkingHoursDateEventsSummary;
}

const WorkingHourEventsSummary: React.FC<IProps2> = ({ summary }) => {
	const { i18n } = useTranslation();
	return (
		<div>
			{summary.months.map((month) => (
				<Fieldset key={`${month.date}`} label={new Date(month.date).toLocaleDateString(i18n.language, { month: "long", year: 'numeric'})}>
					<Table>
						<thead>
							<tr>
								<th style={{ width: 1 }}>Etunimi</th>
								<th style={{ width: 1 }}>Sukunimi</th>
								<th>Vuosiloma</th>
								<th>Sairasloma</th>
								<th>Palkaton</th>
								<th>Muut</th>
								<th>Projektit</th>
							</tr>
						</thead>
						<tbody>
							{month.users.map((user) => (
								<tr key={user.userId}>
									<td>{user.firstName}</td>
									<td>{user.lastName}</td>
									<td dangerouslySetInnerHTML={{ __html: user.annualLeave}}></td>
									<td dangerouslySetInnerHTML={{ __html: user.sickLeave}}></td>
									<td dangerouslySetInnerHTML={{ __html: user.absentUnpaid}}></td>
									<td>
										{user.work}<br/>
										{user.absentFlex}<br/>
										{user.noShift}<br/>
									</td>
									<td>
										{user.projects.map(str => <>{str}<br/></>)}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Fieldset>
			))}
		</div>
	);
};

export default WorkingHoursListPage;
