import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../classes/Routes';
import EmailOrderStatus from '../../components/EmailOrders/EmailOrderStatus';
import Heading from '../../components/Heading/Heading';
import Alert from '../../components/ui/Alert/Alert';
import Button, { EButtonColor, EButtonSize } from '../../components/ui/Button/Button';
import Container from '../../components/ui/Container/Container';
import Fieldset from '../../components/ui/Fieldset/Fieldset';
import Spinner from '../../components/ui/Spinner/Spinner';
import Table from '../../components/ui/Table/Table';
import { useConfirmModal } from '../../hooks/useConfirmModal';
import { useLanguages } from '../../hooks/useLanguages';
import { useSingleOptionLabel } from '../../hooks/useSingleOptionLabel';
import { IEmailOrderRow, IEmailOrderRowInterpretation, IEmailOrderRowKela } from '../../interfaces/IEmailOrder';
import IAppState from '../../interfaces/store/IAppState';
import IEmailOrderState from '../../interfaces/store/IEmailOrderState';
import { EEmailOrderType } from '../../shared/email-order-data';
import { INTERPRETATION_STATUS, YOUPRET_TYPES } from '../../shared/interpretation-data';
import * as actions from '../../store/actions';
import { formatDateTime } from '../../utils/date-utils';

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

const EmailOrderEditPage: React.FC<IProps> = ({ history, match }) => {
	const openConfirmModal = useConfirmModal();

	const dispatch = useDispatch();

	const { id } = match.params;
	const isAdd = id === "add";

	const { t } = useTranslation();

	useEffect(() => {
		if (!isAdd) {
			dispatch(actions.getEmailOrder(id));
		}
	}, [isAdd, id, dispatch]);

	const { error, loading, saveOrUpdateOk, emailOrder, deleting, deletingError, deletingOk } = useSelector<
		IAppState,
		IEmailOrderState
	>((state) => state.emailOrder);

	useEffect(() => {
		if (saveOrUpdateOk) {
			history.push(ERoute.EMAIL_ORDERS_LIST);
		}
		return () => {
			dispatch(actions.saveOrUpdateEmailOrderClear());
			dispatch(actions.getEmailOrderClear());
			dispatch(actions.deleteEmailOrderClear());
		};
	}, [dispatch, saveOrUpdateOk, history]);

	useEffect(() => {
		if (deletingOk) {
			history.push(ERoute.EMAIL_ORDERS_LIST);
		}
	}, [deletingOk, history]);


	const deleteHandler = async () => {
		if (emailOrder) {
			const success = await openConfirmModal("Haluatko varmasti poistaa tilauksen?");
			if (success) {
				dispatch(actions.deleteEmailOrder(emailOrder.id));
			}
		}
	};

	/*
	const createInterpretationsHandler = async () => {
		if (emailOrder) {
			const success = await openConfirmModal("Haluatko varmasti luoda tulkkaukset riveistä?");
			if (success) {
				alert("TODO");
			}
		}
	};
	*/

	const addInterpretationHandler = (rowId: string) => {
		window.open(`/interpretation/add?emailOrderId=${emailOrder?.id}&rowId=${rowId}`, "_blank", "noopener, noreferrer")?.focus()
	}

	return (
		<Container>
			{error && <Alert>{error}</Alert>}
			<Heading>{t("Sähköposti tilaus")}</Heading>
			{loading ? (
				<Spinner />
			) : (
				<>
					{error && <Alert>{error}</Alert>}
					{emailOrder && (
						<>
							<EmailOrderStatus status={emailOrder.status} />
							<p><b>Luotu:</b> {formatDateTime(emailOrder.created)}</p>
							<p><b>Sähköposti:</b> {emailOrder.email}</p>
							<p><b>Asiakas:</b> {emailOrder.userName}</p>
							<EmailOrderRows type={emailOrder.type} rows={emailOrder.rows} onAddInterpretation={addInterpretationHandler} />
							{emailOrder.attachmentContent && (
								<Fieldset label="Tiedoston sisältö" style={{ whiteSpace: 'pre-wrap' }} >
									<div dangerouslySetInnerHTML={{ __html: emailOrder.attachmentContent }}></div>
								</Fieldset>
							)}
							{deletingError && <Alert>{deletingError}</Alert>}
							<div style={{ display: "flex", gap: ".5rem", marginTop: "1rem" }}>
								{/*
									<Button onClick={createInterpretationsHandler}>Luo tulkkaukset</Button>
								*/}
								<Button onClick={deleteHandler} loading={deleting} color={EButtonColor.DANGER}>
									Poista
								</Button>
							</div>
						</>
					)}
				</>
			)}
		</Container>
	);
};

interface IEmailOrderRowsProps {
	type: EEmailOrderType;
	rows: IEmailOrderRow[];
	onAddInterpretation: (rowId: string) => void
}

const EmailOrderRows: React.FC<IEmailOrderRowsProps> = ({ type, rows, onAddInterpretation }) => {
	if (type === EEmailOrderType.KELA) {
		return (
			<>
				<Fieldset label="Luetut rivit">
					<EmailOrderRowsKela rows={rows as IEmailOrderRowKela[]} />
				</Fieldset>
				<Fieldset label="Tulkkaus tiedot">
					<EmailOrderRowsInterpretation rows={rows as IEmailOrderRowKela[]} onAdd={onAddInterpretation} />
				</Fieldset>
			</>
		);
	}
	return null;
};


interface IEmailOrderRowsKelaProps {
	rows: IEmailOrderRowKela[];
}

const EmailOrderRowsKela: React.FC<IEmailOrderRowsKelaProps> = ({ rows }) => {
	return (
		<Table>
			<thead>
				<tr>
					<th>Skype</th>
					<th>Puhelin</th>
					<th>Neuvottelupuhelu</th>
					<th>Pvm</th>
					<th>Kellonaika</th>
					<th>Palvelupiste/osoite</th>
					<th>Asioinnin aihe</th>
					<th>Tulkattava kieli</th>
					<th>Palvelukieli</th>
					<th>ID-numero</th>
					<th>Lisätietoja</th>
				</tr>
			</thead>
			<tbody>
				{rows.map((row) => {
					return (
						<tr key={row.id}>
							<td>{row.skype}</td>
							<td>{row.phoneNumber}</td>
							<td>{row.conferenceCall}</td>
							<td>{row.date}</td>
							<td>{row.timeRange}</td>
							<td>{row.place}</td>
							<td>{row.subject}</td>
							<td>{row.toLanguage}</td>
							<td>{row.fromLanguage}</td>
							<td>{row.kelaId}</td>
							<td>{row.info}</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
};


interface IEmailOrderRowsInterpretationProps {
	rows: IEmailOrderRowKela[];
	onAdd: (rowId: string) => void;
}

const EmailOrderRowsInterpretation: React.FC<IEmailOrderRowsInterpretationProps> = ({ rows, onAdd }) => {
	return (
		<Table>
			<thead>
				<tr>
					<th>Tila</th>
					<th>Tyyppi</th>
					<th>Pvm</th>
					<th>Kesto minuuteissa</th>
					<th>Kielestä</th>
					<th>Kieleen</th>
					<th>Lisätiedot työntekijälle</th>
					<th>Asiakkaan viite</th>
					<th>Admin muistiinpanot</th>
					<th>Tulkkaus</th>
				</tr>
			</thead>
			<tbody>
				{rows.map((row) => <EmailOrderRowsInterpretationItem key={row.id} interpretation={row.interpretation} onAdd={() => onAdd(row.id)} />)}
			</tbody>
		</Table>
	);
};

interface IEmailOrderRowsInterpretationItemProps {
	interpretation: IEmailOrderRowInterpretation;
	onAdd: VoidFunction;
}

const EmailOrderRowsInterpretationItem: React.FC<IEmailOrderRowsInterpretationItemProps> = ({ interpretation, onAdd }) => {

	const openInterpretationHandler = (id: string) => {
		window.open(`/interpretations/${id}`, "_blank", "noopener, noreferrer")?.focus()
	}

	const { languageOptions } = useLanguages();
	const interpretationId = interpretation.interpretationId;
	const status = useSingleOptionLabel(INTERPRETATION_STATUS, interpretation.status);
	const type = useSingleOptionLabel(YOUPRET_TYPES, interpretation.type);
	const toLanguage = useSingleOptionLabel(languageOptions, interpretation.toLanguage);
	const fromLanguage = useSingleOptionLabel(languageOptions, interpretation.fromLanguage);
	return (
		<tr>
			<td>{status}</td>
			<td>{type}</td>
			<td>{formatDateTime(interpretation.startDate)}</td>
			<td>{interpretation.durationInMinutes}</td>
			<td>{fromLanguage}</td>
			<td>{toLanguage}</td>
			<td>{interpretation.customerNotes}</td>
			<td>{interpretation.customerReference}</td>
			<td>{interpretation.adminNotes}</td>
			<td>
				{interpretationId && (
					<Button size={EButtonSize.X_SMALL} onClick={() => openInterpretationHandler(interpretationId)}>Avaa</Button>
				)}
				<Button size={EButtonSize.X_SMALL} onClick={onAdd}>
					Lisää tulkkaus
				</Button>
			</td>
			{/*
			<td style={{ whiteSpace: "nowrap" }}>
				<Button size={EButtonSize.X_SMALL} onClick={() => {}} style={{ marginRight: ".5rem" }}>
					Muokkaa
				</Button>
				<Button size={EButtonSize.X_SMALL} color={EButtonColor.DANGER} onClick={() => {}}>
					Poista
				</Button>
			</td>
			*/}
		</tr>
	)
}

export default EmailOrderEditPage;
