import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { ERoute, Routes } from '../../classes/Routes';
import { IUser } from '../../classes/User';
import useCommentsModal from '../../components/Comments/CommentUtils/UseCommentModal';
import Heading from '../../components/Heading/Heading';
import Container from '../../components/ui/Container/Container';
import Spinner from '../../components/ui/Spinner/Spinner';
import UserListTable from '../../components/User/UsersList';
import { useUserPagination } from '../../hooks/useUserPagination';
import { ETranslation } from '../../translations/translation-keys';
import InterpretersSearch from '../../components/User/UsersPages/InterpretersPage/InterpretersSearch';
import { useDispatch } from 'react-redux';
import { getUsersClear } from '../../store/actions';
import { ILeadNew } from '../../interfaces/ILead';
import { ILeadableEntity } from '../../components/CreateLeads/useLeadableItems';
import CreateLeadsButton from '../../components/CreateLeads/CreateLeadsButton';
import SiteOnlyContent from '../../components/ui/SiteOnlyContent/SiteOnlyContent';

interface IProps extends RouteComponentProps { }



const InterpretersPage: React.FC<IProps> = ({ history, location, match }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const openCommentsModal = useCommentsModal();

	const { loaderRef, users, loading, searchHandler, hasMoreUsers } = useUserPagination();

	const openHandler = (id: string) => {
		history.push(Routes.withParams(ERoute.USER_EDIT, { id }));
	};

	// Transform for leads
	const transform = (items: ILeadableEntity[]) => {
		return items.map((item): ILeadNew => {
			return {
				firstName: item.firstName,
				lastName: item.lastName,
				email: item.email,
				phoneNumber: item.phoneNumber,
				uri: "console/interpreters",
				languageCode: "fi"
			}
		})
	};

	const [filteredUsers, setFilteredUsers] = useState<IUser[]>([])

	useEffect(() => {
		return () => {
			dispatch(getUsersClear());
		}
	}, [dispatch])

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_USERS_INTERPRETERS)}</Heading>
			<InterpretersSearch
				searchHandler={searchHandler}
				items={users ?? []}
				loading={loading}
				history={history}
				location={location}
				match={match}
				setFilteredUsers={setFilteredUsers}
			/>
			<SiteOnlyContent>
				{(filteredUsers && filteredUsers.length > 0) && <CreateLeadsButton leadableItems={filteredUsers} transform={transform} />}
			</SiteOnlyContent>
			{loading ? (
				<Spinner />
			) : (
				<UserListTable
					users={filteredUsers}
					onOpen={openHandler}
					onComment={(user) => openCommentsModal(user)}
					location={location}
					history={history}
					match={match}
				/>
			)}
			<p ref={loaderRef} style={{ color: hasMoreUsers ? "#ccc" : "#fff" }}>
				{hasMoreUsers ? t(ETranslation.COMMON_SHOW_MORE_ROWS) : ""}
			</p>
		</Container>
	);
};

export default InterpretersPage;
