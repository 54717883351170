import React from "react";
import { ILeadNew } from "../../interfaces/ILead";
import Button from "../ui/Button/Button";
import UseLeadableItems, { ILeadableEntity } from "./useLeadableItems";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IProps {
	leadableItems: ILeadableEntity[];
	transform: (items: ILeadableEntity[]) => ILeadNew[];
}

const CreateLeadsButton: React.FC<IProps> = ({ leadableItems, transform }) => {

	const { t } = useTranslation();

	const { handler } = UseLeadableItems({ leadableItems, transform });


	const openModal = () => {

		handler(transform(leadableItems))
	}

	return <Button onClick={openModal}>{t(ETranslation.LEADS_CREATE_NEW_LEADS)}</Button>

}

export default CreateLeadsButton;