import { apiUrl } from "./config";
import i18n from "./i18n";

export enum EFetchMethod {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT"
}

type TBody =
  | string
  | Blob
  | ArrayBufferView
  | ArrayBuffer
  | FormData
  | URLSearchParams
  | ReadableStream<Uint8Array>
  | null
  | undefined;

  export const customFetchWithResponse = function(
    url: string,
    method: EFetchMethod = EFetchMethod.GET,
    body?: TBody,
    signal?: AbortSignal
  ): Promise<Response> {
    return fetch(`${apiUrl}${url}`, {
      method,
      body,
      credentials: "include",
      headers: {
        'X-Client': 'React',
		"X-TimeZoneName": Intl.DateTimeFormat().resolvedOptions().timeZone,
		"X-language": i18n.language,
      },
      signal
    })
      .then(res => {
        if (res.ok) {
          return res;
        }
        return res.json().then(error => {
          if(error === 'NO_AUTH') error = 'Virhe! Käyttäjää ei tunnistettu tai ei oikeutta.'
          throw new Error(error);
        });
      })
      .catch(error => {
        if(error instanceof Error) {
          throw error;
        } else {
          throw new Error(error);
        }
      });
  };

export const customFetch = async function<T>(
  url: string,
  method: EFetchMethod = EFetchMethod.GET,
  body?: TBody,
  signal?: AbortSignal
): Promise<T> {
  const res = await customFetchWithResponse(url, method, body, signal);
  	// Handle no content status (empty body)
	if (res.status === 204) {
		return new Promise((resolve, reject) => {
			reject(res.statusText);
		})
	}
  return res.json();
};


