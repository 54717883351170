import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import IAppState from "../interfaces/store/IAppState";
import { ITask, ITaskSearch, searchTasks, searchTasksClear } from "../store/actions/tasksActions";

export const TasksPagination = function () {
	const dispatch = useDispatch();
	const loaderRef = useRef<HTMLParagraphElement>(null);

	const [searchOptions, setSearchOptions] = useState<ITaskSearch | null>(null);
	const [tasks, setTasks] = useState<ITask[] | null>(null);

	const [loadingCheck, setLoadingCheck] = useState(false);

	const {useTasks, error, taskItems, loading,hasNext } = useSelector(
		(state: IAppState) => ({
			useTasks: state.tasks.tasks,
			loading: state.tasks.loading,
			error: state.tasks.error,
			taskItems: state.tasks.taskItems,
			hasNext: state.tasks.hasNext,
		})
	);

	const clearTasks = () =>{
		dispatch(searchTasksClear())
	}

	useEffect(() => {
		setTasks(useTasks);
		setLoadingCheck(false);
	}, [useTasks]);

	const searchHandler = useCallback((search: ITaskSearch) => {
		dispatch(searchTasks(search));
		setSearchOptions(search);
	}, [dispatch]);

	const handleObserver = useCallback((entries) => {
		if (!hasNext || !searchOptions || loading || loadingCheck || !useTasks || useTasks?.length === 0) return;
		const target = entries[0];
		if (target.isIntersecting) {
			setLoadingCheck(true);
			searchHandler({
				...searchOptions,
				page: searchOptions?.page ?  searchOptions?.page + 1 : 1,
			});
		} else {
			setLoadingCheck(false);
		}
	}, [loading, useTasks, searchHandler, searchOptions, hasNext, loadingCheck]);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "",
			threshold: 1,
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [handleObserver]);

	return { loaderRef, tasks, loading, searchHandler, hasNext, error, clearTasks, taskItems };
};
