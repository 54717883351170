import React, { useCallback, useContext } from "react";
import { ILead } from "../../../interfaces/ILead";
import Button, { EButtonColor } from "../../ui/Button/Button";
import { useDispatch } from "react-redux";
import { deleteLeads, deleteLeadsClear } from "../../../store/actions/leadsActions";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import ModalContext, { EModalSize } from "../../ui/Modal/ModalContext";
import LeadsRemoveModalContent from "./LeadsRemoveModalContent";

interface IProps {
	leads: ILead[];
}

const LeadsRemoveLeadsButton: React.FC<IProps> = ({ leads }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch();
	const { setModal, closeModal } = useContext(ModalContext);

	const removeLeads = () => {
		const keys = leads.map((lead) => lead.id);
		dispatch(deleteLeads(keys));
	}

	const onCloseModal = useCallback(() => {
		dispatch(deleteLeadsClear());
		closeModal();
	}, [dispatch, closeModal])

	const modal = () => {
		setModal({
			isOpen: true,
			size: EModalSize.SMALL,
			title: t(ETranslation.UI_DELETE),
			content: <LeadsRemoveModalContent onDelete={removeLeads} onCloseModal={onCloseModal} />,
			onModalClose: onCloseModal,
		});
	}

	return <>
		<Button color={EButtonColor.DANGER} onClick={() => modal()}>{t(ETranslation.COMMON_DELETE_SELECTED)}</Button>
	</>
}

export default LeadsRemoveLeadsButton;
