import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import OrdersList from "../../components/Orders/OrdersList";
import { IOrder, saveOrUpdateOrderClear } from "../../store/actions/ordersActions";
import Button from "../../components/ui/Button/Button";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import IAppState from "../../interfaces/store/IAppState";
import { RouteComponentProps } from "react-router-dom";
import { ERoute, Routes } from "../../classes/Routes";
import { OrdersPagePagination } from "../../hooks/useOrders";
import Spinner from "../../components/ui/Spinner/Spinner";

interface IProps extends RouteComponentProps { }

const OrdersPage: React.FC<IProps> = ({ history }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { saveOrUpdateOk, saveOrUpdateLoading } = useSelector(
		(state: IAppState) => state.orders
	);

	const { orders, error, loading, searchHandler, clearOrders, loaderRef, hasMoreOrders } = OrdersPagePagination();

	useEffect(() => {
		searchHandler(0);
		return () => {
			clearOrders();
		}
	}, [searchHandler, clearOrders]);

	useEffect(() => {
		if (!saveOrUpdateOk) return;
		dispatch(saveOrUpdateOrderClear());
		searchHandler(0);
	}, [saveOrUpdateOk, dispatch, searchHandler])

	const loaderEl = <p ref={loaderRef} style={{ color: hasMoreOrders ? "#ccc" : "#fff" }}>{hasMoreOrders ? t(ETranslation.COMMON_SHOW_MORE_ROWS) : ""}</p>;

	const openOrder = (order: IOrder) => {
		history.push(Routes.withParams(ERoute.ORDER_EDIT, { id: order.id }));
	};

	const newOrder = () => {
		history.push(Routes.withParams(ERoute.ORDER_EDIT, { id: "add" }));
	};

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_ORDERS_PAGE)}</Heading>{" "}
			<Button onClick={newOrder}>{t(ETranslation.COMMON_ADD_NEW)}</Button>
			{(orders && !error) && <OrdersList
				orders={orders}
				openOrder={openOrder}
			/>}
			{((loading || saveOrUpdateLoading) && !error) ? <Spinner /> : loaderEl}
		</Container>
	);
};

export default OrdersPage;
