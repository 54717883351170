import React from "react";
import { ISummary } from "../../../store/actions/summariesActions";
import SummaryRowItem from "./SummaryRowItem";

interface IProps {
	summaries: ISummary[];
	openHandler: (id: string) => void;
}


const SummaryTableBody: React.FC<IProps> = ({ summaries, openHandler }) => {

	return <tbody>
		{summaries.map((summary, id) => (
			<SummaryRowItem
				key={id}
				summary={summary}
				openHandler={openHandler}
			/>
		))}
	</tbody>
}

export default SummaryTableBody;